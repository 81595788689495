import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Styles from './style.module.css'

const NewConfirmLinkAlert = (props) => {
  let default_params = {
    type: 'warning',
    title: '',
    description: '',
    highlightText: '',
    cancelText: 'No, Keep it',
    submitText: 'Yes, Delete it',
    onCloseClick: () => {},
    onSubmit: () => {},
    closeOnClickOutside: true,
    width: '400px',
    footerLink: false
  }
  let data_params = {...default_params, ...props}
  let {
    title, description, closeOnClickOutside, cancelText, submitText, onCloseClick, onSubmit, width, footerLink
  } = data_params
  const handleSubmit = (onClose) => {
    onSubmit();
    onClose();
  }
  return (
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className={Styles.mainWrp} style={{ width: width }}>
            <div className={`${Styles.bodyWrp} `}>
              <span className={Styles.bodyTitle}>{title}</span>
                {
                    description ? <span className={Styles.descriptionText}>{description}</span> : ''
                }
            </div>
            <div className={Styles.footerWrp}>
                <span onClick={() => {handleSubmit(onClose)}} className={`${Styles.button} ${Styles.submitButton}`}>
                 {submitText}
                </span>
              <span onClick={() => { onCloseClick(); onClose();}} className={`${Styles.button} ${Styles.cancelButton}`}>{cancelText}</span>
            </div>
            {footerLink &&
              <div className={`mt-3 ${Styles.footerLinkText}`} >
                <a target='_blank' href={`${footerLink.link}`}>{footerLink.text}</a>
              </div>
            }
          </div>
        );
      },
      closeOnClickOutside: closeOnClickOutside,
      overlayClassName: Styles.overlay
    })
  )
}
export default NewConfirmLinkAlert;