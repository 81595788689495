import React, { Fragment, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import { useIsMounted } from '../../hooks/IsMounted';
import BootstrapTooltip from '../globals/BootstrapTooltip';
import Popup from './Popup';
import Loader from '../globals/Loader';
import "./Menu.css";
import { deleteCustomMenuApi, getUserCustomMenusApi } from '../../api/profileApi';
import { Icon } from '@material-ui/core';
import Utils from '../../helpers/Utils';
import NewConfirmAlert from '../activity/new-alert/NewConfirmAlert';
import { SettingIconList } from '../../constants/SettingIconList';
import { Skeleton } from '@material-ui/lab';

const CustomMenu = (props) => {
    const history = useHistory();
    const isMounted = useIsMounted();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [reset, setReset] = useState(0);
    const [modalVisible, setModalVisible] = useState(false);
    const [dataToEdit, setDataToEdit] = useState(null);

    useEffect(() => {
        if (window.setActiveSidebar) {
            window.setActiveSidebar("setting");
        }
    }, []);

    useEffect(() => {
        loadData();
    }, [reset]);

    const loadData = () => {
        setLoading(true);
        getUserCustomMenusApi({ form_data: { } })
            .then((response) => {
                setData(response.data.data);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const removeData = (removeId) => {
        let newData = data.filter((elem)=>{
            return (elem.id != removeId)
        })
        setData(newData)
    }

    const updateEditData = (updatedItem)=>{
        const newData = data.map(currentItem => {
            return currentItem.id === updatedItem.id ? updatedItem : currentItem;
        })
        setData(newData);
    }

    const deleteItem = (_id) => {
        NewConfirmAlert({
            onSubmit : () => {
                setLoading(true);
                deleteCustomMenuApi({
                    form_data: {
                        id: _id,
                    },
                })
                .then((response) => {
                    removeData(_id)
                    Utils.showNotification(response.data.message, 'success');
                })
                .finally(() => {
                    setLoading(false);
                });
            },
            title: 'Are You Sure?',
            description: 'Do you want to delete this menu?',
            cancelText: 'Cancel',
            submitText: 'Confirm',
            width: '480px'
        });
    };

    const renderData = () => {
        if (loading) {
            let loadingView = [];
            for (let i = 0; i < 5; i++) {
                loadingView.push(
                    <tr key={i}>
                        <td > <Skeleton width="100%" height={"16px"}> </Skeleton></td>
                        <td > <Skeleton width="100%" height={"16px"}> </Skeleton></td>
                        <td > <Skeleton width="100%" height={"16px"}> </Skeleton></td>
                        <td > <Skeleton width="100%" height={"16px"}> </Skeleton></td>
                        <td > <Skeleton width="100%" height={"16px"}> </Skeleton></td>
                        <td > <Skeleton width="100%" height={"16px"}> </Skeleton></td>
                        <td > <Skeleton width="100%" height={"16px"}> </Skeleton></td>
                        <td > <Skeleton width="100%" height={"16px"}> </Skeleton></td>
                        <td > <Skeleton width="100%" height={"16px"}> </Skeleton></td>
                    </tr>    
                );
            }
            return loadingView;
        } else if (!loading && !data.length) {
            return (
                <tr>
                    <td colspan='9'>
                        <div className='text-center p-3' style={{ paddingTop: 50 }}>NO DATA AVAILABLE</div>
                    </td>
                </tr>
            );
        } else if (!loading && data.length) {
            return data.map((item, index) => (
                <tr key={index}>
                    <td>{item.title}</td>
                    <td>{item.link}</td>
                    <td>{(item.link_target==='CURRENT_TAB')?'Current Tab':'New Tab'}</td>
                    <td className='text-center'><Icon style={{fontSize : 24}}>{item.icon}</Icon></td>
                    <td>
                        <div className='p-2 color-border'  style={{backgroundColor : item.background_color}}></div>
                    </td>
                    <td>
                        <div className='p-2  color-border' style={{backgroundColor : item.text_color}}></div>
                    </td>
                    <td className='text-center'>{item.is_iframe}</td>
                    <td>{item.status}</td>
                    <td>
                        <div>
                            <BootstrapTooltip arrow title='Edit Menu'>
                                <a
                                    href='#!'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setDataToEdit(item);
                                        setModalVisible(true);
                                    }}
                                >
                                    <span>
                                        {SettingIconList.edit}
                                    </span>
                                </a>
                            </BootstrapTooltip>
                            <BootstrapTooltip arrow title='Delete Menu'>
                                <a
                                    href='#!'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        deleteItem(item.id);
                                    }}
                                >
                                    {SettingIconList.delete}
                                </a>
                            </BootstrapTooltip>
                        </div>
                    </td>
                </tr>
            ));
        }
    }

    return (
        <Fragment>
            <div className='settings_section'>
              
                <Fragment>
                    <div className='container-fluid mail__inbox mt-1'>
                        <div className='tagBox'>
                            <div className='tag_list'>
                                <h5>Custom Menu </h5>
                            </div>
                            <div className='add_new_user'>
                                <a
                                    href='#!'
                                    className='modal-trigger accent--bg--text--color'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setModalVisible(true);
                                    }}
                                >
                                    <span>
                                        <svg
                                            width='20'
                                            height='20'
                                            viewBox='0 0 20 20'
                                            fill='none'
                                            xmlns='http://www.w3.org/2000/svg'
                                        >
                                            <circle cx='10' cy='10' r='10'></circle>
                                            <path
                                                d='M13.8447 9.9985C13.8447 10.353 13.8139 10.6395 13.4594 10.6395H10.6395V13.4594C10.6395 13.8132 10.353 13.8447 9.9985 13.8447C9.64401 13.8447 9.35747 13.8132 9.35747 13.4594V10.6395H6.5376C6.18375 10.6395 6.15234 10.353 6.15234 9.9985C6.15234 9.64401 6.18375 9.35747 6.5376 9.35747H9.35747V6.5376C9.35747 6.18311 9.64401 6.15234 9.9985 6.15234C10.353 6.15234 10.6395 6.18311 10.6395 6.5376V9.35747H13.4594C13.8139 9.35747 13.8447 9.64401 13.8447 9.9985Z'
                                                className={"accent--fill--color"}
                                            ></path>
                                        </svg>
                                    </span>
                                    Create Custom Menu
                                </a>

                                <Link
                                    className={"modal-trigger accent--bg--text--color"}
                                    to='/user/settings'
                                >
                                    <span>
                                        {SettingIconList.back}
                                    </span>
                                    <span>Back</span>
                                </Link>

                            </div>
                        </div>
                        <div className='details'>

                            <table className='custom-menu-table'>
                                <thead>
                                    <tr className='header'>
                                        <th style={{width: '10%'}}>Title </th>
                                        <th style={{width: '20%'}}>Link</th>
                                        <th  style={{width: '10%'}}> Link target</th>
                                        <th className='text-center' style={{width: '10%'}}>Icon</th>
                                        <th  style={{width: '11%'}}>Background color</th>
                                        <th  style={{width: '10%'}}>Text color</th>
                                        <th className='text-center' style={{width: '12%'}}>Show inside platform</th>
                                        <th  style={{width: '10%'}}>Status</th>
                                        <th  style={{width: '10%'}}>Action</th>
                                    </tr>
                                </thead>
                                <tbody className='tbody_content'>{renderData()}</tbody>
                            </table>
                        </div>
                    </div>
                </Fragment>
             
            </div>
            {modalVisible && (
                <Popup
                    hideModal={(isReset, updateData) => {
                        setDataToEdit(null);
                        setModalVisible(false);
                        if(isReset) {
                            setReset(reset + 1);
                        }
                        if(updateData){
                            updateEditData(updateData)
                        }
                    }}

                    isOpen={modalVisible}
                    title={dataToEdit?'Update Custom Menu':'Create Custom Menu'}
                    data={dataToEdit}
                />
            )}
        </Fragment>
    );
}

export default CustomMenu