import { Box, Grid, Typography } from "@material-ui/core";
import moment from "moment";
import { useEffect } from "react";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PendingMapRequest from "./PendingMapRequest";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import UpdatePackage from "./UpdatePackge";
import NewConfirmAlert from "../activity/new-alert/NewConfirmAlert";
import { cancelGeofarmingApi } from "../../api/profileApi";
import Utils from "../../helpers/Utils";

const PurchasedPackageInformation = (props) => {

    const showpackageInfos=()=>{

        if(props.packageInformation.packageInfo?.is_downgrade === 1 && props.packageInformation.packageInfo?.downgrade_package_id >0){
            window.showNotification('ERROR', 'You already have a downgrade request pending')

        }else{
            props.updateReducerInformations({changePackage: !props.packageInformation.changePackage})
        }
    }

    
    const cancelPackage = () =>{
        // console.log(props.packageInformation.packageInfo)
        NewConfirmAlert({
			onSubmit : () => {
                cancelGeofarmingApi({
                    "form_data": {
                        packageId: props.packageInformation.packageInfo.id
                    }
                })
                    .then(response => {
                        if (response.data.status === 'success') {
                            Utils.showNotification(response.data.message, 'success');
                            window.location.href = '/';
                        }else {
                            Utils.showNotification(response.data.message, 'error');
                        }
                    }).finally(() => {
        
                });
			},
			onCloseClick : () => {
				
			},
			title: 'Are you sure?',
			description: 'Do you want to cancel Geofarming?',
			cancelText: 'No',
			submitText: 'Yes',
			width: '480px'
		});
    }
    return (
        <Box className="mapping__mysale mi_updated">
            <Typography className="mapping__mysale_title">
                {props.packageInformation.changePackage &&
                    <ExitToAppIcon onClick={()=>showpackageInfos()} />
                }
                <div>
                    <span>Geofarming Package already purchased</span>
                    <p>You can change your subscription anytime.</p>
                    {props.packageInformation.packageInfo.is_downgrade !== 1 && !props.packageInformation.changePackage &&
                    <>
                    <span className="package__hide__show__button_v2" onClick={()=>cancelPackage()}>
                        Cancel
                    </span>
                    <span className="package__hide__show__button" onClick={()=>showpackageInfos()}>
                        Change package
                    </span>
                    </>
                    
                    }
                </div>
            </Typography>
            {!props.packageInformation.changePackage ?
            <div className={`mi_row ${props.packageInformation.packageInfo.is_downgrade !== 1 ? 'fullRow': ''}`}> {/* fullRow */}
                <div className="mi_left_col mi_col">
                    <Typography className="mapping__mysale_subtitle mi_purchased_list" style={{'height':'unset'}}>
                    <CheckCircleIcon /> Last purchase amount: <span>&nbsp; {(parseFloat(props.packageInformation.packageInfo.last_purchase_amount)).toFixed(2)}</span>
                    </Typography>
                    {parseFloat(props.packageInformation.packageInfo.last_purchase_amount) > 0 &&
                    <Typography className="mapping__mysale_subtitle mi_purchased_list" style={{'height':'unset'}}>
                    <CheckCircleIcon /> Next recurring date: <span>&nbsp;{moment(props.packageInformation.packageInfo.renew_date).format('MM/DD/YYYY')} </span>
                    </Typography>
                    }
                    <Typography className="mapping__mysale_status mi_purchased_list" style={{'height':'unset'}}>
                    <CheckCircleIcon /> Total Usages limit(Daily) : <span>&nbsp; {props.packageInformation.packageInfo?.usage_limit !== undefined ? props.packageInformation.packageInfo?.usage_limit : props.packageInformation.selectedPackage?.usage_limit} (Currently used: {props.packageInformation.currentUsages})</span>
                    </Typography>
                    <Typography className="mapping__mysale_status mi_purchased_list" style={{'height':'unset'}}>
                    <CheckCircleIcon /> Total members limit: <span>&nbsp;{props.packageInformation.packageInfo?.total_team_user}</span>
                    </Typography>
                    {/* {props.packageInformation.packageInfo.forceLogin && 
                    <Typography className="mapping__mysale_status mi_purchased_list alt">
                    <CheckCircleIcon /> Login into Geofarming: &nbsp;<span style={{cursor: 'pointer'}} onClick={props.handleForceLoginToMapYourSales}>Click here</span>
                    </Typography>
                    } */}
                </div>
                
                {props.packageInformation.packageInfo.is_downgrade === 1 && props.packageInformation.packageInfo.downgrade_package_id > 0 &&
                    <div className="mi_right_col mi_col">
                        <PendingMapRequest packageInformation={props.packageInformation} updateReducerInformations={props.updateReducerInformations} />
                    </div>
                }

            </div>
            :
            <Grid container className="mi_grid_wrapper" spacing={3}>                    
                    {Array.isArray(props.packageInformation.packageList) &&  props.packageInformation.packageList.map((item, index) => {

                        return (
                            item.id !== props.packageInformation.packageInfo.agency_external_approved_package_id ?

                          <>
                            <Grid item sm={4} md={4} lg={4} key={index} >
      
                              <UpdatePackage
                                item={item}
                                key={index}
                                packageInformation={props.packageInformation}
                                onChangePackage={props.onChangePackage}
                                updateReducerInformations={props.updateReducerInformations}
                              />
                            </Grid>
                          </>: null
                        )  
                      })
                       
                }
            </Grid>
            }
      
        </Box>
    )
}
export default PurchasedPackageInformation;