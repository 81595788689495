import { Fragment, useEffect, useState } from "react";
import { useIsMounted } from "../../hooks/IsMounted";
import Loader from "../globals/Loader";
import TablePagination from '@material-ui/core/TablePagination';
import './VirtualNumbers.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { getUserCallRecordingSettingApi, getVirtualNumberList, updateUserCallRecordingSettingApi, virtualNumberCallRecordSetting, virtualNumberUpdateDialerSetting, virtualNumberUpdateForwardSetting } from "../../api/profileApi";
import SwitchC from "../customField/subComponents/Switch";
import ForwardNumberEdit from "./ForwardNumberEdit";
import CallWhisperEdit from "./CallWhisperEdit";
import VoicemailMessageEdit from "./VoicemailMessageEdit";
import CallRecordingDisclosureEdit from "./CallRecordingDisclosureEdit";
import VirtualNumberEdit from "./VirtualNumberEdit";
import { FormControlLabel, MenuItem, Radio, RadioGroup, Select } from "@material-ui/core";
import AddVirtualNumberPopup from "./AddVirtualNumberPopup";
import IncomingCallTimeout from "./IncomingCallTimeout";
import CustomConfirmAlertMod from "../customField/custom-field-v2/helpers/confirm-alert-modified/CustomConfirmAlertMod";
import RegisterShakenStirView from "./RegisterShakenStirView";



const VirtualNumbers = (props) => {

    const isMounted = useIsMounted();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [sortType, setSortType] = useState('asc');
    const [sortBy, setSortBy] = useState('created_at');
    const [debouncedQuery, setDebouncedQuery] = useState('');
    const [query, setQuery] = useState('');
    const [reset, setReset] = useState(0);
    const [addNewModalVisible, setAddNewModalVisible] = useState(false);
    const [callForwarding, setCallForwarding] = useState((props.data.setting && typeof props.data.setting.forward !== 'undefined') ? props.data.setting.forward : '2');
    const [callRecording, setCallRecording] = useState((props.data.call_record_setting && typeof props.data.call_record_setting.value !== 'undefined' && parseInt(props.data.call_record_setting.value) ? 1 : 0));
    const [dialFrom, setDialFrom] = useState((props.data.setting && typeof props.data.setting['dialer-selection'] !== 'undefined') ? props.data.setting['dialer-selection'] : '2');
    const [number, setNumber] = useState((props.data.setting && typeof props.data.setting['dialer-number'] !== 'undefined') ? props.data.setting['dialer-number'] : null);
    const [userCallRecording, setUserCallRecording] = useState("1");
    const [savingUserCallRecording, setSavingUserCallRecording] = useState(false);
    const [callRecordingCharge,setCallRecordingCharge] = useState((props.data.call_record_charge && typeof props.data.call_record_charge !== 'undefined') ? props.data.call_record_charge : 0);
    const [canRegisterShakenStir,setCanRegisterShakenStir] = useState(false);


    useEffect(() => {
        getUserCallRecordSetting()
    }, [])

    useEffect(() => {
        loadData();
    }, [currentPage, reset, perPage, sortType, debouncedQuery]); // eslint-disable-line react-hooks/exhaustive-deps

    // debouncing
    useEffect(() => {
        if (isMounted) {
            const timer = setTimeout(() => {
                setDebouncedQuery(query);
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [query]); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if(isMounted) {
            if (dialFrom === '2' && !number) {
                window.showNotification('ERROR','Please Select a number');
                return false;
            }
    
            let form = {
                "dialer-selection": dialFrom,
                "dialer-number": number
            }
    
            virtualNumberUpdateDialerSetting({
                "form_data": form
            }).then(response => {
                if (response.data.status === 'success') {
                    window.showNotification('SUCCESS', response.data.message);
                } else {
                    window.showNotification('ERROR', response.data.message );
                }
            }).catch(error => {
                window.showNotification('ERROR', 'Something went wrong' );
            })
        }
    },[number,dialFrom]) // eslint-disable-line react-hooks/exhaustive-deps


    const getUserCallRecordSetting = () => {
        getUserCallRecordingSettingApi().then(res => {
            const response = res.data;
            if(response.success === "success"){
                setUserCallRecording(response.message.value)
            }
        }).catch(error =>{
            console.log("Profile module > VirtualNumbers > getUserCallRecordSetting", error)
        })
    }

    const loadData = () => {
        setLoading(true);

        getVirtualNumberList({
            "form_data": {
                page: currentPage + 1,
                per_page: perPage,
                sort_by: sortBy,
                sort_type: sortType,
                query: debouncedQuery
            }
        })
            .then(response => {
                if(response.data.canRegisterShakenStir !== undefined && response.data.canRegisterShakenStir === true){
                    if (props.registrationType === "SOLE_PROPRIETOR"){
                        setCanRegisterShakenStir(false);
                    }else {
                        setCanRegisterShakenStir(true);
                    }
                }
                setData(response.data.data.data);
                setTotal(response.data.data.total);
            }).finally(() => {
                setLoading(false);
            });
    }

    const renderData = () => {
        if (loading) {
            return (
                <tr>
                    <td colspan="5">
                        <div style={{ paddingTop: 50 }}>
                            <Loader />
                        </div>
                    </td>
                </tr>
            )
        } else if (!loading && !data.length) {
            return (
                <tr>
                    <td colspan="5">
                        <div style={{ paddingTop: 50 }}>
                            NO DATA AVAILABLE
                        </div>
                    </td>
                </tr>
            )
        } else if (!loading && data.length) {
            return data.map((item, index) => (
                <tr>
                    <td><VirtualNumberEdit item={item} updateTable={() => setReset(reset+1)}/></td>
                    <td><ForwardNumberEdit item={item} /></td>
                    <td><RegisterShakenStirView item={item} updateTable={() => setReset(reset+1)} canRegisterShakenStir={canRegisterShakenStir} /></td>
                    <td><CallWhisperEdit item={item} /></td>
                    <td><VoicemailMessageEdit item={item} /></td>
                    <td><IncomingCallTimeout item={item} /></td>
                    <td><CallRecordingDisclosureEdit item={item} /></td>
                </tr>
            ))
        }
    }

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    const addNew = (e) => {
        e.preventDefault();

        confirmAlert({
            title: 'Notice:',
            message: `Additional phone numbers are charged at a rate of $${parseFloat(props.data.buyPrice.replace(/,/g, '')).toFixed(2)}/month. Do you wish to continue?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        setAddNewModalVisible(true);
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        return;
                    }
                }
            ],
            overlayClassName : "virtual_number_add_warning_popup"
        })
    }

    const changeCallSetting = (e) => {

        // let value = props.data.call_record_setting && typeof props.data.call_record_setting.value !== 'undefined' && parseInt(props.data.call_record_setting.value) ? 0 : 1;
        let changeValue = callRecording ? 0 : 1;
        CustomConfirmAlertMod({
            onSubmit : () => {
                virtualNumberCallRecordSetting({
                    "form_data": {
                        value: changeValue
                    }
                }).then(response => {
                    if (response.data.status === 'success') {
                        setCallRecording(changeValue);
                        window.showNotification('SUCCESS',response.data.message);
                    } else {
                        window.showNotification('ERROR', response.data.message);
                    }
                }).catch(error => {
                    window.showNotification('ERROR','Something went wrong');
                })
            },
            title: `Would you like to ${(changeValue === 1 ? 'turn on' : 'turn off')} call recording?`,
            description: changeValue === 1 ? ( callRecordingCharge != 0 ? (`You will be charged an extra $${callRecordingCharge} per minute if you enable call recording.`) : '') : 'It will disable call record when make a call to contact.',
            cancelText: 'Cancel',
            submitText: 'Yes, Confirm'
        });
    }

    const updateForwardNumber = () => {

        let newSetting = callForwarding.toString() === '1' ? '2' : '1';
        setCallForwarding(newSetting);

        let form = {
            forward: newSetting
        }

        virtualNumberUpdateForwardSetting({
            "form_data": form
        }).then(response => {
            if (response.data.status === 'success') {
                window.showNotification( 'SUCCESS', response.data.message);
            } else {
                setCallForwarding(newSetting.toString() === '1' ? '2' : '1');
                window.showNotification( 'ERROR', response.data.message );
            }
        }).catch(error => {
            window.showNotification('ERROR', 'Something went wrong' );
        });
    }

    const updateUserCallRecording = () => {
        if(savingUserCallRecording){
            return
        }
        setSavingUserCallRecording(true)
        setUserCallRecording(userCallRecording === "1" ? "0" : "1")
        const previous_value = userCallRecording;
        updateUserCallRecordingSettingApi({
            value: userCallRecording === "1" ? "0" : "1"
        }).then(res => {
            const response = res.data;
            if(response.success === "error"){
                setUserCallRecording(previous_value)
            }
            setSavingUserCallRecording(false)
        }).catch(error => {
            console.log("Profile module > VirtualNumbers > updateUserCallRecording", error)
            setSavingUserCallRecording(false)
        })
    }

    const handleVirtualNumberAddModal = (needToReload = false) => {
        if(needToReload){
            setReset(reset + 1)
        }
        setAddNewModalVisible(false)
    }

    return (
        <Fragment>
            <div className="settings_section mt-0">
                <Fragment>
                    <div className="container-fluid mail__inbox">
                    <div className="dialer_settings_wrapper">
                                <p>
                                    <label>
                                        <h6>Dial From</h6>
                                        <RadioGroup row value={dialFrom} onChange={(e) => setDialFrom(e.target.value)}>
                                            <FormControlLabel value={'1'} control={<Radio />} label="Select before dial" />
                                            <FormControlLabel value={'2'} control={<Radio />} label="Fixed dial number" />
                                        </RadioGroup>
                                    </label>
                                </p>
                                {
                                    dialFrom === '2' && (
                                        <p>
                                            <label>
                                                <p>Select a number</p>
                                                <Select
                                                    className={"dialer-select-box"}
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={number}
                                                    onChange={(e) => setNumber(e.target.value)}
                                                    variant='outlined'
                                                    MenuProps={{
                                                        getContentAnchorEl: null,
                                                        anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "left"
                                                        }
                                                    }}
                                                >
                                                    {
                                                        props.data.setting && typeof props.data.numbers !== 'undefined' && Object.entries(props.data.numbers).map(([key,value],i) => <MenuItem key={i} value={key}>{value}</MenuItem>)
                                                    }
                                                </Select>
                                            </label>
                                        </p>
                                    )
                                }
                            </div>

                            <div className="tag_list">
                                <h5>Phone Numbers</h5>
                            </div>
                        <div className="tagBox">

                            <div className="add_new_user alt d-flex">

                                <div className="modal-trigger mr-2 m-profile-virtual-enable accent--bg--text--color" style={{ backgroundColor: '#F3F4F6' }} >
                                    <span className="">Call Recording Announcement</span>
                                    <SwitchC
                                        status={userCallRecording === '1'}
                                        brandColor={"#f94f72"}
                                        onChange={updateUserCallRecording}
                                    />
                                </div>

                                <div className="modal-trigger mr-2 m-profile-virtual-enable accent--bg--text--color" style={{ backgroundColor: '#F3F4F6' }} >
                                    <span className="">Call Forwarding</span>
                                    <SwitchC
                                        status={callForwarding.toString() === '1'}
                                        brandColor={"#f94f72"}
                                        onChange={updateForwardNumber}
                                    />
                                </div>

                                <div className="modal-trigger mr-2 m-profile-virtual-enable accent--bg--text--color" style={{ backgroundColor: '#F3F4F6' }} >
                                    <span className="">Call Recording</span>
                                    <SwitchC
                                        status={callRecording}
                                        brandColor={"#f94f72"}
                                        onChange={changeCallSetting}
                                    />
                                </div>

                                {
                                    props.data.numberLimit && (

                                        <a href="#!" className="by-additional-phone-number modal-trigger accent--bg--text--color" onClick={addNew}>
                                            <span>
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="10" cy="10" r="10" fill="white"></circle>
                                                    <path d="M13.8447 9.9985C13.8447 10.353 13.8139 10.6395 13.4594 10.6395H10.6395V13.4594C10.6395 13.8132 10.353 13.8447 9.9985 13.8447C9.64401 13.8447 9.35747 13.8132 9.35747 13.4594V10.6395H6.5376C6.18375 10.6395 6.15234 10.353 6.15234 9.9985C6.15234 9.64401 6.18375 9.35747 6.5376 9.35747H9.35747V6.5376C9.35747 6.18311 9.64401 6.15234 9.9985 6.15234C10.353 6.15234 10.6395 6.18311 10.6395 6.5376V9.35747H13.4594C13.8139 9.35747 13.8447 9.64401 13.8447 9.9985Z" className={"accent--fill--color"}></path>
                                                </svg>
                                            </span>Buy additional phone number
                                        </a>
                                    )}
                            </div>

                        </div>
                        <div className="details phone">
                            <div className="items">

                                <a style={{visibility: canRegisterShakenStir === true ? "visible" : "hidden"}} target="_blank" download="" className="image-popup-container"
                                   href="https://s3.us-east-1.amazonaws.com/pypepro/contact/27567256/SRH4WAqAZ5RoKYDk3IduoNTmKPxTQcDM3x5CYYgZ.pdf"
                                   rel="noreferrer">
                                    Shaken STIR, CNAM registration Help Doc
                                </a>

                                <div className="record_search">
                                    <input type="text" placeholder="Search Number" className="search" onChange={(e) => setQuery(e.target.value)} />
                                    <div className="search_icon">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.4351 10.0629H10.7124L10.4563 9.81589C11.3528 8.77301 11.8925 7.4191 11.8925 5.94625C11.8925 2.66209 9.23042 0 5.94625 0C2.66209 0 0 2.66209 0 5.94625C0 9.23042 2.66209 11.8925 5.94625 11.8925C7.4191 11.8925 8.77301 11.3528 9.81589 10.4563L10.0629 10.7124V11.4351L14.6369 16L16 14.6369L11.4351 10.0629ZM5.94625 10.0629C3.66838 10.0629 1.82962 8.22413 1.82962 5.94625C1.82962 3.66838 3.66838 1.82962 5.94625 1.82962C8.22413 1.82962 10.0629 3.66838 10.0629 5.94625C10.0629 8.22413 8.22413 10.0629 5.94625 10.0629Z" fill="#133159" fill-opacity="0.65"></path>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <table>
                                <thead>
                                    <tr className="header">
                                        <th style={{width: '13%'}}>
                                            Number
                                            <svg onClick={() => {
                                                setSortBy('virtual_number');
                                                if (sortType === 'asc') {
                                                    setSortType('desc');
                                                } else {
                                                    setSortType('asc');
                                                }
                                            }}
                                                style={{ cursor: 'pointer', float: 'right' }}
                                                width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 11.5L5.14286 17.5L10.2857 11.5H0Z" fill="#546376"></path>
                                                <path d="M10.2852 6.5L5.1423 0.5L-0.000557899 6.5L10.2852 6.5Z" fill="#546376"></path>
                                            </svg>
                                        </th>
                                        <th style={{width: '12%'}}>
                                            Forward Number
                                            <svg onClick={() => {
                                                setSortBy('forward_number');
                                                if (sortType === 'asc') {
                                                    setSortType('desc');
                                                } else {
                                                    setSortType('asc');
                                                }
                                            }}
                                                style={{ cursor: 'pointer', float: 'right' }}
                                                width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 11.5L5.14286 17.5L10.2857 11.5H0Z" fill="#546376"></path>
                                                <path d="M10.2852 6.5L5.1423 0.5L-0.000557899 6.5L10.2852 6.5Z" fill="#546376"></path>
                                            </svg>
                                        </th>
                                        <th style={{width: '12%'}}>
                                            Shaken/Stir, CNAM
                                        </th >
                                        <th style={{width: '14%'}}>
                                            Call Whisper Message
                                            <svg onClick={() => {
                                                setSortBy('call_whisper_message');
                                                if (sortType === 'asc') {
                                                    setSortType('desc');
                                                } else {
                                                    setSortType('asc');
                                                }
                                            }}
                                                style={{ cursor: 'pointer', float: 'right' }}
                                                width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 11.5L5.14286 17.5L10.2857 11.5H0Z" fill="#546376"></path>
                                                <path d="M10.2852 6.5L5.1423 0.5L-0.000557899 6.5L10.2852 6.5Z" fill="#546376"></path>
                                            </svg>
                                        </th>
                                        <th style={{width: '16%'}}>
                                            Voicemail Message
                                            <svg onClick={() => {
                                                setSortBy('no_answer_whisper_message');
                                                if (sortType === 'asc') {
                                                    setSortType('desc');
                                                } else {
                                                    setSortType('asc');
                                                }
                                            }}
                                                style={{ cursor: 'pointer', float: 'right' }}
                                                width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 11.5L5.14286 17.5L10.2857 11.5H0Z" fill="#546376"></path>
                                                <path d="M10.2852 6.5L5.1423 0.5L-0.000557899 6.5L10.2852 6.5Z" fill="#546376"></path>
                                            </svg>
                                        </th>
                                        <th style={{width: '17%'}}>
                                            Incoming Call Timeout
                                        </th>
                                        <th style={{width: '17%'}}>
                                            Call Recording Disclosure
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="tbody_content">
                                    {renderData()}
                                </tbody>
                            </table>
                        </div>
                        <div className="mt-3">
                            <TablePagination
                                component="div"
                                count={total}
                                page={currentPage}
                                onChangePage={handleChangePage}
                                rowsPerPage={perPage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                             onPageChange={()=>{}}/>
                        </div>
                    </div>
                </Fragment>
            </div>
            {
                addNewModalVisible && (
                    <AddVirtualNumberPopup
                        isOpen={addNewModalVisible}
                        handleModal={handleVirtualNumberAddModal}
                    />
                )
            }
        </Fragment>
    )
}


export default VirtualNumbers;