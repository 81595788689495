import {useState, useEffect, useRef} from "react";
import {PROFILE_TAB} from "../../../constants/CoreConstants";
import TextInput from "../../globals/TextInput";
import PhoneInput from "../../globals/PhoneInput";
import TextAreaInput from "../../globals/TextAreaInput";
import SimpleReactValidator from "simple-react-validator";
import {connect} from "react-redux";
import {updateUser} from "../../../actions/profileAction";
import { makeStyles, Tooltip } from "@material-ui/core";
import SwitchC from "../../customField/subComponents/Switch";
import Utils from "../../../helpers/Utils";
import CustomConfirmAlert from "../../customField/custom-field-v2/helpers/confirm-alert/CustomConfirmAlert";

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      backgroundColor: theme.palette.common.black,
    },
}));
  
function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} {...props} />;
}


const PersonalInfo = (props) => {
    let userInitialValue = {
        full_name:'',
        email:'',
        phone:'',
        address:'',
        appointment_url:'',
        show_appointment_url:1
    };

    const [user, setUser] = useState(userInitialValue);
    const [loading,setLoading] = useState(true);
    const [, forceUpdate] = useState();

    const validator = useRef(new SimpleReactValidator({
        className: 'text-danger',
        autoForceUpdate: {forceUpdate: forceUpdate
    }}));

    const callback = (name,value) => {
        // const { name, value } = e.target;
        setUser(prevState => ({
            ...prevState,
            [name]: value
        }));
    };


    useEffect(() => {
        loadData();
    }, [props.user]) // eslint-disable-line react-hooks/exhaustive-deps


    const loadData = () => {
        //make request
        if(props.user) {
            setUser(prevState => ({
                ...prevState,
                full_name: props.user.full_name,
                email: props.user.email,
                phone: props.user.phone,
                address: props.user.address,
                appointment_url: props.user.appointment_url,
                show_appointment_url: props.user.show_appointment_url,
            }));
        }
        setLoading(false);
    }

    const handleSave = (allowNonUsNumber=0) => {
        setLoading(true);
        const formValid = validator.current.allValid();
        if (formValid) {
            user['appointmentUrlShow'] = user.show_appointment_url ? 1 : 0;
            //make request
            props.updateUser({
                "form_data": {
                    'columns':['id','full_name','email','phone','address','appointment_url','show_appointment_url'],
                    allowNonUsNumber: allowNonUsNumber,
                    ...user
                }
            },(res) => {
                if(res.success) {
                    window.showNotification("SUCCESS", res.message)
                } else {
                    let message = '';
                    if (res.status === 'validation-error') {
                        message = res.message[Object.keys(res.message)[0]][0];
                    } else {
                        message = res.message;
                    }
                    window.showNotification("ERROR", message)
                }

            });
            setLoading(false);
        }else{
            validator.current.showMessages();
            forceUpdate(true);
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        if(!Utils.validateUsMobileNumber(user.phone)){
            CustomConfirmAlert({
                onSubmit : () => {
                    handleSave(1);
                },
                title: 'Important Information!',
                description: 'The phone number you have entered is not a US number. If you continue with this number, the user will not receive any kind of SMS notifications.',
                cancelText: 'Cancel',
                submitText: 'Yes, Proceed with this'
            });
            return;
        }

        handleSave();
    };

    return (
        <div id="personal__info" className={"profile_right_item"+(parseInt(props.active_tab) === PROFILE_TAB.PROFILE_INFO ? " active" : " d-none")}>
            <form method={'post'} onSubmit={(event) => handleSubmit(event)} className="profile__right__side__wr">
                <div className="accent--bg--text--color p-4 row d-flex">
                    <h6 className="m-0">Personal Info </h6>
                    {props.user && props.user.api_key &&
                        <h6 className="api-key-text">Api Token : &nbsp; {props.user.api_key}
                        </h6>
                    }
                {/* </div> */}
                </div>
                <div className="profile__right__side__single__container">
                    <div className="row profile__right__side__single">
                        <div className="">
                            <p className="profile__right__side__single__p">Full Name <span className="required__field">*</span></p>
                        </div>
                        <div className="">
                            <TextInput
                                fieldName    = {'full_name'}
                                callBack     = {callback}
                                value        = {user.full_name}
                                fieldType    = {'text'}
                                placeHolder  = "Enter your name"
                                extraClassName = ""
                                required = {true}
                            />
                            {validator.current.message('full_name', user.full_name, 'required|min:2|max:120')}
                        </div>
                    </div>
                    <div className="row profile__right__side__single">
                        <div className="">
                            <p className="profile__right__side__single__p">Email 
                                {/* <span className="required__field">*</span> */}
                            </p>
                        </div>
                        <div className="">
                            <input className={"personal_info_text_field"}
                                value={user.email}
                                required={true}
                                disabled/>
                        </div>
                    </div>
                    <div className="profile__right__side__single profile__right__side__single__phone">
                        <div className="">
                            <p className="profile__right__side__single__p">Phone <span className="required__field">*</span></p>
                        </div>
                        <PhoneInput
                            fieldName    = {'phone'}
                            callBack     = {callback}
                            value        = {user.phone}
                            placeHolder  = "2202210000"
                            extraClassName = "flag_phone_field"
                            required = {true}
                        />
                        <div className="row profile__right__side__single">
                            <div className="">

                            </div>
                            <div className="">
                                {validator.current.message('phone', user.phone, 'required|min:10|max:20')}
                            </div>
                        </div>
                    </div>
                    <div className="row profile__right__side__single">
                        <div className="">
                            <p className="profile__right__side__single__p">Address</p>
                        </div>
                        <div className="">
                            <TextAreaInput
                                fieldName    = {'address'}
                                callBack     = {callback}
                                value        = {user.address}
                                placeHolder  = "Street address"
                                extraClassName = "pt-3"
                            />
                        </div>
                    </div>
                    <div className="row profile__right__side__single">
                        <div className="">
                            <p className="profile__right__side__single__p">Appointment Url</p>
                        </div>
                        <div className="">
                            <TextInput
                                fieldName    = {'appointment_url'}
                                callBack     = {callback}
                                value        = {user.appointment_url}
                                fieldType    = {'text'}
                                placeHolder  = "https://www.example.com"
                                // placeHolder  = {window.location.protocol+"//"+window.location.hostname}
                                extraClassName = ""
                            />
                            {validator.current.message('appointment_url', user.appointment_url, 'url')}
                        </div>
                    </div>
                    <div className="row profile__right__side__single">
                        {/* <div className="col l3 m4">
                        </div> */}
                        <div className="col l12 m12 pl-0 d-flex align-items-center">
                            <p className="show-appointemnet-text">Show appointment url in email body?
                                <BootstrapTooltip title="Turn it ON if you want to show the appointment url by default. You can also use appointment url from personalize field.">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M10 0C4.486 0 0 4.48606 0 10.0001C0 15.5141 4.486 20 10 20C15.514 20 20 15.5141 20 10.0001C20 4.48606 15.514 0 10 0ZM10 18.1818C5.48848 18.1818 1.81818 14.5115 1.81818 10.0001C1.81818 5.48861 5.48848 1.81818 10 1.81818C14.5115 1.81818 18.1818 5.48861 18.1818 10.0001C18.1818 14.5115 14.5115 18.1818 10 18.1818Z"
                                            fill="#E5E8EF"/>
                                        <path
                                            d="M10.0009 4.24219C9.3327 4.24219 8.78906 4.78619 8.78906 5.45485C8.78906 6.12292 9.3327 6.66643 10.0009 6.66643C10.6692 6.66643 11.2128 6.12292 11.2128 5.45485C11.2128 4.78619 10.6692 4.24219 10.0009 4.24219Z"
                                            fill="#E5E8EF"/>
                                        <path
                                            d="M9.99893 8.48438C9.49687 8.48438 9.08984 8.89141 9.08984 9.39347V14.848C9.08984 15.3501 9.49687 15.7571 9.99893 15.7571C10.501 15.7571 10.908 15.3501 10.908 14.848V9.39347C10.908 8.89141 10.501 8.48438 9.99893 8.48438Z"
                                            fill="#E5E8EF"/>
                                    </svg>
                                </BootstrapTooltip>
                            </p>
                            <div className="running_switch">
                                <div id="MainContainer">
                                    <div className="demo" style={{marginLeft: 10}}>
                                        <SwitchC 
                                            status={user.show_appointment_url} 
                                            brandColor={"#f94f72"} 
                                            onChange={(e) => callback('show_appointment_url',!user.show_appointment_url)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                 </div>
                <div className="profile_right_footer">
                    <div className="row">
                        <div className="col l8 d-flex align-items-center">
                            <button disabled={loading} type={'submit'} className="mt-3 profile_right_footer__btn__wr p-2 d-inline-flex justify-content-center align-items-center accent--bg--text--color">
                                        <span
                                            className="mr-2 d-inline-flex justify-content-center align-items-center">
                                            <i className="material-icons">
                                                save
                                            </i>
                                        </span>
                                Save Basic Information
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}
const MapStateToProps = (state) => {
    return {
        user: state.profileReducer.user,
        response: state.profileReducer.response
    };
}
const MapDispatchToProps = (dispatch) => {
    return {
        updateUser: (params,callBack) => dispatch(updateUser(params,callBack))
    }
}
const PersonalInfoTab = connect(MapStateToProps,MapDispatchToProps)(PersonalInfo);
export default PersonalInfoTab;