import { useEffect, useState } from "react";
import { changeNotificationStatus, getNotifications, removeNotification } from "../../api/NotificationsApi";
import { confirmAlert } from "react-confirm-alert";
import Errorbox from "./Errorbox";
import Loader from "../globals/Loader";
import Notification from "./Notification";

const UnseenNotifications = () => {

    const [page,setPage] = useState(1);
    const [notifications,setNotifications] = useState([]);
    const [hideLoadMore,setHideLoadMore] = useState(false);
    const [loading,setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        loadData();
    },[]); // eslint-disable-line react-hooks/exhaustive-deps

    const statusChange = (id,index) => {
        changeNotificationStatus({
            notification_id: id,
            seen: 0
        }).then(res => {
            let notificationArr = [...notifications];
            notificationArr.splice(index, 1);
            setNotifications(notificationArr);
            if(notificationArr.length < 10) {
                setHideLoadMore(true);
            }
            window.showNotification("SUCCESS", "Notification marked as seen")
        });
    }

    const loadData = () => {
        getNotifications({
            type : 0,
            page : page
        }).then(res => {
            let data = res.data.data.notifications.data;
            if(data.length < 10) {
                setHideLoadMore(true);
            }
            setNotifications([...notifications,...data]);
            setPage(page+1);
            setLoading(false);
        });
    }

    const deleteNotification = (id,index) => {
        confirmAlert({
            title: 'Do you want to delete this?',
            message: 'Are you sure to delete this notification?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        removeNotification({
                            id : id
                        }).then(res => {
                            let notificationArr = [...notifications];
                            notificationArr.splice(index, 1);
                            setNotifications(notificationArr);
                            window.showNotification("SUCCESS", res.data.message)
                        });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {return}
                }
            ]
          });
    }

    const renderNotifications = () => {

        if(loading) {
            return <Loader/>
        }

        if(notifications.length === 0) {
            return <Errorbox/>
        }

        return notifications.map((notification,index) => {
            return <Notification
                key={notification.id} 
                notification={notification} 
                index={index}
                statusChange={statusChange}
                deleteNotification={deleteNotification}
            />
        })
    }

    return ( 
        <div class="tabs_list_items">
            <div class="tab-notifications-items awesome__scroll_bar">
                {renderNotifications()}
            </div>

            {
                !hideLoadMore && 
                <div class="load">
                    <a href="#!" onClick={e => { e.preventDefault(); loadData() }}>load more</a>
                </div>
            }
        </div>
     );
}
 
export default UnseenNotifications;