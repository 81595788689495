import { useState } from 'react';
import { FieldTypes } from '../helpers/Constants';
import { AddIcon, CloseIcon } from '../helpers/IconList';
import { useCFContext } from '../reducer/CustomProvider';
import {addCustomFieldsV2Api} from '../../../../api/CustomFieldApi';
import Styles from './style.module.css'
import {handleSelectFolder, handleStoreFields, handleStoreFolders} from "../reducer/Actions";
import {updateLocalStorage} from "../CustomFieldV2App";
const AddCustomField = ({
  title="Add New Custom Field",
  onClose=() => {}
}) => {
  const {state, dispatch} = useCFContext();
  const [states, setStates] = useState({
    adding: false,
    field_type: null,
    field_name: '',
    field_value: '',
    group_name: state.selectedFolder,
    new_group: ''
  });
  const handleFieldType = (type) => {
    setStates({...states, field_type: type})
  }
  const handleFieldName = (e) => {
    setStates({...states, field_name: e.target.value})
  }
  const handleFieldValue = (e) => {
    setStates({...states, field_value: e.target.value})
  }
  const handleGroupName = (name) => {
    setStates({...states, group_name: name, new_group: ''})
  }
  const handleNewGroupName = (e) => {
    setStates({...states, group_name: null, new_group: e.target.value})
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    if(states.adding){
      return
    }

    const validationCheck = handleFormValidation(states, state);
    if(validationCheck !== ""){
      window.showNotification('warning', validationCheck);
      setStates({...states, adding: false})
      return;
    }

    try {
      setStates({...states, adding: true})
      addCustomFieldsV2Api(states)
      .then((res)=>{
        let data = res.data;
        if (data.status === 'success'){
          window.showNotification('success', data.message);
          if (!state.folders[0] || state.folders.every((folder)=>(folder.group_name.trim() !== data.data.group_name.trim()))){
            dispatch(handleStoreFolders([ ...state.folders, { group_name: data.data.group_name } ]));
          }

          if ((state.selectedFolder && state.selectedFolder.toLowerCase()) !== data.data.group_name.toLowerCase()){
            dispatch(handleSelectFolder(data.data.group_name));
          }

          if (state.folders.some((folder)=>(folder.group_name === data.data.group_name))){
            dispatch(handleStoreFields([...state.fields, data.data]))
          }
          updateLocalStorage()
          onClose();
        }else {
          let message = "";
          if (typeof data.message === 'object'){
            Object.values(data.message).forEach((mes)=>{
              message += mes;
            });
          }else {
            message = data.message;
          }
          window.showNotification('warning', message);
        }
        setStates({...states, adding: false});
      }).catch((err)=>{
        setStates({...states, adding: false});
      })
    }catch (err){setStates({...states, adding: false});}
  }
  const renderFieldTypes = () => {
    const view = [];
    FieldTypes.forEach((item, index) => {
      view.push(
        <span onClick={() => handleFieldType(item.value)} key={index} className={`${Styles.fieldTypeWrp} ${states.field_type === item.value? Styles.selected:''}`}>
          {item.title}
          {item.new && <small className={Styles.new}>new</small>}
        </span>
      );
    })
    return view;
  }
  const renderFolderGroup = () => {
    const view = [];
    state.folders.forEach((item, index) => {
      view.push(
        <span onClick={() => handleGroupName(item.group_name)} key={index} className={`${Styles.fieldTypeWrp} ${states.group_name === item.group_name ? Styles.selected : ''}`}>{item.group_name}</span>
      )
    })
    return view;
  }
  return (
    <div className={Styles.mainWrp}>
      <div className={Styles.headerWrp}>
        <span className={Styles.title}>{title}</span>
        <span className={Styles.closeButton} onClick={onClose}><CloseIcon /></span>
      </div>
      <div className={`${Styles.bodyWrp} cf_scroll_bar`} >
        <div className={Styles.inputWrp}>
          <span className={Styles.lebelText}>What kind of field would you like to add?</span>
          <div className={Styles.contentBlock}>{renderFieldTypes()}</div>
        </div>

        <div className={Styles.inputWrp}>
          <span className={Styles.lebelText}>Field Name <code style={{color:'red'}}>*</code></span>
          <div className={Styles.inputFieldWrp}>
            <input onChange={handleFieldName} value={states.field_name} className={Styles.inputField} placeholder="Enter field name" />
          </div>
        </div>

        {/* for value: check box, radio button, drop down */}
        {(states.field_type === 5 || states.field_type === 6 || states.field_type === 7) &&
        <div className={Styles.inputWrp}>
          <span className={Styles.lebelText}>Set value <code style={{color:'red'}}>*</code></span>
          <div className={Styles.inputFieldWrp}>
            <input onChange={handleFieldValue} value={states.field_value} className={Styles.inputField} placeholder="Enter field value" />
            <span className={Styles.hintText}>Multiple values separated by <code>,</code> sign. For example: value1 , value2 , value3</span>
          </div>
        </div>
        }

        <div className={Styles.inputWrp}>
          <span className={Styles.lebelText}>Select a folder group</span>
          <div className={Styles.contentBlock}>{renderFolderGroup()}</div>
        </div>

        <div className={Styles.inputWrp}>
          {state.folders.length > 0 &&
          <span className={Styles.lebelText}><small>OR</small> Create A New Folder</span>
          }
          <div className={Styles.inputFieldWrp}>
            <input onChange={handleNewGroupName} value={states.new_group} className={Styles.inputField} placeholder="Enter folder name" />
          </div>
        </div>
      </div>

      <div className={Styles.footerWrp}>
        <div className={Styles.buttonWrp}>
          <div className={Styles.button} onClick={handleSubmit}>
            <span className={Styles.submitIcon}><AddIcon/> </span>
            <span className={Styles.submitText}>{states.adding ? 'Adding Field...' :'Add Field'}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AddCustomField;
const handleFormValidation = (states, state) => {
  if(states.field_type === null){
    return "Please select a field type !";
  }
  if(states.field_name.trim() === ''){
    return "Please enter your custom field name !";
  }
  if(states.field_type === 5 || states.field_type === 6 || states.field_type === 7){
    if(states.field_value.trim() === ''){
      return "Please enter your custom field value !";
    }
  }
  if(states.group_name === null && states.new_group.trim() === ""){
    return "Please select a folder or enter new folder name !";
  }

  if(state.folders.some((folder)=>(folder.group_name === states.new_group))){
    return "Folder name already exist !";
  }

  return ""
}