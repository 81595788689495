import React,{ useState } from 'react'
import Switch from "react-switch";


const UnChackedIcon = () =>{
  return(
    <div className="checkoutIcon__off"
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        fontSize: 16,
        color: "#F2994A",
        padidingTop:'3px'
      }}>
      Off
    </div>
    )
}
const CheckedIcon = () =>{
  return(
    <div className="checkoutIcon__on"
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        height: "100%",
        fontSize: 16,
        color: "#27AE60",
        padidingTop:'3px'
      }}>
     On
    </div>
    )
}

const SwitchC = ({status}) => {
  const [checked, setChecked] = useState(status);
  const handleChange = () => {
    setChecked(!checked)
  };
  return (
      <>
        <Switch className={checked?'active__switch':'Unactive__switch'}
        onChange={handleChange} 
        checked={checked} 
        uncheckedIcon={<UnChackedIcon/>}
        checkedIcon={<CheckedIcon/>}
        boxShadow='0 0 0px 0px #3bf'
        activeBoxShadow='0 0 0px 0px #3bf'
        onHandleColor='#27AE60'
        offHandleColor='#F2994A'
        onColor="#27AE60"
        offColor="#F2994A"
        height="24"
        width="55"
        />
      </>
  )
}

export default SwitchC

