import React from "react";
import { Box, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import useBoolean from "../../../hooks/useBoolean";
import BasicModalWithHeader from "../common/modal/BasicModalWithHeader";
import AddOrEditReminder from "./AddOrEditReminder";
import NewConfirmAlert from "../../activity/new-alert/NewConfirmAlert";
import { TYPE } from "../../../constants/reminderConstant";
import { deleteReminderInvoice } from "../../../api/InvoiceApi";

const ReminderActions = ({ reminderType, reminderInfo, onRenderCallback, systemDefaultInfo }) => {
  const {
    value: openModal,
    setFalse: onCloseModal,
    setTrue: onOpenModal,
  } = useBoolean(false);

  const handleDelete = () => {
    NewConfirmAlert({
      onSubmit: () =>
        deleteReminderInvoice({ id: reminderInfo.id, type: reminderType })
          .then((res) => {
            res = res.data;
            if (res.success) {
              window.showNotification("SUCCESS", res.message);
              onRenderCallback();
              onCloseModal();
            } else {
              window.showNotification(
                "ERROR",
                res.message || "Error occurred while deleting data"
              );
            }
          })
          .catch((error) => {
            window.showNotification(
              "Error occurred while deleting data",
              "error"
            );
          }),
      title: "Are You Sure?",
      description: "Do you want to delete this information?",
      cancelText: "Cancel",
      submitText: "Confirm",
      width: "480px",
    });
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: 10,
        }}
      >
        <IconButton size="small" onClick={onOpenModal}>
          <EditIcon style={{ fontSize: 20 }} />
        </IconButton>
        <IconButton size="small" onClick={handleDelete}>
          <DeleteIcon style={{ color: "#FF264A", fontSize: 20 }} />
        </IconButton>
      </Box>

      <BasicModalWithHeader
        title={
          reminderType === TYPE.EMAIL
            ? "Edit Email Reminder"
            : "Edit Text Message Reminder"
        }
        open={openModal}
        onClose={onCloseModal}
      >
        <AddOrEditReminder
          isEdit={true}
          reminderInfo={reminderInfo}
          onClose={onCloseModal}
          type={reminderType}
          onRenderCallback={onRenderCallback}
          systemDefaultInfo={systemDefaultInfo}
        />
      </BasicModalWithHeader>
    </>
  );
};

export default ReminderActions;
