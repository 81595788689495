import * as ACTION from "../constants/leadSourceActionTypes";

const initialState = {
    show_modal: false,
    edit: false,
    edit_data: null,
    search_text: '',
    need_to_reload: false,
    total_records: 0,
    total_pages: 0,
    current_page: 1,
    per_page: 10,
    show_delete_modal: false,
    delete_data: null,
    isTransfer: true
};

const leadSourceReducer = (state = initialState, action) => {
    const {type, payload} = action 
    switch (type) {
        case ACTION.SHOW_LEAD_SOURCE_MODAL:
            return {...state, show_modal: payload}

        case ACTION.TOGGLE_LEAD_SOURCE_EDIT:
            return {...state, edit: payload.editFlag, edit_data: payload.editData}

        case ACTION.LEAD_SOURCE_SEARCH:
            return {...state, search_text: payload}

        case ACTION.LEAD_SOURCE_RELOAD_FLAG:
            return {...state, need_to_reload: payload}

        case ACTION.LEAD_SOURCE_PAGINATION:
            return {...state, 
                total_records: payload.totalRecords !== undefined ? payload.totalRecords : state.total_records, 
                current_page: payload.currentPage !== undefined ? payload.currentPage : state.current_page, 
                per_page: payload.perPage !== undefined ? payload.perPage : state.per_page,
                total_pages: payload.totalPages !== undefined ? payload.totalPages : state.total_pages
            }

        case ACTION.SHOW_LEAD_SOURCE_DELETE_MODAL:
            return {...state, 
                show_delete_modal: payload.deleteFlag, 
                delete_data: payload.deleteData,
                isTransfer: payload.isTransfer
            }

        default:
            return state;
    }
}

export default leadSourceReducer;
