import * as ACTION from "../constants/leadSourceActionTypes";

export const addLeadSource = (payload, callback) => ({
    type: ACTION.ADD_LEAD_SOURCE,
    payload: {payload, callback}
});

export const editLeadSource = (payload, callback) => ({
    type: ACTION.EDIT_LEAD_SOURCE,
    payload: {payload, callback}
});

export const deleteLeadSource = (payload, callback) => ({
    type: ACTION.DELETE_LEAD_SOURCE,
    payload: {payload, callback}
});

export const getLeadSourceByPagination = (payload, callback) => ({
    type: ACTION.GET_LEAD_SOURCE_LIST_BY_PAGINATE,
    payload: {payload, callback}
});

export const getAllLeadSource = (payload, callback) => ({
    type: ACTION.GET_LEAD_SOURCE_LIST_ALL,
    payload: {payload, callback}
});

/* reducers */
export const _handleLeadSourceModal = (payload) => ({
    type: ACTION.SHOW_LEAD_SOURCE_MODAL,
    payload: payload
});

export const _handleLeadSourceEdit = (payload) => ({
    type: ACTION.TOGGLE_LEAD_SOURCE_EDIT,
    payload: payload
});

export const _handleLeadSourceSearch = (payload) => ({
    type: ACTION.LEAD_SOURCE_SEARCH,
    payload: payload
});

export const _handleLeadSourceReload = (payload) => ({
    type: ACTION.LEAD_SOURCE_RELOAD_FLAG,
    payload: payload
});

export const _handleLeadSourcePagination = (payload) => ({
    type: ACTION.LEAD_SOURCE_PAGINATION,
    payload: payload
});

export const _handleDeleteLeadSourceModal = (payload) => ({
    type: ACTION.SHOW_LEAD_SOURCE_DELETE_MODAL,
    payload: payload
});