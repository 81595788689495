import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {Edit, Delete} from '@material-ui/icons';
import { createPersonalizedFieldData, deletePersonalizedTag, loadUserPersonalizedData, reloadPersonalizedTag, updatePersonalizedFieldValue } from '../../../actions/CustomFieldAction';
import { IconList } from '../../../constants/IconList';
import ClearIcon from '@material-ui/icons/Clear';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import { confirmAlert } from 'react-confirm-alert';

const mapStateToProps = state => ({
  reloadPersonalizedFlag: state.profileReducer.reloadPersonalizedFlag,
});

const mapDispatchToProps = dispatch => ({
  loadUserPersonalizedData: (params, callback) => dispatch(loadUserPersonalizedData(params, callback)),
  createPersonalizedFieldData: (params, callback) => dispatch(createPersonalizedFieldData(params, callback)),
  updatePersonalizedFieldValue: (params, callback) => dispatch(updatePersonalizedFieldValue(params, callback)),
  deletePersonalizedTag: (params, callback) => dispatch(deletePersonalizedTag(params, callback)),
  reloadPersonalizedTag: (params, callback) => dispatch(reloadPersonalizedTag(params, callback)),
})

const PersonilizTableApp = (props) => {

  const [data, setData] = useState([])

  useEffect(() => {
    props.loadUserPersonalizedData({}, (response) => {
      setData(response)
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(props.reloadPersonalizedFlag){
      props.reloadPersonalizedTag({status : false})
      props.loadUserPersonalizedData({}, (response) => {
        setData(response)
      })
    }
  }, [props.reloadPersonalizedFlag]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <TableContainer>
      <Table className="personiliz__table">
        <TableHead>
          <TableRow>
            <TableCell className="personiliz__th1 table__thead__th">
              <span className="label__name1">Filed Label</span> 
              <span className="lable__icon1">{IconList.info}</span>
            </TableCell>
            <TableCell className="personiliz__th2 table__thead__th">Personalize Value	</TableCell>
            <TableCell className="personiliz__th3 table__thead__th">Personalize Tag</TableCell>
            <TableCell className="personiliz__th4 table__thead__th">Action</TableCell>
          </TableRow>
        </TableHead>

         <TableBody>
           {data.map((item, index) => {
             return <EachRow item={item} key={index} />
           })}
         </TableBody>
       
      </Table>
     </TableContainer>
  )
}
const PersonilizTable = connect(mapStateToProps, mapDispatchToProps)(PersonilizTableApp);
export default PersonilizTable;


const EachRowApp = props => {
  const {item} = props
  const [edit, setEdit] = useState(false)


  const EditItem = (value) =>{
    setEdit(value)
  }

  const DeleteItem = () =>{
    confirmAlert({
      title: 'Do you want to delete this?',
      message: 'Are you sure to delete this personalized tag?',
      buttons: [
          {
              label: 'Yes',
              onClick: () => {
                props.deletePersonalizedTag({fieldId : item.id}, (response) => {
                  if (response.status === 'success') {
                    window.showNotification("SUCCESS", response.html)
                    props.reloadPersonalizedTag({status : true})
                    try{
                        let local_data = localStorage.getItem("global__custom__field__data");
                        if(local_data){
                            localStorage.removeItem("global__custom__field__data")
                        }
                    }
                    catch(error){
                        console.log(error)
                    }
                  } else if (response.status === 'error') {
                    window.showNotification("ERROR", response.html)
                  }
                })
              }
          },
          {
            label: 'No',
            onClick: () => {return}
          }
      ]
    });
  }

  const renderTitle = () => {
    if(edit){
      return(
        <FormControl className="custom__datafeild__edit">
          <Input disableUnderline={true}  defaultValue={item.title} placeholder="Field name" onKeyPress={handleTitleChange}/>
        </FormControl>
      )
    }
    else{
      if(item.title === ''){
        return(
          <span style={{color: 'red'}}>Field Name</span>
        )
      }
      return(item.title)
    }
  }

  const renderValue = () => {
    if(edit){
      return(
        <FormControl className="custom__datafeild__edit">
          <Input disableUnderline={true}  defaultValue={item.field_value} placeholder="Field value" onKeyPress={handleValueChange}/>
        </FormControl>
      )
    }
    else{
      if(item.field_value === ''){
        return(
          <span style={{color: 'red'}}>Field value</span>
        )
      }
      return(item.field_value)
    }
  }

  const handleTitleChange = (event) => {
    var code = (event.keyCode ? event.keyCode : event.which);
    if(code === 13) {
      if(event.target.value === '')return
      props.createPersonalizedFieldData({
        fieldId: "personalized-field-name-"+item.id,
        fieldName: event.target.value
      }, (res) => {
        item['title'] = event.target.value
        item['personalize_tag'] = `[[${event.target.value}]]`
        setEdit(false)
        try{
            let local_data = localStorage.getItem("global__custom__field__data");
            if(local_data){
                localStorage.removeItem("global__custom__field__data")
            }
        }
        catch(error){
            console.log(error)
        }
      })
    }

    /* 
    create-personalized-field-tag
    fieldId: personalized-field-name-1
fieldName: klmkk
    
    
    */
  }

  const handleValueChange = (event) => {
    var code = (event.keyCode ? event.keyCode : event.which);
    if(code === 13) {
      if(event.target.value === '')return
      props.updatePersonalizedFieldValue({
        ID: item.id,
        fieldValue: event.target.value
      }, (res) => {
        item['field_value'] = event.target.value
        setEdit(false)
        try{
            let local_data = localStorage.getItem("global__custom__field__data");
            if(local_data){
                localStorage.removeItem("global__custom__field__data")
            }
        }
        catch(error){
            console.log(error)
        }
      })
    }
  }

  return(
    <TableRow key={item.id}>
      <TableCell className="table__body__td">{renderTitle()}</TableCell>
      <TableCell className="table__body__td">{renderValue()}</TableCell>
      <TableCell className="table__body__td">{item.personalize_tag}</TableCell>
      <TableCell className="editDelete__wr table__body__td">
        {edit ? 
          <div className="edit__button" onClick={()=>EditItem(false)}><ClearIcon color='error' /></div>
          :
          <div className="edit__button" onClick={()=>EditItem(true)}><Edit>Edit</Edit> <span>Edit</span></div>
        }
        <div className="delete__button" onClick={DeleteItem}><Delete>Delete</Delete><span>Delete</span></div>
      </TableCell>
    </TableRow>
  )
}
export const EachRow = connect(mapStateToProps, mapDispatchToProps)(EachRowApp);