import {getCookie, getCookieLogin} from "./Cookie";

/**
 * Handle error exception
 * 
 * @param Error error 
 * @param Function/null callback 
 */
const handleException = (error, callback = null) => {
    console.error(error);

    // to do
}

/**
 * Display notification
 *
 * @param string message
 * @param string type
 * @param string/null title
 */
const showNotification = (message = 'Something went wrong', type = 'error') => {
    window.showNotification(type, message);
};
  

const generateTimezoneList = (tzone = null) => {
    let timezone =  [
        {'America/New_York': 'Eastern Time'},
        {'America/Chicago': 'Central Time'},
        {'America/Denver': 'Mountain Time'},
        {'America/Phoenix': 'Arizona Time'},
        {'America/Los_Angeles': 'Pacific Time'},
        {'America/Anchorage': 'Alaska Time'},
        {'Pacific/Honolulu': 'Hawaii Time'},

    ];

    if(tzone){
        try {
            const found = timezone.find(element => Object.getOwnPropertyNames(element)[0] === tzone);
            return found[Object.getOwnPropertyNames(found)];
        } catch (ex) {
            console.log(ex);
            return '';
        }
    }

    return timezone;
}

/**
 * Function name: getAccountData
 * This function will provide
 * AuthUserAgencyName
 * packageType
 * packageType
 * starterPack
 * packageType
 * starterProPack
 * packageType
 * standardPack
 * packageType
 * starterPack
 * starterProPack
 * standardPack
 * baseUrl
 * userIsAgent
 * userIsOwner
 * userIsAdmin
 * button_text_transform_class
 * userTimezone
 * userTimezoneRaw
 * userId
 * agencyId
 * canvaApiKey
 * carrierLookup
 */
export const getAccountData = (needle) => {
    // let accountData = getCookie(process.env.REACT_APP_ACCOUNT_DATA);
    let accountData = null;
    let check_new = getCookie("encode__process__new")
    if(check_new !== undefined){
        accountData = getCookieLogin(process.env.REACT_APP_ACCOUNT_DATA);
    }else{
        accountData = getCookie(process.env.REACT_APP_ACCOUNT_DATA);
    }
    if (accountData) {
        accountData = JSON.parse(accountData);
        try{
            return accountData[needle] ?? false;
        }catch(e){
            return false;
        }
    }
    return false;
}

/**
 * Get contact's display name
 * 
 * @param string name 
 * @param string number 
 * @param string email 
 * @returns string
 */
 const getContactDisplayName = (name, number, email,full = false) => {
    if (name === '' || name === ' ' || name === null || name === 'null null') {
        if (number === '' || number === ' ') {
            if (email === '' || email === ' ') {
                return '[No Name]';
            } else {
                return email.length > 15 && !full ? email.substr(0, 13)+' ...' : email;
            }
        } else {
            return number;
        }
    }

    return name.length > 15 && !full ? name.substr(0, 13)+' ...' : name;
}

/**
 * Get initial of name
 * 
 * @param string name 
 * @returns string
 */
 const getInitial = (name,initialCharacter = '') => {
    try {
        if (typeof name != 'undefined') {
            let fullNameInitial = initialCharacter
            if(name === 'null null' || name === '' || name === ' '){
                fullNameInitial = initialCharacter;
            }else if(name !== 'undefined' || name !== null){
                fullNameInitial = name.split(" ").map((n,i,a)=> i === 0 || i+1 === a.length ? n[0] : null).join("");
            }
            return fullNameInitial;
        } else {
            return initialCharacter;
        }
    } catch (error) {
        return initialCharacter;
    }
}

const scrollToTop = () => {
    document.getElementById('main__content_wrapper_parent_module')?.scrollTo({ top: 0, behavior: 'smooth' });
}

const generateRandomString = (length) => {
    let result           = '';
    const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
   }
   return result;
}

const escapeRegex = ( value ) => {
    return value.replace( /[\-\[\]{}()*+?.,\\\^$|#\s]/g, "\\$&" ); // eslint-disable-line no-useless-escape
}

const validateEmail = (email) => {
    // eslint-disable-next-line no-useless-escape
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

const validateMobileNumber = (mobileNumber) => {

    if(mobileNumber !== '' && mobileNumber.length < 10){
        return false;
    } else if (mobileNumber !== '' && mobileNumber.length > 11){
        return false
    } else if(mobileNumber.length === 11 && mobileNumber.substring(0,1) !== '1'){
        return false;
    }
    return true    
}

const validateUsMobileNumber = (mobileNumber) => {
     return /^\+?1?(\(?[0-9]{3}\)?|[0-9]{3}-)[0-9]{3}-?[0-9]{4}$/.test(mobileNumber);
}

const checkLetterExistInText = (text) => {
    var regExp = /[^0-9]+/g;
    let result = false
    if(regExp.test(text)){
        
        result = true
    }
    return result
}

const returnFilteredText = (text,filter='text')=>{

    if(filter === 'number'){
        return text.replace(/[^0-9]/g,'');
    
    }else{
        return text.replace(/[^a-zA-Z]/g,'');

    }
}

function formatCurrency(n, currency = "") {
    if (typeof n !== "number" || !n) return currency + Number(0).toFixed(2);

    return (
        currency +
        n.toFixed(2).replace(/./g, function (c, i, a) {
            return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
        })
    );
}



/**
 * Utility helper
 */
const Utils = {
    handleException,
    showNotification,
    generateTimezoneList,
    getAccountData,
    validateMobileNumber,
    getContactDisplayName,
    getInitial,
    checkLetterExistInText,
    generateRandomString,
    validateEmail,
    scrollToTop,
    escapeRegex,
    returnFilteredText,
    validateUsMobileNumber,
    formatCurrency
}

export default Utils;