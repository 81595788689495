import React from 'react';
import AddOrEditProduct from "./AddOrEditProduct";
import BootstrapTooltip from "../../globals/BootstrapTooltip";
import BasicModalWithHeader from "../common/modal/BasicModalWithHeader";
import NewConfirmAlert from "../../activity/new-alert/NewConfirmAlert";
import {DeleteIcon, EditBoxIcon} from "../common/icons/InvoiceIcons";
import useBoolean from "../../../hooks/useBoolean";
import {deleteProductInvoice} from "../../../api/InvoiceApi";

const SingleProductActions = ({productInfo, onRenderCallback}) => {
    const {value: openModal, setFalse: onCloseModal, setTrue: onOpenModal} = useBoolean(false);

    const handleDelete = () => {
        NewConfirmAlert({
            onSubmit: () => {
                deleteProductInvoice({ id: productInfo.id })
                    .then((res) => {
                        res = res.data;
                        if (res.success) {
                            onRenderCallback();
                            window.showNotification("SUCCESS", res.message);
                        } else {
                            window.showNotification(
                                "ERROR",
                                res.message || "Error occurred while deleting data"
                            );
                        }
                    })
                    .catch((error) => {
                        window.showNotification(
                            "Error occurred while deleting data",
                            "error"
                        );
                    })
            },
            title: "Are You Sure?",
            description: "Do you want to delete this information?",
            cancelText: "Cancel",
            submitText: "Confirm",
            width: "480px",
        });
    };


    return (
        <>
            <div style={{ display: "flex" }}>
                <BootstrapTooltip arrow title="Delete">
                     <span style={{ padding: "10px", cursor: "pointer" }} onClick={handleDelete}>
                        <DeleteIcon />
                    </span>
                </BootstrapTooltip>
                <BootstrapTooltip arrow title="Edit">
                     <span style={{ padding: "10px", cursor: "pointer" }}  onClick={onOpenModal}>
                        <EditBoxIcon />
                    </span>
                </BootstrapTooltip>
            </div>

            <BasicModalWithHeader title={"Edit Product or Service"} open={openModal} onClose={onCloseModal}>
                <AddOrEditProduct isEdit={true} productInfo={productInfo} onClose={onCloseModal} onRenderCallback={onRenderCallback} />
            </BasicModalWithHeader>
        </>
    );
};

export default SingleProductActions;