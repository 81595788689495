import React, {useEffect, useRef, useState} from 'react';
import Modal from '@material-ui/core/Modal';
import Paper from "@material-ui/core/Paper";
import {
  Button,
  FormControl,
  InputBase,
  FormHelperText,
  makeStyles,
  MenuItem,
  Select,
  TextareaAutosize,
  withStyles, Typography, Box
} from '@material-ui/core';
import Styles from "./PowerDialerModal.module.css";
import {addToPowerDialerMapContact, getPowerDialerLists, getPowerDialerVirtualNumbers} from "../../api/profileApi";
import {useParams} from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import Utils from "../../helpers/Utils";

const CustomInput = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
        color: 'black !important',
        fontSize: '20px!important'

      },
    },
    input: {
      height: 'auto !important',
      borderRadius: '4px !important',
      position: 'relative',
      border: '1px solid #ced4da!important',
      fontSize: 16,
      padding: '10px 12px !important',
      marginBottom: '0!important',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:focus': {
        boxShadow: 'none!important',
        borderColor: 'black !important',
      },
    },
  }))(InputBase);


  const BootstrapInput = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:focus': {
        borderRadius: 4,
        // borderColor: '#80bdff',
        // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }))(InputBase);

  const useStyles = makeStyles((theme) => {
    return {
      root: {
        "&.MuiButton-root": {
          backgroundColor: '#008945'
        },
        "&.MuiButton-text": {
          color: "grey"
        },
        "&.MuiButton-contained": {
          color: "white"
        },
        "&.MuiButton-outlined": {
          color: "brown"
        }
      }
    };
  });

const PowerDialerModal = (props) => {
  const { id } = useParams();
  const listNameRef = useRef(null);
  const [powerDialerVirtualNumbers, setPowerDialerVirtualNumbers] = useState([]);
  const [powerDialerSettings, setPowerDialerSettings] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    virtual_number_id: '',
    copy_settings_id: '',
    color: "#546376",
    order_id: id,
    contact_ids: props.selectedIds
  });
  const [formErrorMessage, setFormErrorMessage] = useState({
    name: '',
    description: '',
    virtual_number_id: '',
    copy_settings_id: ''
  });
  const [submitting, setSubmitting] = useState(false);

  const classes = useStyles();

  useEffect(()=>{
   try{
     getPowerDialerVirtualNumbers()
         .then((res)=>{
           if (res.success){
             let data = res.data.data;
             setPowerDialerVirtualNumbers(data);
           }else {
             setPowerDialerVirtualNumbers([]);
           }
         }).catch((err)=>{
       setPowerDialerVirtualNumbers([]);
     });
   }catch (err){
     setPowerDialerVirtualNumbers([]);
   }

    try{
      getPowerDialerLists()
          .then((res)=>{
            if (res.success){
              let data = res.data.data;
              setPowerDialerSettings(data);
            }else {
              setPowerDialerSettings([]);
            }
          }).catch((err)=>{
        setPowerDialerSettings([]);
      });
    }catch (err){
      setPowerDialerSettings([]);
    }
  },[]);

  const handleFormDataChange = (name, value) => {
      setFormData({ ...formData, [name]: value });
      if (formErrorMessage[name]){
        setFormErrorMessage({ ...formErrorMessage, [name]: '' });
      }
  }

  const renderPwdVnOptions = () => {
    const item = [];
    for(let i = 0; i < powerDialerVirtualNumbers.length; i++){
      item.push(<MenuItem className="dropdownhelper-menuitem-class" value={powerDialerVirtualNumbers[i].id}>{powerDialerVirtualNumbers[i].virtual_number}</MenuItem>);
    }
    return item;
  }

  const renderPwdSettingOptions = () => {
    const item = [];
    for(let i = 0; i < powerDialerSettings.length; i++){
      item.push(<MenuItem className="dropdownhelper-menuitem-class" value={powerDialerSettings[i].id}>{powerDialerSettings[i].title}</MenuItem>);
    }
    return item;
  }

  const renderErrorMessage = (fieldName) => {
    return <FormHelperText error>{formErrorMessage[fieldName]}</FormHelperText>;
  }

  const handleSubmit = () => {
    let hot = 0;
    const errorState = {
      name: '',
      virtual_number_id: ''
    };
    if (formData.name.length <= 0){
      errorState.name = 'List name is required!';
      if (listNameRef){
        listNameRef.current.focus();
      }
      hot = 1;
    }

    if (!formData.virtual_number_id){
      errorState.virtual_number_id = 'Virtual number is required!';
      hot = 1;
    }

    if (hot === 1){
      setFormErrorMessage({ ...formErrorMessage, ...errorState });
      return;
    }
    try{
      setSubmitting(true);
      addToPowerDialerMapContact(formData).then((res)=>{
        if (res.success){
          props.setOpen(false);
          props.setReset(props.reset + 1);
          Utils.showNotification(res.message, 'success');
        }else {
          Utils.showNotification(res.message, 'error');
        }
      }).catch((err)=>{
        Utils.showNotification(err.message, 'error');
      }).finally(()=>{
        setSubmitting(false);
      });
    }catch (err){
      setSubmitting(false);
      Utils.showNotification('Something went wrong!', 'error');
    }
  };
    return (
        <Modal
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={`${Styles.modalWrapper} global-xs-modal add_list_modal`}
        >
            <Paper className={Styles.modalInner}>
              <div className={Styles.headerWrapper}>
                <Typography variant="h5">Create New Power Dialer List</Typography>
                <Box className={Styles.headerClose} onClick={()=>{ props.setOpen(false) }}>
                  <CloseIcon />
                </Box>
              </div>
                <div className={Styles.paddingWrp}>
                  <div className={Styles.modalBody}>
                    <FormControl className={Styles.formControl}>
                      <span className={Styles.label}>
                        List Name 
                        <span className={Styles.required}> *</span>
                      </span>
                      <CustomInput
                          inputRef={listNameRef}
                          placeholder='List name here'
                          id="list-name"
                          name="name"
                          value={formData.name}
                          onChange={(e)=>{handleFormDataChange('name', e.target.value)}}
                      />
                      {renderErrorMessage('name')}
                    </FormControl>

                    <FormControl className={Styles.formControl}>
                      <span className={Styles.label}>
                        Which number to call from? 
                        <span className={Styles.required}> *</span>
                      </span>

                      <Select
                        labelId="vn-select-label"
                        id="vn-select"
                        name="virtual_number_id"
                        value={formData.virtual_number_id}
                        displayEmpty
                        onChange={(e)=>{ handleFormDataChange('virtual_number_id', e.target.value) }}
                        input={<BootstrapInput/>}
                        inputProps={{ "aria-label": "Without label" }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        <MenuItem className="dropdownhelper-menuitem-class" value={''} disabled>Select a virtual number</MenuItem>
                        {renderPwdVnOptions()}
                      </Select>
                      {renderErrorMessage('virtual_number_id')}
                    </FormControl>

                    <FormControl className={Styles.formControl}>
                      <span className={Styles.label}>
                        Description 
                      </span>
                      <TextareaAutosize
                          className={Styles.textArea}
                          aria-label="Description"
                          minRows={4}
                          placeholder="Write here..."
                          name="description"
                          value={formData.description}
                          onChange={(e)=>{handleFormDataChange('description', e.target.value)}}
                      />
                      {renderErrorMessage('description')}
                    </FormControl>

                    <FormControl className={Styles.formControl}>
                      <span className={Styles.label}>
                        Copy Settings from
                      </span>

                      <Select
                        labelId="setting-select-label"
                        id="setting-select"
                        name="copy_settings_id"
                        value={formData.copy_settings_id}
                        displayEmpty
                        onChange={(e)=>{handleFormDataChange('copy_settings_id', e.target.value)}}
                        input={<BootstrapInput />}
                        inputProps={{ "aria-label": "Without label" }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        <MenuItem className="dropdownhelper-menuitem-class" value={''}>None</MenuItem>
                        {renderPwdSettingOptions()}
                      </Select>
                      {renderErrorMessage('copy_settings_id')}
                    </FormControl>
                  </div>

                  <div className={Styles.modalFooter}>
                    <Button  onClick={()=>{ props.setOpen(false) }} size="large">Cancel</Button>
                    <Button disabled={submitting} size="large" onClick={handleSubmit} className={classes.root} variant="contained">
                      {submitting ? 'Saving....' : 'Save'}
                    </Button>
                  </div>
                </div>
            </Paper>
        </Modal>
    );
}

export default PowerDialerModal;
