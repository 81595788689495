import { makeStyles } from "@material-ui/core";

const InvoiceUseStyles = makeStyles((theme) => ({
    formControl: {
      background: theme.palette.background.paper,
      marginBlock: theme.spacing(1),
      minWidth: 120,
      border: '1px solid #e2e3e9',
      borderRadius: theme.spacing(.5),
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    select: {
      paddingBlock: theme.spacing(.5),
      paddingInline: theme.spacing(1.5),
      '& .MuiSelect-select:focus': {
        backgroundColor: 'transparent !important'
      }
    },
    MuiDisabled: {
      "&#ibMenuDisabledId": {
        color: `${theme.palette.text.primary} !important`
      }
    },
    
    menuItem: {
      "&#ibMenuItemId": {
        color: `${theme.palette.text.primary} !important`
      }
    },

    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #000",
      boxShadow: theme.shadows[5],
      borderRadius: theme.spacing(.5)
    },
    itemInput: {
      background: 'white !important',
      border: '1px solid !important',
      paddingBlock:'10px !important',
      paddingLeft: '10px !important',
      borderRadius: '5px !important',
      marginBottom: '0px !important'
    },

    textArea: {
      /* width: 75%; */
      height: '100px',
      padding:'10px',
      borderRadius: '5px',

      '&:focus-visible': {
        outline: 'none!important'
    },

    label_text: {
      color: '#133159 !important',
      fontSize: '16px',
      lineHeight: '30px',
      margin: 0,
  }
      
    },

    add_product_modal: {
      
    }



  }));

export default InvoiceUseStyles;