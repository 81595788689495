import React, { createContext } from "react";
import Routes from "./routes/Routes";
import { createBrowserHistory } from "history";
import { createGenerateClassName, StylesProvider } from "@material-ui/styles";
import Utils from "./helpers/Utils";
import { Router } from "react-router-dom";

const defaultHistory = createBrowserHistory();
export const HistoryProvider = createContext({});

const generateClassName = createGenerateClassName({
    productionPrefix: 'prfl_' + Utils.generateRandomString(5),
});

function App({ history = defaultHistory }) {
    return (
        // <HistoryProvider.Provider value={history}>
        //     <StylesProvider generateClassName={generateClassName}>
        //         <ToasterProvider>
        //             <Routes/>
        //         </ToasterProvider>
        //     </StylesProvider>
        // </HistoryProvider.Provider>
        <HistoryProvider.Provider value={history}>
        <StylesProvider generateClassName={generateClassName}>
          <Router history={history}>
            <Routes history={history} />
          </Router>
        </StylesProvider>
    </HistoryProvider.Provider>
    );
}

export default App;