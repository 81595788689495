import React, {useEffect, useRef, useState} from "react";
import "./contact-validation-report.css";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import moment from "moment";
import CustomMenuPype from "../custom-menu/CustomMenuPype";
import {Icons} from "../custom-components/custom-table/components/Icons";
import MEISCustomTable from "../custom-components/custom-table/MEISCustomTable";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {getContactLookupUsagesReportApi} from "../../api/profileApi";

const back_icon = (<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M14.1258 6.7925L12.8333 5.5L7.33331 11L12.8333 16.5L14.1258 15.2075L9.92748 11L14.1258 6.7925Z" fill="#1C1B1F"/>
</svg>);
const next_icon = (<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M9.16671 5.5L7.87421 6.7925L12.0725 11L7.87421 15.2075L9.16671 16.5L14.6667 11L9.16671 5.5Z" fill="#1C1B1F"/>
</svg>);

let currentDate = moment(new Date()).format("YYYY-MM-DD");
let prevDate = moment(currentDate).subtract(30,"days").format("YYYY-MM-DD");
const ContactValidationReport = () => {
  const [perPage, setPerPage] = useState(20);
  const [contactValidationReport, setContactValidationReport] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [filterQuery, setFilterQuery] = useState({
    page: 1,
    pageSize: 20,
    startDate: prevDate,
    endDate: currentDate
  });

  const [openDateField, setOpenDateField] = useState({
    isStartDateOpen: false,
    isEndDateOpen: false
  });

  const startDateRef = useRef(null);
  const endDateRef = useRef(null);

  const handleDateChange = (item, value) => {
    let momentObj = moment(value);
    if (item === "startDate") {
      if(value) {
        setFilterQuery({ ...filterQuery, startDate: momentObj.format("YYYY-MM-DD") });
      } else {
        setFilterQuery({ ...filterQuery, startDate: prevDate});
      }
    } else if (item === "endDate") {
      if(value) {
        setFilterQuery({ ...filterQuery, endDate: momentObj.format("YYYY-MM-DD") });
      } else {
        setFilterQuery({ ...filterQuery, endDate: currentDate });
      }
    }
  };

  useEffect(()=>{
    setIsLoading(true);
    getContactLookupUsagesReportApi(filterQuery)
        .then((res)=>{
          if (res.success){
            setContactValidationReport(res.data);
            setTotalPages(res.totalPages);
          }
        })
        .catch((err)=>{
          setContactValidationReport([]);
          setTotalPages(0);
        })
        .finally(()=>{
      setIsLoading(false);
    });
  },[filterQuery]);

  const onClickEach = (contact_id) => {

  };

  const onChangePage = (page) => {
    page = page.replace(/[a-z|A-Z|!@#$%^&* ]/g,"");
    if (page !== "" && page > 0) {
      if (totalPages >= page) {
        setFilterQuery({ ...filterQuery, page: parseInt(page) });
        setCurrentPage(parseInt(page));
      }
    }else{
      setCurrentPage("");
    }
  }

  const handleStepChange = (event, page) => {
    event.preventDefault();
    if (page != null && page > 0) {
      setFilterQuery({ ...filterQuery, page: parseInt(page) });
      setCurrentPage(page);
    }
  }

  let previous_page = null;
  let next_page = null;

  if (currentPage > 1) {
    previous_page = currentPage - 1;
  }

  if (currentPage !== totalPages) {
    if (typeof currentPage === "string" && currentPage !== ""){
      let parsedCurrentPage = parseInt(currentPage);
      setCurrentPage(parsedCurrentPage);
    }
    next_page = currentPage + 1;
  }

  const renderFilter = () => {
    return(
        <div className="d-flex justify-content-between align-items-center">
          <div className="select-wrapper campaign_report_select_wrapper_v2 ml-2">
              <h5>Contact Validation Report</h5>
          </div>
          <div className="filter__date__area">
            <div className="d-flex align-items-center" style={{ width: "290px" }}>
              Starting From
              <div className="ml-1 each__date each__date--startdate">
                <div className={`${filterQuery.startDate ? "d-none" : ""}`}>
                  <button onClick={e => startDateRef.current.click()}>Start Date</button>
                </div>
                <div className={`${!filterQuery.startDate ? "d-none" : ""}`}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        inputRef={startDateRef}
                        clearable
                        onClick={() => setOpenDateField({isStartDateOpen: true, isEndDateOpen: false})}
                        onClose={() => setOpenDateField({isStartDateOpen: false, isEndDateOpen: false})}
                        open={openDateField.isStartDateOpen}
                        inputProps={{readOnly : true}}
                        value={filterQuery.startDate ? filterQuery.startDate : null}
                        onChange={(value) => handleDateChange("startDate", value)}
                        margin="normal"
                        format="MM/dd/yyyy"
                        KeyboardButtonProps={{ disabled: true, style: { display: "none" } }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center" style={{ width: "250px" }}>
              Ending At
              <div className="ml-1 each__date each__date--endDate">

                <div className={`${filterQuery.endDate ? "d-none" : ""}`}>
                  <button onClick={e => endDateRef.current.click()}>End Date</button>
                </div>

                <div className={`${!filterQuery.endDate ? "d-none" : ""}`}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        inputRef={endDateRef}
                        clearable
                        onClick={() => setOpenDateField({isStartDateOpen: false, isEndDateOpen: true})}
                        onClose={() => setOpenDateField({isStartDateOpen: false, isEndDateOpen: false})}
                        open={openDateField.isEndDateOpen}
                        inputProps={{readOnly : true}}
                        value={filterQuery.endDate ? filterQuery.endDate : null}
                        onChange={(value) => handleDateChange("endDate", value)}
                        margin="normal"
                        format="MM/dd/yyyy"
                        KeyboardButtonProps={{ disabled: true, style: { display: "none" } }}
                    />
                  </MuiPickersUtilsProvider>
                </div>

              </div>
            </div>
          </div>
        </div>
    );
  }

  const renderPagination = () => {
    return (
        totalPages !== 0 ?
            <div className="contact-validation-report_table_pagination">
              <div className="rftp_table_per_page_select">
                <span>Rows per page:</span>
                <div>
                  <CustomMenuPype
                      wrapperClass="mlvc_row_per_page_dropdown"
                      popup= {{
                        placement: "center",
                        list: [
                          {label: "10", value: 10},
                          {label: "20", value: 20},
                          {label: "30", value: 30},
                          {label: "50", value: 50},
                          {label: "100", value: 100},
                        ],
                        onClick: (item) => {
                          if(item.value === perPage){
                            return;
                          }else {
                            setPerPage(parseInt(item.value));
                            setCurrentPage(1);
                            setFilterQuery({ ...filterQuery, pageSize: parseInt(item.value), page: 1 });
                          }
                        },
                        height: "200px",
                        width: "80px",
                        listStyle: {
                          justifyContent: "center"
                        }
                      }}
                      label= {{
                        renderCustom: (
                            <div className="contact-validation-report_perpage_body">
                              <span className="clpb_label">{perPage}</span>
                              <span className="clpb_icon">{Icons.arrow_down}</span>
                            </div>
                        )
                      }}
                  />
                </div>
              </div>
              <div className="contact-validation-report_pagination">
                <span className={`rftp_back_button  ${previous_page == null ? "rftp_bb_disabled":""}`} onClick={(event) => handleStepChange(event, previous_page)}>{back_icon}</span>
                <input type="text" className="rftp_input_field" value={currentPage} onChange={(event)=> {onChangePage(event.target.value)}}/>
                <span className="rftp_divider"></span>
                <input className="rftp_input_field_total" readOnly value={totalPages}/>
                <span className={`rftp_next_button ${next_page == null ? "rftp_bb_disabled":""}`} onClick={(event) => handleStepChange(event, next_page)}>{next_icon}</span>
              </div>
            </div> : <div/>
    );
  }

  return (
      <MEISCustomTable
          tableWrapperclass="contact-validation-report_ria"
          isLoading={isLoading}
          data={contactValidationReport}
          customHeader={renderFilter()}
          renderPagination={renderPagination()}
          rowDecoration={[
            {
              title: <div style={{ color: "#546376", fontWeight: "normal", marginLeft: "10px" }}>Date</div>,
              value: (row) => {
                return <div className="contact-validation-report_th--value" style={{cursor: "pointer", color: "#1C1B1F", marginLeft: "10px", textAlign: "left"}} onClick={() => onClickEach(row.id)}>
                  {row.date}
                </div>
              },
              width: "unset"
            },
            {
              title: <div style={{ color: "#546376", fontWeight: "normal", marginLeft: "10px" }}>Total Contact</div>,
              value: (row) => {
                return <div className="contact-validation-report_th--value" style={{cursor: "pointer", color: "#1C1B1F", textAlign: "center"}} onClick={() => onClickEach(row.id)}>
                  {row.totalContact}
                </div>
              },
              width: "unset"
            },
            {
              title: <div style={{ color: "#546376", fontWeight: "normal", textAlign: "left" }}>Email Information</div>,
              value: (row) => {
                return <div className="contact-validation-report_th--value" style={{cursor: "pointer", color: "#1C1B1F", textAlign: "left"}} onClick={() => onClickEach(row.id)}>
                  {
                    (row.emailInformations && typeof row.emailInformations === "object")
                        ? <ul>
                          { Object.entries(row.emailInformations).map(([key,value])=>(
                              <li>{key}: {value}</li>))
                          }
                        </ul>
                        :  '-'
                  }
                </div>
              },
              width: "unset"
            },
            {
              title: <div style={{ color: "#546376", fontWeight: "normal", textAlign: "left" }}>Number Information</div>,
              value: (row) => {
                return <div className="contact-validation-report_th--value" style={{cursor: "pointer", color: "#1C1B1F", textAlign: "left"}} onClick={() => onClickEach(row.id)}>
                  {
                    (row.numberInformations && typeof row.numberInformations === "object")
                        ? <ul>
                            { Object.entries(row.numberInformations).map(([key,value])=>(
                              <li>{key}: {value}</li>))
                            }
                          </ul>
                        :  '-'
                  }
                </div>
              },
              width: "unset"
            },
            {
              title: <div style={{ color: "#546376", fontWeight: "normal" }}>Total Cost</div>,
              value: (row) => {
                return <div className="contact-validation-report_th--value" style={{cursor: "pointer", color: "#1C1B1F"}} onClick={() => onClickEach(row.id)}>
                  {row.totalCost}
                </div>
              },
              width: "unset"
            }
          ]}
          emptyContainer={{
            text: "No Contact Validation Report",
          }}
      />
  );
};

export default ContactValidationReport;