import React from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {Edit, Delete} from '@material-ui/icons';


const PersonilizTable = () => {

  const EditItem = (item) =>{
    console.log(item)
  }
  const DeleteItem = (item) =>{
    console.log(item)
  }
  const data = [
          {
            id:1,
            label:'Number',
            Personalize_Value:'phone',
            Personalize_Tag:'[[contact_phone_number]]',
          },
          {
            id:2,
            label:'Number',
            Personalize_Value:'phone',
            Personalize_Tag:'[[contact_phone_number]]',
          },
          {
            id:3,
            label:'Number',
            Personalize_Value:'phone',
            Personalize_Tag:'[[contact_phone_number]]',
          },{
            id:4,
            label:'Number',
            Personalize_Value:'phone',
            Personalize_Tag:'[[contact_phone_number]]',
          }
  ]

  return (
    <TableContainer>
       <Table className="personiliz__table">
         <TableHead>
           <TableRow>
             <TableCell className="personiliz__th1 table__thead__th"><span className="label__name1">Filed Label</span> <span className="lable__icon1"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 0C3.5888 0 0 3.58885 0 8.00005C0 12.4112 3.5888 16 8 16C12.4112 16 16 12.4112 16 8.00005C16 3.58885 12.4112 0 8 0ZM8 14.5455C4.39079 14.5455 1.45455 11.6092 1.45455 8.00005C1.45455 4.39088 4.39079 1.45455 8 1.45455C11.6092 1.45455 14.5455 4.39088 14.5455 8.00005C14.5455 11.6092 11.6092 14.5455 8 14.5455Z" fill="#ADB7C5"/>
<path d="M7.99911 3.40112C7.46451 3.40112 7.0296 3.83632 7.0296 4.37126C7.0296 4.90571 7.46451 5.34052 7.99911 5.34052C8.5337 5.34052 8.96861 4.90571 8.96861 4.37126C8.96861 3.83632 8.5337 3.40112 7.99911 3.40112Z" fill="#ADB7C5"/>
<path d="M7.99864 6.78271C7.59699 6.78271 7.27136 7.10834 7.27136 7.50999V11.8736C7.27136 12.2753 7.59699 12.6009 7.99864 12.6009C8.40028 12.6009 8.72591 12.2753 8.72591 11.8736V7.50999C8.72591 7.10834 8.40028 6.78271 7.99864 6.78271Z" fill="#ADB7C5"/>
</svg>
</span></TableCell>
             <TableCell className="personiliz__th2 table__thead__th">Personalize Value	</TableCell>
             <TableCell className="personiliz__th3 table__thead__th">Personalize Tag</TableCell>
             <TableCell className="personiliz__th4 table__thead__th">Action</TableCell>
           </TableRow>
         </TableHead>

         <TableBody>
           {data.map(item=>(
             <TableRow key={item.id}>
               <TableCell className="table__body__td">{item.label}</TableCell>
               <TableCell className="table__body__td">{item.Personalize_Value}</TableCell>
               <TableCell className="table__body__td">{item.Personalize_Tag}</TableCell>
               <TableCell className="editDelete__wr table__body__td">
                 <div className="edit__button" onClick={()=>EditItem(item)}><Edit>Edit</Edit> <span>Edit</span></div>
                 <div className="delete__button" onClick={()=>DeleteItem(item)}><Delete>Delete</Delete><span>Delete</span></div>
                 </TableCell>
             </TableRow>
           ))}
         </TableBody>
       </Table>
     </TableContainer>
  )
}

export default PersonilizTable

