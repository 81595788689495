import React, { Fragment, useEffect, useState } from "react";

import Pagination from "./Pagination";
import Utils from "../../helpers/Utils";
import './report-style.css';
import { FormControl, MenuItem, Select } from "@material-ui/core";
import { useIsMounted } from "../../hooks/IsMounted";
import { Skeleton } from "@material-ui/lab";
import { Link } from "react-router-dom";
import { getUserSmartWebhooksListApi } from "../../api/smartWebhookApi";

const module_const = { 1:'Campaign', 2:'Trigger'};
const SmartWebhookReport = (props) => {
    const isMounted = useIsMounted();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [searchText, setSearchText] = useState('');

    useEffect(() => {

        document.title = `Smart Webhook Report | ${Utils.getAccountData('AuthUserAgencyName')}`;
        loadData({ currentPage: 1, perPage: 10 })
    }, [])

    useEffect(() => {
        if (isMounted) {
            const timer = setTimeout(() => {
                loadData({ currentPage: 1, perPage: perPage })
            }, 1000);

            return () => clearTimeout(timer);
        }

        console.log("Hello")
    }, [searchText]);


    const loadData = (payload = null) => {
        if (payload && payload.currentPage !== currentPage) {
            setCurrentPage(payload.currentPage)
        }
        if (payload && payload.perPage !== perPage) {
            setPerPage(payload.perPage)
        }

        setLoading(true);

        getUserSmartWebhooksListApi(
            {
                page: payload.currentPage,
                limit: payload.perPage,
                searchText: searchText,
            }
        )
        .then(response => {
            console.log(response)
            if (response.data && response.success) {
                if(payload.currentPage === 1 ){
                    let pages = Math.round(response.count / payload.perPage);
                    if (pages < 1) {
                        setTotalPages(1);
                    } else {
                        setTotalPages(pages);
                    }
                    setTotal(response.count);
                }
                setData(response.data);
            }
            setLoading(false);
        }).finally(() => {
            setLoading(false);
        });
    }

    const renderData = () => {
        if (loading) {
            let loadingView = [];
            for (let i = 0; i < perPage; i++) {
                loadingView.push(
                    <tr key={i}>
                        <td > <Skeleton width="100%" height={"16px"}> </Skeleton></td>
                        <td > <Skeleton width="100%" height={"16px"}> </Skeleton></td>
                        <td > <Skeleton width="100%" height={"16px"}> </Skeleton></td>
                        <td > <Skeleton width="100%" height={"16px"}> </Skeleton></td>
                        <td > <Skeleton width="100%" height={"16px"}> </Skeleton></td>
                        <td > <Skeleton width="100%" height={"16px"}> </Skeleton></td>
                    </tr>
                );
            }
            return loadingView;

        } else if (!loading && !data.length) {
            return (
                <tr>
                    <td colSpan={6} style={{ paddingTop: 50, textAlign: "center" }}>
                        NO DATA AVAILABLE
                    </td>
                </tr>
            )
        } else if (!loading && data.length) {
            return (
                data.map((item, index) => (
                    <tr key={index}>
                        <td>
                            <Link to={{ pathname: "/user/smart-webhook-report/history", state: { item: item } }} >
                                {item.webhookTitle}
                            </Link>
                        </td>
                        <td>{module_const[item.module]??''}</td>
                        <td>{item.usedAt}</td>
                        <td style={{textAlign:"center"}}>{item.success}</td>
                        <td style={{textAlign:"center"}}>{item.failed}</td>
                        <td style={{textAlign:"center"}}>{item.total}</td>
                    </tr>
                ))
            )
        }
    }

    return (
        <Fragment>
            <div className='container-fluid mail__inbox mt-1'>
                <div className='tagBox'>
                    <div className='tag_list'>
                        <h5>Smart Webhook Report</h5>
                    </div>
                </div>
                <div className="details">

                    <Fragment>
                        <div className="">
                            <div className='tagBox'>
                                <div className='d-flex'>
                                    <FormControl fullWidth>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={perPage}
                                            label=""
                                            onChange={(e) => { loadData({ currentPage: 1, perPage: e.target.value }) }}
                                        >
                                            <MenuItem value={10}>10</MenuItem>
                                            <MenuItem value={20}>20</MenuItem>
                                            <MenuItem value={50}>50</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <div style={{ paddingLeft: "10px", paddingTop: "3px" }}>records</div>
                                </div>
                                <div style={{ marginLeft: "auto", padding: "0px 15px" }}>
                                    <div class="record_search">
                                        <input type="text" value={searchText} placeholder="Search by campaign setting title, trigger title" class="search-text" onChange={(e) => setSearchText(e.target.value)} />
                                        <div class="search_icon">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.4351 10.0629H10.7124L10.4563 9.81589C11.3528 8.77301 11.8925 7.4191 11.8925 5.94625C11.8925 2.66209 9.23042 0 5.94625 0C2.66209 0 0 2.66209 0 5.94625C0 9.23042 2.66209 11.8925 5.94625 11.8925C7.4191 11.8925 8.77301 11.3528 9.81589 10.4563L10.0629 10.7124V11.4351L14.6369 16L16 14.6369L11.4351 10.0629ZM5.94625 10.0629C3.66838 10.0629 1.82962 8.22413 1.82962 5.94625C1.82962 3.66838 3.66838 1.82962 5.94625 1.82962C8.22413 1.82962 10.0629 3.66838 10.0629 5.94625C10.0629 8.22413 8.22413 10.0629 5.94625 10.0629Z" fill="#133159" fill-opacity="0.65"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <table >
                                <thead >
                                    <tr>
                                        <th style={{paddingLeft: "30px"}}>Title</th>
                                        <th style={{paddingLeft: "30px"}}>Source</th>
                                        <th style={{paddingLeft: "30px"}}>Source Title</th>
                                        <th style={{textAlign:"center"}}>Success </th>
                                        <th style={{textAlign:"center"}}>Failed </th>
                                        <th style={{textAlign:"center"}}>Total </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {renderData()}
                                </tbody>
                            </table>

                            <Pagination
                                paginationData={{ totalRecords: total, totalPages, currentPage, perPage }}
                                setCurrentPage={setCurrentPage}
                                getNewData={loadData}
                            />

                        </div>
                    </Fragment>
                </div>
            </div>
        </Fragment>
    )
}

export default SmartWebhookReport;