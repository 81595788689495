import {useEffect, useState} from "react"
import Select from "react-select";
import {snapShotAccountList} from "../../api/profileApi";
import Utils from "../../helpers/Utils";

const Step1 = ({data, handleUpdateData, handleStep, activeSnapShot}) => {

    const [snapShot, setSnapShot] = useState(null);
    const [snapShotList, setSnapShotList] = useState([]);

    useEffect(()=>{
        loadSnapShots();
        if(activeSnapShot && activeSnapShot.value != undefined){
            setSnapShot(activeSnapShot);
        }
    }, [])


    const loadSnapShots = () => {
        snapShotAccountList({
            "form_data": {
                page: 1,
                per_page: 25,
            }
        })
            .then(response => {
                let accountOption = [];
                let object = response.data.data.data;

                object.forEach((item)=>{
                    accountOption.push({
                        value: item.id,
                        label: item.title
                    })
                })

                setSnapShotList(accountOption);
            }).finally(() => {
        });
    }

    const handleNextClick = () => {
        if(snapShot) {
            handleStep(snapShot)
        }else{
            Utils.showNotification('Select a quick clone to proceed', 'error');
        }
    }

    return(
        <div className="twilio___migration__gerenal_step twili__migration___container">
            <div className="row">
                <div className="input-field">
                    <h5 className="label_text">Select quick clone<span>*</span></h5>
                    <Select
                        options={snapShotList}
                        isClearable={false}
                        value={snapShot}
                        styles={{
                            menu: provided => ({ ...provided, zIndex: 9999999 }),
                            valueContainer: provided => ({
                                ...provided,
                                width: '50px',
                            })
                        }}
                        onChange={(_selectedOption) => {setSnapShot(_selectedOption)}}
                    />

                </div>

                <div
                    className="col s12 twilio__migration__submit__btn"
                    style={
                        snapShot == null
                    ? {'background': '#9e9e9e', 'cursor': 'not-allowed'} : {}}
                    onClick={handleNextClick}
                >
                    Next
                </div>
            </div>
        </div>
    )
}
export default Step1;