import React,{useEffect, useState} from 'react';
import { connect } from 'react-redux'
import { confirmAlert } from 'react-confirm-alert';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import {Add} from '@material-ui/icons';
import { ALL_FIELD_NAME, CHECKBOX, DATE, MULTILINE_TEXT, NUMERIC, PHONE, RADIO, SELECT, TEXT, WEBSITE, ZIP_CODE } from '../../constants/CoreConstants';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {Edit, Delete} from '@material-ui/icons';
import Switch from './subComponents/Switch';
import { changeCustomFieldStatus, changeCustomFieldValue, customFieldAdd, deleteCustomField, loadCustomFields, reloadCustomField, updateCustomField, updateCustomFieldLength } from '../../actions/CustomFieldAction';
import { IconList } from '../../constants/IconList';
import CustomFieldPopup from "./CustomFieldPopup";
import CustomFieldDragDrop from './CustomFieldDragDrop';


const mapStateToProps = state => ({
  reloadCustomFieldFlag: state.profileReducer.reloadCustomFieldFlag,
});

const mapDispatchToProps = dispatch => ({
  loadCustomFields: (params, callback) => dispatch(loadCustomFields(params, callback)),
  updateCustomField: (params, callback) => dispatch(updateCustomField(params, callback)),
  updateCustomFieldLength: (params, callback) => dispatch(updateCustomFieldLength(params, callback)),
  deleteCustomField: (params, callback) => dispatch(deleteCustomField(params, callback)),
  reloadCustomField: (params) => dispatch(reloadCustomField(params)),
  changeCustomFieldStatus: (params, callback) => dispatch(changeCustomFieldStatus(params, callback)),
  customFieldAdd: (params, callback) => dispatch(customFieldAdd(params, callback)),
  changeCustomFieldValue: (params, callback) => dispatch(changeCustomFieldValue(params, callback)),
})


const CustomFieldApp = props => {
    const [modalVisible, setModalVisible] = useState(false);
    const [reset, setReset] = useState(0);


    const createNewCustomField = () => {
        setModalVisible(true)
    }

    return(
        <>

            <Button type="button"
                    className="add__icon custom__form__icon accent--bg--color mb-3"
                    startIcon={<Add className={"accent--fill--color"}/>}
                    onClick={createNewCustomField}
            >
                <span className={"text-white"}>Add New</span>
            </Button>

            {/* <CustomFieldTable /> */}
            <CustomFieldDragDrop />

            {
                modalVisible && (
                    <CustomFieldPopup
                        hideModal={(isReset) => {
                            if(isReset) {
                              setReset(reset + 1);
                              props.reloadCustomField({status: true});
                            }
                            setModalVisible(false);
                        }}
                        title={'Custom Field'}
                    />
                )
            }
    </>
    )
}
const CustomFields = connect(mapStateToProps, mapDispatchToProps)(CustomFieldApp);
export default CustomFields;


const TableApp = props => {
  const [customFields, setCustomFields] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    props.loadCustomFields({for_custom_field_update:true}, (res) => {
      setCustomFields(res)
      setLoading(false)
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if(props.reloadCustomFieldFlag){
      props.reloadCustomField({status : false})
      props.loadCustomFields({for_custom_field_update:true}, (res) => {
        setCustomFields(res)
      })
    }
  }, [props.reloadCustomFieldFlag]) // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <TableContainer>
       <Table className="custom__table">
          <TableHead>
            <TableRow  style={{ width: 100 }} className="table__head__row__custom">
              <TableCell className="customtable__th1 table__thead__th">
                <span className="label__name1">Filed Label</span> 
                <span className="lable__icon1">{IconList.info}</span>
              </TableCell>
              <TableCell className="customtable__th2 table__thead__th">Type</TableCell>
              <TableCell className="customtable__th3 table__thead__th">Personalize Tag	</TableCell>
              <TableCell className="customtable__th4 table__thead__th">Maximum Character Allowed</TableCell>
              <TableCell className="customtable__th5 table__thead__th">Format</TableCell>
              <TableCell className="customtable__th6 table__thead__th">
                <span className="label__name1">Active field?</span>
                <span className="lable__icon1">{IconList.info}</span>
              </TableCell>
              <TableCell className="customtable__th7 table__thead__th">Action</TableCell>
            </TableRow>
         </TableHead>

          <TableBody>
            {loading ? 
              <span>Loading...</span>:
              customFields.map((item, index) => {
                return <TableEachRow data={item} key={`${index}${item.id}`} />
              })
            }
          </TableBody>
       </Table>
     </TableContainer>
  )

}
export const CustomFieldTable = connect(mapStateToProps, mapDispatchToProps)(TableApp);


const TableEachRowApp = props => {
  const {data} = props
  const [edit, setEdit] = useState(false)
  const [maxLengthEditable, setMaxLengthEditable] = useState(false)
  const [status, setStatus] = useState(data.status);
  const [modalVisible, setModalVisible] = useState(false);
  const [reset, setReset] = useState(0);

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (data.type_id == DATE || data.type_id == MULTILINE_TEXT)
      setMaxLengthEditable(false)
    else
      setMaxLengthEditable(true)    
  }, []) // eslint-disable-line react-hooks/exhaustive-deps


  const format = () => {
    let typeId = parseInt(data.type_id);
    if(typeId === TEXT)
        return 'text'
    else if(typeId === MULTILINE_TEXT)
        return 'text'
    else if(typeId === NUMERIC)
        return '0-9'
    else if(typeId === DATE)
        return 'date'
    else if(typeId === CHECKBOX)
        return 'checkbox'
    else if(typeId === SELECT)
        return 'select'
    else if(typeId === RADIO)
        return 'radio'
    else if(typeId === PHONE)
        return '0-9'
    else if(typeId === ZIP_CODE)
        return '0-9'
    else if(typeId === WEBSITE)
        return 'url'
  }

  const deleteItem = () =>{
    if(!data.user_id){
      confirmAlert({
        title: 'Invalid',
        message: 'you can not delete agency default custom fields!',
        buttons: [
            {
                label: 'Close',
                onClick: () => {return}
            }
        ]
      });
      return
    }
    confirmAlert({
      title: 'Do you want to delete this?',
      message: 'Are you sure to delete this custom field?',
      buttons: [
          {
              label: 'Yes',
              onClick: () => {
                props.deleteCustomField({fieldId : data.id}, (response) => {
                  if (response.status === 'success') {
                    window.showNotification("SUCCESS", response.html)
                    props.reloadCustomField({status : true})
                  } else if (response.status === 'error') {
                    window.showNotification("ERROR", response.html)
                  }
                })
              }
          },
          {
              label: 'No',
              onClick: () => {return}
          }
      ]
    });
  }

  const editItem=()=>{
    if(!data.user_id){
      confirmAlert({
        title: 'Invalid',
        message: 'you can not edit agency default custom fields!',
        buttons: [
            {
                label: 'Close',
                onClick: () => {return}
            }
        ]
      });
      return
    }
    setModalVisible(true)
  }

  const renderTitle = () => {
    if(edit){
      return(
        <FormControl className="custom__datafeild__edit">
          <Input disableUnderline={true}  defaultValue={data.title} placeholder="Field name" onKeyPress={handleNameChange}/>
        </FormControl>
      )
    }
    else{
      if(data.title === ''){
        return(
          <span style={{color: 'red'}}>Field Name</span>
        )
      }
      return(data.title)
    }
  } 

  const renderMaxLength = () => {
    if(edit && maxLengthEditable){
      return(
        <FormControl className="custom__datafeild__edit">
          <Input disableUnderline={true} defaultValue={data.max_length} placeholder="Max length" onKeyPress={handleMaxLengthChange}/>
        </FormControl>
      )
    }
    else{
      return(data.max_length)
    }
  }

  const handleNameChange = (event) => {
    var code = (event.keyCode ? event.keyCode : event.which);
    if(code === 13) {
      props.updateCustomField({
        fieldId: data.id,
        fieldName: event.target.value
      }, (res) => {
        data['title'] = event.target.value
        data['personalize_tag'] = `[[${event.target.value.split(" ").join("_")}]]`
        setEdit(false)
      })
    }
  }

  const handleMaxLengthChange = (event) => {
    var code = (event.keyCode ? event.keyCode : event.which);
    if(code === 13) {
      props.updateCustomFieldLength({
        fieldId: data.id,
        max_length: event.target.value
      }, (res) => {
        data['max_length'] = event.target.value
        setEdit(false)
      })
    }
  }

  const handleStatusChange = (status) => {

    if(!data.user_id){
      confirmAlert({
        title: 'Invalid',
        message: 'you can not change agency default custom fields !',
        buttons: [
            {
                label: 'Close',
                onClick: () => {return}
            }
        ]
      });
      return
    }
    else if(data.title === ''){
      confirmAlert({
        title: 'Invalid',
        message: 'Title field is empty !',
        buttons: [
            {
                label: 'Close',
                onClick: () => {return}
            }
        ]
      });
      return
    }
    confirmAlert({
      title: 'Are you sure?',
      message: 'Do you want to chagned this activity status of this field?',
      buttons: [
          {
              label: 'Yes',
              onClick: () => {
                props.changeCustomFieldStatus({fieldId : data.id, status: status}, (response) => {
                  if (response.status === 'success') {
                    window.showNotification("SUCCESS", response.html)
                    setStatus(status)
                  } else if (response.status === 'error') {
                      window.showNotification("ERROR", response.html)
                  }
                })
              }
          },
          {
              label: 'No',
              onClick: () => {return}
          }
      ]
    });
  }


  return(
      <>
    <TableRow  style={{ width: 100 }} className="table__body__row__custom">
      <TableCell className="table__body__td">
        {renderTitle()}
      </TableCell>
      <TableCell className="table__body__td">{ALL_FIELD_NAME[data.type_id.toString()]}</TableCell>
      <TableCell className="table__body__td">{data.personalize_tag}</TableCell>
      <TableCell className="table__body__td">{renderMaxLength()}</TableCell>
      <TableCell className="table__body__td">{format()}</TableCell>
      <TableCell className="table__body__td"><Switch status={status} brandColor={"#f94f72"} onChange={handleStatusChange}/></TableCell>
      <TableCell className="editDelete__wr">
          <div className="edit__button" onClick={editItem}><Edit>Edit</Edit> <span>Edit</span></div>
        <div className="delete__button" onClick={deleteItem}><Delete>Delete</Delete><span>Delete</span></div>
      </TableCell>
    </TableRow>
          {
              modalVisible && (
                  <CustomFieldPopup
                      hideModal={(isReset) => {
                          if(isReset) {
                            setReset(reset + 1);
                            props.reloadCustomField({status: true});
                          }
                          setModalVisible(false);
                      }}
                      dataSet={data}
                      title={'Update Field'}
                  />
              )
          }
    </>
  )
}
export const TableEachRow = connect(mapStateToProps, mapDispatchToProps)(TableEachRowApp);