import { all } from "redux-saga/effects";
import customFieldMiddleware from "./customFieldMiddleware";
import leadSourceMiddleware from "./leadSourceMiddleware";
import profileMiddleware from "./profileMiddleware";

export default function* rootMiddleware() {
    yield all(
        [
            profileMiddleware(),
            customFieldMiddleware(),
            leadSourceMiddleware()
        ]
    )
}
