import React, { useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { Box, Button, Divider, Grid, TextareaAutosize } from "@material-ui/core";
import PersonalizedTag from "../common/Personalized/PersonalizedTag";
import { insertPersonalizedTag } from "../common/utils/insertPersonalizedTag";
import { PERSONALIZED_TAGS_FOR_EMAIL_SEND } from "../../../constants/personalizedTags";
import { addSmsTemplate, getSmsTemplate } from "../../../api/InvoiceApi";
import InvoiceUseStyles from "../invoiceUseStyles";

const SendWithText = () => {
  const classes = InvoiceUseStyles();
  const [, setForceUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [body, setBody] = useState("");

  const bodyRef = useRef(null);

  const handleBody = (value) => {
    setBody(value);
  };

  const simpleValidator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: setForceUpdate },
      className: "small text-danger mdi mdi-alert pt-1 pl-1",
    })
  );

  useEffect(() => {
    getSmsTemplate().then((res) => {
      res = res.data;
      if (res.success) {
        const { body } = res.data || {};
        handleBody(body);
      } else {
        window.showNotification("ERROR", res.message);
      }
    });
  }, []);

  const handleSubmit = () => {
    if (simpleValidator.current.allValid()) {
      try {
        const requestBody = {
          body,
        };
        console.log(requestBody);

        if (requestBody) {
          addSmsTemplate(requestBody)
            .then((res) => {
              res = res.data;
              if (res.success) {
                window.showNotification("SUCCESS", res.message);
                // onRenderCallback();
              } else {
                window.showNotification("ERROR", res.message);
              }
            })
            .catch((error) => {
              window.showNotification("ERROR", "Error occurred");
            })
            .finally(() => setLoading(false));
          window.showNotification("SUCCESS", "SMS template saved successfully");
        }
        window.showNotification("SUCCESS", "SMS Template Saved");
      } catch (error) {
        console.error("An error occurred:", error);
      }
    } else {
      simpleValidator.current.showMessages();
      setForceUpdate(true);
    }
  };
  return (
    <>
      <Box
        alignItems={"center"}
        p={2.5}
        sx={{ display: "flex", bgcolor: "#006df514" }}
      >
        <h5 style={{ margin: 0 }}>Text Message Template</h5>

        <Divider light />
      </Box>

      <Box component="form" sx={{ bgcolor: "white", p: 3, pt: 0, mb: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box className="editor-container">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p className={classes.label_text}>SMS Body *</p>

                <PersonalizedTag
                  onClick={(personalizedTag) =>
                    insertPersonalizedTag(personalizedTag, bodyRef, (value) =>
                      handleBody(value)
                    )
                  }
                  personalizeTags={PERSONALIZED_TAGS_FOR_EMAIL_SEND}
                />
              </Box>

              <TextareaAutosize
                className={classes.textArea}
                fullWidth
                name="description"
                minRows={6}
                maxRows={6}
                aria-label="maximum height"
                placeholder=""
                value={body}
                ref={bodyRef}
                onChange={(e) => handleBody(e.target.value)}
              />

              {simpleValidator.current.message("SMS Body", body, "required|max:250")}
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#3C7EF3",
              color: "white",
              width: "115px",
              fontSize: "16px",
            }}
            disabled={loading}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default SendWithText;
