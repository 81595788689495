import React from "react";
import './activity.css';

const ActivitySingle = ({Activites}) => {

    const styleActivties = () =>{
        if((Activites.status) === 'logged'){
            return ' '
        }
        if((Activites.status) === 'delete'){
            return 'delete_log'
        }
        if((Activites.status) === 'contact'){
            return 'contact_log'
        }
    }
    return (
        
        <li className={"logged_in "+ styleActivties()}>
            <h6>{Activites.title}</h6>
            <p>{Activites.subTitle}</p>
            <span>{Activites.date}</span>
        </li>
        
    )
}

export default ActivitySingle;