import React, { useState } from "react";

import {
    Box,
    Button
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Styles from "../PaymentGateway.module.css";
import BasicModalWithHeader from "../common/modal/BasicModalWithHeader";
import AddOrEditTax from "./AddOrEditTax";
import InvoicePageSubHeader from "../common/header/InvoicePageSubHeader";

const TaxesHeader = ({ loading, onRenderCallback }) => {
//   const classes = InvoiceUseStyles();
  const [open, setOpen] = useState(false);
  const handleOpenModal = () => {
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };

  return (
    <div className={Styles.payment_info}>
      <Box sx={{ display: "flex", justifyContent: "space-between", flex: 1 }}>
        <Box>
          <InvoicePageSubHeader
            title={"Taxes"}
            description={'Manage your invoice tax settings. To enable taxes click "Add Tax".'}
          />
        </Box>

        <Box>
          <Button
            variant="contained"
            size="small"
            startIcon={<AddIcon />}
            style={{
              backgroundColor: "#3C7EF3",
              color: "white",
              fontSize: "16px",
              minWidth: 116
            }}
            onClick={handleOpenModal}
          >
            Add Tax
          </Button>

          <BasicModalWithHeader title={'Add Tax'} open={open} onClose={handleCloseModal}>
            <AddOrEditTax onRenderCallback={onRenderCallback} onClose={handleCloseModal} />
          </BasicModalWithHeader>
        </Box>
      </Box>
    </div>
  );
};

export default TaxesHeader;
