import { useState } from 'react';
import {AddIcon, CloseIcon, EditIcons} from '../helpers/IconList';
import { useCFContext } from '../reducer/CustomProvider';
import Styles from './style.module.css'
import {updateCustomFieldDataApi} from "../../../../api/CustomFieldApi";
import {handleSelectFolder, handleStoreFields, handleStoreFolders} from "../reducer/Actions";
import {updateLocalStorage} from "../CustomFieldV2App";

const MoveField = ({
  onClose=()=>{}, selectedItem, index
}) => {
  const {state, dispatch} = useCFContext();
  const [states, setStates] = useState({
    submitting: false,
    select: '',
    newGroup: ''
  });
  const handleGroupName = (name) => {
    setStates({...states, select: name, newGroup: ''})
  }

  const handleNewGroupName = (e) => {
    setStates({...states, select: '', newGroup: e.target.value})
  }

  const handleSubmitGroupField = () => {
    if(states.submitting){
      return
    }

    const validationCheck = handleFormValidation(states, state);
    if(validationCheck !== ""){
      window.showNotification('warning', validationCheck);
      setStates({...states, submitting: false})
      return;
    }

    try{
      if (states.select.toLowerCase() !== (state.selectedFolder && state.selectedFolder.toLowerCase()) || states.newGroup.toLowerCase() !== (state.selectedFolder && state.selectedFolder.toLowerCase())){
        setStates({...states, submitting: true});
        updateCustomFieldDataApi({
          'id': selectedItem.id,
          'field_type' : 'move_field',
          'previous_group_name' : state.selectedFolder,
          'newGroup': states.newGroup,
          'select': states.select
        }).then(res => {
          let response = res.data;
          if(response.status === 'success'){
            window.showNotification('success', response.html);
            onClose();
            let old_data = [...state.fields];
            old_data.splice(index, 1)

            if (!old_data[0]){
              let updatedFolders = state.folders.filter((folder)=>(folder.group_name.toLowerCase() !== (state.selectedFolder && state.selectedFolder.toLowerCase())));
              dispatch(handleStoreFolders(updatedFolders));
              dispatch(handleSelectFolder(null));
            }

            if (states.newGroup !== ''){
              dispatch(handleStoreFolders([ ...state.folders, { group_name: states.newGroup } ]));
            }

            dispatch(handleStoreFields(old_data));
            updateLocalStorage()
          }else{
            window.showNotification('error', response.html);
          }
        }).catch(error => {
          console.log(error)
        }).finally(()=>{
          setStates({...states, submitting: false});
        })
      }else {
        onClose();
      }
    }catch(error){

    }
  }
  const renderFolderGroup = () => {
    const view = [];
    state.folders.forEach((item, index) => {
      if (item.group_name.toLowerCase() !== (state.selectedFolder && state.selectedFolder.toLowerCase())){
        view.push(
            <span onClick={() => handleGroupName(item.group_name)} key={index} className={`${Styles.folderText} ${states.select === item.group_name ? Styles.selected : ''}`}>{item.group_name}</span>
        )
      }
    })
    return view;
  }
  return(
    <div className={Styles.mainWrp}>
      <div className={Styles.header}>
        <span className={Styles.headerText}>Move field to another folder</span>
      </div>

      <div className={Styles.body}>
        {
            state.folders.some((folder)=>(folder.group_name.toLowerCase() !== (state.selectedFolder && state.selectedFolder.toLowerCase()))) && (
                <>
                  <span className={Styles.textLabel}>Select a folder group</span>
                  <div className={Styles.folderWrp}>{renderFolderGroup()}</div>
                </>
          )
        }
        <span className={Styles.textLabel}>{state.folders.some((folder)=>(folder.group_name.toLowerCase() !== (state.selectedFolder && state.selectedFolder.toLowerCase()))) && (<small>OR</small> )} Create a new folder</span>
        <input className={Styles.folderInput} onChange={handleNewGroupName} value={states.newGroup}  placeholder='Enter folder name' />
      </div>

      <div className={Styles.footer}>
        <div className={`${Styles.button} ${Styles.cancelButton}`} onClick={onClose}>
          <span className={Styles.icon}><CloseIcon color={'#fff'} height="18px" width='18px' /></span>
          <span className={Styles.buttonText}>Cancel</span>
        </div>
        <div className={`${Styles.button} ${Styles.submitButton}`} onClick={handleSubmitGroupField}>
          <span className={Styles.icon}><EditIcons  height='18px' width='18px' color="#fff"/></span>
          <span className={Styles.buttonText}>{ states.submitting ? 'Submitting...' : 'Submit Move' }</span>
        </div>
      </div>
    </div>
  );
}
export default MoveField;
const handleFormValidation = (states,state) => {
  if(states.select.trim() === "" && states.newGroup.trim() === ""){
    return "Please select a folder or enter new folder name !";
  }

  if(state.folders.some((folder)=>(folder.group_name === states.newGroup))){
    return "Folder name already exist !";
  }

  return ""
}