/* middleware type */
export const ADD_LEAD_SOURCE = 'ADD_LEAD_SOURCE'
export const EDIT_LEAD_SOURCE = 'EDIT_LEAD_SOURCE'
export const DELETE_LEAD_SOURCE = 'DELETE_LEAD_SOURCE'
export const GET_LEAD_SOURCE_LIST_BY_PAGINATE = 'GET_LEAD_SOURCE_LIST_BY_PAGINATE'
export const GET_LEAD_SOURCE_LIST_ALL = 'GET_LEAD_SOURCE_LIST_ALL'


/* reducer types */
export const SHOW_LEAD_SOURCE_MODAL = 'SHOW_LEAD_SOURCE_MODAL'
export const TOGGLE_LEAD_SOURCE_EDIT = 'TOGGLE_LEAD_SOURCE_EDIT'
export const LEAD_SOURCE_SEARCH = 'LEAD_SOURCE_SEARCH'
export const LEAD_SOURCE_RELOAD_FLAG = 'LEAD_SOURCE_RELOAD_FLAG'
export const LEAD_SOURCE_PAGINATION = 'LEAD_SOURCE_PAGINATION'
export const SHOW_LEAD_SOURCE_DELETE_MODAL = 'SHOW_LEAD_SOURCE_DELETE_MODAL'