import React from "react";

const icons = {
    checkMark: <svg width="26" height="26" viewBox="0 0 2047 1829" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M750 1206.5C639.6 1069.3 382 825.667 267 721L1 1063.5C246.2 1209.9 620.5 1633.83 777 1827.5C1386.2 875.9 1876.83 468 2046 383C1964.8 310.2 1849.5 98 1802 1C1220 493.4 858.167 1009.83 750 1206.5Z" fill="url(#paint0_linear_606_12824)" stroke="black"/>
        <defs>
            <linearGradient id="paint0_linear_606_12824" x1="1023.5" y1="1" x2="1023.5" y2="1827.5" gradientUnits="userSpaceOnUse">
                <stop stopColor="#00D932"/>
                <stop offset="1" stopColor="#00870D"/>
            </linearGradient>
        </defs>
    </svg>,
    sortIcon: <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 11.5L5.14286 17.5L10.2857 11.5H0Z" fill="#546376"></path>
        <path d="M10.2852 6.5L5.1423 0.5L-0.000557899 6.5L10.2852 6.5Z" fill="#546376"></path>
    </svg>
};

export default icons;