const PhoneInput = ({fieldName="", callBack, value = "", placeHolder = "", extraClassName = "", rootClass="col xl8 l8 m8 pl-0 d-flex", isRequired=false}) => {
    return (
        <div className={rootClass}>
            <input className={"personal_info_text_field "+extraClassName}
                   type={'text'}
                   name={fieldName}
                   value={value}
                   onChange={(event)=>callBack(event.target.name,event.target.value)}
                   placeholder={placeHolder}
                   required={isRequired}
            />
        </div>
    )
}
export default PhoneInput;