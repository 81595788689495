import Drawer from '@material-ui/core/Drawer';
import Styles from './style.module.css';

const CustomDrawerPype = ({
  style={},
  isShow= false,
  anchor='right',
  drawerClassName= '',
  componentWrpClassName='',
  renderChild=() => {},
  backdropClick=true,
  hanldeDrawer=() => {}
}) =>  {
  const toggleDrawer = (open) => (event, reason) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if(backdropClick && reason === "backdropClick"){
      return;
    }
    hanldeDrawer(open)
  };
  return (
    <Drawer 
      open={isShow} 
      onClose={toggleDrawer(false)} 
      anchor={anchor} 
      className={`${Styles.pypeDrawer} ${drawerClassName}`}
      ModalProps={{
        disableEnforceFocus: true,
        disableAutoFocus: true,
      }}
      PaperProps={{
        style: {
          borderRadius: '10px 0px 0px 10px'
        }
      }}
    >
      <div className={`${Styles.pypeCustomDrawer} ${componentWrpClassName}`} style={{...style}} >
        {renderChild()}
      </div>
    </Drawer>
  );
}
export default CustomDrawerPype;