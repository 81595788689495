import {useEffect, useState} from "react";
import {PROFILE_TAB} from "../../../constants/CoreConstants";
import ImageUploadInput from "../../globals/ImageUploadInput";
import ImageShow from "../../globals/ImageShow";
import {connect} from "react-redux";
import {updateFavicon} from "../../../actions/profileAction";
import './favicon-info.css'


const FaviconInfo = (props) => {

    const [fav_icon,setFavIcon] = useState(null);
    const [loading,setLoading] = useState(true);
    const [uploaded_file,setUploadedFile] = useState(null);

    useEffect(() => {
        loadData();
    }, [props.user]); // eslint-disable-line react-hooks/exhaustive-deps


    const loadData = () => {
        //make request
        if(props.user) {
            setFavIcon(props.user.fav_icon);
        }
        setLoading(false);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if(!uploaded_file) {
          window.showNotification("ERROR", 'Favicon is not set')
            return false;
        }
        setLoading(true);
        //make request
        let formData = new FormData();
        formData.append("qqfile", uploaded_file[0]);
        formData.append("columns[]", 'id');
        formData.append("columns[]", 'fav_icon');

        props.updateFavicon(formData,(res) => {
            if(res.success) {
              window.showNotification("SUCCESS", res.message)
            } else {
              window.showNotification("ERROR", res.message)
            }

        });
        setLoading(false);

    };

    return (
        <div id="favicon_info" className={"profile_right_item"+(parseInt(props.active_tab) === PROFILE_TAB.FAVICON ? " active" : " d-none")}>
            <form method={'post'} onSubmit={(event) => handleSubmit(event)}>
                <div className="accent--bg--text--color p-4">
                    <h6 className="m-0">Favicon</h6>
                </div>
                <div className="company__favicon__container">
                <div className="company__favicon__row">
                <p className="company__favicon__row__title">My Favicon</p>
                    <div className="company__favicon__row__right">
                        <ImageShow
                            imageUrl = {fav_icon ?? "https://i.ibb.co/4Fb05kc/image.png"}
                            isServerImage = { false }
                            extraClassName={'responsive-img'}
                        />
                    </div>
                </div>
                <div className="company__favicon__row">
                    <div className="">
                        <p className="company__favicon__row__title">New Favicon</p>
                    </div>
                    <div className="company__favicon__row__right">
                        <p className="img__file__condition">Recommend image size 250 x 100px</p>
                        {/*<label>
                            <div className="upload__field py-3 mr-4">
                                <div className="place__holder">
                                    <i className="material-icons">
                                        cloud_upload
                                    </i>
                                    <p className="m-0">Drag & Drop files here</p>
                                    <p className="m-0">Or</p>
                                </div>
                                <span className="d-inline-block file_browse_btn py-3 mt-2">Browse Files</span>
                                <input className="d-none" type="file"/>
                            </div>
                        </label>*/}
                        <ImageUploadInput
                            callBack     = {setUploadedFile}
                            filesLimit   = {1}
                        />
                    </div>
                </div>
                </div>
                <div className="profile_right_footer py-3">
                    <div className="">
                        <div className="col l8 d-flex  align-items-center p-0">
                            <button disabled={loading} type={'submit'} className="mt-2 mb-3 p-2 profile_right_footer__btn__wr d-inline-flex justify-content-center align-items-center border-0 accent--bg--text--color"
                               href="#">
                                        <span
                                            className="mr-2 d-inline-flex justify-content-center align-items-center">
                                            <i className="material-icons">
                                                save
                                            </i>
                                        </span>
                                Save New Favicon
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}
const MapStateToProps = (state) => {
    return {
        user: state.profileReducer.user,
        response: state.profileReducer.response
    };
}
const MapDispatchToProps = (dispatch) => {
    return {
        updateFavicon: (params,callback) => dispatch(updateFavicon(params,callback))
    }
}
const FaviconInfoTab = connect(MapStateToProps,MapDispatchToProps)(FaviconInfo);
export default FaviconInfoTab;
