import { Fragment, useEffect, useRef, useState } from "react"
import styled from 'styled-components';
import Drawer from '@material-ui/core/Drawer';
import { Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SimpleReactValidator from 'simple-react-validator';
import Utils from "../../helpers/Utils";
import { mapOrdersSaveTitle } from "../../api/profileApi";

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E5E8EF;
    margin-left: 10px;
    margin-right: 10px;
`;

const Wrapper = styled.div`
    // margin: 10px;
    max-width: 100%;
    width: 500px;
`;

const TitlePopup = (props) => {
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState('');

    //validator
    const [, forceUpdate] = useState() //this is a dummy state, when form submitted, change the state so that message is rendered
    const simpleValidator = useRef(new SimpleReactValidator({
            autoForceUpdate: {forceUpdate: forceUpdate},
            className: 'small text-danger mdi mdi-alert pt-1 pl-1'
    }));

    useEffect(() => {
        if (props.data) {
            setTitle(props.data.title)
        }
    }, [props.data])

    const toggle = () => {
        props.hideModal(title);
    }

    const onSubmitHandle = (e) =>{
        e.preventDefault();
        
        if (simpleValidator.current.allValid()) {
            let formData = {
                order_id: props.data.id,
                title: title
            };

            setLoading(true);

            mapOrdersSaveTitle({
                "form_data": formData
            }).then((response) => {
                if (response.data.status === 'success') {
                    toggle();
                } else if (response.data.status === 'validation-error') {
                    for (var key of Object.keys(response.data.message)) {
                        let errors = response.data.message[key];
                        errors.forEach(error => {
                            Utils.showNotification(error, 'error');
                        });
                    }
                } else {
                    Utils.showNotification(response.data.message, 'error');
                }
            }).finally(() => {
                setLoading(false);
            });
        } else {
            simpleValidator.current.showMessages();
            forceUpdate(1);
        }

    }

    return (
        <Fragment>
            <Drawer anchor={'right'} open={props.open} onClose={toggle}>
                <Wrapper className={"manage-tag-modal add_list_modal " + props.customClassName }>
                    <Grid container>
                        <Grid item xs={12}>
                            <Header>
                                <h5 className="map__order__name__title">{ props.title}</h5>
                                <div class="modal-close cross-icon d-flex justify-content-center align-items-center" onClick={toggle}>
                                    <CloseIcon/>
                                </div>
                            </Header>
                            <Grid item xs={12} className={"p-4"}>
                                <form onSubmit={onSubmitHandle}>
                                    <div class="modal-content">
                                        <div class="modal_body">
                                            <input class="modal_input" type="text" placeholder="Order Title" value={title} onChange={e => setTitle(e.target.value)}/>
                                            {simpleValidator.current.message('title', title, 'required')}
                                        </div>
                                        <div class="modal--footer">
                                            <button disabled={loading} type="submit" class="btn-primary"><svg width="20" height="20" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.34465 3.9985C8.34465 4.35298 8.31388 4.63952 7.95939 4.63952H5.13952V7.45939C5.13952 7.81324 4.85298 7.84465 4.4985 7.84465C4.14401 7.84465 3.85747 7.81324 3.85747 7.45939V4.63952H1.0376C0.683754 4.63952 0.652344 4.35298 0.652344 3.9985C0.652344 3.64401 0.683754 3.35747 1.0376 3.35747H3.85747V0.5376C3.85747 0.183113 4.14401 0.152344 4.4985 0.152344C4.85298 0.152344 5.13952 0.183113 5.13952 0.5376V3.35747H7.95939C8.31388 3.35747 8.34465 3.64401 8.34465 3.9985Z" fill="#3C7EF3"></path>
                                            </svg> {loading ? 'Saving..' : 'Save Title'}</button>
                                            <button type="button" class="btn-danger modal-close" onClick={toggle}>
                                                <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M18.1016 3.90156C14.2016 0.0015626 7.80156 0.0015626 3.90156 3.90156C0.00156271 7.80156 0.00156271 14.2016 3.90156 18.1016C7.80156 22.0016 14.1016 22.0016 18.0016 18.1016C21.9016 14.2016 22.0016 7.80156 18.1016 3.90156ZM13.8016 15.2016L11.0016 12.4016L8.20156 15.2016L6.80156 13.8016L9.60156 11.0016L6.80156 8.20156L8.20156 6.80156L11.0016 9.60156L13.8016 6.80156L15.2016 8.20156L12.4016 11.0016L15.2016 13.8016L13.8016 15.2016Z" fill="white"></path>
                                                    </svg>
                                                    Cancel
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </Grid>
                        </Grid>
                    </Grid>
                </Wrapper>
            </Drawer>
        </Fragment>
    )
}

export default TitlePopup;