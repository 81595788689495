import * as Action from './ActionTypes'

export const handleStates = (payload) => ({
  type: Action.HANDLE_STATES,
  payload: payload
});
export const handleStoreFolders = (payload) => ({
  type: Action.HANDLE_STORE_FOLDERS,
  payload: payload
});
export const handleSelectFolder = (payload) => ({
  type: Action.HANDLE_SELECT_FOLDER,
  payload: payload
});
export const handleStoreFields = (payload) => ({
  type: Action.HANDLE_STORE_FIELDS,
  payload: payload
});
export const handlePaginationPage = (payload) => ({
  type: Action.HANDLE_PAGINATION_PAGE,
  payload: payload
});
export const handlePaginationTotal = (payload) => ({
  type: Action.HANDLE_PAGINATION_TOTAL,
  payload: payload
});