import React, { useContext, useState, useRef } from "react";
import GlobalModal from "../globals/Modal/GlobalModal";
import Utils from "../../helpers/Utils";
import Select from "@material-ui/core/Select";
import { saveUserPaymentCredential } from '../../api/InvoiceApi';
import MenuItem from "@material-ui/core/MenuItem";
import { Save } from "@material-ui/icons";
import { InvoiceContext } from "./InvoiceProvider";
import ActionTypes from "./ActionTypes";
import './InvoiceModal.css';
import SimpleReactValidator from 'simple-react-validator';
import Loader from "../globals/Loader";


const GATEWAY_TYPE_STRIPE = "STRIPE";
// const GATEWAY_TYPE_PAYPAL = "PAYPAL";

const gatewayCreds =
{
  [GATEWAY_TYPE_STRIPE]: [
    {
      type: "text",
      label: "Publishable Key",
      name: "publishable_key",
      required: true,
      placeholder: "Enter the publishable key"
    },
    {
      type: "password",
      label: "Secret Key",
      name: "secret_key",
      required: true,
      placeholder: '***********'
    }
  ],
  // [GATEWAY_TYPE_PAYPAL]: [
  //   {
  //     type: "text",
  //     label: "ABCD",
  //     name: "publishable_key",
  //     required: true,
  //     placeholder:"Enter the publishable key"
  //   },
  //   {
  //     type: "password",
  //     label: "EFGH",
  //     name: "secret_key",
  //     required: true,
  //     placeholder:'***********'
  //   }
  // ]
};


export default function App() {
  const { state, dispatch } = useContext(InvoiceContext);
  const [openModal, setOpenModal] = useState(false);
  const [name, setName] = useState("");
  const [selectedPaymentGateway, setSelectedPaymentGateway] = useState(GATEWAY_TYPE_STRIPE);
  const [paymentCredentials, setPaymentCredentials] = useState({});
  const [submitting, setSubmitting] = useState(false);

  //validator
  const [forceUpdate, setForceUpdate] = useState(false);
  const simpleValidator = useRef(new SimpleReactValidator({
    autoForceUpdate: { forceUpdate: setForceUpdate },
    className: 'small text-danger mdi mdi-alert pt-1 pl-1'
  }));

  const CreateNewIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="10"></circle>
      <path d="M13.8447 9.9985C13.8447 10.353 13.8139 10.6395 13.4594 10.6395H10.6395V13.4594C10.6395 13.8132 10.353 13.8447 9.9985 13.8447C9.64401 13.8447 9.35747 13.8132 9.35747 13.4594V10.6395H6.5376C6.18375 10.6395 6.15234 10.353 6.15234 9.9985C6.15234 9.64401 6.18375 9.35747 6.5376 9.35747H9.35747V6.5376C9.35747 6.18311 9.64401 6.15234 9.9985 6.15234C10.353 6.15234 10.6395 6.18311 10.6395 6.5376V9.35747H13.4594C13.8139 9.35747 13.8447 9.64401 13.8447 9.9985Z" className="accent--fill--color"></path>
    </svg>
  );

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setName("");
    setPaymentCredentials({});
    setOpenModal(false);
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    if (!submitting) {
      setSubmitting(true);
      if (simpleValidator.current.allValid()) {
        saveUserPaymentCredential({
          "payment_credentials": paymentCredentials,
          "type": selectedPaymentGateway,
          "payment_name": name
        })
          .then((res) => {
            res = res.data;
            if (res.success) {
              handleCloseModal();
              dispatch({
                type: ActionTypes.SAVE_CREDENTIAL,
                payload: res.data,
              });
              Utils.showNotification("Payment credential saved successfully", "success");
            } else {
              // Show error message from API response in the UI
              Utils.showNotification(res.message || "Error occurred while saving payment credentials", "error");
            }
          })
          .catch((error) => {
            // Handle API request error
            Utils.showNotification("Error occurred while saving payment credentials", "error");
          })
          .finally(() => {
            setSubmitting(false);

          })
      } else {
        simpleValidator.current.showMessages();
        setForceUpdate(true);
        setSubmitting(false);

      }
    }

  };

  const handlePaymentGatewayCredsFields = (name, value) => {
    setPaymentCredentials({ ...paymentCredentials, [name]: value });
  };

  return (
    <div>
      <a href="#!" className="modal-trigger accent--bg--text--color mr-2" onClick={(e) => {
        e.preventDefault();
        handleOpenModal();
      }}>
        <span><CreateNewIcon /></span> <span>Create New</span>
      </a>

      <GlobalModal
        open={openModal}
        onClose={handleCloseModal}
        title="Payment Credential Form"
        onSubmit={handleSubmit}
        buttonText={submitting ? "Saving..." : "Save"}
        buttonIcon={<Save />}
        className="global-750-modal global-modal-avoid-header global-xs-modal invoice-list"
      >
        <div className="invoice_form_wrapper">

          {submitting &&
            <div className="loader-container">
              <Loader />
            </div>}
          <div className="input-field custom_input">
            <h5 className="label_text">Name</h5>
            <input
              placeholder="Enter Name"
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {simpleValidator.current.message('name', name, 'required')}
          </div>
          <div className="input-field">
            <h5 className="label_text">Select Payment Gateway</h5>

            <Select
              labelId="select_payment_gateway"
              value={selectedPaymentGateway}
              className="select_gateway"
              onChange={(e) => {
                setSelectedPaymentGateway(e.target.value);
                setPaymentCredentials({});
              }}
            >
              <MenuItem disabled value="">
                Select a payment gateway
              </MenuItem>
              {Object.keys(gatewayCreds).map((gatewayType) => (
                <MenuItem key={gatewayType} value={gatewayType}>
                  {gatewayType}
                </MenuItem>
              ))}
            </Select>

          </div>
          {
            gatewayCreds[selectedPaymentGateway]?.map((field, index) => (
              <div className="input-field custom_input" key={index}>
                <h5 className="label_text">{field.label}</h5>
                <input onChange={(e) => { handlePaymentGatewayCredsFields(field.name, e.target.value) }} type={field.type} autocomplete="off" placeholder={field.placeholder} />
                {simpleValidator.current.message(field.name, paymentCredentials[field.name], 'required')}
              </div>
            ))
          }
        </div>
      </GlobalModal>
    </div>
  );
}