import React from "react";
import {Typography} from "@material-ui/core";
import globalEmptyPageUseStyles from "./globalEmptyPageUseStyles";

const GlobalEmptyPage = ({image, icon, title, description}) => {
    const classes = globalEmptyPageUseStyles();

    return (
        <div className={classes.wrapper}>
            {image && (
                <div className={classes.imageWrapper}>
                    <img src={image} alt='Not Found'/>
                </div>
            )}
            {icon && <div className={classes.iconWrapper}>{icon}</div>}
            <Typography variant='h4' mt={4}>
                {title}
            </Typography>
            <Typography variant='body1medium' color='text.secondary' sx={{maxWidth: "520px", textAlign: "center"}}>
                {description}
            </Typography>
        </div>
    );
};

export default GlobalEmptyPage;
