import { useEffect, useRef, useState } from "react"
import SimpleReactValidator from 'simple-react-validator';
import Utils from "../../helpers/Utils";
import { saveTag } from "../../api/profileApi";
import GlobalModal from "../../components/globals/Modal/GlobalModal";
import SaveIcon from "@material-ui/icons/Save";
import Loader from "../globals/Loader";


const Popup = (props) => {
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState('');

    //validator
    const [, forceUpdate] = useState() //this is a dummy state, when form submitted, change the state so that message is rendered
    const simpleValidator = useRef(new SimpleReactValidator({
            autoForceUpdate: {forceUpdate: forceUpdate},
            className: 'small text-danger mdi mdi-alert pt-1 pl-1'
    }));

    useEffect(() => {
        if (props.data) {
            setTitle(props.data.name)
        }
    }, [props.data])

    const toggle = () => {
        props.hideModal();
    }

    const onSubmitHandle = (e) =>{
        e.preventDefault();
        
        if (simpleValidator.current.allValid()) {
            let formData;

            if (!props.data) {
                formData = {
                    name: title
                };
            } else {
                formData = {
                    name: title,
                    id: props.data.id
                };
            }

            setLoading(true);

            saveTag({
                "form_data": formData
            }).then((response) => {
                if (response.data.status === 'success') {
                    toggle();
                } else if (response.data.status === 'validation-error') {
                    for (var key of Object.keys(response.data.message)) {
                        let errors = response.data.message[key];
                        errors.forEach(error => {
                            Utils.showNotification(error, 'error');
                        });
                    }
                } else {
                    Utils.showNotification(response.data.message, 'error');
                }
            }).finally(() => {
                setLoading(false);
            });
        } else {
            simpleValidator.current.showMessages();
            forceUpdate(1);
        }

    }

    return (
        <GlobalModal
            open={props.isOpen}
            onClose={() => props.hideModal()}
            title={props.title}
            buttonIcon={<SaveIcon />}
            buttonText="Save Tag"
            className={'global-xs-modal'}
            onSubmit={onSubmitHandle}>
            <div className="modal-content">
                {loading ?
                    <div className="modal_body">
                        <Loader />
                    </div>
                    :
                    <div className="modal_body">
                        <input className="modal_input" type="text" placeholder="Tag Name" value={title} onChange={e => setTitle(e.target.value)}/>
                        {simpleValidator.current.message('name', title, 'required')}
                    </div>
                }
            </div>
        </GlobalModal>
    )
}

export default Popup;