import { makeStyles } from "@material-ui/core";

const commonUseStyles = makeStyles((theme) => ({
    table: {
        border: `1px solid ${theme.palette.divider}`,
        borderBottom: "none",
        "& .MuiTableRow-root": {
            "& .MuiTableCell-root": { borderBottom: `1px solid ${theme.palette.divider}` },
        },
    }
}))

export default commonUseStyles;