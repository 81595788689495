import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import PaymentForm from './PaymentForm';

const Payment = (props) => {
    const stripePromise = loadStripe(props.stripePublicKey);

    return (
        <Elements stripe={stripePromise}>
            <PaymentForm callback={props.callback}/>
        </Elements>
    )
}

export default Payment;