import {useRef, useState} from "react";
import {PROFILE_TAB} from "../../../constants/CoreConstants";
import TextInput from "../../globals/TextInput";
import SimpleReactValidator from "simple-react-validator";
import {connect} from "react-redux";
import { updateUserPassword} from "../../../actions/profileAction";

const ChangePassword = (props) => {
    let passwordInitialValue = {
        old_password:'',
        new_password:'',
        retype_password:''
    };
    
    const [password, setPassword] = useState(passwordInitialValue);
    const [, forceUpdate] = useState();

    const validator = useRef(new SimpleReactValidator({
        className: 'text-danger',
        autoForceUpdate: {forceUpdate: forceUpdate
        }}));

    const callback = (name,value) => {
        // const { name, value } = e.target;
        setPassword(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const formValid = validator.current.allValid();
        if (formValid) {
            //make request
            props.updateUserPassword({
                "form_data": password
            },(res) => {
                if(res.success) {
                  window.showNotification("SUCCESS", res.message)
                } else {
                    let message = '';
                    if (res.status === 'validation-error') {
                        message = res.message[Object.keys(res.message)[0]][0];
                    } else {
                        message = res.message;
                    }
                    window.showNotification("ERROR", message)
                }
            });
        }else{
            validator.current.showMessages();
            forceUpdate(true);
        }
    };

    return (
        <div id="change__password" className={"profile_right_item"+(parseInt(props.active_tab) === PROFILE_TAB.CHANGE_PASSWORD ? " active" : " d-none")}>
            <form method={'post'} onSubmit={(event) => handleSubmit(event)} className="profile__right__side__wr">
                <div className=" accent--bg--text--color p-4">
                    <h6 className="m-0">Change Password</h6>
                </div>
                <div className="profile__right__side__single__container">
                    <div className="profile__right__side__single">
                        <div className="">
                            <p className="profile__right__side__single__p">Current <span className="d-inline-flex">Password <span className="required__field">*</span></span></p>
                        </div>
                        <div className="">
                            <TextInput
                                fieldName    = {'old_password'}
                                callBack     = {callback}
                                value        = {password.old_password}
                                fieldType    = {'password'}
                                placeHolder  = "******"
                                extraClassName = ""
                                required = {true}
                            />
                            {validator.current.message('old_password', password.old_password, 'required|min:6|max:120')}
                        </div>
                    </div>
                    <div className="profile__right__side__single">
                        <div className="">
                            <p className="profile__right__side__single__p">New <span className="d-inline-flex">Password <span className="required__field">*</span></span></p>
                        </div>
                        <div className="">
                            <TextInput
                                fieldName    = {'new_password'}
                                callBack     = {callback}
                                value        = {password.new_password}
                                fieldType    = {'password'}
                                placeHolder  = "******"
                                extraClassName = ""
                                required = {true}
                            />
                            {validator.current.message('new_password', password.new_password, 'required|min:6|max:120')}
                        </div>
                    </div>
                    <div className="profile__right__side__single">
                        <div className="">
                            <p className="profile__right__side__single__p">Retype <span className="d-inline-flex">Password <span className="required__field">*</span></span></p>
                        </div>
                        <div className="">
                            <TextInput
                                fieldName    = {'retype_password'}
                                callBack     = {callback}
                                value        = {password.retype_password}
                                fieldType    = {'password'}
                                placeHolder  = "******"
                                extraClassName = ""
                                required = {true}
                            />
                            {validator.current.message('retype_password', password.retype_password, `required|min:6|max:120|in:${password.new_password}`, {messages: {in: 'Passwords need to match with new password!'}})}

                        </div>
                    </div>
                </div>
                <div className="profile_right_footer mt-3">
                    <div className="row">
                        <div className="col l8 d-flex align-items-center">
                            <button type={'submit'} className="mt-3 p-2 profile_right_footer__btn__wr d-inline-flex justify-content-center align-items-center border-0 accent--bg--text--color" href="#">
                                        <span
                                            className="mr-2 d-inline-flex justify-content-center align-items-center">
                                            <i className="material-icons">
                                                save
                                            </i>
                                        </span>
                                Save New Password
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}
const MapStateToProps = (state) => {
    return {
        user: state.profileReducer.user,
        response: state.profileReducer.response
    };
}
const MapDispatchToProps = (dispatch) => {
    return {
        updateUserPassword: (params,callBack) => dispatch(updateUserPassword(params,callBack))
    }
}
const ChangePasswordTab = connect(MapStateToProps,MapDispatchToProps)(ChangePassword);
export default ChangePasswordTab;