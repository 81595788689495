import React from "react";
import { Box, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddOrEditTax from "./AddOrEditTax";
import useBoolean from "../../../hooks/useBoolean";
import BasicModalWithHeader from "../common/modal/BasicModalWithHeader";
import NewConfirmAlert from "../../activity/new-alert/NewConfirmAlert";
import { deleteTaxInvoice } from "../../../api/InvoiceApi";

const TaxItemAction = ({ taxInfo, onRenderCallback }) => {
  const {value: open, setTrue: onOpenModal, setFalse: onCloseModal} = useBoolean(false);


  const handleDelete = () => {
    NewConfirmAlert({
      onSubmit: () => {
        deleteTaxInvoice({ id: taxInfo.id })
          .then((res) => {
            res = res.data;
            if (res.success) {
              onRenderCallback();
              window.showNotification("SUCCESS", res.message);
            } else {
              window.showNotification(
                "ERROR",
                res.message || "Error occurred while deleting data"
              );
            }
          })
          .catch((error) => {
            window.showNotification(
              "Error occurred while deleting data",
              "error"
            );
          });
      },
      title: "Are You Sure?",
      description: "Do you want to delete this information?",
      cancelText: "Cancel",
      submitText: "Confirm",
      width: "480px",
    });
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: 10,
        }}
      >
        <IconButton size="small" onClick={onOpenModal}>
          <EditIcon style={{ fontSize: 16 }} />
        </IconButton>
        <IconButton size="small" onClick={handleDelete}>
          <DeleteIcon style={{ color: "#FF264A", fontSize: 16 }} />
        </IconButton>
      </Box>
      <BasicModalWithHeader
        title={"Edit Tax"}
        open={open}
        onClose={onCloseModal}
      >
        <AddOrEditTax
          isEdit={true}
          taxInfo={taxInfo}
          onClose={onCloseModal}
          onRenderCallback={onRenderCallback}
        />
      </BasicModalWithHeader>
    </>
  );
};

export default TaxItemAction;
