import React from "react";

const icons = {
    checkMark: <svg width="26" height="26" viewBox="0 0 2047 1829" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M750 1206.5C639.6 1069.3 382 825.667 267 721L1 1063.5C246.2 1209.9 620.5 1633.83 777 1827.5C1386.2 875.9 1876.83 468 2046 383C1964.8 310.2 1849.5 98 1802 1C1220 493.4 858.167 1009.83 750 1206.5Z" fill="url(#paint0_linear_606_12824)" stroke="black"/>
        <defs>
            <linearGradient id="paint0_linear_606_12824" x1="1023.5" y1="1" x2="1023.5" y2="1827.5" gradientUnits="userSpaceOnUse">
                <stop stopColor="#00D932"/>
                <stop offset="1" stopColor="#00870D"/>
            </linearGradient>
        </defs>
    </svg>,
    info: <svg height="14" viewBox="0 0 48 48" width="14" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h48v48h-48z" fill="none"/><path d="M22 34h4v-12h-4v12zm2-30c-11.05 0-20 8.95-20 20s8.95 20 20 20 20-8.95 20-20-8.95-20-20-20zm0 36c-8.82 0-16-7.18-16-16s7.18-16 16-16 16 7.18 16 16-7.18 16-16 16zm-2-22h4v-4h-4v4z"/></svg>
};

export default icons;