import React, { useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { Box, Button, Divider, Grid } from "@material-ui/core";
import DefaultEditor from "../common/Editor/DefaultEditor";
import PersonalizedTag from "../common/Personalized/PersonalizedTag";
import { insertPersonalizedTag } from "../common/utils/insertPersonalizedTag";
import {
  addEmailTemplateInvoice,
  getEmailTemplate,
} from "../../../api/InvoiceApi";
import { PERSONALIZED_TAGS_FOR_EMAIL_SEND } from "../../../constants/personalizedTags";
import InvoiceUseStyles from "../invoiceUseStyles";

const initialState = {
  subject: "",
  body: "",
};

const SendWithMail = () => {
  const classes = InvoiceUseStyles();
  const [loading, setLoading] = useState(false);
  const [, setForceUpdate] = useState(false);
  const [formData, setFormData] = useState(initialState);

  const subjectRef = useRef(null);
  const bodyRef = useRef(null);

  const handleChangeInput = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const simpleValidator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: setForceUpdate },
      className: "small text-danger mdi mdi-alert pt-1 pl-1",
    })
  );

  useEffect(() => {
    getEmailTemplate().then((res) => {
      res = res.data;
      if (res.success) {
        const { id, subject, body } = res.data || {};
        if (id) setFormData({ subject, body });
      } else {
        window.showNotification("ERROR", res.message);
      }
    });
  }, []);

  const handleSubmit = () => {
    if (simpleValidator.current.allValid()) {
      try {
        const requestBody = {
          subject: formData.subject,
          body: formData.body,
        };

        if (requestBody) {
          addEmailTemplateInvoice(requestBody)
            .then((res) => {
              res = res.data;
              if (res.success) {
                window.showNotification("SUCCESS", res.message);
                // onRenderCallback();
              } else {
                window.showNotification("ERROR", res.message);
              }
            })
            .catch((error) => {
              window.showNotification("ERROR", error.response.data.message);
            })
            .finally(() => setLoading(false));
          window.showNotification(
            "SUCCESS",
            "Email template saved successfully"
          );
        } else {
          window.showNotification("ERROR", "res.message");
        }
      } catch (error) {
        console.error("An error occurred:", error.response.data.message);
      }
    } else {
      simpleValidator.current.showMessages();
      setForceUpdate(true);
    }
  };

  return (
    <>
      <Box
        alignItems={"center"}
        p={2.5}
        sx={{ display: "flex", bgcolor: "#006df514" }}
      >
        <h5 style={{ margin: 0 }}>Email Template</h5>

        <Divider light />
      </Box>

      <Box component="form" sx={{ bgcolor: "white", p: 3, pt: 0, mb: 4 }}>
        <Grid container style={{gap:'16px'}}>
          <Grid item xs={12}>
            <Box >
              <p className={classes.label_text}>Subject *</p>

              <Box sx={{ display: "flex", gap: 20 }}>
                <input
                  type="text"
                  placeholder="Type email subject"
                  className={`${classes.itemInput}`}
                  style={{ height: 38 }}
                  name="subject"
                  value={formData.subject}
                  ref={subjectRef}
                  maxLength={200}
                  onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                />

                {simpleValidator.current.message(
                  "Email Subject",
                  formData.subject,
                  "required|max: 200"
                )}

                <PersonalizedTag
                  onClick={(personalizedTag) =>
                    insertPersonalizedTag(
                      personalizedTag,
                      subjectRef,
                      (value) => handleChangeInput("subject", value)
                    )
                  }
                />
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ mt: -2 }} className="editor-container">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p className={classes.label_text}>Email Body *</p>
                <PersonalizedTag
                  onClick={(personalizedTag) =>
                    insertPersonalizedTag(personalizedTag, bodyRef, (value) =>
                      handleChangeInput("body", value)
                    )
                  }
                  personalizeTags={PERSONALIZED_TAGS_FOR_EMAIL_SEND}
                />
              </Box>

              <DefaultEditor
                name={"body"}
                value={formData.body}
                onChange={(value) => handleChangeInput("body", value)}
                forwardRef={bodyRef}
              />

              {simpleValidator.current.message(
                "Email Body",
                formData.body,
                "required"
              )}
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#3C7EF3",
              color: "white",
              width: "115px",
              fontSize: "16px",
            }}
            onClick={handleSubmit}
            disabled={loading}
          >
            Save
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default SendWithMail;
