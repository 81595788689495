import { all, takeLatest, call } from 'redux-saga/effects';
import * as ACTION from '../constants/customFieldActionTypes'
import * as API from '../api/CustomFieldApi';
import Utils from "./../helpers/Utils";

function* customFieldWatcher() {
    yield takeLatest(ACTION.LOAD_CUSTOM_FIELD, loadCustomFieldMiddleware)
    yield takeLatest(ACTION.UPDATE_CUSTOM_FIELD, updateCustomFieldMiddleware)
    yield takeLatest(ACTION.UPDATE_CUSTOM_FIELD_LENGTH, updateCustomFieldLengthMiddleware)
    yield takeLatest(ACTION.DELETE_CUSTOM_FIELD, deleteCustomFieldMiddleware)
    yield takeLatest(ACTION.CHANGE_CUSTOM_FIELD_STATUS, changeCustomFieldStatusMiddleware)
    yield takeLatest(ACTION.CUSTOM_FIELD_ADD, customFieldAddsMiddleware)
    yield takeLatest(ACTION.CHANGE_CUSTOM_FIELD_VALUE, changeCustomFieldValueMiddleware)
    yield takeLatest(ACTION.LOAD_USER_PERSONALIZED_DATA, loadUserPersonalizedDataMiddleware)
    yield takeLatest(ACTION.CREATE_PERSONALIZED_FIELD_DATA, createPersonalizedFieldDataMiddleware)
    yield takeLatest(ACTION.UPDATE_PERSONALIZED_VALUE, updatePersonalizedFieldValueMiddleware)
    yield takeLatest(ACTION.DELETE_PERSONALIZED_TAG, deletePersonalizedTagMiddleware)
    yield takeLatest(ACTION.CREATE_NEW_PERSONALIZED_FIELD, createNewPersonalizedFieldMiddleware)
}

function* loadCustomFieldMiddleware(action) {
    try {
        const { payload } = action;
        const response = yield call(API.loadCustomFieldsApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* updateCustomFieldMiddleware(action) {
    try {
        const { payload } = action;
        const response = yield call(API.updateCustomFieldApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* updateCustomFieldLengthMiddleware(action) {
    try {
        const { payload } = action;
        const response = yield call(API.updateCustomFieldLengthApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* deleteCustomFieldMiddleware(action) {
    try {
        const { payload } = action;
        const response = yield call(API.deleteCustomFieldApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* changeCustomFieldStatusMiddleware(action) {
    try {
        const { payload } = action;
        const response = yield call(API.changeCustomFieldStatusApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* changeCustomFieldValueMiddleware(action) {
    try {
        const { payload } = action;
        const response = yield call(API.changeCustomFieldValueApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* customFieldAddsMiddleware(action) {
    try {
        const { payload } = action;
        const response = yield call(API.customFieldAddsApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* loadUserPersonalizedDataMiddleware(action) {
    try {
        const { payload } = action;
        const response = yield call(API.loadUserPersonalizedDataApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* createPersonalizedFieldDataMiddleware(action) {
    try {
        const { payload } = action;
        const response = yield call(API.createPersonalizedFieldDataApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* updatePersonalizedFieldValueMiddleware(action) {
    try {
        const { payload } = action;
        const response = yield call(API.updatePersonalizedFieldValueApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* deletePersonalizedTagMiddleware(action) {
    try {
        const { payload } = action;
        const response = yield call(API.deletePersonalizedTagApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* createNewPersonalizedFieldMiddleware(action) {
    try {
        const { payload } = action;
        const response = yield call(API.createNewPersonalizedFieldApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

export default function* customFieldMiddleware() {
    yield all([
        customFieldWatcher()
    ])
}