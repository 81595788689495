import * as ACTION from "../constants/customFieldActionTypes";
import { RELOAD_CUSTOM_FIELD_FLAG, RELOAD_PERSONALIZED_TAG_FLAG } from "../constants/profileActionTypes";

export const loadCustomFields = (payload, callback) => ({
    type: ACTION.LOAD_CUSTOM_FIELD,
    payload: {payload, callback}
});


export const updateCustomField = (payload, callback) => ({
    type: ACTION.UPDATE_CUSTOM_FIELD,
    payload: {payload, callback}
});

export const updateCustomFieldLength = (payload, callback) => ({
    type: ACTION.UPDATE_CUSTOM_FIELD_LENGTH,
    payload: {payload, callback}
});

export const deleteCustomField = (payload, callback) => ({
    type: ACTION.DELETE_CUSTOM_FIELD,
    payload: {payload, callback}
});

export const reloadCustomField = (payload) => ({
    type: RELOAD_CUSTOM_FIELD_FLAG,
    payload: {payload}
});

export const changeCustomFieldStatus = (payload, callback) => ({
    type: ACTION.CHANGE_CUSTOM_FIELD_STATUS,
    payload: {payload, callback}
});

export const customFieldAdd = (payload, callback) => ({
    type: ACTION.CUSTOM_FIELD_ADD,
    payload: {payload, callback}
});

export const changeCustomFieldValue = (payload, callback) => ({
    type: ACTION.CHANGE_CUSTOM_FIELD_VALUE,
    payload: {payload, callback}
});

export const loadUserPersonalizedData = (payload, callback) => ({
    type: ACTION.LOAD_USER_PERSONALIZED_DATA,
    payload: {payload, callback}
});

export const createPersonalizedFieldData = (payload, callback) => ({
    type: ACTION.CREATE_PERSONALIZED_FIELD_DATA,
    payload: {payload, callback}
});

export const updatePersonalizedFieldValue = (payload, callback) => ({
    type: ACTION.UPDATE_PERSONALIZED_VALUE,
    payload: {payload, callback}
});

export const deletePersonalizedTag = (payload, callback) => ({
    type: ACTION.DELETE_PERSONALIZED_TAG,
    payload: {payload, callback}
});

export const reloadPersonalizedTag = (payload) => ({
    type: RELOAD_PERSONALIZED_TAG_FLAG,
    payload: {payload}
});

export const createNewPersonalizedField = (payload, callback) => ({
    type: ACTION.CREATE_NEW_PERSONALIZED_FIELD,
    payload: {payload, callback}
});