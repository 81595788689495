import React from "react";

const StepLeft = ({step,title,content='',content2='',img}) => {
    return(
        <div className="geo__how__work__wr">
            <div className="geo__how__work__wr__left">
                <h2 className="geo__how__work__wr__left__step">{step}</h2>
                <h3 className="geo__how__work__wr__left__title">{title}</h3>
                <p className="geo__how__work__wr__left__content">{content}</p>
                <p>{content2}</p>
            </div>
            <div className="geo__how__work__wr_right">
                <img src={img}/>
            </div>
           
        </div>
    )
}


export default StepLeft;