import React from "react";
import TelegramIcon from '@material-ui/icons/Telegram';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import VideocamIcon from '@material-ui/icons/Videocam';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import VoicemailIcon from '@material-ui/icons/Voicemail';

const StepRight = ({step,title,content="",img}) => {
    return(
        <div className="geo__step__right__wr">
            <div className="geo__step__right__left">
                <img src={img}/>
            </div>
            <div className="geo__step__right__right">
                <h2 className="geo__step__right__right__step">{step}</h2>
                <h3 className="geo__step__right__right__title">{title}</h3>
                <p className="geo__step__right__right__content">{content}</p>
                
                <div className="geo__marketing__social">
                    <div className="geo__marketing__social__single">
                        <TelegramIcon/><span>Direct Mail & Postcards</span>
                    </div>
                    <div className="geo__marketing__social__single">
                        <AllInboxIcon/><span>Email Marketing</span>
                    </div>
                    <div className="geo__marketing__social__single">
                        <VideocamIcon/><span>Video Email</span>
                    </div>
                    <div className="geo__marketing__social__single">
                        <ChatBubbleOutlineIcon/><span>Text Message Marketing</span>
                    </div>
                    <div className="geo__marketing__social__single">
                        <VoicemailIcon/><span>Ringless Voicemail</span>
                    </div>
                   
            
                </div>
            </div>
          
        </div>
    )
}


export default StepRight;