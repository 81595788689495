import React, { Fragment, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, Button } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { CompanyInfo } from "./CompanyInfo";
import PaymentGateWay from "./PaymentGateWay";
import EmailTextTemplates from "./EmailTextTemplates";
import { ProductsServices } from "./ProductAndServices/ProductAndServices";
import InvoiceReminders from "./InvoiceReminders/InvoiceReminders";
import Utils from "../../helpers/Utils";
import Styles from "./InvoiceSettings.module.css";

const TAB_COMPANY = 0;
const TAB_PAYMENT = 1;
const TAB_PRODUCTS = 2;
const TAB_TEMPLATES = 3;
const TAB_INVOICE_REMINDERS = 4;

const InvoiceSettings = () => {
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState(TAB_COMPANY);
  const wrpRef = useRef(null);

  useEffect(() => {
    if (history.location.state?.tab) {
      setSelectedTab(history.location.state.tab);
    }

    document.title = `Invoice Settings | ${Utils.getAccountData(
      "AuthUserAgencyName"
    )}`;
    if (window?.setActiveSidebar) {
      window.setActiveSidebar("setting");
    }

    // setTimeout(() => {
    //   wrpRef.current.scrollIntoView({
    //     behavior: "smooth",
    //     block: "start",
    //     inline: "start",
    //   });
    // }, 0);
  }, []);

  const handleGoBack = () => {
    if (history.location.state?.prevPath) {
      window.open(history.location.state.prevPath, "_self");
    } else {
      history.goBack();
    }
  };

  return (
    <Fragment>
      <div
        className={`${Styles.settings_section1}`}
        id="element_within_div"
        ref={wrpRef}
      >
        <Fragment>
          <Box
            sx={{
              position: "absolute",
              top: 4,
              right: 20,
              zIndex: 99,
            }}
          >
            <Button
              variant="contained"
              size="small"
              startIcon={<ArrowBack />}
              style={{
                backgroundColor: "#3C7EF3",
                color: "white",
                fontSize: "16px",
                "& .MuiButton-label": {
                  marginLeft: "0 !important",
                },
              }}
              onClick={handleGoBack}
            >
              Back
            </Button>
          </Box>

          <ul className={`${Styles.tabs}`}>
            <li
              className={`${Styles.tab} ${
                selectedTab === TAB_COMPANY ? Styles.active : ""
              }`}
              onClick={(e) => {
                e.preventDefault();
                setSelectedTab(TAB_COMPANY);
              }}
            >
              <a href="#g_setting">Company Info</a>
            </li>
            <li
              className={` ${Styles.tab} ${
                selectedTab === TAB_PAYMENT ? Styles.active : ""
              }`}
              onClick={(e) => {
                e.preventDefault();
                setSelectedTab(TAB_PAYMENT);
              }}
            >
              <a href="#g_setting">Payment Gateway / Taxes</a>
            </li>
            <li
              className={` ${Styles.tab} ${
                selectedTab === TAB_PRODUCTS ? Styles.active : ""
              }`}
              onClick={(e) => {
                e.preventDefault();
                setSelectedTab(TAB_PRODUCTS);
              }}
            >
              <a href="#g_setting">Products/Services</a>
            </li>
            <li
              className={` ${Styles.tab} ${
                selectedTab === TAB_TEMPLATES ? Styles.active : ""
              }`}
              onClick={(e) => {
                e.preventDefault();
                setSelectedTab(TAB_TEMPLATES);
              }}
            >
              <a href="#g_setting">Email/Text Templates</a>
            </li>
            <li
              className={` ${Styles.tab} ${
                selectedTab === TAB_INVOICE_REMINDERS ? Styles.active : ""
              }`}
              onClick={(e) => {
                e.preventDefault();
                setSelectedTab(TAB_INVOICE_REMINDERS);
              }}
            >
              <a href="#g_setting">Invoice Reminders</a>
            </li>
          </ul>

          <div className={Styles.settings_content}>
            {selectedTab === TAB_COMPANY && <CompanyInfo />}
            {selectedTab === TAB_PAYMENT && <PaymentGateWay />}
            {selectedTab === TAB_PRODUCTS && <ProductsServices />}
            {selectedTab === TAB_TEMPLATES && <EmailTextTemplates />}
            {selectedTab === TAB_INVOICE_REMINDERS && <InvoiceReminders />}
          </div>
        </Fragment>
      </div>
    </Fragment>
  );
};

export default InvoiceSettings;
