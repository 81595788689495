import { Box } from "@material-ui/core";
import React from "react";
import { PERSONALIZED_TAGS } from "../../../../constants/personalizedTags";
import TriggerPersonalizedAccordion from "../Accordion/TriggerPersonalizedAccordion";
import CustomPopOver from "../Popover/CustomPopOver";
import { StyledList } from "./PersonalizedTagStyles";

const PersonalizedTag = ({ onClick, personalizeTags }) => {
  const renderData = (personalizeTags || PERSONALIZED_TAGS).map(
    (eachGroup, eachGroupIndex) => (
      <TriggerPersonalizedAccordion
        key={eachGroupIndex}
        title={eachGroup.title}
        expanded={eachGroupIndex === 0}
      >
        <StyledList>
          {/* <StyledList> */}
          {eachGroup.tags.map((eachData, eachDataIndex) => (
            <li
              key={eachGroupIndex + "_" + eachDataIndex}
              onClick={() => onClick(eachData.value)}
            >
              {eachData.title}
            </li>
          ))}
        </StyledList>
      </TriggerPersonalizedAccordion>
    )
  );

  return (
    <CustomPopOver buttonText={"Personalized"} color={"inherit"}>
      <Box
        sx={{
          maxHeight:400,
          overflowY: 'auto',
          background: '#c2c2c21f',
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          maxWidth: "250px",
          width: "100%",
          padding: "8px",
        }}
      >
        {renderData}
      </Box>
      {/* <StyledListWrapper>{renderData}</StyledListWrapper> */}
    </CustomPopOver>
  );
};

export default PersonalizedTag;
