import { Radio, withStyles } from "@material-ui/core";

const CustomRadio = withStyles((theme) => ({
  root: {
    "&.MuiRadio-root": {
      padding: "5px",
    },
    '& [type="radio"]:not(:checked)+span, & [type="radio"]:checked+span': {
      paddingLeft: 0,
      display: "grid",
      placeItems: "center",
      "&::before, &::after": {
        content: `none`,
      },
    },
  }
}))(Radio);

export default CustomRadio;