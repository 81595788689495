import {makeStyles} from "@material-ui/core/styles";

const globalEmptyPageUseStyles = makeStyles((theme) => ({
    wrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        padding: "24px",
        height: "100%",
        width: "100%",
        gap: "5px",
    },

    imageWrapper: {
        height: "224px",
        width: "310px",
        "& img": {
            height: "100%",
            width: "100%",
            objectFit: "contain",
        }
    },

    iconWrapper: {
        width: "auto", height: "60%",
        "& svg": {
            height: "100%",
            width: "100%",
            objectFit: "contain",
        }
    }
}))

export default globalEmptyPageUseStyles;

