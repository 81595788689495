const ActionTypes = {
    GET_CREDENTIAL: "GET_CREDENTIAL",
    SAVE_CREDENTIAL: "SAVE_CREDENTIAL",
    SET_ERROR: "SET_ERROR",
    UPDATE_TABLE_DATA: "UPDATE_TABLE_DATA", 
    SET_LOADING: "SET_LOADING",
    UPDATE_CREDENTIALS: "UPDATE_CREDENTIALS",
    UPDATE_PER_PAGE: "UPDATE_PER_PAGE",
    UPDATE_PAGE: "UPDATE_PAGE",
    UPDATE_TOTAL_PAGE: "UPDATE_TOTAL_PAGE",
    UPDATE_TOTAL_RECORDS: "UPDATE_TOTAL_RECORDS",
    SET_CREDENTIAL: "SET_CREDENTIAL"
  };
  
  export default ActionTypes;
  