import './errorbox.css';
const Errorbox = () => {
    return ( 
        <div id="not-found-alert">
            <div id="error-box">
                <div class="dot"></div>
                    <div class="dot two"></div>
                        <div class="face">
                        <div class="eye"></div>
                        <div class="eye right"></div>
                        <div class="mouth sad"></div>
                    </div>
                    <div class="shadow move"></div>
                <div class="message"><h3>No Notification Found!</h3></div>
            </div>
        </div>
    );
}
 
export default Errorbox;