import React, { useEffect } from "react";
import ActivitySingle from "./ActivitySingle";
import './activity.css';
import Utils from "../../helpers/Utils";
const Activites=[
    {
        title:'Logged in',
        subTitle:'You Logged in',
        date:'20 May, 01:17 AM',
        status:'Logged'
    },
    {
        title:'Trigger Delete',
        subTitle:'Trigger deleted - Deluxe',
        date:'20 May, 01:17 AM',
        status:'delete'
    },
    {
        title:'Contact insert',
        subTitle:'Contact inserted - testdeal1@yopmail.com',
        date:'20 May, 01:17 AM',
        status:'contact'
    }
]
const Activity = () => {

    useEffect(() => {
        document.title = `User Activity | ${Utils.getAccountData('AuthUserAgencyName')}`;
        if(window.setActiveSidebar) {
            window.setActiveSidebar('setting');
        }
    },[]);

    return (
            <div className="activity_log">
                <div className="activity_title">
                    <h1>Your Recent Activities</h1>
                </div>
                <div className="activity_list">
                    <ul>
                    {Activites.map((Activite,i)=>
                         <ActivitySingle Activites={Activite}/>
                    )}
                       
                        {/* <ActivitySingle log="contact_log"/>
                        <ActivitySingle log="delete_log"/> */}
                    </ul>
                    <div className="pagenation-middle d-flex justify-content-center align-items-center">
                        <ul className="pagination">
                            <li className="arrow-left"><a href="#!" onClick={e => e.preventDefault()}><i className="material-icons">chevron_left</i></a></li>
                            <li className="active"><a href="#!" onClick={e => e.preventDefault()}>1</a></li>
                            <li className="waves-effect">
                                <a href="#!" onClick={e => e.preventDefault()}>
                                    <span>
                                        <svg width="21" height="37" viewBox="0 0 21 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <line x1="19.7066" y1="0.470588" x2="0.883042" y2="35.7647" stroke="#3C7EF3" stroke-width="2"></line>
                                        </svg>
                                    </span>
                                </a>
                            </li>
                            <li className="waves-effect"><a href="#!" onClick={e => e.preventDefault()}>100</a></li>
                            <li className="arrow-left waves-effect"><a href="#!" onClick={e => e.preventDefault()}><i className="material-icons">chevron_right</i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
    )
}

// const mapStateToProps = state => {
//     return {
//         user: state.profileReducer.user
//     }
// }
// const mapDispatchToProps = dispatch => {
//     return {
//         getUser: (params) => dispatch(getUser(params)),
//     };
// }
// const Profile = connect(mapStateToProps, mapDispatchToProps)(App);
export default Activity;