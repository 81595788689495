import React, { useEffect, useState } from "react"
import { TextField, IconButton, Tooltip} from '@material-ui/core';
import Utils from "../../helpers/Utils";
import FormLabel from "@material-ui/core/FormLabel";
import Select from "@material-ui/core/Select";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import MenuItem from "@material-ui/core/MenuItem";
import {All_FIELD_TYPE} from "../../constants/CoreConstants";
import FormControl from "@material-ui/core/FormControl";
import {makeStyles} from "@material-ui/core/styles";
import If from 'if-else-react';
import InfoIcon from "@material-ui/icons/Info";
import {customFieldCreateOrUpdateApi} from '../../api/CustomFieldApi';
import GlobalModal from "../globals/Modal/GlobalModal";
import { AddCircle, Save } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(2),
        minWidth: 120,
        height:50
    },
    selectEmpty: {
        marginTop: theme.spacing(0),
    },
}));

const CustomFieldPopup = (props) => {
    const classes = useStyles();
    const [type, setType] = useState(null);
    const [fieldId, setFieldId] = useState(null);
    const [fieldName, setFieldName] = useState(null);
    const [fieldOption, setFieldOption] = useState(null);
    const [typeError, setTypeError] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [optionError, setOptionError] = useState(false);

    useEffect(() => {
        setTypeError(false);
        setNameError(false);
        setOptionError(false);
        if(props.dataSet !== undefined && props.dataSet.id !== undefined){
            setType(props.dataSet.type_id);
            setFieldName(props.dataSet.title);
            let tempDefValue = props.dataSet.default_values;
            if(tempDefValue) {
                tempDefValue = tempDefValue.toString().replace(/,/g, '|');
            }
            setFieldOption(tempDefValue);
            setFieldId(props.dataSet.id);
        }
    }, [props])

    const toggle = (reset = false) => {
        props.hideModal(reset);
    }

    const onSubmitHandle = (e) =>{
        e.preventDefault();
        setTypeError(false);
        setNameError(false);
        setOptionError(false);

            if(!type){
                setTypeError(true);
                return false;
            } else if(!fieldName){
                setNameError(true);
                return false;
            }

            // eslint-disable-next-line eqeqeq
            if((type==5 || type==6 || type==7) && ! fieldOption){
                setOptionError(true);
                return false;
            }

            customFieldCreateOrUpdateApi({
                "field_type": type,
                "field_name":fieldName,
                "default_values":fieldOption,
                "id":fieldId,
            }).then((response) => {
                if (response.data.status === 'success') {
                    toggle(true);
                    try{
                        let local_data = localStorage.getItem("global__custom__field__data");
                        if(local_data){
                            localStorage.removeItem("global__custom__field__data")
                        }
                    }
                    catch(error){
                        console.log(error)
                    }
                } else if (response.data.status === 'validation-error') {
                    for (var key of Object.keys(response.data.message)) {
                        let errors = response.data.message[key];
                        errors.forEach(error => {
                            Utils.showNotification(error, 'error');
                        });
                    }
                } else {
                    Utils.showNotification(response.data.message, 'error');
                }
            });
    }

    return (
        <GlobalModal
            open={true}
            title={props.title}
            onClose={toggle}
            onSubmit={onSubmitHandle}
            buttonText={props.title === 'Update Field' ? "Save" : "Create New"}
            buttonIcon={props.title === 'Update Field' ? <Save/> : <AddCircle/>}
            className="global-750-modal global-modal-avoid-header global-xs-modal"
        >
            <div class="px-2 py-4">
                <div class="modal_body user_custom_field_form">
                    <FormControl color={"primary"} variant="outlined" margin="none" className="custom__form__select w-100">
                        <FormLabel className="mb-3">What kind of field would you like to add?</FormLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={type}
                            onChange={e => setType(e.target.value)}
                            IconComponent = {KeyboardArrowDownIcon}
                            displayEmpty
                            className={classes.selectEmpty}
                            inputProps={{ 'aria-label': 'Without label' }}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left"
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left"
                                },
                                classes: { paper: "custom-fields-form-list" },
                                getContentAnchorEl: null
                            }}
                            disabled={fieldId ? true : false}
                        >
                            <MenuItem value={null} disabled>
                                Select Field Type
                            </MenuItem>
                            {All_FIELD_TYPE.map((item, index) => {
                                return(
                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                )
                            })}
                        </Select>
                        <If condition={typeError}><span className={"text-danger"}>Select a type</span></If>

                        <If condition={type!=null}>
                        <br/>
                            <FormLabel>Field Name</FormLabel>
                            <TextField placeholder={"Enter field name"} variant="outlined" value={fieldName} onChange={e => setFieldName(e.target.value)}/>
                            <If condition={nameError}><span className={"text-danger"}>Field name is required</span></If>
                            {
                                // eslint-disable-next-line eqeqeq
                            }<If condition={type==5 || type==6 ||type==7}>
                            <br/>
                                <FormLabel>Set Value<IconButton>
                                    <Tooltip title={"Multiple values separated by | sign"}>
                                    <InfoIcon />
                                    </Tooltip>
                                </IconButton></FormLabel>
                                <TextField variant="outlined" value={fieldOption} placeholder={"Example Value1|Value2|Value3"} onChange={e => setFieldOption(e.target.value)}/>
                                <If condition={optionError}><span className={"text-danger"}>Field value is required</span></If>
                            </If>
                        </If>
                    </FormControl>
                </div>
            </div>
        </GlobalModal>
    );
}

export default CustomFieldPopup;