import React, { Fragment, useState, useEffect } from "react";
import {Link, useHistory} from "react-router-dom";
import {snapShotAccountList, syncAccountSnapShot, snapShotAccountDelete, snapShotCategoryList, snapShotPublicUpdate} from "../../api/profileApi";
import Loader from "../globals/Loader";
import BootstrapTooltip from "../globals/BootstrapTooltip";
import CustomConfirmAlert from "../team/CustomConfirmAlert";
import Popup from "./Popup";
import Utils from "../../helpers/Utils";
import VisibilityIcon from '@material-ui/icons/Visibility';

const SnapShot = (props) => {
    const history = useHistory();
    const [accountList, setAccountList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [data, setData] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [dataToEdit, setDataToEdit] = useState(null);
    const [categoryList, setCategoryList] = useState([]);

    useEffect(() => {
        loadData();
    }, [])

    const deleteSnapShot = (snapShotId) => {
        snapShotAccountDelete({
            "form_data": {
                snapShotId: snapShotId
            }
        })
            .then(response => {
                loadData();
                if (response.data.status === 'success') {
                    Utils.showNotification(response.data.message, 'success');
                }else {
                    Utils.showNotification(response.data.message, 'error');
                }
            }).finally(() => {

        });
    }

    const updateSnapShot = (snapShotId, isPublic) => {
        snapShotPublicUpdate({
            "form_data": {
                snapShotId: snapShotId,
                is_public: isPublic
            }
        })
            .then(response => {
                loadData();
                if (response.data.status === 'success') {
                    Utils.showNotification(response.data.message, 'success');
                }else {
                    Utils.showNotification(response.data.message, 'error');
                }
            }).finally(() => {

        });
    }

    const syncSnapShot = (snapShotId) => {
        setLoading(true);
        syncAccountSnapShot({
            "form_data": {
                snapShotId: snapShotId
            }
        })
            .then(response => {
                if (response.data.status === 'success') {
                    Utils.showNotification(response.data.message, 'success');
                }else {
                    Utils.showNotification(response.data.message, 'error');
                }
            }).finally(() => {
            setLoading(false);
            });
    }

    const loadData = () => {
        setLoading(true);

        snapShotAccountList({
            "form_data": {
                page: currentPage + 1,
                per_page: perPage,
            }
        })
            .then(response => {
                setData(response.data.data.data);
                setTotal(response.data.data.total);
            }).finally(() => {
            setLoading(false);
        });

        snapShotCategoryList({})
            .then(response => {
                setCategoryList(response.data.data);
            }).finally(() => {
        });


    }

    const renderData = () => {
        if (loading) {
            return (
                <tr style={{height: 200}}>
                    <td colspan="7">
                        <div style={{paddingTop: 0}}>
                            <Loader/>
                        </div>
                    </td>
                </tr>
            )
        } else if (!loading && !data.length) {
            return (
                <tr>
                    <td colspan="7">
                        <div style={{paddingTop: 0}}>
                            NO DATA AVAILABLE
                        </div>
                    </td>
                </tr>
            )
        } else if (!loading && data.length) {
            return data.map((item,index) => (
                <tr>
                    <td>{item.title}</td>
                    <td>{item.full_name}</td>
                    <td>{item.email}</td>
                    <td>{item.category}</td>
                    <td>{(item.is_public==1) && 
                        <BootstrapTooltip arrow title={`Public`}>
                            <VisibilityIcon fontSize="small" style={{height: '22px', width: '22px', color: '#68EBCA'}} />
                        </BootstrapTooltip>
                    }
                        {(item.is_public==0) &&
                         <BootstrapTooltip arrow title={`Private`}>
                            <VisibilityIcon fontSize="small" style={{height: '22px', width: '22px', color: '#000000'}} />
                        </BootstrapTooltip>
                        }
                    </td>
                    <td>{item.created_at}</td>
                    <td>
                        <BootstrapTooltip arrow title="Sync account">
                            <a href="#!" onClick={(e) => {
                                e.preventDefault();
                                CustomConfirmAlert({
                                    handleConfirm : () => {
                                        syncSnapShot(item.id)
                                    },
                                    callConfirmLast: true,
                                    title: 'Are you sure?',
                                    description: 'This will update your existing clone with the most up to date version. You cannot revert back.',
                                    type_word: 'UPDATE CLONE',
                                    icon: 'warning',
                                    closeButtonText: 'Cancel'
                                })
                            }}>
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M4 20v-2h2.75l-.4-.35q-1.3-1.15-1.825-2.625Q4 13.55 4 12.05q0-2.775 1.662-4.938Q7.325 4.95 10 4.25v2.1Q8.2 7 7.1 8.562 6 10.125 6 12.05q0 1.125.425 2.187Q6.85 15.3 7.75 16.2l.25.25V14h2v6Zm10-.25v-2.1q1.8-.65 2.9-2.212Q18 13.875 18 11.95q0-1.125-.425-2.188Q17.15 8.7 16.25 7.8L16 7.55V10h-2V4h6v2h-2.75l.4.35q1.225 1.225 1.788 2.662Q20 10.45 20 11.95q0 2.775-1.663 4.937Q16.675 19.05 14 19.75Z"/></svg>
                                </span>
                            </a>
                        </BootstrapTooltip>
                        <BootstrapTooltip arrow title="Edit title">
                            <a href="#!" onClick={(e) => {
                                e.preventDefault();
                                setDataToEdit(item);
                                setModalVisible(true);
                            }}>
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M5 19h1.4l8.625-8.625-1.4-1.4L5 17.6ZM19.3 8.925l-4.25-4.2 1.4-1.4q.575-.575 1.413-.575.837 0 1.412.575l1.4 1.4q.575.575.6 1.388.025.812-.55 1.387ZM17.85 10.4 7.25 21H3v-4.25l10.6-10.6Zm-3.525-.725-.7-.7 1.4 1.4Z"/></svg>
                                </span>
                            </a>
                        </BootstrapTooltip>
                        <BootstrapTooltip arrow title="Delete snapshot">
                            <a href="#!" onClick={(e) => {
                                e.preventDefault();
                                CustomConfirmAlert({
                                    handleConfirm : () => {
                                        deleteSnapShot(item.id);
                                    },
                                    callConfirmLast: true,
                                    title: 'Are you sure?',
                                    description: 'Deleted quick clones cannot be restored',
                                    type_word: 'DELETE QUICK CLONE',
                                    icon: 'question',
                                    closeButtonText: 'Cancel'
                                })
                            }}>
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M7 21q-.825 0-1.412-.587Q5 19.825 5 19V6H4V4h5V3h6v1h5v2h-1v13q0 .825-.587 1.413Q17.825 21 17 21ZM17 6H7v13h10ZM9 17h2V8H9Zm4 0h2V8h-2ZM7 6v13Z"/></svg>
                                </span>
                            </a>
                        </BootstrapTooltip>

                        {item.category_id != null && 
                        <BootstrapTooltip arrow title={`Make ${(item.is_public==0)?'Public':'Private'}`}>
                            <a href="#!" onClick={(e) => {
                                e.preventDefault();
                                let description = 'Do you want to make ' +((item.is_public==0)?'public':'private') +' this quick clone'
                                CustomConfirmAlert({
                                    handleConfirm : () => {
                                        updateSnapShot(item.id, (item.is_public==1)?0:1)
                                    },
                                    callConfirmLast: true,
                                    title: 'Are you sure?',
                                    description: description,
                                    type_word: 'YES',
                                    icon: 'question',
                                    closeButtonText: 'Cancel'
                                })
                            }}>
                                <span>
                                {(item.is_public==1) && 
                                    <VisibilityIcon fontSize="small" style={{height: '22px', width: '22px', color: '#000000'}} />
                                }
                                {(item.is_public==0) && 
                                    <VisibilityIcon fontSize="small" style={{height: '22px', width: '22px', color: '#68EBCA'}} />
                                }

                                </span>
                            </a>
                        </BootstrapTooltip>
                        }
                    </td>

                </tr>
            ))
        }
    }


    return (
        <Fragment>
            <div class="settings_section">
                <Fragment>
                    <div className="container-fluid mail__inbox">
                        <div className="tagBox">
                            <div className="tag_list">
                                <h5>Quick Clone <span>list</span></h5>
                            </div>
                            <div className="add_new_user">
                                <a href="#add_new_user_modal" className="modal-trigger accent--bg--text--color"
                                   onClick={(e) => {
                                       e.preventDefault();
                                       setDataToEdit(null);
                                       setModalVisible(true);
                                   }}>
                                            <span>
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="10" cy="10" r="10"></circle>
                                                    <path
                                                        d="M13.8447 9.9985C13.8447 10.353 13.8139 10.6395 13.4594 10.6395H10.6395V13.4594C10.6395 13.8132 10.353 13.8447 9.9985 13.8447C9.64401 13.8447 9.35747 13.8132 9.35747 13.4594V10.6395H6.5376C6.18375 10.6395 6.15234 10.353 6.15234 9.9985C6.15234 9.64401 6.18375 9.35747 6.5376 9.35747H9.35747V6.5376C9.35747 6.18311 9.64401 6.15234 9.9985 6.15234C10.353 6.15234 10.6395 6.18311 10.6395 6.5376V9.35747H13.4594C13.8139 9.35747 13.8447 9.64401 13.8447 9.9985Z"
                                                        className="accent--fill--color"></path>
                                                </svg>
                                            </span>Create New Quick Clone
                                </a>
                                {
                                    history.location.state !== undefined && history.location.state.from === 'accountPage' &&
                                    <Link className={'modal-trigger accent--bg--text--color'} to="/user/profile"><span><svg
                                        width="20" height="20" viewBox="0 0 20 20" fill="none"
                                        xmlns="http://www.w3.org/2000/svg"><path
                                        d="M0 10C0 4.4774 4.4774 0 10 0C15.5226 0 20 4.4774 20 10C20 15.5226 15.5226 20 10 20C4.4774 20 0 15.5226 0 10ZM10.2236 14.3923C10.2953 14.3212 10.3523 14.2366 10.3913 14.1434C10.4304 14.0502 10.4507 13.9502 10.4511 13.8492C10.4515 13.7482 10.432 13.6481 10.3937 13.5546C10.3554 13.4611 10.299 13.376 10.2279 13.3043L7.7125 10.7692H14.1346C14.3386 10.7692 14.5343 10.6882 14.6785 10.5439C14.8228 10.3997 14.9038 10.204 14.9038 10C14.9038 9.79599 14.8228 9.60033 14.6785 9.45607C14.5343 9.31181 14.3386 9.23077 14.1346 9.23077H7.7125L10.2279 6.69567C10.299 6.62389 10.3554 6.53879 10.3936 6.44524C10.4319 6.35169 10.4514 6.25152 10.4509 6.15045C10.4505 6.04937 10.4301 5.94938 10.391 5.85617C10.3519 5.76296 10.2949 5.67837 10.2231 5.60721C10.1513 5.53606 10.0662 5.47974 9.97264 5.44147C9.8791 5.40321 9.77892 5.38374 9.67785 5.38419C9.57678 5.38463 9.47678 5.40498 9.38357 5.44407C9.29037 5.48317 9.20577 5.54023 9.13462 5.61202L5.31779 9.45817C5.17481 9.60226 5.09458 9.79702 5.09458 10C5.09458 10.203 5.17481 10.3977 5.31779 10.5418L9.13462 14.388C9.20578 14.4599 9.29042 14.517 9.3837 14.5561C9.47697 14.5952 9.57704 14.6156 9.67819 14.616C9.77934 14.6164 9.87957 14.5968 9.97315 14.5585C10.0667 14.5201 10.1518 14.4636 10.2236 14.3923Z"></path></svg></span><span>Back</span></Link>
                                }
                                {
                                    history.location.state !== undefined && history.location.state.from === 'settingPage' &&
                                    <Link className={'modal-trigger accent--bg--text--color'} to="/user/settings"><span><svg
                                        width="20" height="20" viewBox="0 0 20 20" fill="none"
                                        xmlns="http://www.w3.org/2000/svg"><path
                                        d="M0 10C0 4.4774 4.4774 0 10 0C15.5226 0 20 4.4774 20 10C20 15.5226 15.5226 20 10 20C4.4774 20 0 15.5226 0 10ZM10.2236 14.3923C10.2953 14.3212 10.3523 14.2366 10.3913 14.1434C10.4304 14.0502 10.4507 13.9502 10.4511 13.8492C10.4515 13.7482 10.432 13.6481 10.3937 13.5546C10.3554 13.4611 10.299 13.376 10.2279 13.3043L7.7125 10.7692H14.1346C14.3386 10.7692 14.5343 10.6882 14.6785 10.5439C14.8228 10.3997 14.9038 10.204 14.9038 10C14.9038 9.79599 14.8228 9.60033 14.6785 9.45607C14.5343 9.31181 14.3386 9.23077 14.1346 9.23077H7.7125L10.2279 6.69567C10.299 6.62389 10.3554 6.53879 10.3936 6.44524C10.4319 6.35169 10.4514 6.25152 10.4509 6.15045C10.4505 6.04937 10.4301 5.94938 10.391 5.85617C10.3519 5.76296 10.2949 5.67837 10.2231 5.60721C10.1513 5.53606 10.0662 5.47974 9.97264 5.44147C9.8791 5.40321 9.77892 5.38374 9.67785 5.38419C9.57678 5.38463 9.47678 5.40498 9.38357 5.44407C9.29037 5.48317 9.20577 5.54023 9.13462 5.61202L5.31779 9.45817C5.17481 9.60226 5.09458 9.79702 5.09458 10C5.09458 10.203 5.17481 10.3977 5.31779 10.5418L9.13462 14.388C9.20578 14.4599 9.29042 14.517 9.3837 14.5561C9.47697 14.5952 9.57704 14.6156 9.67819 14.616C9.77934 14.6164 9.87957 14.5968 9.97315 14.5585C10.0667 14.5201 10.1518 14.4636 10.2236 14.3923Z"></path></svg></span><span>Back</span></Link>
                                }
                            </div>
                        </div>
                        <div className="details snapshot-details">
                            <div className="items">
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                                </div>
                                {/*<div class="record_search">*/}
                                {/*    <input type="text" placeholder="Search user" class="search" onChange={(e) => setQuery(e.target.value)}/>*/}
                                {/*    <div class="search_icon">*/}
                                {/*        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                                {/*            <path d="M11.4351 10.0629H10.7124L10.4563 9.81589C11.3528 8.77301 11.8925 7.4191 11.8925 5.94625C11.8925 2.66209 9.23042 0 5.94625 0C2.66209 0 0 2.66209 0 5.94625C0 9.23042 2.66209 11.8925 5.94625 11.8925C7.4191 11.8925 8.77301 11.3528 9.81589 10.4563L10.0629 10.7124V11.4351L14.6369 16L16 14.6369L11.4351 10.0629ZM5.94625 10.0629C3.66838 10.0629 1.82962 8.22413 1.82962 5.94625C1.82962 3.66838 3.66838 1.82962 5.94625 1.82962C8.22413 1.82962 10.0629 3.66838 10.0629 5.94625C10.0629 8.22413 8.22413 10.0629 5.94625 10.0629Z" fill="#133159" fill-opacity="0.65"></path>*/}
                                {/*        </svg>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                            <table>
                                <thead>
                                <tr className="header">
                                    <th>
                                        Title
                                    </th>
                                    <th>
                                        Account Name
                                    </th>
                                    <th>
                                        Account Email
                                    </th>
                                    <th>Category</th>

                                    <th>Visibility</th>
                                    <th>
                                        Created At
                                    </th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody className="tbody_content">
                                    {renderData()}
                                </tbody>
                            </table>
                        </div>
                        {
                            modalVisible && (
                                <Popup
                                    hideModal={() => {
                                        setDataToEdit(null);
                                        setModalVisible(false);
                                        loadData();
                                    }}
                                    title={'Account Quick Clone'}
                                    user={props.user}
                                    data={dataToEdit}
                                    categoryList ={categoryList}
                                />
                            )
                        }
                    </div>
                </Fragment>
            </div>
        </Fragment>
    )
}

export default (SnapShot);
