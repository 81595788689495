import React, {useEffect, useState} from "react";
import {TablePagination,} from "@material-ui/core";
import SingleProductActions from "./SingleProductActions";
import GlobalSearchBox from "../../globals/GlobalSearchBox";
import ProductAndServiceHeader from "./ProductAndServiceHeader";
import TableLoaderSkeleton from "../common/TableLoaderSkeleton";
import GlobalEmptyPage from "../common/emptyPage/GlobalEmptyPage";
import {EmptyInboxIcon} from "../common/icons/invoicePageIcons";
import {getProductInvoice,} from "../../../api/InvoiceApi";
import {useIsMounted} from "../../../hooks/IsMounted";
import Styles from "../ProductServices.module.css";
import Utils from "../../../helpers/Utils";

export const ProductsServices = () => {
  const isMounted = useIsMounted();
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [renderId, setRenderId] = useState("");

  const [query, setQuery] = useState("");
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("debouncedQuery:", debouncedQuery);
    setLoading(true);
    getProductInvoice({
      currentPage: page + 1,
      limit: perPage,
      searchText: debouncedQuery,
    })
      .then((res) => {
        res = res.data;
        if (res.success) {
          res = res.data;

          setData(res.content);
          setTotal(res.count);
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [debouncedQuery, page, perPage, renderId]);

  //debouncing
  useEffect(() => {
    if (isMounted) {
      const timer = setTimeout(() => {
        setDebouncedQuery(query);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [query]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onRenderCallback = () => {
    setRenderId(Utils.generateRandomString(5))
  }

  // decide what to render
  let content = null;
  if(loading && !renderId) {
    content = <TableLoaderSkeleton numberOfRows={10} columns={5} />
  } else if(data?.length === 0) {
    content = (
        <GlobalEmptyPage icon={<EmptyInboxIcon />} title={"No data available"} />
    )
  } else if(data?.length > 0) {
    content = (
        <>
          <table>
            <thead>
            <tr className="header">
              <th>Name</th>
              <th>Description</th>
              <th>Item Type</th>
              <th>Rate</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody className="tbody_content">
            {data.map(item => {

              return (
                  <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>{item.description}</td>
                    <td>{item.itemType}</td>
                    <td>{Utils.formatCurrency(item.price)}</td>
                    <td>
                      <SingleProductActions onRenderCallback={onRenderCallback} productInfo={item}/>
                    </td>
                  </tr>
              )
            })}
            </tbody>
          </table>
          <div className={Styles.sticky_pagination}>
            <TablePagination
                component="div"
                count={total}
                page={page}
                rowsPerPage={perPage}
                onPageChange={handleChangePage}
                rowsPerPageOptions={[5, 10, 25]}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </>
    )
  }

  return (
    <div>
      <ProductAndServiceHeader onRenderCallback={onRenderCallback} />

      <div className={Styles.global_search}>
        <GlobalSearchBox
          style={{ borderBottom: "1px solid #546376" }}
          placeholder="Search Items"
          onChange={(e) => setQuery(e.target.value)}
        />
      </div>

      {content}
    </div>
  );
};