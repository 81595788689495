export const BUILDER_OPTIONS = {
  BUILDER: 1,
  PREVIEW: 2,
  SETTINGS: 3,
  SEND: 4,
};

export const TEMPLATE_TYPE = {
  STANDARD: 1,
  DEFAULT: 2,
  BASIC: 3,
};

export const INVOICE_TEMPLATE_IMAGE = {
  BASIC: "https://forms-a5940fe4.s3.amazonaws.com/user/%2F1_form_1698311272986_basic.png",
  STANDARD: "https://forms-a5940fe4.s3.amazonaws.com/user/%2F1_form_1698311344729_standard.png",
  DEFAULT: "https://forms-a5940fe4.s3.amazonaws.com/user/%2F1_form_1698311504487_default.png",
};

export const CHOOSE_TEMPLATE_LIST = {
  [TEMPLATE_TYPE.STANDARD]: {
    label: "Standard",
    value: TEMPLATE_TYPE.STANDARD,
    image: INVOICE_TEMPLATE_IMAGE.STANDARD,
  },
  [TEMPLATE_TYPE.DEFAULT]: { label: "Default", value: TEMPLATE_TYPE.DEFAULT, image: INVOICE_TEMPLATE_IMAGE.DEFAULT },
  [TEMPLATE_TYPE.BASIC]: { label: "Basic", value: TEMPLATE_TYPE.BASIC, image: INVOICE_TEMPLATE_IMAGE.BASIC },
};