import React from "react";

const Question = ({step,title,content='',content2='',img}) => {
    return(
        <div className="geo__question__inner">
            <h2>Questions?</h2>
            <p>Reach out via live chat or book a live call below</p>
            <div className="question__step__btn__wr">
                <a href="#" className="geo__question__btn">{"Book A Live Call"}</a>
            </div>
           
        </div>
    )
}


export default Question;