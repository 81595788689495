import React, { useEffect } from 'react'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box'
import './customdata-fields.css'
import SystemTable from './subcomponents/SystemTable'
import CustomForm from './subcomponents/CustomForm'
import PersonilizFrom from './subcomponents/PersonilizForm'
import PersonilizTable from './subcomponents/PersonilizTable'
import Utils from "../../helpers/Utils";

function TabPanel(props) {
    const { children, value, index } = props;
    return (
      <div>
        {value === index && (
          <Box p={3}>
           {children}
          </Box>
        )}
      </div>
    );
  }
  
const ContactCustomFields = ({submitForm}) => {

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
        setValue(newValue);
  };

  useEffect(() => {
    document.title = `Custom Field | ${Utils.getAccountData('AuthUserAgencyName')}`;
    if(window.setActiveSidebar) {
      window.setActiveSidebar('setting');
    }
  },[]);

  return (
      <div className="contact__custom__section">
          <Tabs className="tab__topper" value={value} onChange={handleChange}>
              <Tab className="system__tab__button" label="Standard Fields"  />
              <Tab className="fields__tab__button" label="Custom Data Fields"  />
              <Tab className="personalized__tab__button" label="Personalized Fields" />
          </Tabs>
          <div className="tab__contnet">
              <TabPanel value={value} index={0}>
                  <SystemTable/>
              </TabPanel>
              <TabPanel value={value} index={1}>
                  <CustomForm />
              </TabPanel>
              <TabPanel value={value} index={2}>
                  <PersonilizFrom/>
                  <PersonilizTable/>
              </TabPanel>
          </div>   
      </div>

  )
}

export default ContactCustomFields
