import React, {useEffect, useState, useRef} from 'react'
import { connect } from 'react-redux'
import { addLeadSource, editLeadSource, _handleLeadSourceEdit, _handleLeadSourceModal, _handleLeadSourcePagination, _handleLeadSourceReload } from '../../../actions/leadSourceAction';
import { icons } from '../icon';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import EditIcon from '@material-ui/icons/Edit';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Input from '@material-ui/core/Input';

const mapStateToProps = state => ({
    leadSourceReducer : state.leadSourceReducer
});

const mapDispatchToProps = dispatch => ({
    addLeadSource: (params, callback) => dispatch(addLeadSource(params, callback)),
    editLeadSource: (params, callback) => dispatch(editLeadSource(params, callback)),
    _handleLeadSourceModal: (params) => dispatch(_handleLeadSourceModal(params)),
    _handleLeadSourceEdit: (params) => dispatch(_handleLeadSourceEdit(params)),
    _handleLeadSourceReload: (params) => dispatch(_handleLeadSourceReload(params)),
    _handleLeadSourcePagination: (params) => dispatch(_handleLeadSourcePagination(params)),
})

const AddLeadSourceModalApp = props => {
    const titleField = useRef(null)
    const {show_modal, edit, edit_data} = props.leadSourceReducer
    const [submit, setSubmit] = useState(false)
    const [title, setTitle] = useState('')

    useEffect(() => {
        if(edit){
            setTitle(edit_data.source_title !== undefined ? edit_data.source_title : '')
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    const handleAddLeadSourceModal = () => {
        if(edit){
    		props._handleLeadSourceEdit({editFlag : false, editData: null})
        }
        props._handleLeadSourceModal(false)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if(title === ''){
          window.showNotification("warning", "Lead source title is required !")
            titleField.current.focus()
            return
        }

        if(!title.match(/^[A-Za-z 0-9]+$/)){
          window.showNotification("warning", "Invalid lead source title. Valid text: [A-Z, a-z, 0-9]")
            titleField.current.focus()
            return
        }
        
        setSubmit(true)
        if(edit){
            props.editLeadSource({'contact-source-type-id': edit_data.id,source_title : title}, (res) => {
                setSubmit(false)
                if(res.status === "validation-error"){
                  window.showNotification("error", res['html']['source_title'][0])
                    titleField.current.focus()
                }
                else if(res.status === "success"){
                  window.showNotification("SUCCESS", res['html'])
                    props._handleLeadSourceModal(false)
                    props._handleLeadSourceEdit({editFlag : false, editData: null})
                    props._handleLeadSourceReload(true)
                }
            })
        }else{
            props.addLeadSource({source_title : title}, (res) => {
                setSubmit(false)
                if(res.status === "validation-error"){
                  window.showNotification("error", res['html']['source_title'][0])
                    titleField.current.focus()
                }
                else if(res.status === "success"){
                  window.showNotification("info", res['html'])
                    props._handleLeadSourceModal(false)
                    props._handleLeadSourcePagination({currentPage: 1})
                    props._handleLeadSourceReload(true)
                }
            })
        }
    }

    return(
        <div className="modal_details modal alt" style={{display: show_modal ? 'block' : 'none'}}>
            <div className="top_content d-flex justify-content-between align-items-center p-4">
                <div className="sub_title">
                    <h2>{edit ? 'Edit lead source' : 'Add A New Lead Source'}</h2>
                </div>
                <div className="cross_button d-flex justify-content-center align-items-center modal-close" onClick={handleAddLeadSourceModal}>
                    {icons.cross}
                </div>
            </div>
            <div className="leadsrc_input_fileds">
                <div className="input-field col s12">
                <FormControl className="personalized__field">
                    <FormLabel>Personalized Field Name</FormLabel>
                    <Input inputRef={titleField} placeholder="Enter lead source" onChange={(event) => setTitle(event.target.value)} value={title} disableUnderline={true} />
                </FormControl>
                </div>
            </div>
            <div className="modal__buttons d-flex justify-content-center align-content-center">
                <button type="submit" onClick={handleSubmit} className="add__group__btn accent--bg--text--color">
                    {submit ? <MoreHorizIcon /> : (edit ? <EditIcon fontSize="small" /> : icons.plus)}
                    <span className="mx-2">{edit ? 'Edit' : 'Save'} Lead Source</span>
                </button>
                <button className="modal-close modal__close__btn" onClick={handleAddLeadSourceModal}>	
                    {icons.crossWhiteBg}
                    <span className="mx-2">Close</span>
                </button>
            </div>
        </div>
    )
}
const AddLeadSourceModal = connect(mapStateToProps, mapDispatchToProps)(AddLeadSourceModalApp);
export default AddLeadSourceModal;