export const FieldTypes = [
  {value: 1, title: 'Text', new: false},
  {value: 2, title: 'Multiline Text', new: false},
  {value: 3, title: 'Number', new: false},
  {value: 4, title: 'Date', new: false},
  {value: 5, title: 'Checkbox', new: false},
  {value: 6, title: 'Dropdown', new: false},
  {value: 7, title: 'Radio', new: false},
  {value: 8, title: 'Phone', new: false},
  {value: 9, title: 'Zip Code', new: false},
  {value: 10, title: 'Website', new: false},
  {value: 11, title: 'Datetime', new: true}
  // {value: 'new', title: 'Custom', new: true}
];