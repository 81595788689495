import React, {useState} from "react";
import {LeadPermissionRequestProvider} from "./StateProvider";
import Header from "./Header";
import Search from "./Search";
import PermissionTable from "./PermissionTable";
import Pagination from "./Pagination";
import "../leadSource/lead_source.css";

const App = () => {
    const [isRefresh, setIsRefresh] = useState(null);
    const refresh = (token) => {
        console.log(token)
        setIsRefresh(token);
    }


    return (
        <LeadPermissionRequestProvider>
            <div className="container-fluid mail__inbox">
                <Header refresh={refresh}/>
                <div className="details">
                    <Search/>
                    <PermissionTable isRefresh={isRefresh}/>
                </div>
                <Pagination />
            </div>
        </LeadPermissionRequestProvider>
    );
};

export default App;