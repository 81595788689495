import React, { useEffect, useRef, useState } from "react";

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { Editor } from "@tinymce/tinymce-react";
import Select from "react-select";
import InvoiceUseStyles from "../invoiceUseStyles";
import SimpleReactValidator from "simple-react-validator";
import {
  ADDRESS_TYPE_LIST,
  REMIND_TYPE_LIST,
  STATUS,
  TYPE,
} from "../../../constants/reminderConstant";
import NewConfirmAlert from "../../activity/new-alert/NewConfirmAlert";
import {
  addNewReminderInvoice,
  deleteReminderInvoice,
  editReminderInvoice,
} from "../../../api/InvoiceApi";

const colourStyles = {
  input: (styles) => ({
    ...styles,
    height: 37,
    display: "flex",
    alignItems: "center",
  }),
};

const AddOrEditReminder = ({
  isEdit,
  reminderInfo,
  onRenderCallback,
  onClose,
  type,
  systemDefaultInfo
}) => {
  const classes = InvoiceUseStyles();
  const [, setForceUpdate] = useState(false);

  const [loading, setLoading] = useState(false);

  const simpleValidator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: setForceUpdate },
      className: "small text-danger mdi mdi-alert pt-1 pl-1",
    })
  );

  const initialFormDataState = {
    name: "",
    addressType: "",
    numberOfDays: "",
    remindType: "",
    from: "",
    subject: "",
    body: "",
    status: true,
    type: "",
  };

  const [formData, setFormData] = useState(initialFormDataState);

  const handleChangeInput = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const addressTypeOptions = Object.values(ADDRESS_TYPE_LIST);
  const remindTypeOptions = Object.values(REMIND_TYPE_LIST);

  useEffect(() => {
    if (reminderInfo?.id) {
      setFormData({
        name: reminderInfo.name,
        addressType: addressTypeOptions.find(
          (item) => item.value === reminderInfo.addressType
        ),
        numberOfDays: reminderInfo.numberOfDays,
        remindType: remindTypeOptions.find(
          (item) => item.value === reminderInfo.remindType
        ),
        from: type === TYPE.EMAIL ? systemDefaultInfo?.email : systemDefaultInfo?.phone,
        subject: reminderInfo.subject,
        body: reminderInfo.body,
        status: reminderInfo.status === STATUS.ACTIVE,
        type: reminderInfo.type,
      });
    }
  }, []);

  const handleSubmit = () => {
    if (simpleValidator.current.allValid()) {
      try {
        const requestBody = {
          id: reminderInfo?.id,
          name: formData.name,
          addressType: formData.addressType.value,
          numberOfDays: +formData.numberOfDays,
          remindType: formData.remindType.value,
          from: type === TYPE.EMAIL ? systemDefaultInfo?.email : systemDefaultInfo?.phone,
          subject: formData.subject,
          body: formData.body,
          status: formData.status ? STATUS.ACTIVE : STATUS.INACTIVE,
          type: type,
        };

        if (!requestBody.from) return;

        if (isEdit) {
          requestBody.id = reminderInfo?.id;

          editReminderInvoice(requestBody)
            .then((res) => {
              res = res.data;
              if (res.success) {
                  window.showNotification("SUCCESS", res.message);
                  onRenderCallback();
                  onClose();
              } else {
                window.showNotification("ERROR", res.message);
              }
            })
            .catch((error) => {
              window.showNotification("ERROR", error.response.data.message);
            })
            .finally(() => setLoading(false));
        } else {
            addNewReminderInvoice(requestBody)
                .then((res) => {
                    res = res.data;
                    if (res.success) {
                        window.showNotification("SUCCESS", res.message);
                        onRenderCallback();
                        onClose();
                    } else {
                        window.showNotification("ERROR", res.message);
                    }
                })
                .catch((error) => {
                    window.showNotification("ERROR", error.response.data.message);
                })
                .finally(() => setLoading(false));
        }
      } catch (error) {
        window.showNotification("ERROR", error.response.data.message);
        onClose();
      }
    } else {
      simpleValidator.current.showMessages();
      setForceUpdate(true);
    }
  };

  const handleDelete = () => {
    NewConfirmAlert({
      onSubmit: () =>
        deleteReminderInvoice({ id: reminderInfo.id, type })
          .then((res) => {
            res = res.data;
            if (res.success) {
              onRenderCallback();
              window.showNotification("SUCCESS", res.message);
            } else {
              window.showNotification(
                "ERROR",
                res.message || "Error occurred while deleting data"
              );
            }
          })
          .catch((error) => {
            window.showNotification(
              "Error occurred while deleting data",
              "error"
            );
          }),
      title: "Are You Sure?",
      description: "Do you want to delete this information?",
      cancelText: "Cancel",
      submitText: "Confirm",
      width: "480px",
    });
  };

  const from = type === TYPE.EMAIL ? systemDefaultInfo?.email : systemDefaultInfo?.phone;
  return (
    <Box sx={{ width: 1000 }}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ paddingX: 4, paddingBottom: 20, bgcolor: "#eeeeee" }}>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <p className={classes.label_text} style={{ mb: 5 }}>
                Name *
              </p>
              <input
                type="text"
                placeholder="Type your name"
                className={classes.itemInput}
                style={{ height: "38px !important" }}
                name="name"
                value={formData.name}
                onChange={(e) => {
                  handleChangeInput(e.target.name, e.target.value);
                }}
              />
              {simpleValidator.current.message(
                "Name",
                formData.name,
                "required"
              )}
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={7}>
              <p className={classes.label_text} style={{ marginBottom: 5 }}>
                Remind *
              </p>
              <Select
                options={addressTypeOptions}
                value={formData.addressType}
                onChange={(selectedOption) => {
                  handleChangeInput("addressType", selectedOption);
                }}
                size={"small"}
                styles={colourStyles}
              />

              {simpleValidator.current.message(
                "Remind",
                formData.addressType,
                "required"
              )}
            </Grid>

            <Grid item xs={2}>
              <p
                className={classes.label_text}
                style={{ marginBottom: 5, visibility: "hidden" }}
              >
                Days
              </p>

              <Box sx={{ display: "flex", alignItems: "center", gap: 10 }}>
                <input
                  type="text"
                  placeholder="1"
                  className={classes.itemInput}
                  name="numberOfDays"
                  value={formData.numberOfDays}
                  onChange={(e) => {
                    handleChangeInput(e.target.name, e.target.value);
                  }}
                />

                <Box>Days</Box>
              </Box>
            </Grid>

            <Grid item xs={3}>
              <p
                className={classes.label_text}
                style={{ marginBottom: 5, visibility: "hidden" }}
              >
                {"Due Date"}
              </p>

              <Box sx={{ display: "flex", alignItems: "center", gap: 10 }}>
                <Box sx={{ flex: 1 }}>
                  <Select
                    options={remindTypeOptions}
                    value={formData.remindType}
                    onChange={(selectedOption) => {
                      handleChangeInput("remindType", selectedOption);
                    }}
                    fullWidth
                    styles={colourStyles}
                  />
                </Box>

                <Box>Due Date *</Box>
              </Box>
              {simpleValidator.current.message(
                "Due Date",
                formData.remindType,
                "required"
              )}
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={12}>
              <p className={classes.label_text} style={{ marginBottom: 5 }}>
                From *
              </p>
              <input
                type={type === TYPE.EMAIL ? "email" : "tel"}
                placeholder={
                    (type === TYPE.EMAIL ? "From email " : "From number ") + "Should not be empty"
                }
                className={classes.itemInput}
                style={{ height: "38px !important" }}
                name="from"
                maxLength={100}
                value={from}
                disabled
                onChange={(e) => {
                  handleChangeInput(e.target.name, e.target.value);
                }}
              />
            </Grid>

            {type === TYPE.EMAIL && (
              <Grid item xs={12}>
                <p className={classes.label_text} style={{ marginBottom: 5 }}>
                  Subject *
                </p>
                <input
                  type="text"
                  placeholder="Type subject"
                  className={classes.itemInput}
                  style={{ height: "38px !important" }}
                  name="subject"
                  value={formData.subject}
                  onChange={(e) => {
                    handleChangeInput(e.target.name, e.target.value);
                  }}
                />
                {simpleValidator.current.message(
                  "Subject",
                  formData.subject,
                  "required"
                )}
              </Grid>
            )}
          </Grid>
        </Box>

        <Box sx={{ paddingY: 2 }}>
          <p className={classes.label_text} style={{ marginBottom: 5 }}>
            {type === TYPE.EMAIL ? "Email" : "SMS"} Body *
          </p>
          <Editor
            apiKey="bicllzrgrdrhiix7wookyk6xg818xio48pu2dpyp7rtzcqtr"
            value={formData.body}
            textareaName="invoice_note"
            onEditorChange={(value) => handleChangeInput("body", value)}
          />

          {simpleValidator.current.message("From", formData.body, "required")}
        </Box>
      </Box>

      {/* <Divider light /> */}

      <Box
        sx={{ display: "flex", justifyContent: "space-between", gap: 10 }}
        py={1}
      >
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              name="status"
              checked={formData.status}
              onChange={(e) =>
                handleChangeInput(e.target.name, e.target.checked)
              }
            />
          }
          label="Enable This Reminder"
        />

        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 10 }}>
          {isEdit && (
            <Button
              variant="contained"
              endIcon={<DeleteIcon />}
              size="small"
              onClick={handleDelete}
            >
              Delete this reminder
            </Button>
          )}

          <Button variant="contained" size="small" onClick={onClose}>
            Cancel
          </Button>

          <Button
            variant="contained"
            style={{
              backgroundColor: "#3C7EF3",
              color: "white",
              width: "115px",
              fontSize: "16px",
            }}
            size="small"
            onClick={handleSubmit}
            disabled={loading}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AddOrEditReminder;
