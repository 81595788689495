import { Button } from "@material-ui/core";
import { Fragment, useEffect, useRef, useState } from "react"
import styled from "styled-components";
import { saveEmailProvider } from "../../api/profileApi";
import Utils from "../../helpers/Utils";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import SimpleReactValidator from 'simple-react-validator';

const StyledRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    justify-content: center !important;
`;

const FlexDiv = styled.div`
    align-items: center !important;
    display: flex !important;
`;

const CenterSaveButtonWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center !important;
    text-align: center !important;
`;

const StyledCol = styled.div`
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 25%;
    max-width: 25%;
`;

const StyledButton = styled.button`
    min-width: 180px;
    background: transparent;
    color: #00b8ff;
    border: 1px solid #00b8ff;
    transition: all .3s ease-in;
    cursor: pointer;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    padding-bottom: .5rem !important;
    padding-top: .5rem !important;

    .logo {
        width: 64px;
        height: 64px;
    }
`;

const EmailProvider = (props) => {
    const [sendgridSetupVisible, setSendgridSetupVisible] = useState(false);
    const [nylasSetupVisible, setNylasSetupVisible] = useState(false);

    const [loading, setLoading] = useState(false);
    const [mail, setMail] = useState('');

    //validator
    const [, forceUpdate] = useState() //this is a dummy state, when form submitted, change the state so that message is rendered
    const simpleValidator = useRef(new SimpleReactValidator({
            autoForceUpdate: {forceUpdate: forceUpdate},
            className: 'small text-danger mdi mdi-alert pt-1 pl-1'
    }));

    useEffect(() => {
        if (props.user && (props.user.email_provider !== null) && parseInt(props.user.email_provider) === 0) {
            setSendgridSetupVisible(true);
        } else if (props.user && (props.user.email_provider !== null) && parseInt(props.user.email_provider) === 1) {
            setNylasSetupVisible(true);

            if (props.user.nylas_email_provider_info !== null) {
                setMail(JSON.parse(props.user.nylas_email_provider_info).email);
            }
        }
    }, [props.user]);

    const saveProvider = (e) => {
        e.preventDefault();

        confirmAlert({
            title: 'Are you sure?',
            message: 'Once provider is set, if you change this setting you may lose your previous data!',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        setLoading(true);

                        saveEmailProvider({
                            "form_data": {
                                provider: sendgridSetupVisible ? 'Sendgrid' : 5 //5 is nylas
                            }
                        })
                        .then(response => {
                            if (response.data.data && typeof response.data.data.redirect !== 'undefined') {
                                window.location.href = response.data.data.redirect;
                            } else {
                                if(response.data.status !== undefined && response.data.status === "success"){
                                    Utils.showNotification(response.data.message, "success");
                                }else{
                                    Utils.showNotification(response.data.message);
                                }
                            }
                        })
                        .finally(() => {
                            setLoading(false)
                        })
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        return;
                    }
                }
            ]
        })
    } 

    const renderProviderOption = (
        <Fragment>
            <p style={{color: 'gray'}}>Simply connect your email provider to get started</p>
            <StyledRow>
                <StyledCol>
                    <StyledButton class="px-3 py-2 provider-item provider-item-system" onClick={() => setSendgridSetupVisible(true)}>
                        <img class="logo" alt="system" src={process.env.REACT_APP_CDN_LINK + 'asset/images/releases/1.0.0/system.png'}/><br/>Use <br/><b>System</b> default
                    </StyledButton>
                </StyledCol>
                <StyledCol>
                    <StyledButton class="px-3 py-2 provider-item provider-item-system" onClick={() => setNylasSetupVisible(true)}>
                        <img class="logo" alt="hosted" src={process.env.REACT_APP_CDN_LINK + 'asset/images/releases/1.0.0/hosted.png'}/><br/>Use <br/><b>Personal</b> default
                    </StyledButton>
                </StyledCol>
            </StyledRow>
        </Fragment>
    )

    const renderSendgridProvider = (
        <Fragment>
            <div class="szn-system-email-provider-block">
                <div id="szn-container">
                    <div id="success-box">
                        <div class="dot"></div>
                        <div class="dot two"></div>
                        <div class="face">
                            <div class="eye"></div>
                            <div class="eye right"></div>
                            <div class="mouth happy"></div>
                        </div>
                        <div class="shadow scale"></div>
                        {/* <div class="message"><span class="alert span">Psstt!!</span></div> */}
                        <button type="button" class="button-box"><span class="zh1">You are using the system default email. This is not recommended.</span></button>
                    </div>
                </div>
            </div>
            <CenterSaveButtonWrapper className="mt-3 text-center">
                <Button className={"accent--bg--text--color"} variant="contained" color="primary" disabled={loading} onClick={saveProvider}>
                    {!loading ? 'Save' : 'Please Wait...'}
                </Button>
            </CenterSaveButtonWrapper>
        </Fragment>
    )

    const renderNylasProvider = (
        <form onSubmit={(e) => {
            e.preventDefault();
        
            if (simpleValidator.current.allValid()) {
                saveProvider(e);
            } else {
                simpleValidator.current.showMessages();
                forceUpdate(1);
            }
        }}>
            {
                (props.user.nylas_email_provider_info !== null) && (
                    <p style={{color: 'gray'}}>You have connected your email {JSON.parse(props.user.nylas_email_provider_info).email} to this system</p>
                )
            }
            <StyledRow>
                <StyledCol className="mt-5">
                    <h5>Credential</h5>
                    <input type="text" placeholder="Enter your personal mail" value={mail} onChange={e => setMail(e.target.value)}/>
                    {simpleValidator.current.message('email', mail, 'required|email')}
                    <Button className={"accent--bg--text--color"} variant="contained" color="primary" disabled={loading} type="submit">
                        {!loading ? ((props.user.nylas_email_provider_info !== null) ? 'Reconnect' : 'Connect') : 'Please Wait...'}
                    </Button>
                </StyledCol>
            </StyledRow>
            {/*<CenterSaveButtonWrapper className="mt-3 text-center">*/}
            {/*    <Button className={"accent--bg--text--color"} variant="contained" color="primary" disabled={loading} type="submit">*/}
            {/*        {!loading ? ((props.user.nylas_email_provider_info !== null) ? 'Reconnect' : 'Connect') : 'Please Wait...'}*/}
            {/*    </Button>*/}
            {/*</CenterSaveButtonWrapper>*/}
        </form>
    )

    return (
        <div>
            <FlexDiv>
                <div style={{ marginRight: 'auto'}}>
                    <h5>Email Provider</h5>
                    <p>
                        {
                            sendgridSetupVisible ? (
                                <b>You are using the system default email. This is not recommended.</b>
                            ) : (
                                nylasSetupVisible ? (
                                    <b>You're setting up Personal email provider</b>
                                ) : (
                                    <b>Email provider that will be use to send emails</b>
                                )
                            )
                        }
                    </p>
                </div>
                {
                    (sendgridSetupVisible || nylasSetupVisible) && (
                        <div>
                            <button class="btn change-provider accent--bg--text--color" type="button" onClick={() => {
                                setSendgridSetupVisible(false);
                                setNylasSetupVisible(false);
                            }}>Choose another email provider</button>
                        </div>
                    )
                }
            </FlexDiv>
            <hr/>
            <div>
                {
                    (!sendgridSetupVisible && !nylasSetupVisible) && renderProviderOption
                }
                {
                    sendgridSetupVisible && renderSendgridProvider
                }
                {
                    nylasSetupVisible && renderNylasProvider
                }
            </div>
        </div>
    )
}

export default EmailProvider;