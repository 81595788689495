import React, { useState, useEffect } from "react";
import './geo-style.css'

const WhatIsGeoFraming = ({title,content1,content2,content3,img}) => {
    return(
        <div className="what__geo__farming__wr">
            <div className="what__geo__farming__left">
                {/* <div className="what__borer__dashed">

                </div> */}
                <h2 className="what__geo__farming__left__title">{title}</h2>
                <p>{content1}</p>
                <p>{content2}</p>
                <p>{content3}</p>
            </div>
            <div className="what__geo__farming__right">
            <img src={`${process.env.REACT_APP_CDN_LINK}asset/images/releases/1.0.0/geo_farming.png`} alt="geo-farm"/>
            </div>
           
        </div>
    )
}


export default WhatIsGeoFraming;