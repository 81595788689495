import React, {createContext, useContext, useReducer} from 'react'
import CustomFieldReducer from './CustomFieldReducer';
import { cfStates } from './states';

const CustomFieldReducerContext = createContext({});
const CustomFieldProvider = ({children}) => {
  const [state, dispatch] = useReducer(CustomFieldReducer, cfStates);
  return (
    <CustomFieldReducerContext.Provider value={{ state, dispatch }}>{children} </CustomFieldReducerContext.Provider>
  );
}
export {CustomFieldReducerContext, CustomFieldProvider}
export const useCFContext = () => {
  return useContext(CustomFieldReducerContext)
}