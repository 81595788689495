import React, { useRef, useState } from 'react';
import DoneIcon from '@material-ui/icons/Done';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ClearIcon from '@material-ui/icons/Clear';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import BootstrapTooltip from '../globals/BootstrapTooltip';
import { deleteVirtualNumberSetting, forwardVirtualNumberSubmit } from '../../api/profileApi';
import { confirmAlert } from 'react-confirm-alert';

const ForwardNumberEdit = ({ item }) => {

    const inputField = useRef(null);
    const [validation,setValidation] = useState(false);
    const [validationText,setValidationText] = useState('');
    const [edit,setEdit] = useState(false);
    const [saving,setSaving] = useState(false);
    const [fieldValue,setFieldValue] = useState(item.forward_number);
    const [mouseOver,setMouseOver] = useState(false);
    const [defaultValue,setDefaultValue] = useState(item.forward_number);


    const handleCrossPress = (forceClose = true) => {
        setEdit(false);
        setValidation(false);
        setValidationText('');
        if(forceClose) {
            setFieldValue(defaultValue);
        }
    }

    const handleEnterPress = (event) => {
        var code = (event.keyCode ? event.keyCode : event.which);
        if(code === 13) {
            handleSubmit();
        }
    }

    const handleCheckPress = () => {
        handleSubmit();
    }

    const handleSubmit = () => {
        setSaving(true);
        forwardVirtualNumberSubmit({id : item.id,forward_number : fieldValue}).then(res => {
            setSaving(false);
            if(res.data.status === 'validation-error') {
                window.showNotification('ERROR',res.data.message[Object.keys(res.data.message)[0]][0]);
            } else if(res.data.status === 'error') {
                window.showNotification('ERROR',res.data.message);
            } else if(res.data.status === 'success'){
                setDefaultValue(fieldValue);
                window.showNotification('SUCCESS','Forward Number Changed');
                handleCrossPress(false);
            }
        });
    }

    const deleteSetting = () => {
        confirmAlert({
            title: 'Are you sure?',
            message: 'Are you sure to delete this forward number?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        setSaving(true);
                        deleteVirtualNumberSetting({id : item.id,setting : 'forward_number'}).then(res => {
                            setSaving(false);
                            if(res.data.status === 'validation-error') {
                                window.showNotification('ERROR',res.data.message[Object.keys(res.data.message)[0]][0]);
                            } else if(res.data.status === 'error') {
                                window.showNotification('ERROR',res.data.message);
                            } else if(res.data.status === 'success'){
                                setDefaultValue('');
                                setFieldValue('');
                                window.showNotification('SUCCESS','Forward Number Deleted');
                                handleCrossPress(false);
                            }
                        });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {return}
                }
            ]
          });
    }

    const focusTextField = () => {
        setTimeout(() => {
            inputField.current.focus();
        }, 200);
    }

    return (
        <>
            <div className="d-flex align-items-center justify-content-between" 
            >
                {/* <h6>{fieldValue}</h6> */}
                {validation &&
                    <small className="text-danger">{validationText}</small>
                }
            </div>
            <div className="text-field-item-vn-page forward__number_" onMouseEnter={()=>setMouseOver(true)} onMouseLeave={()=> setMouseOver(false)}>
                <input ref={inputField} className="text-field-vn-page text-field-vn-page-v2 text-gray" value={fieldValue ? fieldValue : ""} onKeyPress={handleEnterPress} onChange={e => setFieldValue(e.target.value)} type="text" disabled={!edit} defaultValue={fieldValue} placeholder={edit ? 'Enter Forward number' : ''}/>

                {(!edit && !saving && mouseOver) &&
                    <>
                        {
                            defaultValue !== '' &&
                            <BootstrapTooltip arrow title={`Delete Forward Number Setting`}>
                                <span className="small-round-icon-vn-page checkIcon" onClick={() => {deleteSetting()}}>{<DeleteIcon fontSize="small" />}</span>
                            </BootstrapTooltip>
                        }
                        <BootstrapTooltip arrow title={`Update Forward Number`}>
                            <span className="small-round-icon-vn-page" onClick={() => {setEdit(true);setMouseOver(false);focusTextField()}}>{<CreateIcon fontSize="small" />}</span>
                        </BootstrapTooltip>
                        
                    </>
                }

                {(saving) &&
                    <BootstrapTooltip arrow title={`Updating Forward Number`}>
                        <span className="small-round-icon-vn-page" >{<MoreHorizIcon fontSize="small" />}</span>
                    </BootstrapTooltip>
                }

                {(edit && !saving) &&
                    <div>
                        <BootstrapTooltip arrow title="confirm">
                            <span className="small-round-icon-vn-page checkIcon" onClick={handleCheckPress}>{<DoneIcon fontSize="small" />}</span>
                        </BootstrapTooltip>

                        <BootstrapTooltip arrow title="cancel">
                            <span className="small-round-icon-vn-page" onClick={handleCrossPress}>{<ClearIcon fontSize="small" />}</span>
                        </BootstrapTooltip>
                    </div>
                }
            </div>
        </>
    );
}

export default ForwardNumberEdit;