import { Fragment, useEffect, useState } from "react"
import Loader from "../globals/Loader";
import { getNotificationSettings, getUserAccountDataApi, updateNotificationSettings } from "../../api/profileApi";
import BootstrapTooltip from "../globals/BootstrapTooltip";
import { setCookie } from "../../helpers/Cookie";
import Utils from "../../helpers/Utils";

const renderTooltip = (_message) => (
    <BootstrapTooltip arrow title={_message}>
        <svg className="ml-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 0C4.486 0 0 4.48606 0 10.0001C0 15.5141 4.486 20 10 20C15.514 20 20 15.5141 20 10.0001C20 4.48606 15.514 0 10 0ZM10 18.1818C5.48848 18.1818 1.81818 14.5115 1.81818 10.0001C1.81818 5.48861 5.48848 1.81818 10 1.81818C14.5115 1.81818 18.1818 5.48861 18.1818 10.0001C18.1818 14.5115 14.5115 18.1818 10 18.1818Z" fill="#133159" fill-opacity="0.5"></path>
            <path d="M10.0009 4.24219C9.3327 4.24219 8.78906 4.78619 8.78906 5.45485C8.78906 6.12292 9.3327 6.66643 10.0009 6.66643C10.6692 6.66643 11.2128 6.12292 11.2128 5.45485C11.2128 4.78619 10.6692 4.24219 10.0009 4.24219Z" fill="#133159" fill-opacity="0.5"></path>
            <path d="M9.99893 8.48438C9.49687 8.48438 9.08984 8.89141 9.08984 9.39347V14.848C9.08984 15.3501 9.49687 15.7571 9.99893 15.7571C10.501 15.7571 10.908 15.3501 10.908 14.848V9.39347C10.908 8.89141 10.501 8.48438 9.99893 8.48438Z" fill="#133159" fill-opacity="0.5"></path>
        </svg>
    </BootstrapTooltip>
);

const Notification = (props) => {

    const [componentLoading, setComponentLoading] = useState(true);
    const [settings, setSettings] = useState(null);
    const [isSuccess,setSuccess] = useState(true)

    useEffect(() => {
        loadData();
    }, [isSuccess])


    const loadData = () => {
        getNotificationSettings({})
        .then(response => {
            setSettings(response.data.data);
        })
        .finally(() => {
            setComponentLoading(false);
        })
    }

    const onChangeHandler = (e, _setting) => {

        updateNotificationSettings({
            "form_data": {
                setting: _setting
            }
        })
        .then(response => {
          window.showNotification("SUCCESS", response.data.message)
            let res = response.data;
            if(res.status === "success"){
                getUserAccountDataApi().then(res => {
                    let response = res.data;
                    if(response.status){
                        setCookie(process.env.REACT_APP_ACCOUNT_DATA, JSON.stringify(response.data), process.env.REACT_APP_ACCESS_TOKEN_VALIDITY);
                    }
                })
                window.showNotification("SUCCESS",response.data.message)
                setSuccess(!isSuccess)
            }
        });
    }

    return (
        <Fragment>
            {
                (componentLoading || !settings) ? (
                    <div style={{paddingTop: 50}}>

                        <Loader />

                    </div>
                ) : (
                    <div id="notification" className="col s12 active">
                        <div className="responsive-table">
                            <table className="notification_table">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Email</th>
                                        {
                                            Utils.validateUsMobileNumber(Utils.getAccountData("phone_number")) && (
                                                <th className="text-center">SMS</th>
                                            )
                                        }
                                        <th className="text-center">
                                            Push Notification
                                            {renderTooltip('Upcoming')}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            Daily Task/appointment summary
                                            {renderTooltip('Total task appointment list with time and contact details')}
                                        </td>
                                        <td>
                                            <label className="switch">
                                                <small className="on">On</small>
                                                <input 
                                                    type="checkbox" 
                                                    defaultChecked={parseInt(settings.task_appointment_summary_email)}
                                                    onChange={(e) => onChangeHandler(e, 'task_appointment_summary_email')}
                                                />
                                                <span className="slider round"></span>
                                                <small className="off">Off</small>
                                            </label>
                                        </td>
                                        <td className="text-center"></td>
                                        <td className="text-center"></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Send a notification 30 minutes prior to an upcoming activity/appointment
                                            {renderTooltip('Notify before 30 minutes of task with contact details,task and time.')}
                                        </td>
                                        <td>
                                            <label className="switch">
                                                <small className="on">On</small>
                                                <input 
                                                    type="checkbox" 
                                                    defaultChecked={parseInt(settings.task_appointment_notify_email)}
                                                    onChange={(e) => onChangeHandler(e, 'task_appointment_notify_email')}
                                                />
                                                <span className="slider round"></span>
                                                <small className="off">Off</small>
                                            </label>
                                        </td>
                                        <td className="text-center"></td>
                                        <td className="text-center"></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Daily summary of won deals
                                            {renderTooltip('How many deal win, with contact details.')}
                                        </td>
                                        <td>
                                            <label className="switch">
                                                <small className="on">On</small>
                                                <input 
                                                    type="checkbox" 
                                                    defaultChecked={parseInt(settings.deal_win_summary_email)}
                                                    onChange={(e) => onChangeHandler(e, 'deal_win_summary_email')}
                                                />
                                                <span className="slider round"></span>
                                                <small className="off">Off</small>
                                            </label>
                                        </td>
                                            {
                                                Utils.validateUsMobileNumber(Utils.getAccountData("phone_number")) && (
                                                    <td className="text-center">
                                                    <label className="switch">
                                                        <small className="on">On</small>
                                                        <input
                                                            type="checkbox"
                                                            defaultChecked={parseInt(settings.deal_win_summary_sms)}
                                                            onChange={(e) => onChangeHandler(e, 'deal_win_summary_sms')}
                                                        />
                                                        <span className="slider round"></span>
                                                        <small className="off">Off</small>
                                                    </label>
                                                    </td>
                                                )
                                            }
                                        <td className="text-center"></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Daily new contact summary
                                            {renderTooltip('Notify at day end, with total new contact count.')}
                                        </td>
                                        <td>
                                            <label className="switch">
                                                <small className="on">On</small>
                                                <input 
                                                    type="checkbox" 
                                                    defaultChecked={parseInt(settings.total_new_contact_summary_email)}
                                                    onChange={(e) => onChangeHandler(e, 'total_new_contact_summary_email')}
                                                />
                                                <span className="slider round"></span>
                                                <small className="off">Off</small>
                                            </label>
                                        </td>
                                            {
                                                Utils.validateUsMobileNumber(Utils.getAccountData("phone_number")) && (
                                                    <td className="text-center">
                                            <label className="switch">
                                                <small className="on">On</small>
                                                <input 
                                                    type="checkbox" 
                                                    defaultChecked={parseInt(settings.total_new_contact_summary_sms)}
                                                    onChange={(e) => onChangeHandler(e, 'total_new_contact_summary_sms')}
                                                />
                                                <span className="slider round"></span>
                                                <small className="off">Off</small>
                                            </label>
                                                    </td>)
                                            }
                                        <td className="text-center"></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Daily campaign fire summary
                                            {renderTooltip('Notify at day end, with total new campaign count.')}
                                        </td>
                                        <td>
                                            <label className="switch">
                                                <small className="on">On</small>
                                                <input 
                                                    type="checkbox" 
                                                    defaultChecked={parseInt(settings.total_campaign_fire_summary_email)}
                                                    onChange={(e) => onChangeHandler(e, 'total_campaign_fire_summary_email')}
                                                />
                                                <span className="slider round"></span>
                                                <small className="off">Off</small>
                                            </label>
                                        </td>
                                            {
                                                Utils.validateUsMobileNumber(Utils.getAccountData("phone_number")) && (
                                                    <td className="text-center">
                                            <label className="switch">
                                                <small className="on">On</small>
                                                <input 
                                                    type="checkbox" 
                                                    defaultChecked={parseInt(settings.total_campaign_fire_summary_sms)}
                                                    onChange={(e) => onChangeHandler(e, 'total_campaign_fire_summary_sms')}
                                                />
                                                <span className="slider round"></span>
                                                <small className="off">Off</small>
                                            </label></td>)
                                            }
                                        <td className="text-center"></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Daily total reply summary
                                            {renderTooltip('Notify at day end, with total new reply count.')}
                                        </td>
                                        <td>
                                            <label className="switch">
                                                <small className="on">On</small>
                                                <input 
                                                    type="checkbox" 
                                                    defaultChecked={parseInt(settings.total_reply_summary_email)}
                                                    onChange={(e) => onChangeHandler(e, 'total_reply_summary_email')}
                                                />
                                                <span className="slider round"></span>
                                                <small className="off">Off</small>
                                            </label>
                                        </td>
                                            {
                                                Utils.validateUsMobileNumber(Utils.getAccountData("phone_number")) && (
                                                    <td className="text-center">
                                            <label className="switch">
                                                <small className="on">On</small>
                                                <input 
                                                    type="checkbox" 
                                                    defaultChecked={parseInt(settings.total_reply_summary_sms)}
                                                    onChange={(e) => onChangeHandler(e, 'total_reply_summary_sms')}
                                                />
                                                <span className="slider round"></span>
                                                <small className="off">Off</small>
                                            </label> </td>)
                                            }
                                        <td className="text-center"></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            New lead notification
                                            {renderTooltip('New lead incoming, with contact details')}
                                        </td>
                                        <td>
                                            <label className="switch">
                                                <small className="on">On</small>
                                                <input 
                                                    type="checkbox" 
                                                    defaultChecked={parseInt(settings.new_lead_notify_email)}
                                                    onChange={(e) => onChangeHandler(e, 'new_lead_notify_email')}
                                                />
                                                <span className="slider round"></span>
                                                <small className="off">Off</small>
                                            </label>
                                        </td>
                                            {
                                                Utils.validateUsMobileNumber(Utils.getAccountData("phone_number")) && (
                                                    <td className="text-center">
                                            <label className="switch">
                                                <small className="on">On</small>
                                                <input 
                                                    type="checkbox" 
                                                    defaultChecked={parseInt(settings.new_lead_notify_sms)}
                                                    onChange={(e) => onChangeHandler(e, 'new_lead_notify_sms')}
                                                />
                                                <span className="slider round"></span>
                                                <small className="off">Off</small>
                                            </label></td>)
                                            }
                                        <td className="text-center"></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            New reply notification
                                            {renderTooltip('New reply incoming, with contact details')}
                                        </td>
                                        <td >
                                            <label className="switch">
                                                <small className="on">On</small>
                                                <input 
                                                    key={settings?.new_reply_notify_email}
                                                    type="checkbox" 
                                                    defaultChecked={parseInt(settings.new_reply_notify_email)}
                                                    onChange={(e) => onChangeHandler(e, 'new_reply_notify_email')}
                                                />
                                                <span className="slider round"></span>
                                                <small className="off">Off</small>
                                            </label>
                                        </td>
                                            {
                                                Utils.validateUsMobileNumber(Utils.getAccountData("phone_number")) && (
                                                    <td className="text-center">
                                            <label className="switch">
                                                <small className="on">On</small>
                                                <input 
                                                    key={settings?.new_reply_notify_sms}
                                                    type="checkbox" 
                                                    defaultChecked={parseInt(settings.new_reply_notify_sms)}
                                                    onChange={(e) => onChangeHandler(e, 'new_reply_notify_sms')}
                                                />
                                                <span className="slider round"></span>
                                                <small className="off">Off</small>
                                            </label></td>)
                                            }

                                        <td className="text-center">
                                            <label className="switch">
                                                <small className="on">On</small>
                                                <input 
                                                    type="checkbox" 
                                                    defaultChecked={parseInt(settings.new_reply_notify_push_notification)}
                                                    onChange={(e) => onChangeHandler(e, 'new_reply_notify_push_notification')}
                                                />
                                                <span className="slider round"></span>
                                                <small className="off">Off</small>
                                            </label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            New reply with history
                                            {renderTooltip('New reply incoming, with contact details,incomming content and history')}
                                        </td>
                                        <td >
                                            <label className="switch">
                                                <small className="on">On</small>
                                                <input 
                                                    key={settings?.new_reply_with_history_email}
                                                    type="checkbox" 
                                                    defaultChecked={parseInt(settings.new_reply_with_history_email)}
                                                    onChange={(e) => onChangeHandler(e, 'new_reply_with_history_email')}
                                                />
                                                <span className="slider round"></span>
                                                <small className="off">Off</small>
                                            </label>
                                        </td>
                                            {
                                                Utils.validateUsMobileNumber(Utils.getAccountData("phone_number")) && (
                                                    <td className="text-center">
                                            <label className="switch">
                                                <small className="on">On</small>
                                                <input
                                                    key={settings?.new_reply_with_history_sms} 
                                                    type="checkbox" 
                                                    defaultChecked={parseInt(settings.new_reply_with_history_sms)}
                                                    onChange={(e) => onChangeHandler(e, 'new_reply_with_history_sms')}
                                                />
                                                <span className="slider round"></span>
                                                <small className="off">Off</small>
                                            </label></td>)
                                            }
                                    </tr>
                                    <tr>
                                        <td>
                                            New reply notification (includes contact info and their message)
                                            {renderTooltip('New reply incoming, with contact details,incomming content')}
                                        </td>
                                        <td>
                                            <label className="switch">
                                                <small className="on">On</small>
                                                <input
                                                    key={settings?.new_reply_with_incomming_content_email} 
                                                    type="checkbox" 
                                                    defaultChecked={parseInt(settings.new_reply_with_incomming_content_email)}
                                                    onChange={(e) => onChangeHandler(e, 'new_reply_with_incomming_content_email')}
                                                />
                                                <span className="slider round"></span>
                                                <small className="off">Off</small>
                                            </label>
                                        </td>
                                            {
                                                Utils.validateUsMobileNumber(Utils.getAccountData("phone_number")) && (
                                                    <td className="text-center">
                                            <label className="switch">
                                                <small className="on">On</small>
                                                <input
                                                    key={settings?.new_reply_with_incomming_content_sms} 
                                                    type="checkbox" 
                                                    defaultChecked={parseInt(settings.new_reply_with_incomming_content_sms)}
                                                    onChange={(e) => onChangeHandler(e, 'new_reply_with_incomming_content_sms')}
                                                />
                                                <span className="slider round"></span>
                                                <small className="off">Off</small>
                                            </label></td>)
                                            }
                                    </tr>
                                    <tr>
                                        <td>
                                            Daily summary of completed tasks/appointments
                                            {renderTooltip('Notify at day end, with total mark as done count.')}
                                        </td>
                                        <td>
                                            <label className="switch">
                                                <small className="on">On</small>
                                                <input 
                                                    type="checkbox" 
                                                    defaultChecked={parseInt(settings.mark_as_done_summary_email)}
                                                    onChange={(e) => onChangeHandler(e, 'mark_as_done_summary_email')}
                                                />
                                                <span className="slider round"></span>
                                                <small className="off">Off</small>
                                            </label>
                                        </td>
                                            {
                                                Utils.validateUsMobileNumber(Utils.getAccountData("phone_number")) && (
                                                    <td className="text-center">
                                            <label className="switch">
                                                <small className="on">On</small>
                                                <input 
                                                    type="checkbox" 
                                                    defaultChecked={parseInt(settings.mark_as_done_summary_sms)}
                                                    onChange={(e) => onChangeHandler(e, 'mark_as_done_summary_sms')}
                                                />
                                                <span className="slider round"></span>
                                                <small className="off">Off</small>
                                            </label></td>)
                                            }
                                        <td className="text-center"></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Get notified when tasks/appointments are completed
                                            {renderTooltip('Notify after mark as done, with activity and contact details')}
                                        </td>
                                        <td>
                                            <label className="switch">
                                                <small className="on">On</small>
                                                <input 
                                                    type="checkbox" 
                                                    defaultChecked={parseInt(settings.mark_as_done_single_email)}
                                                    onChange={(e) => onChangeHandler(e, 'mark_as_done_single_email')}
                                                />
                                                <span className="slider round"></span>
                                                <small className="off">Off</small>
                                            </label>
                                        </td>
                                            {
                                                Utils.validateUsMobileNumber(Utils.getAccountData("phone_number")) && (
                                                    <td className="text-center">
                                            <label className="switch">
                                                <small className="on">On</small>
                                                <input 
                                                    type="checkbox" 
                                                    defaultChecked={parseInt(settings.mark_as_done_single_sms)}
                                                    onChange={(e) => onChangeHandler(e, 'mark_as_done_single_sms')}
                                                />
                                                <span className="slider round"></span>
                                                <small className="off">Off</small>
                                            </label></td>)
                                            }
                                        <td className="text-center"></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Payment notifications (success/declined)
                                            {renderTooltip('Success or fail payment notification')}
                                        </td>
                                        <td>
                                            <label className="switch">
                                                <small className="on">On</small>
                                                <input 
                                                    type="checkbox" 
                                                    defaultChecked={parseInt(settings.success_fail_payment_email)}
                                                    onChange={(e) => onChangeHandler(e, 'success_fail_payment_email')}
                                                />
                                                <span className="slider round"></span>
                                                <small className="off">Off</small>
                                            </label>
                                        </td>
                                            {
                                                Utils.validateUsMobileNumber(Utils.getAccountData("phone_number")) && (
                                                    <td className="text-center">
                                            <label className="switch">
                                                <small className="on">On</small>
                                                <input 
                                                    type="checkbox" 
                                                    defaultChecked={parseInt(settings.success_fail_payment_sms)}
                                                    onChange={(e) => onChangeHandler(e, 'success_fail_payment_sms')}
                                                />
                                                <span className="slider round"></span>
                                                <small className="off">Off</small>
                                            </label></td>)
                                            }
                                        <td className="text-center"></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Activity Reminders
                                            {renderTooltip('Reminder notification of tasks or appointments')}
                                        </td>
                                        <td>
                                            <label className="switch">
                                                <small className="on">On</small>
                                                <input 
                                                    type="checkbox" 
                                                    defaultChecked={parseInt(settings.activity_reminder_email)}
                                                    onChange={(e) => onChangeHandler(e, 'activity_reminder_email')}
                                                />
                                                <span className="slider round"></span>
                                                <small className="off">Off</small>
                                            </label>
                                        </td>
                                        <td className="text-center"></td>
                                        <td className="text-center"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                )
            }
        </Fragment>
    )
}

export default Notification;