import React, { forwardRef } from "react";
import { FormControlLabel, styled, Typography } from "@material-ui/core";
import { CHOOSE_TEMPLATE_LIST } from "../../constants/invoiceConstant";
import CustomRadio from "../common/Radio/CustomRadio";
import CustomRadioGroup from "../common/Radio/CustomRadioGroup";
import  CheckCircleIcon  from "@material-ui/icons/CheckCircle";
import {createTheme,ThemeProvider } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const theme = createTheme({
  palette: {
    other: {
      outlinedBorderDarker: '#0000', 
    },

  },
});
const StyledRadioGroup = styled(CustomRadioGroup)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: theme.spacing(2),
  flexWrap: "nowrap",
}));

export const ChooseTemplateWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  sx: {overflowX: 'auto'},
  lg: {overflowX: 'unset'},
  '&::-webkit-scrollbar': { display: 'none' },
  padding: '0'
}));

export const TemplateBoxWrp = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap', 
  gap: theme.spacing(1),
  padding:'10px'
}));

export const TemplateBox = styled(Box)(({ theme }) => ({
  minWidth: '270px',
  width: '100%',
  height: '450px',
  padding: theme.spacing(0.5),
  border: `2px solid #e1e1e1`,
  borderRadius: '4px',
  position: 'relative',
  transition: 'all 0.3s ease',
  '& .ib_checkedIcon': {
    position: 'absolute',
    bottom: '10px',
    right: '10px',
    transform: 'scale(0)',
  },
  '&.inv_selected': {
    borderColor: theme.palette.primary.light,
    '& .ib_checkedIcon': {
      transform: 'scale(1)',
    },
  },
  '&:hover': {
    borderColor: theme.palette.primary.light,
  },
  '& img': {
    width: '100%',
    height: '100%',
  },
}));



const ChooseTemplate = forwardRef((allProps, ref) => {
  const { selectedTemplate,onSelect, ...props } = allProps;

  const templateList = Object.values(CHOOSE_TEMPLATE_LIST);
  return (
    <ThemeProvider theme={theme}>
    <ChooseTemplateWrapper>
      <StyledRadioGroup>
        {templateList.map((templateInfo, index) => {
          return (
            <FormControlLabel
              style={{  width: "100%"}}
              key={templateInfo.value}
              control={<CustomRadio {...props} value={templateInfo.value} onClick={()=>{
                onSelect(templateInfo)
              }} ref={ref} style={{ display: "none" }} />}
              label={ 
                <TemplateBoxWrp>
                  <TemplateBox className={selectedTemplate  === templateInfo.value ? "inv_selected" : ""}>
                    <img src={templateInfo.image} alt='default invoice' />
                    <CheckCircleIcon color='primary' className='ib_checkedIcon' />
                  </TemplateBox>
                  <Typography variant='body2'>{templateInfo.label}</Typography>
                </TemplateBoxWrp>
              }
            />
          );
        })}
      </StyledRadioGroup>
    </ChooseTemplateWrapper>
    </ThemeProvider>
  );
});

ChooseTemplate.displayName = "ChooseTemplate";
export default ChooseTemplate;