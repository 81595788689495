import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { setMarkAllAsSeen } from '../../api/NotificationsApi';
import SeenNotifications from './SeenNotifications';
import './siteNotification.css';
import UnseenNotifications from './UnseenNotifications';
import Utils from "../../helpers/Utils";

const SiteNotification = () => {

    const [activeTab,setActiveTab] = useState(0);

    useEffect(() => {
        document.title = `Site Notification | ${Utils.getAccountData('AuthUserAgencyName')}`;
    },[])
 

    const handleMarkAllAsSeen = () => {

        confirmAlert({
            title: 'Are you sure?',
            message: 'Do you want to mark as seen all notifications?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        setMarkAllAsSeen({}).then((res) => {
                            if(res.data.success == true) { // eslint-disable-line eqeqeq
                                setActiveTab(1);
                                window.showNotification("SUCCESS", res.data.message)
                            }
                        });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {return}
                }
            ]
          });
    }

    return ( 		
        <div class="container-fluid">
            <div class="notifications_bx">
                <h5>Notifications</h5>
            </div>
            <div class="notification_tab">
                <ul class="tabs tabs_list">
                    <li class="tab" onClick={() => setActiveTab(0)}>
                        {
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        } <a className={activeTab === 0 ? 'active' : ''}>Unseen</a>
                    </li>
                    <li class="tab" onClick={() => setActiveTab(1)}>
                        {
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        } <a className={activeTab !== 0 ? 'active' : ''}>Seen</a>
                    </li>
                </ul>
                {
                    activeTab === 0 &&
                    <div class="mark_list">
                        <p onClick={() => handleMarkAllAsSeen()}>Mark All as Seen</p>
                    </div>
                }

            </div>

            {
                activeTab === 0 &&
                <UnseenNotifications/>
            }

            {
                activeTab !== 0 &&
                <SeenNotifications/>
            }
        </div>
     );
}
 
export default SiteNotification;