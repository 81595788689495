
import React, { createContext, useReducer } from "react";
import ActionTypes from "./ActionTypes";

export const InvoiceContext = createContext();

const initialState = {
  isLoading: false,
  payment_credentials: [],
  error: null,
  total: 0,
  total_page: 0,
  per_page: 10,
  page: 1
};


const invoiceReducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.GET_CREDENTIAL:
      return {
        ...state,
        isLoading: false,
        payment_credentials: action.payload.data,
        page: action.payload.page,
        per_page: action.payload.per_page,
        total: action.payload.total,
        total_page: action.payload.total_page,
        error: null,
      };

    case ActionTypes.SET_CREDENTIAL:
      return {
        ...state,
        ...action.payload
      };
    case ActionTypes.SAVE_CREDENTIAL:
      return {
        ...state,
        isLoading: false,
        payment_credentials: [...state.payment_credentials, action.payload],
        error: null,
      };
    case ActionTypes.SET_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
   
    case ActionTypes.UPDATE_TABLE_DATA:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
        error: null,
      };
    case ActionTypes.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
};


export const InvoiceProvider = ({ children }) => {
  const [state, dispatch] = useReducer(invoiceReducer, initialState);

  return (
    <InvoiceContext.Provider value={{ state, dispatch }}>
      {children}
    </InvoiceContext.Provider>
  );
};
