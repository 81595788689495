import { styled } from '@material-ui/core/styles';

export const StyledList = styled('ul')(({ theme }) => ({
  margin: 0,
  width: '100%',
  "& li": {
    color: 'black',
    margin: theme.spacing(0.5),
    cursor: "pointer",
    padding: "4px 15px",
    fontFamily: "Poppins, Arial, sans-serif",
    fontSize: "13px",
    textTransform: "capitalize",
    transition: "all 0.3s ease",
    borderRadius: theme.spacing(0.5),
    "&:hover": {
      borderColor: "transparent",
      backgroundColor: "#3C7EF3",
      color: theme.palette.primary.contrastText,
    },
  },
}));