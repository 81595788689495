import { IconList } from "./IconList";

export const PROFILE_TAB = {
    PROFILE_INFO: 1,
    CHANGE_PASSWORD: 2,
    COMPANY_INFO: 3,
    FAVICON: 4,
}

export const GENERAL_ACTIVE_TAB = {
    PROFILE: 1,
    TEAM: 2,
    SETTINGS: 3,
}

export const STATUS_TRUE  = 1;
export const STATUS_FALSE = 0;

const number_field=1;const email_field=2;const first_name_field=3;const last_name_field=4;
const address_field=5;const city_field=6;const state_field=7;const country_field=8;
const zip_field=9;const url_field=10;const deal_value_field=11;const birth_date_field=12;const anniversary_date_field=13;
const company_name=14;
export const getContactFormFields = [
    {value: number_field, label : 'Number', type: 'phone', tag: '[[contact_phone_number]]', length : '20', format: 'US-Number'},
    {value: email_field, label : 'Email', type: 'email', tag: '[[email]]', length : '200', format: 'email'},
    {value: first_name_field, label : 'First Name', type: 'text', tag: '[[first_name]]', length : '100', format: 'string'},
    {value: last_name_field, label : 'Last Name', type: 'text', tag: '[[last_name]]', length : '100', format: 'string'},
    {value: address_field, label : 'Address', type: 'text', tag: '[[contact_street_address]]', length : '200', format: 'string'},
    {value: city_field, label : 'City', type: 'text', tag: '[[contact_city]]', length : '100', format: 'string'},
    {value: state_field, label : 'State', type: 'text', tag: '[[contact_state]]', length : '100', format: 'string'},
    {value: country_field, label : 'Country', type: 'text', tag: '[[contact_country]]', length : '100', format: 'string'},
    {value: zip_field, label : 'Zip Code', type: 'number', tag: '[[contact_zip_code]]', length : '15', format: '1-15'},
    {value: url_field, label : 'url', type: 'url', tag: '[[contact_url]]', length : '15', format: 'url'},
    {value: deal_value_field, label : 'Deal Value', type: 'number', tag: '[[contact_deal_value]]', length : '255', format: '1-15'},
    {value: birth_date_field, label : 'Birth Date', type: 'date', tag: '[[contact_birth_date]]', length : 'date', format: 'date'},
    {value: anniversary_date_field, label : 'Anniversary Date', type: 'date', tag: '[[contact_anniversary_date]]', length : 'date', format: 'date'},
    {value: company_name, label : 'Company Name', type: '', tag: '[[contact_company_name]]', length : '---', format: ''}
];

export const DEFAULT_VALUE = 0;
export const TEXT = 1;
export const MULTILINE_TEXT = 2;
export const NUMERIC = 3;
export const DATE = 4;
export const CHECKBOX = 5;
export const SELECT = 6;
export const RADIO = 7;
export const PHONE = 8;
export const ZIP_CODE = 9;
export const WEBSITE = 10;
export const DATETIME = 11;

export const ALL_FIELD_NAME = {
    '1': 'Text',
    '2': 'Multiline Text',
    '3': 'Number',
    '4': 'Date',
    '5': 'Checkbox',
    '6': 'Dropdown',
    '7': 'Radio',
    '8': 'Phone',
    '9': 'Zip code',
    '10': 'Website',
    '11': 'Datetime'
}

export const All_FIELD_TYPE = [
    {value: TEXT, label: 'Text'},
    {value: MULTILINE_TEXT, label: 'Multiline Text'},
    {value: NUMERIC, label: 'Number'},
    {value: DATE, label: 'Date'},
    {value: CHECKBOX, label: 'Checkbox'},
    {value: SELECT, label: 'Dropdown'},
    {value: RADIO, label: 'Radio'},
    {value: PHONE, label: 'Phone'},
    {value: ZIP_CODE, label: 'Zip code'},
    {value: WEBSITE, label: 'Website'},
    {value: DATETIME, label: 'Datetime'}
]

export const NOTIFICATION_ICON = {
    0 : IconList.smsIcon,
    1 : IconList.emailIcon,
    2 : IconList.callIcon,
    3 : IconList.shareIcon,
    4 : IconList.formIcon,
}

export const LAYOUT_SETTINGS = {
    SHORT_MENU         : 19,
    TOPBAR_BACKGROUND  : 20,
    TOPBAR_TEXT_COLOR  : 21,
    SIDEBAR_BACKGROUND : 22,
    SIDEBAR_TEXT_COLOR : 23,
    ACCENT_COLOR : 26,
}

export const MenuProps = {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left"
    },
    getContentAnchorEl: null
}

export const SITE_NOTIFICATION = {
    SMS : 0,
    EMAIL : 1,
    CALL : 2,
    CAMPAIGN_PAUSE : 5
}
export const TWILIO_SETUP = {
    policyTypeLowVolumn: 1,
    policyTypeHighVolumn: 2,
}

export const A2P_REGISTRATION_TYPES = {
    SOLE_PROPRIETOR: "SOLE_PROPRIETOR"
};

