import {
  getCustomFieldsV2Api,
  reorderCustomFieldDataV2Api,
} from "../../../../api/CustomFieldApi";
import { useIsMounted } from "../helpers/IsMounted";
import useDelayCallback from "../helpers/useDelayCallback";
import {
  handlePaginationPage,
  handlePaginationTotal,
  handleStates,
  handleStoreFields,
} from "../reducer/Actions";
import { useCFContext } from "../reducer/CustomProvider";
import EachItem from "./each-item/EachItem";
import Pagination from "./pagination/Pagination";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import CustomMenuPype from "../../../custom-menu/CustomMenuPype";
import Styles from "./style.module.css";

const RightSideApp = () => {
  const isMounted = useIsMounted();
  const { state, dispatch } = useCFContext();
  
  useDelayCallback(() => {
    if (state.blockRefresh) {
      dispatch(handleStates({ blockRefresh: false }));
    } else {
      if (state.selectedFolder != null) {
        if (state.page === 0) {
          getCustomFields();
        } else {
          dispatch(handlePaginationPage(0));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedFolder]);
  useDelayCallback(() => {
    if (isMounted) {
      if (state.selectedFolder != null) {
        getCustomFields();
      }
    }
  }, [state.page, state.perPage]);
  const getCustomFields = () => {
    dispatch(handleStates({ gettingFields: true }));
    getCustomFieldsV2Api({
      folder: state.selectedFolder,
      page: state.page,
      perPage: state.perPage,
    })
      .then((res) => {
        const response = res.data;
        if (response.status === "success") {
          dispatch(handleStoreFields(response.data.item));
          if (state.page === 0) {
            dispatch(handlePaginationTotal(response.data.count));
          }
        } else {
          dispatch(handleStates({ gettingFields: false }));
        }
      })
      .catch((error) => {});
  };
  const onDragEnd = (result) => {
    let { destination, draggableId, source, type } = result;
    if (destination == null || destination.index === source.index) {
      return;
    }

    const custom_fields = Reorder(
      state.fields,
      source.index,
      destination.index
    );
    dispatch(handleStoreFields(custom_fields));

    reorderCustomFieldDataV2Api({
      id: state.fields[parseInt(source.index)].id,
      group_name: state.selectedFolder,
      destination_index: destination.index,
      source_index: source.index,
      destination_id: state.fields[parseInt(destination.index)].id,
    })
      .then((res) => {
        let response = res.data;
        if (response.status === "success") {
          try {
            let local_data = localStorage.getItem(
              "global__custom__field__data"
            );
            if (local_data) {
              localStorage.removeItem("global__custom__field__data");
            }
          } catch (error) {}
        } else {
        }
      })
      .catch((error) => {});
  };

  const renderItem = () => {
    const view = [];
    if (Array.isArray(state.fields)) {
      state.fields.forEach((item, index) => {
        view.push(renderDragContext(index, item));
      });
    }
    return view;
  };
  const renderDragContext = (index, item) => {
    return (
      <Draggable
        key={`${index}-${item.id}`}
        draggableId={`${item.id}`}
        index={index}
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style
            )}
          >
            <EachItem
              key={index}
              item={item}
              index={index}
              provided_ref={provided}
            />
          </div>
        )}
      </Draggable>
    );
  };
  const renderEmptyScreen = (text = "Please wait...") => {
    return <div className={Styles.emptyScreen}>{text}</div>;
  };
  const renderDropContext = () => {
    if (state.selectedFolder == null) {
      return renderEmptyScreen("Select a folder");
    }
    if (state.gettingFields) {
      return renderEmptyScreen();
    }
    if (state.fields.length === 0) {
      return renderEmptyScreen("No custom field found for this folder !");
    }
    return (
      <DragDropContext onDragEnd={onDragEnd} className='cf_scroll_bar'>
        <Droppable
          droppableId={`droppable-${state.selectedFolder}`}
          type={"CUSTOM_FIELD_" + state.selectedFolder}
        >
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getGroupListStyle(snapshot.isDraggingOver)}
              className={`cf_scroll_bar`}
            >
              {renderItem()}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  };
  const renderListContent = () => {
    if (state.selectedFolder === "___more_fields___") {
      const view = [];
      if (state.fields.parent_data !== undefined) {
        state.fields.parent_data.forEach((item, index) => {
          if (item.status === 1) {
            view.push(<EachItem key={index} item={item} index={index} />);
          }
        });
      }
      if (state.fields.agency_data !== undefined) {
        state.fields.agency_data.forEach((item, index) => {
          if (item.status === 1) {
            view.push(<EachItem key={index} item={item} index={index} />);
          }
        });
      }
      if (
        state.fields.parent_data === undefined ||
        state.fields.agency_data === undefined
      ) {
        return renderEmptyScreen("Please wait...");
      }
      if (view.length === 0) {
        return renderEmptyScreen("No fields available !");
      }
      return view;
    }
    return renderDropContext();
  };
  return (
    <div className={Styles.rightMain}>
      {/* <div className={`${Styles.listWrp} cf_scroll_bar`}>{renderListContent()}</div> */}
      <div className={`${Styles.listWrp}`} >
        {renderListContent()}
      </div>
      {state.fields.length !== 0 &&
        state.selectedFolder !== "___more_fields___" && (
          <div className={Styles.paginationWrp}>
            <div style={{marginLeft: "20px"}}>
              <CustomMenuPype  
                popup={{
                  id: "customFieldOrganized",
                  wrapperClass: Styles.popDlvnWrapper,
                  placement: "center",
                  optionClass: Styles.popDlvnItem,
                  list: [
                    {label: 20, value: 20},
                    {label: 50, value: 50},
                    {label: 100, value: 100},
                    // {label: 'All', value: "__all__"},
                  ],
                  onClick: item => dispatch(handleStates({ perPage: item.value, page: 0 })),
                  height: "auto",
                  width: "__parent__",
                  listStyle: {
                    justifyContent: "center",
                  },
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "center",
                  },
                }}
                label={{
                  renderCustom: (
                    <div className={Styles.labelWrp}>
                      {state.perPage}
                    </div>
                  ),
                }}
              />
            </div>
            <Pagination state={state} dispatch={dispatch} />
          </div>
        )}
    </div>
  );
};
export default RightSideApp;
export const Reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};
export const getGroupListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "#ebecf0" : "inherit",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  padding: isDraggingOver ? "10px" : "0px",
});
export const getItemStyle = (isDragging, draggableStyle) => {
  return {
    // change background colour if dragging
    background: isDragging ? "lightgreen" : "inherit",
    borderRadius: "7px",
    // styles we need to apply on draggables
    ...draggableStyle,
  };
};
