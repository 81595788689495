import React, {useEffect, useRef, useState} from 'react';
import SimpleReactValidator from "simple-react-validator";
import Box from "@material-ui/core/Box";
import {Button, MenuItem, Select} from "@material-ui/core";
import {ITEM_TYPE_OPTIONS, ITEM_UNIT_OPTIONS} from "../dropDownConstant";
import {addNewProductInvoice, editProductInvoice} from "../../../api/InvoiceApi";
import InvoiceUseStyles from "../invoiceUseStyles";
import Styles from "../ProductServices.module.css";

// array convert //
const itemTypeOptions = Object.values(ITEM_TYPE_OPTIONS);
const unitOptions = Object.values(ITEM_UNIT_OPTIONS);

const validateTax = (value) => {
    // Regular expression to allow up to two digits with up to two decimal places
    const regex = /^\d*(\.\d{0,2})?$/;
    return regex.test(value);
};

const AddOrEditProduct = ({isEdit, productInfo, onClose, onRenderCallback}) => {
    const InvoiceClasses = InvoiceUseStyles();
    const [, setForceUpdate] = useState(false);
    const [loading, setLoading] = useState(false);

    const simpleValidator = useRef(
        new SimpleReactValidator({
            autoForceUpdate: {forceUpdate: setForceUpdate},
            className: "small text-danger mdi mdi-alert pt-1 pl-1",
        })
    );

    const [formData, setFormData] = useState({
        name: "",
        description: "",
        itemType: "",
        unitOfItem: "",
        price: "",
        quantity: 1,
        tax: 0,
    });

    useEffect(() => {
        if (productInfo?.id) {
            setFormData({
                ...formData,
                ...productInfo
            })
        }
    }, []);

    const handleChangeInput = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = () => {
        if (simpleValidator.current.allValid()) {
            try {
                setLoading(true);
                const requestBody = {
                    id: productInfo?.id,
                    name: formData.name.replace(/\s+/g, ' ').trim(),
                    description: formData.description,
                    itemType: formData.itemType,
                    price: formData.price,
                    unitOfItem: formData.unitOfItem,
                    quantity: 1,
                    tax: 0,
                };

                if (isEdit) {
                    requestBody.id = productInfo?.id;
                    editProductInvoice(requestBody)
                        .then((res) => {
                            res = res.data;
                            if (res.success) {
                                onRenderCallback()
                                window.showNotification("SUCCESS", res.message);
                                onClose();
                            } else {
                                window.showNotification("ERROR", res.message);
                            }
                        })
                        .catch((error) => {
                            window.showNotification(
                                "ERROR",
                                "Error occurred while saving data"
                            );
                        })
                        .finally(() => setLoading(false))
                } else {
                    addNewProductInvoice(requestBody)
                        .then((res) => {
                            res = res.data;
                            if (res.success) {
                                onRenderCallback()
                                window.showNotification("SUCCESS", res.message);
                                onClose();
                            } else {
                                window.showNotification("ERROR", res.message);
                            }
                        })
                        .catch((error) => {
                            window.showNotification(
                                "ERROR",
                                "Error occurred while creating product"
                            );
                        })
                        .finally(() => setLoading(false))
                }
            } catch (error) {
                console.error("An error occurred:", error);
            }
        } else {
            simpleValidator.current.showMessages();
            setForceUpdate(true);
        }
    }

    return (
        <div style={{minWidth:500}}>
            <div>
                <p className="mb-0">Item Name *</p>
                <input
                    type="text"
                    placeholder="Enter a name for your product or service here"
                    className={Styles.item_input}
                    name="name"
                    value={formData.name}
                    onChange={(e) => {
                        handleChangeInput(e.target.name, e.target.value);
                    }}
                />
                {simpleValidator.current.message(
                    "Item Name",
                    formData.name,
                    "required|max:120"
                )}
            </div>
            <div>
                <p className="mb-0">Description</p>
                <textarea
                    className={Styles.text_area}
                    rows="10"
                    cols="50"
                    placeholder="Write a short description for your product or service here"
                    name="description"
                    value={formData.description}
                    onChange={(e) => {
                        handleChangeInput(e.target.name, e.target.value);
                    }}
                />
                {simpleValidator.current.message(
                    "Description",
                    formData.description,
                    "max:250"
                )}
            </div>
            <div className={Styles.dropdown}>
                <div style={{flex: 1}}>
                    <p className="mb-0">Type *</p>

                    <Select
                        fullWidth
                        label="Select an option"
                        value={formData.itemType}
                        className={`${InvoiceClasses.formControl} ${InvoiceClasses.select}`}
                        displayEmpty
                        name="itemType"
                        onChange={(e) => {
                            handleChangeInput(e.target.name, e.target.value);
                        }}
                        inputProps={{"aria-label": "Without label"}}
                    >
                        <MenuItem value="" className={InvoiceClasses.MuiDisabled} id="ibMenuDisabledId" disabled>
                            {" "}
                            Select an option
                        </MenuItem>
                        {itemTypeOptions.map((option) => (
                            <MenuItem className={InvoiceClasses.menuItem} id="ibMenuItemId" key={option.value}
                                      value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                    {simpleValidator.current.message(
                        "Type",
                        formData.itemType,
                        "required"
                    )}
                </div>
                <div style={{flex: 1}}>
                    <p className="mb-0">Unit of Measurement (optional)</p>
                    <Select
                        fullWidth
                        label="Select an option"
                        value={formData.unitOfItem}
                        onChange={(e) => {
                            handleChangeInput(e.target.name, e.target.value);
                        }}
                        displayEmpty
                        className={`${InvoiceClasses.formControl} ${InvoiceClasses.select}`}
                        name="unitOfItem"
                        inputProps={{"aria-label": "Without label"}}
                    >
                        <MenuItem value="" className={InvoiceClasses.MuiDisabled} id="ibMenuDisabledId" disabled>
                            {" "}
                            Select an option
                        </MenuItem>
                        {unitOptions.map((option) => (
                            <MenuItem className={InvoiceClasses.menuItem} id="ibMenuItemId" key={option.value}
                                      value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            </div>

            <div className={Styles.selling_price_title}>
                <div style={{width: "100%"}}>
                    <p className="mb-0">
                        Selling Price *
                    </p>
                    <div className={Styles.selling_price_description}>
                        <h3>$</h3>
                        <input
                            type="number"
                            className={Styles.selling_price_input}
                            name="price"
                            min={0}
                            value={formData.price}
                            onChange={(e) => {
                                if(validateTax(e.target.value)) {
                                    handleChangeInput(e.target.name, e.target.value);
                                }
                            }}
                        />
                    </div>
                    {simpleValidator.current.message(
                        "Selling Price",
                        formData.price,
                        "required|numeric|min:0,num"
                    )}
                </div>
            </div>

            <Box sx={{display: "flex", justifyContent: "flex-end"}}>
                <Button
                    variant="contained"
                    onClick={handleSubmit}
                    style={{
                        backgroundColor: "#3C7EF3",
                        color: "white",
                        width: "115px",
                        fontSize: "16px",
                        marginTop: "24px",
                        textAlign: "right"
                    }}
                    disabled={loading}
                >
                    {loading ? "Saving..." : "Save"}
                </Button>
            </Box>
        </div>
    );
};

export default AddOrEditProduct;