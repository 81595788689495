import * as Action from './ActionTypes'
const CustomFieldReducer = (state, action) => {
  switch(action.type){
    case Action.HANDLE_STATES:
      return {...state, ...action.payload}
      
    case Action.HANDLE_STORE_FOLDERS:
      return {
        ...state, 
        folders: action.payload,
        gettigFolders: false
      }

    case Action.HANDLE_SELECT_FOLDER:
      return {
        ...state, 
        selectedFolder: action.payload,
        gettingFields: true
      }

    case Action.HANDLE_STORE_FIELDS:
      return {
        ...state, 
        fields: action.payload,
        gettingFields: false
      }
      
    case Action.HANDLE_PAGINATION_TOTAL:
      return {
        ...state, 
        totalFields: action.payload
      }

    case Action.HANDLE_PAGINATION_PAGE:
      return {
        ...state, 
        page: action.payload
      }

    default:
      return state;
  }
}
export default CustomFieldReducer;