export const Constants = {
    STATUS_PENDING: "pending",
    STATUS_PROCESSING: "processing",
    STATUS_ACCEPTED: "accepted",
    STATUS_REJECTED: "rejected",

    REQUEST_TYPE_ADD_COLLABORATOR: 1,
    REQUEST_TYPE_CHANGE_OWNER: 2,
    REQUEST_TYPE_REMOVE_COLLABORATOR: 3,
    REQUEST_TYPE_REMOVE_AND_ADD_COLLABORATOR: 4,

    BULK_ACTION_ACCEPT: 1,
    BULK_ACTION_REJECT: 2
};

export const ConstantsLabel = {
  [Constants.STATUS_PENDING]: "Pending",
  [Constants.STATUS_PROCESSING]: "Processing",
  [Constants.STATUS_ACCEPTED]: "Accepted",
  [Constants.STATUS_REJECTED]: "Rejected",

  [Constants.REQUEST_TYPE_ADD_COLLABORATOR]: "For Add Collaborator",
  [Constants.REQUEST_TYPE_CHANGE_OWNER]: "Change Owner",
  [Constants.REQUEST_TYPE_REMOVE_COLLABORATOR]: "Remove Collaborator",
  [Constants.REQUEST_TYPE_REMOVE_AND_ADD_COLLABORATOR]: "Remove and Add Collaborator"
};