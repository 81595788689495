import React, {useEffect, useState} from "react";
import {Box} from "@material-ui/core";
import EmailReminders from "./EmailReminders";
import TextMessageReminders from "./TextMessageReminders";
import InvoicePageSubHeader from "../common/header/InvoicePageSubHeader";
import {getReminderInvoice, getSystemDefaultInfo} from "../../../api/InvoiceApi";
import {TYPE} from "../../../constants/reminderConstant";
import Utils from "../../../helpers/Utils";

const InvoiceReminders = () => {
    const [systemDefaultInfo, setSystemDefaultInfo] = useState({});
    const [reminderList, setReminderList] = useState([]);
    const [renderId, setRenderId] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        getReminderInvoice()
            .then((res) => {
                res = res.data;

                if (res.success) {
                    setReminderList(res.data);
                }
            })
            .catch((error) => {
                console.error("API Error:", error);
                setLoading(false);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [renderId]);

    useEffect(() => {
        getSystemDefaultInfo().then((res) => {
            const response = res.data;
            if (response.success) setSystemDefaultInfo(response.data);
        });
    }, []);

    const onRenderCallback = () => {
        setRenderId(Utils.generateRandomString(5));
    };

    const emailReminderList = reminderList.filter((item) => item.type === TYPE.EMAIL);
    const textReminderList = reminderList.filter((item) => item.type === TYPE.SMS);

    return (
        <Box>
            <Box mb={4}>
                <InvoicePageSubHeader
                    title={"Invoice Reminders"}
                    description={
                        "Save time by creating email and text templates. The messages you create here will be used when you send an invoice a to a client via email or text."
                    }
                />
            </Box>

            <EmailReminders
                loading={loading}
                reminderList={emailReminderList}
                renderId={renderId}
                onRenderCallback={onRenderCallback}
                systemDefaultInfo={systemDefaultInfo}
            />
            <TextMessageReminders
                loading={loading}
                reminderList={textReminderList}
                renderId={renderId}
                onRenderCallback={onRenderCallback}
                systemDefaultInfo={systemDefaultInfo}
            />
        </Box>
    );
};

export default InvoiceReminders;
