import { Fragment, useEffect, useState } from "react"
import styled from 'styled-components';
import Drawer from '@material-ui/core/Drawer';
import { Button, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { deleteUser, transferDataDetails } from "../../api/profileApi";
import Loader from "../globals/Loader";
import Select from 'react-select';
import Utils from "../../helpers/Utils";

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E5E8EF;
`;

const Wrapper = styled.div`
    margin: 10px;
    max-width: 100%;
    width: 500px;
`;

const TransferDataPopup = (props) => {
    const [loading, setLoading] = useState(false);
    const [componentLoading, setComponentLoading] = useState(true);
    const [data, setData] = useState(null);
    const [userList, setUserList] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    
    useEffect(() => {
        loadData();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const loadData = () => {
        transferDataDetails({
            "form_data": {
                user_id: props.data ? props.data.id : null
            }
        })
        .then(response => {
            setData(response.data.data);

            let users = [];
            let object = response.data.data.allUsers;

            for (const property in object) {
                users.push({
                    value: property,
                    label: object[property]
                })
            }

            setUserList(users);
        })
        .finally(() => {
            setComponentLoading(false)
        })
    }   

    const toggle = () => {
        props.hideModal();
    }

    const onSubmitHandle = (e) =>{
        e.preventDefault();
        
        if (!selectedUser) {
            Utils.showNotification('Please select a user to transfer');
            return false;
        }

        setLoading(true);
        
        deleteUser({
            "form_data": {
                remove_user: data.removeUser,
                assign_user_id: (selectedUser.value !== undefined ? selectedUser.value: null)
            }
        }).then((response) => {
            if (response.data.status === 'success') {
                toggle();
            } else if (response.data.status === 'validation-error') {
                for (var key of Object.keys(response.data.message)) {
                    let errors = response.data.message[key];
                    errors.forEach(error => {
                        Utils.showNotification(error, 'error');
                    });
                }
            } else {
                Utils.showNotification(response.data.message, 'error');
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <Fragment>
            <Drawer anchor={'right'} open={true} onClose={toggle}>
                <Wrapper className={"manage-tag-modal create-collaborate-modal"}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Header>
                                <h5>{ props.title}</h5>
                                <div class="modal-close cross-icon d-flex justify-content-center align-items-center" onClick={toggle}>
                                    <CloseIcon/>
                                </div>
                            </Header>
                            <Grid item xs={12} className={"p-4"}>
                                {
                                    componentLoading ? (
                                        <div style={{paddingTop: 50}}>
                                            <Loader/>
                                        </div>
                                    ) : (
                                        <div>
                                            <label>
                                                Transfer contacts and virtual numbers
                                                <p>
                                                    <Select
                                                        // styles={{width: '100%'}}
                                                        options={userList}
                                                        isClearable={false}
                                                        value={selectedUser}
                                                        styles={{
                                                            menu: provided => ({ ...provided, zIndex: 9999999 }),
                                                            valueContainer: provided => ({ 
                                                                ...provided, 
                                                            })
                                                        }}
                                                        placeholder={'Select user'}
                                                        onChange={(_selectedOption) => {setSelectedUser(_selectedOption)}}
                                                    />
                                                </p>
                                            </label>
                                            <div className="mt-3">
                                                <Button variant="contained" color="primary" disabled={loading} onClick={onSubmitHandle}>
                                                    {!loading ? 'Transfer' : 'Please Wait...'}
                                                </Button>
                                            </div>
                                        </div>
                                    )
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Wrapper>
            </Drawer>
        </Fragment>
    )
}

export default TransferDataPopup;