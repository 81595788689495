import React, { useEffect, useState } from "react";
import { editReminderInvoice } from "../../../api/InvoiceApi";
import { STATUS } from "../../../constants/reminderConstant";
import SwitchC from "../../customField/subComponents/Switch";

const ReminderStatusToggle = ({ reminder, status }) => {
  const reminderStatus = status === STATUS.ACTIVE ? true : false;
  const [isEnabled, setIsEnabled] = useState(reminderStatus);

  useEffect(() => {
    setIsEnabled(reminderStatus);
  }, [reminderStatus])

  const handleStatus = () => {
    reminder.status = !isEnabled ? STATUS.ACTIVE : STATUS.INACTIVE;

    setIsEnabled((prevState) => !prevState);

    editReminderInvoice(reminder).then((res) => {
      res = res.data;
      if (res.success) {
        window.showNotification("SUCCESS", res.message);
      } else {
        window.showNotification("ERROR", res.message);
      }
    });
  };

  return (
    <SwitchC
      // status={user.show_appointment_url}
      status={isEnabled}
      brandColor={"#3C7EF3"}
      onChange={handleStatus}
    />
  );
};

export default ReminderStatusToggle;
