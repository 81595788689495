export const cfStates = {
    creatingDOM: false,

    /* folder */
    gettigFolders: true,
    folders: [],
    selectedFolder: null, //___more_fields___

    startFolderDrag: false,
    updatingFolderOrder: false,

    /* fields */
    gettingFields: false,
    fields: [],
    perPage: 20,
    page: 0,
    totalFields: 0,

    deletingField: false,
    addingField: false,
    editingField: false,
    editData: null,

    /* modal */
    showAddModal: false,
    showMoveModal: false,

    /* for folder refresh */
    blockRefresh: false

}