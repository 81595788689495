import moment from "moment";
import { useEffect, useState } from "react";
import { mysCancelDowngradeRequestApi } from "../../api/profileApi";
import { confirmAlert } from 'react-confirm-alert';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const PendingMapRequest=(props)=>{

    const [downgradePackageInfo, setDowngradePackageInfo] =  useState(null)
    useEffect(()=>{

        let packageList = props.packageInformation.packageList
        if(Array.isArray(packageList))
            {
            for(let i=0; i<packageList.length; i++){

                if(packageList[i].package_id === props.packageInformation.packageInfo.downgrade_package_id){

                    setDowngradePackageInfo(packageList[i]);
                    break;
                }
            }
        }
    },[])

    const cancelDownGradeRequest=()=>{

        confirmAlert({
            title: 'Are you sure to cancel downgrade request ?',
            message: '',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        mysCancelDowngradeRequestApi().then((res)=>{
                            let response = res.data;
                            if(response.status === 'success'){
                                let packageInfo = props.packageInformation.packageInfo;
                
                                packageInfo['is_downgrade'] = 0;
                
                                props.updateReducerInformations({packageInfo: packageInfo})
                                window.showNotification('SUCCESS',response.message)
                            }else{
                                window.showNotification('ERROR', response.message)
                            }
                
                        })
                        .catch((e)=>{
                            console.log(e)
                        })
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        return;
                    }
                }
            ]
        })

    }

    return (
        
        <>
            {downgradePackageInfo ?
                <>
                    <div className="mi_pending_header">
                        <div>
                            <p> Pending Request</p>
                            <span>You have a downgrade request pending.</span>
                        </div>
                        <button className="cancel_req" onClick={()=>cancelDownGradeRequest()}>cancel request</button>
                    </div>


                    <p><CheckCircleIcon /> Package Name : &nbsp;<span>{downgradePackageInfo.name}</span></p>
                    <p><CheckCircleIcon /> Subscription Fee : &nbsp;<span>${(parseFloat(downgradePackageInfo.subscription_fee) + parseFloat(downgradePackageInfo.additional_charge)).toFixed(2)}</span></p>
                    <p><CheckCircleIcon /> Charging Date : &nbsp;<span>{moment(props.packageInformation.packageInfo.renew_date).format('MM/DD/YYYY')}</span></p>
                </>
            :
                ''
            }
        </>
    )
}

export default PendingMapRequest;