import React from 'react'
import { connect } from 'react-redux'
import PersonilizFrom from './personalized/PersonilizForm';
import PersonilizTable from './personalized/PersonilizTable';

const mapStateToProps = state => ({
  // reloadCustomFieldFlag: state.profileReducer.reloadCustomFieldFlag,
});

const mapDispatchToProps = dispatch => ({
  // loadCustomFields: (params, callback) => dispatch(loadCustomFields(params, callback))
})

const PersonalizedFieldsApp = props => {
  return(
    <>
      <PersonilizFrom />
      <div className="user_cutom_text">
          <p>Press <span>'enter'</span> to save changes</p>
      </div>
      <PersonilizTable />
    </>
  )

}
const PersonalizedFields = connect(mapStateToProps, mapDispatchToProps)(PersonalizedFieldsApp);
export default PersonalizedFields;