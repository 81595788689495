import React, {useRef, useState} from 'react';
import Select from "react-select";
import {CSVLink} from "react-csv";
import PowerDialerModal from "../addPowerDialerModal/PowerDialerModal";
import SkipTraceModal from '../addSkipTRaceModal/SkipTraceModal';
import NewConfirmAlert from '../activity/new-alert/NewConfirmAlert';
import { getCreditSettings } from '../../api/profileApi';
import Utils from '../../helpers/Utils';

const ADD_CONTACT = 1;
const CREATE_PWD_LIST = 2;
const EXPORT_CONTACT = 3;
const SKIP_TRACE=4;

const MapOrderActions = (props) => {
    const exportButton  = useRef(null);
    const [openPwdModal, setOpenPwdModal] = useState(false);
    const [openSkipModal,setSkipModal]=useState(false);

    let actions = [
        {
            label: 'Add Contact to CRM',
            value: ADD_CONTACT
        },
        {
            label: 'Create a Power Dialing List',
            value: CREATE_PWD_LIST
        },
        {
            label: 'Export Contacts in a CSV File',
            value: EXPORT_CONTACT
        },
    ];  

    if(Utils.getAccountData('skipTrace')) {
        actions.push({
            label: 'Skip Trace',
            value: SKIP_TRACE
        })
    }

    const handleActionChange = (_selectedOption) => {
        _selectedOption = _selectedOption.value;
        switch (_selectedOption){
            case ADD_CONTACT:
                props.makeAddToContactRequest();
                break;
            case CREATE_PWD_LIST:
                setOpenPwdModal(true);
                break;
            case EXPORT_CONTACT:
                if (exportButton.current) {
                    exportButton.current.link.click();
                }
                break;
            case SKIP_TRACE:
                skiptrace_modal();
                break;
            default:
                break;
        }
    };

    const skiptrace_modal = () => {
        getCreditSettings()
        .then(response => {
            if (response.data && response.data.status === 'success' && response.data.data) {
                const apiData = response.data.data;
                const skipTraceValue = apiData.find(item => item.type === 67);
                if (skipTraceValue) {
                    NewConfirmAlert({
                        onSubmit: () => {
                            setSkipModal(true);
                        },
                        title: `SkipTracing each contact will cost:${skipTraceValue.value}$`,
                        description: 'These credits will be deducted from your balance.',
                        cancelText: 'No',
                        submitText: 'Proceed',
                        width: '480px',
                    });
                }else{
                    window.showNotification("Error",response.data.message);
                }
            } else {
                console.log('API request was not successful');
            }
        })
        .catch(error => {
            console.log(error);
        });

        
    };
    

    return (
        <div style={{width: 250}}>
            <Select
                options={actions}
                isSearchable={false}
                isClearable={false}
                isDisabled={props.selectedIds.length === 0}
                onChange={handleActionChange}
                placeholder="Select An Action"
            />
            {
                !props.loading && props.data.length > 0 &&
                <div style={{display: 'none'}}>
                    <CSVLink filename={`${props.orderDetails.title ? props.orderDetails.title : props.orderDetails.order_no}.csv`} ref={exportButton} data={props.data}/>
                </div>
            }

            {
                !props.loading && props.data.length > 0 && openPwdModal &&
                <PowerDialerModal
                    reset={props.reset}
                    setReset={props.setReset}
                    selectedIds={props.selectedIds}
                    open={openPwdModal}
                    setOpen={setOpenPwdModal}
                    onClose={()=>{
                        setOpenPwdModal(false);
                    }}
                />
            }

            {
                !props.loading && props.data.length > 0 && openSkipModal &&
                <SkipTraceModal
                    reset={props.reset}
                    setReset={props.setReset}
                    selectedIds={props.selectedIds}
                    open={openSkipModal}
                    setOpen={setSkipModal}
                    onClose={()=>{
                        setSkipModal(false);
                    }}
                    />
                
            }
        </div>
    );
}

export default MapOrderActions;