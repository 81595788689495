import { all, takeEvery, takeLatest, call, put } from 'redux-saga/effects';
import * as ACTION from '../constants/profileActionTypes'
import {
    fetchLeadGroupForDataTable,
    fetchUser,
    updateUser,
    getTeamUsers,
    updateOrCreateLeadGroup,
    updateUserAvatar,
    updatePassword,
    updateCompanyData,
    updateFavicon
} from "../api/profileApi";
import Utils from "./../helpers/Utils";

// const { REACT_APP_ACCESS_TOKEN, REACT_APP_ACCESS_TOKEN_VALIDITY } = process.env;

function* getUserWatcher() {
    yield takeLatest(ACTION.GET_USER, attemptGetUser)
    yield takeLatest(ACTION.FETCH_LEAD_GROUP_LIST, fetchGroupForDataTable)
    yield takeLatest(ACTION.FETCH_USERS_LIST, fetchTeamUsers)
    yield takeLatest(ACTION.CREATE_OR_UPDATE_LEAD_GROUP, attemptCreateOrUpdateLeadGroup)
}

function* attemptGetUser(action) {
    try {
        const response = yield call(fetchUser, action.payload);
        let responseData = response.data;
        if (responseData) {
            yield put({type: ACTION.SET_USER, payload: responseData});
        } else {
            console.log('not ok');
        }

    } catch (error) {
        console.log(error);
    }

}

function* updateUserWatcher() {
    yield takeEvery(ACTION.UPDATE_USER, attemptUpdateUser)
    yield takeEvery(ACTION.UPDATE_USER_AVATAR, attemptUpdateUserAvatar)
    yield takeEvery(ACTION.UPDATE_USER_PASSWORD, attemptUpdateUserPassword)
    yield takeEvery(ACTION.UPDATE_COMPANY_INFO, attemptUpdateCompanyInfo)
    yield takeEvery(ACTION.UPDATE_FAVICON, attemptUpdateFavIcon)
}

function* attemptUpdateUser(action) {
    try {
        const response = yield call(updateUser, action.payload.payload);
        let responseData = response.data;
        if(action.payload.callback !== undefined) {
            action.payload.callback(responseData);
        }
        if (responseData.success) {
            //updating the header detail
            if(window.globalFetchAccountData) {
                window.globalFetchAccountData();
            }
            yield put({type: ACTION.UPDATE_USER_SET, payload: responseData.data});
        } else {
            console.log('not ok');
        }

    } catch (error) {
        console.log(error);
    }

}

function* attemptUpdateUserAvatar(action) {
    try {
        const response = yield call(updateUserAvatar, action.payload);
        let responseData = response.data;
        if (responseData.success) {
            yield put({type: ACTION.UPDATE_USER_SET, payload: responseData.data});

            //updating the header avatar
            if(window.globalFetchAccountData) {
                window.globalFetchAccountData();
            }
        } else {
            console.log('not ok');
        }

    } catch (error) {
        console.log(error);
    }

}

function* attemptUpdateUserPassword(action) {
    try {
        const response = yield call(updatePassword, action.payload.payload);
        let responseData = response.data;
        if(action.payload.callback !== undefined) {
            action.payload.callback(responseData);
        }
    } catch (error) {
        console.log(error);
    }
}

function* attemptUpdateCompanyInfo(action) {
    try {
        const response = yield call(updateCompanyData, action.payload.payload);
        let responseData = response.data;
        if(action.payload.callback !== undefined) {
            action.payload.callback(responseData);
        }
        if (responseData.success) {
            //updating the header detail
            if(window.globalFetchAccountData) {
                window.globalFetchAccountData();
            }
            yield put({type: ACTION.UPDATE_USER_SET, payload: responseData.data});
        } else {
            console.log('not ok');
        }

    } catch (error) {
        console.log(error);
    }

}

function* attemptUpdateFavIcon(action) {
    try {
        const response = yield call(updateFavicon, action.payload.payload);
        let responseData = response.data;
        if(action.payload.callback !== undefined) {
            action.payload.callback(responseData);
        }
        if (responseData.success) {
            //Updating the header fav
            if(window.globalFetchAccountData) {
                window.globalFetchAccountData();
            }
            yield put({type: ACTION.UPDATE_USER_SET, payload: responseData.data});
        } else {
            console.log('not ok');
        }

    } catch (error) {
        console.log(error);
    }

}

function* attemptCreateOrUpdateLeadGroup(action) {
    try {
        const response = yield call(updateOrCreateLeadGroup, action.payload);
        let responseData = response.data;
        if (responseData.success) {
            yield put({type: ACTION.UPDATE_LEAD_GROUP_SET, payload: responseData.data});
        } else {
            console.log('not ok');
        }

    } catch (error) {
        console.log(error);
    }

}

function* fetchGroupForDataTable(action) {
    const { payload } = action;
    try {
        const response = yield call(fetchLeadGroupForDataTable, action.payload);
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* fetchTeamUsers(action) {
    const { payload } = action;
    try {
        const response = yield call(getTeamUsers, action.payload);
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

export default function* profileMiddleware() {
    yield all([
        getUserWatcher(),
        updateUserWatcher()
    ])
}