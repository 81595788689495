import React, { useMemo } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import useResponsiveFontSize from "../../hooks/useResponsiveFontSize";
import { Fragment } from "react";

const useOptions = () => {
    const fontSize = useResponsiveFontSize();
    const options = useMemo(
        () => ({
            style: {
                base: {
                    fontSize,
                    color: "#424770",
                    letterSpacing: "0.025em",
                    fontFamily: "Source Code Pro, monospace",
                    "::placeholder": {
                        color: "#aab7c4"
                    }
                },
                invalid: {
                    color: "#9e2146"
                },
            },
            hidePostalCode: true
        }),
        [fontSize]
    );

    return options;
};

const PaymentForm = (props) => {

    const stripe = useStripe();
    const elements = useElements();
    const options = useOptions();

    const handleSubmit = async () => {
        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        const token = await stripe.createToken(elements.getElement(CardElement));


        if (typeof token.error === 'undefined' && token.token) {
            props.callback(token.token.id);
        } else {
            props.callback(null);
        }
    };

    return (
        <Fragment>
            <label>
                <CardElement
                    options={options}
                    onReady={() => {
                        // console.log("CardElement [ready]");
                    }}
                    onChange={event => {
                        if (!event.error) {
                            handleSubmit();
                        } else {
                            props.callback(null);
                        }
                    }}
                    onBlur={() => {
                        // console.log("CardElement [blur]");
                    }}
                    onFocus={() => {
                        // console.log("CardElement [focus]");
                    }}
                />
            </label>
        </Fragment>
    );
};

export default PaymentForm;