import React, { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { styled } from '@material-ui/core/styles';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  borderRadius: 0,
  border: `1px solid rgba(148, 157, 178, 0.24)`,
  "&.Mui-expanded": { margin: 0 },
  "&:before": { content: "none" },
  "& .MuiAccordionSummary-root": {
    minHeight: "35px",
    "&.Mui-expanded": { minHeight: "35px", margin: 0 },
  },
  "& .MuiAccordionSummary-content": {
    margin: "5px 0",
    "&.Mui-expanded": {
      margin: "5px 0",
    },
  },
}));



export default function TriggerPersonalizedAccordion({ children, expanded, title }) {
  const [isExpanded, setIsExpanded] = useState(expanded);

  return (
    <StyledAccordion elevation={0} expanded={isExpanded} onChange={() => setIsExpanded(!isExpanded)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant='body2medium' mr={2.5}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ padding: 0 }}>{children}</AccordionDetails>
    </StyledAccordion>
  );
}
