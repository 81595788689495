import React from "react";
import {Box, Button, Divider} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ReminderList from "./ReminderList";
import AddOrEditReminder from "./AddOrEditReminder";
import {TYPE} from "../../../constants/reminderConstant";
import BasicModalWithHeader from "../common/modal/BasicModalWithHeader";
import GlobalEmptyPage from "../common/emptyPage/GlobalEmptyPage";
import TableLoaderSkeleton from "../common/TableLoaderSkeleton";
import {EmptyInboxIcon} from "../common/icons/invoicePageIcons";
import useBoolean from "../../../hooks/useBoolean";

const EmailReminders = ({reminderList = [], renderId, onRenderCallback, loading = false, systemDefaultInfo}) => {
  const {
    value: openModal,
    setFalse: onCloseModal,
    setTrue: onOpenModal,
  } = useBoolean(false);

  let content = null;
  if (loading && !renderId) {
    content = <TableLoaderSkeleton numberOfRows={5} columns={4} />;
  } else if (reminderList?.length === 0) {
    content = (
      <GlobalEmptyPage icon={<EmptyInboxIcon />} title={"No data available"} />
    );
  } else if (reminderList?.length > 0)
    content = (
      <ReminderList onRenderCallback={onRenderCallback} reminderList={reminderList} systemDefaultInfo={systemDefaultInfo}/>
    );

  return (
    <>
      <Box sx={{ marginBottom: 20 }}>
        <Box
          justifyContent={"space-between"}
          alignItems={"center"}
          p={1.5}
          sx={{ display: "flex", color: "#fff", bgcolor: "#3C7EF3" }}
        >
          <h6 style={{ margin: 0 }}>Email Reminders Based on Due Date</h6>
          <Button
            variant="outlined"
            color="inherit"
            size="small"
            onClick={onOpenModal}
            startIcon={<AddIcon />}
          >
            Add Reminder
          </Button>
        </Box>
        <Divider light />

        {content}
      </Box>

      <BasicModalWithHeader
        title={"Invoice Reminders"}
        open={openModal}
        onClose={onCloseModal}
      >
        <AddOrEditReminder
          type={TYPE.EMAIL}
          onClose={onCloseModal}
          onRenderCallback={onRenderCallback}
          systemDefaultInfo={systemDefaultInfo}
        />
      </BasicModalWithHeader>
    </>
  );
};

export default EmailReminders;
