import { RadioGroup, withStyles } from "@material-ui/core";

const CustomRadioGroup = withStyles((theme) => ({
  root: {
  '& [type="radio"]:not(:checked)+span, & [type="radio"]:checked+span': {
    paddingLeft: 0,
    "&::before, &::after": {
      content: `none`,
    },
  },
}
}))(RadioGroup);

export default CustomRadioGroup;