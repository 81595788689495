import { useEffect, useState, useRef } from "react";
import Popover from '@material-ui/core/Popover';
import { updateCustomFieldDataApi } from "../../api/CustomFieldApi";

const moveIcon = (<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" fill="#546376"><path d="M8 20Q5.075 20 3.038 17.962Q1 15.925 1 13Q1 10.3 2.775 8.3Q4.55 6.3 7.175 6L5.6 4.4L7 3L11 7L7 11L5.6 9.6L7.075 8.1Q5.325 8.45 4.162 9.8Q3 11.15 3 13Q3 15.075 4.463 16.538Q5.925 18 8 18H11V20ZM13 20V13H22V20ZM15 18H20V15H15ZM13 11V4H22V11Z"/></svg>)

const CustomFieldMove = ({
    showMenu, hanldeMenu, list, id, callback, previous_group_name
}) => {
    const [anchorActionMenu, setAnchorActionMenu] = useState(null);
    const [open, setOpen] = useState(false);
    const dropDownLabel = useRef(null);
    const [newGroup, setNewGroup] = useState('')
    const [select, setSelect] = useState(null)
    const [submitting, setSubmitting] = useState(false)

    useEffect(() => {
        if(showMenu){
            dropDownLabel.current.click()
        }else{
            setAnchorActionMenu(null);
            setOpen(false)
        }
    }, [showMenu])

    const handleMenuLabel = (e) => {
        setAnchorActionMenu(e.currentTarget);
        setOpen(true)
    }

    const handleClose = (e) => {
        setAnchorActionMenu(null);
        setOpen(false)
        hanldeMenu(false)
    }

    const renderListItem= () => {
        let view = [];
        list.forEach((item, index) => {
            if(item !== previous_group_name){
                view.push(
                    <div 
                        className="drop__down__list__each__item" 
                        key={index} 
                        onClick={() => {
                            setSelect(item)
                            
                        }}
                    >
                        <span className={`${select === item ? 'selected__item' : ''}`} >{item}</span>
                    </div>
                )
            }
        })
        return view;
    }

    const handleSubmit = () => {
        if(submitting){return}

        setSubmitting(true)
        updateCustomFieldDataApi({
            'previous_group_name': previous_group_name,
            'id': id,
            'select': select, 
            'newGroup': newGroup.trim(),
            'field_type': 'move_field'
        }).then(res => {
            let response = res.data;
            if(response.status === 'success'){
                callback()
                handleClose()
            }
            setSubmitting(false)
        }).catch(error => {
            setSubmitting(false)
        })
        console.log({
            previous_group_name: previous_group_name,
            id: id,
            select: select, 
            newGroup: newGroup
        })
    }

    return (
        <div className={`custom_menu_option_container`} onClick={(e) => e.stopPropagation()}>
            <span className={`label_class_wrapper`} onClick={(e) => handleMenuLabel(e)} ref={dropDownLabel}>
                {moveIcon}
            </span>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorActionMenu}
                classes={{className:"ffdghhj"}}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{
                    style: {
                        boxShadow: "0px 2px 3px rgba(0,0,0,0.5)",
                        width: "250px",
                        paddingBottom:10,
                    }
                }}
            >
                <div className="drag__and__drop__move__wrapper awesome__scroll_bar">
                    <span className="drag__and__drop__move__title">Existing group name(s)</span>
                   <div className="drag__and__drop__move__container">
                    {renderListItem()}
                   </div>
                   <span className="drag__and__drop__move__input">
                       <input type={'text'} onChange={(e) => setNewGroup(e.target.value)} value={newGroup} />
                   </span>
                   <div style={{"textAlign":"right"}}>
                    <span className="drag__and__drop__move__btn" onClick={handleSubmit}>
                        Move
                    </span>
                   </div>
                </div>
            </Popover>
        </div>
    )
}
export default CustomFieldMove;