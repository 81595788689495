import React from 'react';
import {DropzoneDialog} from 'material-ui-dropzone';
const DEFAULT_FILE_LIMIT = 1;
const ImageUploadDialogInput = (props) => {

    return (
    <DropzoneDialog
        acceptedFiles={['image/*']}
        cancelButtonText={"cancel"}
        submitButtonText={"submit"}
        filesLimit={props.filesLimit !== undefined ? props.filesLimit : DEFAULT_FILE_LIMIT}
        maxFileSize={props.maxFileSize !== undefined ? props.maxFileSize : 5000000}
        open={props.open_image_dialog}
        onClose={() => props.setOpenImageDialog(false)}
        onSave={(files) => {
            props.callBack(files)
            props.setOpenImageDialog(false)
        }}
        showPreviews={true}
        showFileNamesInPreview={true}
    />
    );
}
export default ImageUploadDialogInput;