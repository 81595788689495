import React, {useRef, useState} from 'react';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import CreateIcon from '@material-ui/icons/Create';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import BootstrapTooltip from '../globals/BootstrapTooltip';
import { incomingCallTimeoutUpdate } from '../../api/profileApi';

const IncomingCallTimeout = ({ item }) => {
    
    const inputField = useRef(null);
    const [validation,setValidation] = useState(false);
    const [validationText,setValidationText] = useState('');
    const [edit,setEdit] = useState(false);
    const [saving,setSaving] = useState(false);
    const [fieldValue,setFieldValue] = useState(item.incoming_call_timeout);
    const [mouseOver,setMouseOver] = useState(false);
    const [defaultValue,setDefaultValue] = useState(item.incoming_call_timeout);


    const handleCrossPress = (forceClose = true) => {
        setEdit(false);
        setValidation(false);
        setValidationText('');
        if(forceClose) {
            setFieldValue(defaultValue);
        }
    }

    const handleEnterPress = (event) => {
        var code = (event.keyCode ? event.keyCode : event.which);
        if(code === 13) {
            handleSubmit();
        }
    }

    const handleCheckPress = () => {
        handleSubmit();
    }

    const handleSubmit = () => {
        let formData = new FormData();
        formData.append('id', item.id);
        formData.append('incoming_call_timeout',fieldValue);

        setSaving(true);
        incomingCallTimeoutUpdate(formData).then(res => {
            setSaving(false);
            if(res.data.status === 'validation-error') {
                window.showNotification('ERROR',res.data.message);
            } else if(res.data.status === 'error') {
                window.showNotification('ERROR',res.data.message);

            } else if(res.data.status === 'success'){
                setDefaultValue(fieldValue);
                window.showNotification('SUCCESS','Incoming Call Timeout changed');
                handleCrossPress(false);
            }
        });
    }

    const focusTextField = (toggle = false) => {
        setTimeout(() => {
            inputField.current.focus();
            
        }, 200);
    }

    return (
        <>
            <div className="d-flex align-items-center justify-content-between" 
            >
                {validation &&
                    <small className="text-danger">{validationText}</small>
                }
            </div>
            <div className="text-field-item-vn-page call__whisper_message" onMouseEnter={()=>setMouseOver(true)} onMouseLeave={()=> setMouseOver(false)}>
                
                {edit ?
                    
                    <div class="w-80"># of seconds before sending to voicemail : 
                    <input ref={inputField} className="text-field-vn-incoming-timeout text-gray" 
                    value={fieldValue ? fieldValue : ""} onKeyPress={handleEnterPress} onChange={e => setFieldValue(e.target.value)}
                    type="number" defaultValue={fieldValue ? fieldValue : ""} placeholder={'Enter Incoming Call Timeout'}/></div>
                    :
                    <div># of seconds before sending to voicemail : <input className="text-field-vn-incoming-timeout text-gray" value={fieldValue ? fieldValue : ""} type="text" disabled={true} defaultValue={fieldValue ? fieldValue : ""}/>  </div>
                    
                }               
                {(!edit && !saving && mouseOver) &&
                    
                        <BootstrapTooltip arrow title={`Update Incoming Call Timeout`}>
                            <span className="small-round-icon-vn-page" onClick={() => {setEdit(true);setMouseOver(false);focusTextField()}}>{<CreateIcon fontSize="small" />}</span>
                        </BootstrapTooltip>
                }

                { saving &&
                    <BootstrapTooltip arrow title={`Updating Incoming Call Timeout`}>
                        <span className="small-round-icon-vn-page" >{<MoreHorizIcon fontSize="small" />}</span>
                    </BootstrapTooltip>
                }

                {(edit && !saving) &&
                    <div>
                        <BootstrapTooltip arrow title="confirm">
                            <span className="small-round-icon-vn-page checkIcon" onClick={handleCheckPress}>{<DoneIcon fontSize="small" />}</span>
                        </BootstrapTooltip>
                        <BootstrapTooltip arrow title="cancel">
                            <span className="small-round-icon-vn-page cancelIconNew" onClick={handleCrossPress}>{<ClearIcon fontSize="small" />}</span>
                        </BootstrapTooltip>
                    </div>
                }
            </div>
        </>
    );
}

export default IncomingCallTimeout;