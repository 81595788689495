import {useContext, useEffect, useState} from "react";
import {PROFILE_TAB} from "../../constants/CoreConstants";
import ImageUploadDialogInput from "../globals/ImageUploadDialogInput";
import ImageShow from "../globals/ImageShow";
import {connect} from "react-redux";
import Api from "../../constants/Api";
import { Link } from 'react-router-dom';
import { deleteCookie } from "../../helpers/Cookie";
import React from "react";
import {updateUserAvatar} from "../../actions/profileAction";
import { HistoryProvider } from "../../App";

const LeftSideIndex = (props) => {
    const history = useContext(HistoryProvider);
    const [full_name,setFullName] = useState('');
    const [profile_image,setProfileImage] = useState('');
    const [open_image_dialog, setOpenImageDialog] = useState(false);

    useEffect(() => {
        loadData();
    }, [props.user]) // eslint-disable-line react-hooks/exhaustive-deps


    const loadData = () => {
        if(props.user) {
            setFullName(props.user.full_name);
            setProfileImage(props.user.profile_image);
        }
    }

    const handleSubmitProfileImage = (files) => {
        if(!files) {
            alert('Profile image is not set');
            return false;
        }
        //make request
        let formData = new FormData();
        //formData.append("api-key", '9S694UIG4WX4Y4A5V51S');
        formData.append("qqfile", files[0]);
        formData.append("columns[]", 'id');
        formData.append("columns[]", 'full_name');
        formData.append("columns[]", 'profile_image');
        props.updateUserAvatar({"form_data":formData, "api_route":Api.updateAvatar});
    };

    const logout = e => {
        e.preventDefault();

        deleteCookie(process.env.REACT_APP_ACCESS_TOKEN);
        deleteCookie(process.env.REACT_APP_ACCOUNT_DATA);
        deleteCookie(process.env.REACT_APP_PERMISSION_DATA);
        history.push('/user/login');
        history.push('/user/login');
        window.location.reload();
    }

    return (
        <div className="col xl4 l5 m6">
            <div className="profile_sidebar white profile_sidebar__v2">
                <div className="profile_sidebar_top d-flex justify-content-center align-items-center">
                    <div className="profile_sidebar_top_img">
                        <ImageShow
                            imageUrl = {profile_image ? profile_image : "https://i.ibb.co/SB7TxkV/image.png"}
                            extraClassName = {'circle'}
                            isServerImage ={false}
                        />

                        <span className="add_img_camera_icon circle d-flex justify-content-center align-items-center" onClick={()=>setOpenImageDialog(true)}>
                            <i className="material-icons white-text">
                                photo_camera
                            </i>
                        </span>
                    </div>
                    <h5 className="white-text">{full_name}</h5>
                    <ImageUploadDialogInput
                        callBack     = {handleSubmitProfileImage}
                        filesLimit   = {1}
                        open_image_dialog = {open_image_dialog}
                        setOpenImageDialog = {setOpenImageDialog}
                    />
                </div>
                <div className="profile_sidebar_items">
                    <ul className="tabs d-flex">
                        <li className="tab">
                            <a className={"d-flex align-items-center"+(parseInt(props.active_tab) === PROFILE_TAB.PROFILE_INFO ? " active accent--bg--text--color " : "")} onClick={(e) => {e.preventDefault();props.setActiveTab(PROFILE_TAB.PROFILE_INFO)}} href="#!">
                                <i className="material-icons mr-2">person</i>Personal Info
                            </a>
                        </li>
                        <li className="tab">
                            <a className={"d-flex align-items-center"+(parseInt(props.active_tab) === PROFILE_TAB.CHANGE_PASSWORD ? " active accent--bg--text--color " : "")} onClick={(e) => {e.preventDefault(); props.setActiveTab(PROFILE_TAB.CHANGE_PASSWORD)}} href="#!">
                                <i className="material-icons mr-2">visibility_off</i>Change Password
                            </a>
                        </li>
                        <li className="tab">
                            <a className={"d-flex align-items-center"+(parseInt(props.active_tab) === PROFILE_TAB.COMPANY_INFO ? " active accent--bg--text--color " : "")} onClick={(e) => {e.preventDefault(); props.setActiveTab(PROFILE_TAB.COMPANY_INFO)}} href="#!">
                                <i className="material-icons mr-2">settings</i>Company Info
                            </a>
                        </li>
                        <li className="tab">
                            <a className={"d-flex align-items-center"+(parseInt(props.active_tab) === PROFILE_TAB.FAVICON ? " active accent--bg--text--color " : "")} onClick={(e) => {e.preventDefault(); props.setActiveTab(PROFILE_TAB.FAVICON)}} href="#!">
                                <i className="material-icons mr-2">image</i>Favicon
                            </a>
                        </li>
                        <li className="tab">
                            <Link className={"d-flex align-items-center"} to={{pathname : 'profile/activity-logs',state : {from:'accountPage'}}}>
                                <i className="material-icons mr-2">receipt_long</i>Activity Logs
                            </Link>
                        </li>
                        <li className="tab">
                            <a onClick={(e) => logout(e)} className="d-flex align-items-center" href="#!">
                                <svg className="mr-2 ml-1" width="15" height="22" viewBox="0 0 22 22" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M21.9314 9.73382C21.8847 9.62198 21.8178 9.52024 21.7326 9.435L18.9836 6.68608C18.6253 6.3286 18.0459 6.3286 17.6876 6.68608C17.3292 7.04446 17.3292 7.62467 17.6876 7.98215L18.8727 9.16732H13.7525C13.2457 9.16732 12.8359 9.57794 12.8359 10.0839C12.8359 10.5899 13.2457 11.0005 13.7525 11.0005H18.8727L17.6875 12.1857C17.3291 12.5441 17.3291 13.1243 17.6875 13.4817C17.8663 13.6614 18.1009 13.7503 18.3356 13.7503C18.5703 13.7503 18.8049 13.6614 18.9836 13.4817L21.7326 10.7328C21.8178 10.6485 21.8847 10.5467 21.9314 10.434C22.024 10.2104 22.024 9.95746 21.9314 9.73382Z"/>
                                    <path
                                        d="M15.5823 12.8325C15.0754 12.8325 14.6657 13.2431 14.6657 13.7491V18.3322H10.9993V3.66641C10.9993 3.26219 10.7335 2.90471 10.3457 2.78832L7.16234 1.83323H14.6657V6.41628C14.6657 6.92225 15.0754 7.33287 15.5823 7.33287C16.0892 7.33287 16.4989 6.92225 16.4989 6.41628V0.916636C16.4989 0.410626 16.0892 0 15.5823 0H0.916593C0.883595 0 0.854249 0.0137491 0.822197 0.0174012C0.779102 0.0219986 0.739702 0.0293028 0.698455 0.0393998C0.602211 0.0641482 0.515119 0.101743 0.43357 0.153045C0.413419 0.165892 0.38867 0.166794 0.369421 0.181445C0.362031 0.186988 0.359281 0.197085 0.351934 0.202585C0.252038 0.281384 0.168641 0.377628 0.108145 0.492218C0.0952985 0.516967 0.0925487 0.543562 0.0824947 0.569213C0.0531489 0.638861 0.0210963 0.706704 0.010097 0.783699C0.00549964 0.811197 0.0137491 0.836848 0.0128468 0.863444C0.0119445 0.881791 0 0.89829 0 0.916593V19.2488C0 19.686 0.308882 20.0618 0.736952 20.147L9.90301 21.9803C9.9626 21.9931 10.0231 21.9986 10.0827 21.9986C10.2925 21.9986 10.4988 21.9262 10.6638 21.7905C10.8755 21.6164 10.9992 21.357 10.9992 21.082V20.1654H15.5823C16.0892 20.1654 16.4989 19.7548 16.4989 19.2488V13.7491C16.4989 13.2431 16.0892 12.8325 15.5823 12.8325Z"/>
                                </svg>
                                Log Out</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
const MapStateToProps = (state) => {
    return {
        user: state.profileReducer.user,
        response: state.profileReducer.response
    };
}
const MapDispatchToProps = (dispatch) => {
    return {
        updateUserAvatar: (params) => dispatch(updateUserAvatar(params))

    }
}
const LeftSide = connect(MapStateToProps,MapDispatchToProps)(LeftSideIndex);
export default LeftSide;
