import React from 'react';
import {Button} from "@material-ui/core";
import AddOrEditProduct from "./AddOrEditProduct";
import InvoicePageSubHeader from "../common/header/InvoicePageSubHeader";
import BasicModalWithHeader from "../common/modal/BasicModalWithHeader";
import useBoolean from "../../../hooks/useBoolean";
import Styles from "../ProductServices.module.css";

const ProductAndServiceHeader = ({onRenderCallback}) => {
    const {value: openModal, setFalse: onCloseModal, setTrue: onOpenModal} = useBoolean(false);

    return (
        <div className={Styles.product_and_service_header}>
            <InvoicePageSubHeader title={"Add Products/Services"} description={"This is where you’ll add the different products and services that your business offers."} />
            <div>
                <Button
                    variant="contained"
                    onClick={onOpenModal}
                    style={{ backgroundColor: "#3C7EF3", color: "white",fontSize:"16px" }}
                >
                    Add New
                </Button>

                <BasicModalWithHeader title={"Add New Product or Service"} open={openModal} onClose={onCloseModal}>
                    <AddOrEditProduct onClose={onCloseModal} onRenderCallback={onRenderCallback} />
                </BasicModalWithHeader>
            </div>
        </div>
    );
};

export default ProductAndServiceHeader;