import { all, takeLatest, call } from 'redux-saga/effects';
import * as ACTION from '../constants/leadSourceActionTypes'
import * as API from '../api/leadSourceApi';
import Utils from "./../helpers/Utils";

function* leadSourcedWatcher() {
    yield takeLatest(ACTION.ADD_LEAD_SOURCE, addLeadSourceMiddleware)
    yield takeLatest(ACTION.EDIT_LEAD_SOURCE, editLeadSourceMiddleware)
    yield takeLatest(ACTION.DELETE_LEAD_SOURCE, deleteLeadSourceMiddleware)
    yield takeLatest(ACTION.GET_LEAD_SOURCE_LIST_BY_PAGINATE, getLeadSourceByPaginationMiddleware)
    yield takeLatest(ACTION.GET_LEAD_SOURCE_LIST_ALL, getAllLeadSourceMiddleware)
}

function* addLeadSourceMiddleware(action) {
    try {
        const { payload } = action;
        const response = yield call(API.addLeadSourceApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* editLeadSourceMiddleware(action) {
    try {
        const { payload } = action;
        const response = yield call(API.editLeadSourceApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* deleteLeadSourceMiddleware(action) {
    try {
        const { payload } = action;
        const response = yield call(API.deleteLeadSourceApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* getLeadSourceByPaginationMiddleware(action) {
    try {
        const { payload } = action;
        const response = yield call(API.getLeadSourceByPaginationApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* getAllLeadSourceMiddleware(action) {
    try {
        const { payload } = action;
        const response = yield call(API.getAllLeadSourceApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

export default function* leadSourceMiddleware() {
    yield all([
        leadSourcedWatcher()
    ])
}