import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { deleteLeadSource, getAllLeadSource, _handleDeleteLeadSourceModal, _handleLeadSourcePagination, _handleLeadSourceReload } from '../../../actions/leadSourceAction';
import { icons } from '../icon';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import DeleteIcon from '@material-ui/icons/Delete';
import SyncIcon from '@material-ui/icons/Sync';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { makeStyles } from '@material-ui/core/styles';
import '../../customField/customdata-fields.css'
import NewConfirmAlert from '../../activity/new-alert/NewConfirmAlert';
import { deleteLeadSourceWithoutTransferApi } from '../../../api/leadSourceApi';

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(2),
      minWidth: 120,
      height:50
    },
    selectEmpty: {
      marginTop: theme.spacing(0),
    },
}));

const mapStateToProps = state => ({
    leadSourceReducer : state.leadSourceReducer
});

const mapDispatchToProps = dispatch => ({
    _handleDeleteLeadSourceModal: (params) => dispatch(_handleDeleteLeadSourceModal(params)),
    _handleLeadSourceReload: (params) => dispatch(_handleLeadSourceReload(params)),
    _handleLeadSourcePagination: (params) => dispatch(_handleLeadSourcePagination(params)),
    getAllLeadSource: (params, callback) => dispatch(getAllLeadSource(params, callback)),
    deleteLeadSource: (params, callback) => dispatch(deleteLeadSource(params, callback)),
})

const DeleteLeadSourceModalApp = props => {
    const classes = useStyles();
    const {show_delete_modal, delete_data, isTransfer} = props.leadSourceReducer
    const [submit, setSubmit] = useState(false)
    const [sources, setSources] = useState([])
    const [select, setSelect] = useState('')

    const handleDeleteLeadSourceModal = () => {
        props._handleDeleteLeadSourceModal({deleteFlag : false, deleteData: null, isTransfer: true})
    }

    useEffect(() => {
        props.getAllLeadSource({sourceTypeId : delete_data.id}, (res) => {
            setSources(res.data)
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(isTransfer === false && delete_data !== null){
            NewConfirmAlert({
                onSubmit : () => {
                    props._handleDeleteLeadSourceModal({deleteFlag : true, deleteData: delete_data, isTransfer:true})
                },
                onCloseClick : () => {
                    handleDeleteData();
                },
                title: 'Transfer Lead Source?',
                description: 'Do you want to transfer the contacts to other contact source?',
                cancelText: 'No',
                submitText: 'Yes',
                width: '480px'
            });     
        }
        
    }, [delete_data, isTransfer])

    const handleDeleteData = () => {
        
        deleteLeadSourceWithoutTransferApi({ id: delete_data.id })
        .then((res)=>{
            if(res.data.success){
                window.showNotification("SUCCESS",res.data.message);
                setTimeout(() => {
                    props._handleDeleteLeadSourceModal({deleteFlag : false, deleteData: null, isTransfer: true})
                    props._handleLeadSourceReload(true)
                }, 500);
            }else {
                window.showNotification("ERROR",res.data.message);
                props._handleDeleteLeadSourceModal({deleteFlag : false, deleteData: null, isTransfer: true})
            }
        }).catch((err)=>{
            window.showNotification("ERROR","Something went wrong. Please try again later!");
        });
    }


    const handleSubmit = (e) => {
        e.preventDefault()
        if(submit)return

        if(select === ''){
          window.showNotification("warning", "Select a lead source")
            return
        }
        

        setSubmit(true)
        props.deleteLeadSource({
            remove_contact_source_id: delete_data.id,
            move_to_contact_source_id: select
        }, (res) => {
            setSubmit(false)
            if(res.status === "success"){
              window.showNotification("success", res['html'])
                setTimeout(() => {
                    props._handleDeleteLeadSourceModal({deleteFlag : false, deleteData: null, isTransfer: true})
                    props._handleLeadSourceReload(true)
                }, 500);
            }
            else{
              window.showNotification("error", res['html'])
            }
        })        
    }

    return(
        <div className="modal_details modal alt" style={{display: show_delete_modal && isTransfer ? 'block' : 'none'}}>
            <div className="top_content d-flex justify-content-between align-items-center p-4">
                <div className="sub_title">
                    <h2>Transfer lead source</h2>
                </div>
                <div className="cross_button d-flex justify-content-center align-items-center modal-close" onClick={handleDeleteLeadSourceModal}>
                    {icons.cross}
                </div>
            </div>
            <div className="leadsrc_input_fileds">
                <div className="input-field col s12">

                <FormControl variant="outlined" margin="none" focused={false} className="custom__form__select full_width">
                    <FormLabel>Select Lead source</FormLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={select}
                        onChange={e => setSelect(e.target.value)}
                        IconComponent = {KeyboardArrowDownIcon}
                        displayEmpty
                        className={classes.selectEmpty}
                        inputProps={{ 'aria-label': 'Without label' }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        MenuProps={{
                            anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                            },
                            transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                            },
                            getContentAnchorEl: null
                        }}
                    >
                        <MenuItem value="" disabled>Select lead source</MenuItem>
                        {sources.map((item, index) => {
                            return(
                            <MenuItem className='dropdown_item__style_new' value={item.id} key={index}>{item.source_title}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
                </div>
            </div>
            <div className="modal__buttons d-flex justify-content-center align-content-center">
                <button type="submit" onClick={handleSubmit} className="add__group__btn">
                    {submit ? <MoreHorizIcon /> : <SyncIcon /> }
                    <span className="mx-2">Transfer</span>
                </button>
                <button className="modal-close modal__close__btn" onClick={handleDeleteLeadSourceModal}>	
                    {icons.crossWhiteBg}
                    <span className="mx-2">Close</span>
                </button>
            </div>
        </div>
    )
}
const DeleteLeadSourceModal = connect(mapStateToProps, mapDispatchToProps)(DeleteLeadSourceModalApp);
export default DeleteLeadSourceModal;