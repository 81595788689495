import React from "react";

import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from "@material-ui/core";
import InvoiceUseStyles from "../invoiceUseStyles";
import ReminderActions from "./ReminderActions";
import ReminderStatusToggle from "./ReminderStatusToggle";
import { REMIND_TYPE } from "../../../constants/reminderConstant";

const ReminderList = ({ reminderList, onRenderCallback, systemDefaultInfo }) => {
  const classes = InvoiceUseStyles();

  const dueDate = (value) => (value === REMIND_TYPE.BEFORE_DUE_DATE ? "Before" : "After");
  return (
    <TableContainer component={Paper} elevation={0}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableBody>
          {reminderList.map((item) => (
            <TableRow key={item.id}>
              <TableCell scope="row">{item.name}</TableCell>
              <TableCell align="left">
                Remind me {item.numberOfDays} days(s) {dueDate(item.remindType)}{" "}
                due date{" "}
              </TableCell>
              <TableCell align="right">
                <ReminderStatusToggle reminder={item} status={item.status} />
              </TableCell>
              <TableCell align="center">
                <ReminderActions reminderType={item.type} reminderInfo={item} onRenderCallback={onRenderCallback} systemDefaultInfo={systemDefaultInfo}/>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReminderList;
