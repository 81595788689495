import {DeleteIcons, EditIcons, GroupIcons} from '../../helpers/IconList';
import {handleSelectFolder, handleStoreFields, handleStoreFolders} from '../../reducer/Actions';
import { useCFContext } from '../../reducer/CustomProvider';
import Styles from './style.module.css'
import CustomModalPype from "../../helpers/modal/CustomModalPype";
import {useState} from "react";
import CustomConfirmAlert from "../../helpers/confirm-alert/CustomConfirmAlert";
import {deleteCustomFieldsV2Api} from "../../../../../api/CustomFieldApi";
import EditGroup from '../../edit-group/EditGroup';
import BootstrapTooltip from "../../../../common/BootstrapTooltip";

const GroupListItem = ({item, index, provided_ref}) => {
  const {state, dispatch} = useCFContext();
  const [openEditModal, setOpenEditModal] = useState(false);

  const handleOnClick = (e) => {
    e.preventDefault();
    if(openEditModal){
      return
    }
    if((state.selectedFolder && state.selectedFolder.toLowerCase()) !== item.group_name.toLowerCase()){
      dispatch(handleSelectFolder(item.group_name))
    }
  }
  const handleEditClick = (e) => {
    e.preventDefault(); 
    if(e.stopPropagation){
      e.stopPropagation(); 
    }
    setOpenEditModal(true)
  }
  const handleDelete = (e) => {
    e.preventDefault(); 
    if(e.stopPropagation){
      e.stopPropagation(); 
    }
    const groupName = item.group_name
    CustomConfirmAlert({
      onSubmit : () => {
        deleteCustomFieldsV2Api({groupName : groupName}).then(res => {
          let response = res.data;
          if (response.status === 'success') {
            let old_data = [...state.folders];
            old_data.splice(index, 1)
            dispatch(handleStoreFolders(old_data));
            if(groupName === state.selectedFolder){
              dispatch(handleSelectFolder(null));
              dispatch(handleStoreFields([]));
            }
            window.showNotification('success', "Folder deleted successfully.");
          }
          else{
            window.showNotification('error', "Can not delete folder. Try again later.");
          }
        })
      },
      title: 'Are you sure to delete ?',
      description: 'Updated & sync with account information.'
    })
  }
  return (
    <div {...provided_ref.dragHandleProps} className={`${Styles.mainWrp} ${(state.selectedFolder && state.selectedFolder.toLowerCase()) === item.group_name.toLowerCase() ? Styles.active : ''}`} onClick={handleOnClick}>
      <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <span style={{ display: "flex" }} className={Styles.groupIcon}><GroupIcons /></span>
        <span className={Styles.title}>{item.group_name}</span>
      </div>
      {
        item.group_name.toLowerCase() !== 'default' && (
          <div className={Styles.sidebarIcon}>
            <BootstrapTooltip
                title={"Rename"}
                arrow
                placement="bottom"
            >
              <div className={Styles.icon} onClick={handleEditClick}><EditIcons /></div>
            </BootstrapTooltip>
            <BootstrapTooltip
                title={"Delete"}
                arrow
                placement="bottom"
            >
              <span className={Styles.icon} onClick={handleDelete}><DeleteIcons /></span>
            </BootstrapTooltip>
          </div>
        )
      }
      <CustomModalPype
        isShow={openEditModal}
        hanldeModal={(status=false) => setOpenEditModal(status)}
        component={(
          <EditGroup dataIndex={index} item={item} onClose={() => {setOpenEditModal(false)}} />
        )}
      />
    </div>
  );
}
export default GroupListItem;