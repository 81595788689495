import React from 'react';
import SwitchC from "../customField/subComponents/Switch";


export const SnapshotOptions = (props) => {

    const onChangeSnapshot = (columnName,value) => {
        props.onChange({...props.selectedSnapshots,[columnName] : value});
    }

    return (
        <div className='snapshot-selections'>
            <h6 className='mt-5'>Select the items you want to share with the new user:</h6>

            <div className="conversative d-flex align-items-center mt-3">
                <div className="title">
                    <h6>Pipelines/Stages</h6>
                </div>
                <div className="switch team_switch">
                    <SwitchC
                        status={props.selectedSnapshots.pipeline_info}
                        brandColor={"#f94f72"}
                        onChange={(value) => onChangeSnapshot('pipeline_info',value)}
                        onText="Yes"
                        offText="No"
                    />
                </div>
            </div>

            <div className="conversative d-flex align-items-center mt-2">
                <div className="title">
                    <h6>Campaigns</h6>
                </div>
                <div className="switch team_switch">
                    <SwitchC
                        status={props.selectedSnapshots.campaign_info}
                        brandColor={"#f94f72"}
                        onChange={(value) => onChangeSnapshot('campaign_info',value)}
                        onText="Yes"
                        offText="No"
                    />
                </div>
            </div>

            <div className="conversative d-flex align-items-center mt-2">
                <div className="title">
                    <h6>Quick Replies</h6>
                </div>
                <div className="switch team_switch">
                    <SwitchC
                        status={props.selectedSnapshots.quick_reply_info}
                        brandColor={"#f94f72"}
                        onChange={(value) => onChangeSnapshot('quick_reply_info',value)}
                        onText="Yes"
                        offText="No"
                    />
                </div>
            </div>

            <div className="conversative d-flex align-items-center mt-2">
                <div className="title">
                    <h6>Calendar</h6>
                </div>
                <div className="switch team_switch">
                    <SwitchC
                        status={props.selectedSnapshots.calendar_info}
                        brandColor={"#f94f72"}
                        onChange={(value) => onChangeSnapshot('calendar_info',value)}
                        onText="Yes"
                        offText="No"
                    />
                </div>
            </div>

            <div className="conversative d-flex align-items-center mt-2">
                <div className="title">
                    <h6>Forms</h6>
                </div>
                <div className="switch team_switch">
                    <SwitchC
                        status={props.selectedSnapshots.form_info}
                        brandColor={"#f94f72"}
                        onChange={(value) => onChangeSnapshot('form_info',value)}
                        onText="Yes"
                        offText="No"
                    />
                </div>
            </div>

            <div className="conversative d-flex align-items-center mt-2">
                <div className="title">
                    <h6>Custom Forms</h6>
                </div>
                <div className="switch team_switch">
                    <SwitchC
                        status={props.selectedSnapshots.custom_form_info}
                        brandColor={"#f94f72"}
                        onChange={(value) => onChangeSnapshot('custom_form_info',value)}
                        onText="Yes"
                        offText="No"
                    />
                </div>
            </div>

            <div className="conversative d-flex align-items-center mt-2">
                <div className="title">
                    <h6>Triggers</h6>
                </div>
                <div className="switch team_switch">
                    <SwitchC
                        status={props.selectedSnapshots.trigger_info}
                        brandColor={"#f94f72"}
                        onChange={(value) => onChangeSnapshot('trigger_info',value)}
                        onText="Yes"
                        offText="No"
                    />
                </div>
            </div>

            {/* <div className="conversative d-flex align-items-center mt-2">
                <div className="title">
                    <h6>Tags</h6>
                </div>
                <div className="switch team_switch">
                    <SwitchC
                        status={props.selectedSnapshots.tag_info}
                        brandColor={"#f94f72"}
                        onChange={(value) => onChangeSnapshot('tag_info',value)}
                        onText="Yes"
                        offText="No"
                    />
                </div>
            </div> */}

            <div className="conversative d-flex align-items-center mt-2">
                <div className="title">
                    <h6>Custom Fields</h6>
                </div>
                <div className="switch team_switch">
                    <SwitchC
                        status={props.selectedSnapshots.custom_fields_info}
                        brandColor={"#f94f72"}
                        onChange={(value) => onChangeSnapshot('custom_fields_info',value)}
                        onText="Yes"
                        offText="No"
                    />
                </div>
            </div>

            <div className="conversative d-flex align-items-center mt-2">
                <div className="title">
                    <h6>Personalized Fields</h6>
                </div>
                <div className="switch team_switch">
                    <SwitchC
                        status={props.selectedSnapshots.personalized_fields_info}
                        brandColor={"#f94f72"}
                        onChange={(value) => onChangeSnapshot('personalized_fields_info',value)}
                        onText="Yes"
                        offText="No"
                    />
                </div>
            </div>
            {/*<div className="conversative d-flex align-items-center mt-2">*/}
            {/*    <div className="title">*/}
            {/*        <h6>Lead Sources</h6>*/}
            {/*    </div>*/}
            {/*    <div className="switch team_switch">*/}
            {/*        <SwitchC*/}
            {/*            status={props.selectedSnapshots.lead_sources_info}*/}
            {/*            brandColor={"#f94f72"}*/}
            {/*            onChange={(value) => onChangeSnapshot('lead_sources_info',value)}*/}
            {/*            onText="Yes"*/}
            {/*            offText="No"*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
}
