import React, { useEffect, useState, useRef } from 'react'
import { Button } from "@material-ui/core";
import { useHistory } from 'react-router';
import { generateOrRefreshPublicApiTokenApi, getUserPublicApiTokenV2Api } from '../../api/profileApi';
import Utils, { getAccountData } from '../../helpers/Utils';
import './public-api-token.css'
import InfoIcon from '@material-ui/icons/Info';
import { confirmAlert } from 'react-confirm-alert';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CheckIcon from '@material-ui/icons/Check';

const AGENCY_ID = getAccountData('agencyId')

const PublicApi = (props) => {
    const copyTextRef = useRef(null)
    const history = useHistory();
    const [token, setToken] = useState('');
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [unAuthorized, setUnAuthorized] = useState(false);

    /* copy text */
    const [copyText, setCopyText] = useState(false);

    /* get user public api token */
    useEffect(() => {
        // if(!Utils.getAccountData('userIsAgent') ){
            getUserPublicApiTokenV2Api(Utils.getAccountData('userId')).then(response => {
                if(response.success){
                    setToken(response.data.apiKey);
                }
                setLoading(false)
            }).catch(error => {
                setLoading(false);
                window.showNotification("ERROR", "Something went wrong!")
                console.log(["from public api token component", JSON.stringify(error)])
            })
        // }
        // else{
        //     setLoading(false);
        //     setUnAuthorized(true);
        // }
    }, [])

    const generateOrRefreshToken = () => {
        if(!(token === null || token === "")){
            confirmAlert({
                title: 'Are you sure to refresh token',
                message: 'Your current public api token will be changed.',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            setSubmitting(true)
                            generateOrRefreshPublicApiTokenApi(Utils.getAccountData('userId')).then(response => {
                                if(response.status !== undefined && response.status){
                                    setToken(response.data.apiKey);
                                }
                                else{
                                    window.showNotification("ERROR", "Something went wrong! Please try again.")
                                }
                                setSubmitting(false)
                            }).catch(error => {
                                setSubmitting(false);
                                window.showNotification("ERROR", "Something went wrong!")
                                console.log(["from public api token component", JSON.stringify(error)])
                            })
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => {return}
                    }
                ]
            });
            return
        }
        setSubmitting(true)
        generateOrRefreshPublicApiTokenApi(Utils.getAccountData('userId')).then(response => {
            // if(response.success){
            if(response.status !== undefined && response.status){
                setToken(response.data.apiKey);
            }
            else{
                window.showNotification("ERROR", "Something went wrong! Please try again.")
            }
            setSubmitting(false)
        }).catch(error => {
            setSubmitting(false);
            window.showNotification("ERROR", "Something went wrong!")
            console.log(["from public api token component", JSON.stringify(error)])
        })
    }


    const tokenCopyToClipboard = () => {
        let copyText = copyTextRef.current;
        var textarea = document.createElement('textarea');
        textarea.textContent = copyText.value;
        textarea.id = "copy-text-id"
        document.body.appendChild(textarea);
      
        var selection = document.getSelection();
        var range = document.createRange();
        range.selectNode(textarea);
        selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand("copy");
        selection.removeAllRanges();
        document.body.removeChild(textarea);
        
        setCopyText(true)
        setTimeout(() => {
            setCopyText(false)
        }, 1000);
    }

    return(
        <div className="container-fluid public-api-container awesome__scroll_bar">
            <div className="row">
                {unAuthorized &&
                    <div>You have no access on this content</div>
                }
                {!unAuthorized &&
                <div className="col s12 m12 l12 xl12 public-api-container-body">
                    <div className="public-api-container-header">
                        <h4>Public Api</h4>
                        {
                            history.location.state !== undefined && history.location.state.from === 'settingPage' &&
                            <Button 
                                variant="outlined" 
                                className={"accent--bg--text--color back_button_component has_gap mt-4 ml-3"} 
                                onClick={e => history.push("/user/settings")}
                                startIcon={<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 10C0 4.4774 4.4774 0 10 0C15.5226 0 20 4.4774 20 10C20 15.5226 15.5226 20 10 20C4.4774 20 0 15.5226 0 10ZM10.2236 14.3923C10.2953 14.3212 10.3523 14.2366 10.3913 14.1434C10.4304 14.0502 10.4507 13.9502 10.4511 13.8492C10.4515 13.7482 10.432 13.6481 10.3937 13.5546C10.3554 13.4611 10.299 13.376 10.2279 13.3043L7.7125 10.7692H14.1346C14.3386 10.7692 14.5343 10.6882 14.6785 10.5439C14.8228 10.3997 14.9038 10.204 14.9038 10C14.9038 9.79599 14.8228 9.60033 14.6785 9.45607C14.5343 9.31181 14.3386 9.23077 14.1346 9.23077H7.7125L10.2279 6.69567C10.299 6.62389 10.3554 6.53879 10.3936 6.44524C10.4319 6.35169 10.4514 6.25152 10.4509 6.15045C10.4505 6.04937 10.4301 5.94938 10.391 5.85617C10.3519 5.76296 10.2949 5.67837 10.2231 5.60721C10.1513 5.53606 10.0662 5.47974 9.97264 5.44147C9.8791 5.40321 9.77892 5.38374 9.67785 5.38419C9.57678 5.38463 9.47678 5.40498 9.38357 5.44407C9.29037 5.48317 9.20577 5.54023 9.13462 5.61202L5.31779 9.45817C5.17481 9.60226 5.09458 9.79702 5.09458 10C5.09458 10.203 5.17481 10.3977 5.31779 10.5418L9.13462 14.388C9.20578 14.4599 9.29042 14.517 9.3837 14.5561C9.47697 14.5952 9.57704 14.6156 9.67819 14.616C9.77934 14.6164 9.87957 14.5968 9.97315 14.5585C10.0667 14.5201 10.1518 14.4636 10.2236 14.3923Z"></path></svg>}>
                                Back
                            </Button> 
                        }
                    </div>
                    <ul className="d-flex justify-content-start align-content-center main-body">
                        {loading &&
                            <div className="first_loading">Please wait</div>
                        }
                        {!loading &&
                            <div className="main___content">
                                <div className="api_info_content">
                                    <div className="api_desc_content">
                                        <p className="api_desc_content_title">Api token: </p>
                                        {(token === null || token === "") &&
                                            <p className="no_token_text">You have no public api token</p>
                                        }
                                        {!(token === null || token === "") &&
                                        <>
                                        <p className="token_text">{token}<span className='copy_to_clipboard' onClick={tokenCopyToClipboard}>{copyText ? <CheckIcon color='primary' fontSize='small' /> : <FileCopyIcon fontSize='small' />}</span></p>
                                        <input id="public_api_token_text" type="text" ref={copyTextRef} className="d-none" value={token} />
                                        </>
                                        }
                                    </div>
                                    <div>
                                        <div className="api_token_button accent--bg--text--color" onClick={generateOrRefreshToken}>{(token === null || token === "") ? submitting ? "Generating " : "Generate " : submitting ? "Refreshing " : "Refresh "}Token</div>
                                    </div>
                                    {submitting &&
                                    <div className="loading_container">
                                        <span>{(token === null || token === "") ? "Generating..." : "Refreshing..."}</span>
                                    </div>
                                    }
                                </div>
                                <div className="api_token_short_description d-none">
                                    <span className="api_token_short_description_icon"><InfoIcon fontSize="large" /></span>
                                    <span className="api_token_short_description_text">
                                        {/* You can use any end-point by using this <b>Api Token</b> and any <b>Route Prefix</b> from route list.<br/> */}
                                        {/* For using any api end-point api URL will be like : <b>{process.env.REACT_APP_PUBLIC_API_BASE_URL}[[Route Prefix]]/v1/campaigns/</b><br/> */}
                                        To read more about public api documentation, <a href='https://documenter.getpostman.com/view/5101444/2s93Y3u1Eb' rel="noreferrer" target='_blank'>click here</a>
                                    </span>
                                </div>

                                <div className='public__api__list__table__container'>
                                    <div className="public-base-url-info-content">
                                        {/* <p>Base url: {process.env.REACT_APP_PUBLIC_API_BASE_URL}</p> */}
                                        {AGENCY_ID == 56 ? 
                                        <p style={{fontWeight: 'unset', marginBottom: 25}}>To read more about public api documentation, <a href='https://app.kindcrm.com/agency/crm-public-api' rel="noreferrer" target='_blank'>click here</a></p>
                                        :
                                        <p style={{fontWeight: 'unset', marginBottom: 25}}>To read more about public api documentation, <a href='https://documenter.getpostman.com/view/5101444/2s93Y3u1Eb' rel="noreferrer" target='_blank'>click here</a></p>
                                        }
                                        
                                    </div>
                                </div>
                            </div>
                        }
                    </ul>
                </div>
                }
            </div>
        </div>
    )
}
export default PublicApi;