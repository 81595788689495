import React, {useEffect, useState} from "react";
import "./assets/css/group-lists.css"
import "./assets/css/group-lists-responsive.css"
import {connect} from "react-redux";
import { GlobalTable } from "./GlobalTable";
import LeadGroupModal  from "./LeadGroupModal";
import Utils from "./../../helpers/Utils";
import {fetchLeadGroupList} from "../../actions/profileAction";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { removeGroup } from "../../api/profileApi";
import "../../components/group/assets/image/plus_btn_circle.png";
import {Link, useHistory} from "react-router-dom";

const App = (props) => {

    const totalGroups = 0;
    const operation = [];
    const queryTerm = [];
    const noHeader = true;

    const history = useHistory();
    const [leadGroups, setLeadGroups] = useState([]);
    const [leadGroupToEdit, setLeadGroupToEdit] = useState(null);
    const [page, setPage] = useState(1)
    const [itemPerPage, setItemPerPage] = useState(10)
    const [firstLoad, setFirstLoad] = useState(true)
    const [isFetchingData, setIsFetchingData] = useState(false)
    const [openLeadGroupModal, setOpenLeadGroupModal] = React.useState(false);

    useEffect(() => {
        document.title = `Lead Group | ${Utils.getAccountData('AuthUserAgencyName')}`;
        if(window.setActiveSidebar) {
            window.setActiveSidebar('setting');
        }
    },[])

    useEffect(() => {
        setIsFetchingData(true);
        props.fetchLeadGroupList({page: page, itemPerPage: itemPerPage, operation: JSON.stringify(operation), queryTerm: JSON.stringify(queryTerm)}, fetchLeadGroupResponse)
    }, [firstLoad]) // eslint-disable-line react-hooks/exhaustive-deps

    const fetchLeadGroupResponse = (response) => {
        setLeadGroups(response)
        setIsFetchingData(false)
    }

    const onChangePage = (page) => {
        setPage(page)
    }

    const onChangeRowsPerPage = (page) => {
        setItemPerPage(page)
    }
    const EachAvatar = (props) => {

        const fullName = props.data.full_name.substr(0,2);
        return (
            <div className="distribution__icon"><span>{fullName}</span></div>
        );
    };


    const generateAvatar = (groupUsers) => {
        let groupUsersArr = [];

        if(groupUsers !== undefined){
            for(let i = 0; i < groupUsers.length; i++){
                groupUsersArr.push(<EachAvatar key={i} data={groupUsers[i]} />)
            }
        }


        return groupUsersArr;
    }

    const handleCloseLeadGroupModal = () => {
        setLeadGroupToEdit(null);
        setOpenLeadGroupModal(false);

    }

    const handleCloseLeadGroupModalAndUpdate = () => {
        handleCloseLeadGroupModal();
        window.showNotification("SUCCESS", "Group create/update success")
        setFirstLoad(!firstLoad);
    }

    const deleteData = (_id) => {
        //setLoading(true);
        removeGroup({
            "form_data": {
                group_id: _id
            }
        })
        .then(response => {
            if (response.data.status === 'success' || response.data.success === true) {
              window.showNotification("SUCCESS", response.data.message)
                setFirstLoad(!firstLoad);
            } else if (response.data.status === 'validation-error') {
                for (var key of Object.keys(response.data.message)) {
                    let errors = response.data.message[key];
                    errors.forEach(error => {
                      window.showNotification("ERROR", 'Not removed')
                    });
                }
            } else {
              window.showNotification("ERROR", response.data.message)
            }
        }).finally(() => {
            //setLoading(false);
        });
    }

    const columns = [
        {
            name: <span style={{fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px'}}>Group Name</span>,
            selector: row => row['name'],
            sortable: false,
            minWidth:'250px'
        },
        {
            name: <span style={{fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px'}}>Distribution Type</span>,
            selector: row => row['method'] == 1 ? 'Round Robin' : 'First To Claim', // eslint-disable-line eqeqeq

            sortable: false,
            minWidth:'250px'
        },
        {
            name: <span style={{fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px'}}>Distribution</span>,
            selector: row => row['group_users'],
            sortable: false,
            minWidth:'250px',
            cell: row =><div className={"d-flex lead-group-distribution"}>
                { generateAvatar(row['group_users'])}
                <button className="group__edit__btn"row={row} onClick={(event) => {
                    setLeadGroupToEdit(row);
                    setOpenLeadGroupModal(true);
                }} >
                        <span className="material-icons">
                                add
                       </span>
                </button>
            </div>
        },
        {
            name: <span style={{fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px'}}>Action</span>,
            selector: row => row['id'],
            sortable: false,
            minWidth:'250px',
            cell: row =>
                <div>
                    <button className="group__edit__btn"row={row} onClick={(event) => {
                        setLeadGroupToEdit(row);
                        setOpenLeadGroupModal(true);
                    }} >
                        <span>
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M4.75015 13.2963C4.38212 13.6652 3.93741 13.889 3.42369 13.9756C3.39338 13.9807 3.36418 13.9916 3.33424 13.9997C2.39589 13.9997 1.45754 13.9997 0.519194 13.9997C0.403818 13.9259 0.274202 13.8671 0.177811 13.774C0.0982161 13.6973 0.0576883 13.5797 0 13.48C0 12.5414 0 11.6029 0 10.6643C0.0255581 10.554 0.0485604 10.443 0.0774045 10.3334C0.178907 9.9474 0.367671 9.60886 0.637857 9.31524C0.680941 9.26849 0.726215 9.22321 0.771124 9.17792C3.6245 6.32351 6.47934 3.47055 9.33016 0.613948C9.7223 0.220991 10.1787 -0.00360816 10.7391 4.38549e-05C11.2251 0.00333067 11.6527 0.166576 12.0003 0.504752C12.5063 0.997044 13.0083 1.49372 13.4972 2.00317C14.1453 2.67843 14.1665 3.75468 13.5611 4.47376C13.5085 4.53621 13.4516 4.59574 13.3935 4.65381C11.1368 6.91294 8.87925 9.17135 6.62247 11.4305L4.75015 13.2963ZM9.93333 1.99514C10.6208 2.68281 11.3175 3.37962 12.0163 4.07898C12.0134 4.08117 12.0258 4.07423 12.0349 4.06474C12.1737 3.92669 12.3139 3.78974 12.4501 3.64914C12.6816 3.40993 12.6801 3.12763 12.4468 2.88733C12.304 2.74052 12.1573 2.59699 12.0123 2.45201C11.7063 2.14597 11.4026 1.83774 11.0933 1.53535C10.894 1.34033 10.6198 1.31075 10.4266 1.48203C10.244 1.64418 10.086 1.83409 9.93333 1.99514Z"
                                    fill="#133159" fill-opacity="0.65"/>
                            </svg>
                        </span>
                    </button>
                    <button className="group__edit__btn"row={row} onClick={(event) => {
                        confirmAlert({
                            title: 'Are you sure?',
                            message: 'Are you sure to want to delete this group.',
                            buttons: [
                                {
                                    label: 'Yes',
                                    onClick: () => {
                                        deleteData(row.id);
                                    }
                                },
                                {
                                    label: 'NO',
                                    onClick: () => {
                                        return;
                                    }
                                }
                            ]
                        })
                    }} >
                        <span>
                            <svg width="11" height="13" viewBox="0 0 11 13" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M9.26442 1.57692H7.09615V1.18269C7.09615 0.529501 6.56665 0 5.91346 0H4.33654C3.68335 0 3.15385 0.529501 3.15385 1.18269V1.57692H0.985577C0.441267 1.57692 0 2.01819 0 2.5625V3.35096C0 3.5687 0.176492 3.74519 0.394231 3.74519H9.85577C10.0735 3.74519 10.25 3.5687 10.25 3.35096V2.5625C10.25 2.01819 9.80873 1.57692 9.26442 1.57692ZM3.94231 1.18269C3.94231 0.965372 4.11922 0.788461 4.33654 0.788461H5.91346C6.13078 0.788461 6.30769 0.965372 6.30769 1.18269V1.57692H3.94231V1.18269Z"
                                    fill="#FF264A"/>
                                <path
                                    d="M0.748192 4.53516C0.677846 4.53516 0.621792 4.59395 0.625143 4.66422L0.950383 11.4904C0.980443 12.1221 1.49935 12.6169 2.1316 12.6169H8.11848C8.75073 12.6169 9.26964 12.1221 9.2997 11.4904L9.62494 4.66422C9.62829 4.59395 9.57223 4.53516 9.50189 4.53516H0.748192ZM6.70196 5.52073C6.70196 5.30292 6.87838 5.1265 7.09619 5.1265C7.31401 5.1265 7.49042 5.30292 7.49042 5.52073V10.6457C7.49042 10.8635 7.31401 11.04 7.09619 11.04C6.87838 11.04 6.70196 10.8635 6.70196 10.6457V5.52073ZM4.73081 5.52073C4.73081 5.30292 4.90723 5.1265 5.12504 5.1265C5.34285 5.1265 5.51927 5.30292 5.51927 5.52073V10.6457C5.51927 10.8635 5.34285 11.04 5.12504 11.04C4.90723 11.04 4.73081 10.8635 4.73081 10.6457V5.52073ZM2.75966 5.52073C2.75966 5.30292 2.93607 5.1265 3.15389 5.1265C3.3717 5.1265 3.54812 5.30292 3.54812 5.52073V10.6457C3.54812 10.8635 3.3717 11.04 3.15389 11.04C2.93607 11.04 2.75966 10.8635 2.75966 10.6457V5.52073Z"
                                    fill="#FF264A"/>
                            </svg>
                        </span>
                    </button>
                </div>

        },
    ];


    return (

            <div className="main__wrapper">
                <div className="main__top d-flex justify-content-between align-items-center">
                    <div className="main__top__text d-flex align-items-center">
                        <h1 className="mt-4">Lead Groups</h1>
                    </div>
                    <div className="main__top__button d-flex align-items-center">
                        <a onClick={(e)=> { e.preventDefault(); setOpenLeadGroupModal(true) }} className="add__group__btn modal-trigger accent--bg--text--color" href="#!">
                           <span className="material-icons">
                                add_circle_outline
                                </span>
                                <span className="mx-2">Add New Group</span>
                        </a>
                        {
                            history.location.state !== undefined && history.location.state.from === 'settingPage' &&
                            <Link className={"add__group__btn modal-trigger accent--bg--text--color"} to="/user/settings"><span><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 10C0 4.4774 4.4774 0 10 0C15.5226 0 20 4.4774 20 10C20 15.5226 15.5226 20 10 20C4.4774 20 0 15.5226 0 10ZM10.2236 14.3923C10.2953 14.3212 10.3523 14.2366 10.3913 14.1434C10.4304 14.0502 10.4507 13.9502 10.4511 13.8492C10.4515 13.7482 10.432 13.6481 10.3937 13.5546C10.3554 13.4611 10.299 13.376 10.2279 13.3043L7.7125 10.7692H14.1346C14.3386 10.7692 14.5343 10.6882 14.6785 10.5439C14.8228 10.3997 14.9038 10.204 14.9038 10C14.9038 9.79599 14.8228 9.60033 14.6785 9.45607C14.5343 9.31181 14.3386 9.23077 14.1346 9.23077H7.7125L10.2279 6.69567C10.299 6.62389 10.3554 6.53879 10.3936 6.44524C10.4319 6.35169 10.4514 6.25152 10.4509 6.15045C10.4505 6.04937 10.4301 5.94938 10.391 5.85617C10.3519 5.76296 10.2949 5.67837 10.2231 5.60721C10.1513 5.53606 10.0662 5.47974 9.97264 5.44147C9.8791 5.40321 9.77892 5.38374 9.67785 5.38419C9.57678 5.38463 9.47678 5.40498 9.38357 5.44407C9.29037 5.48317 9.20577 5.54023 9.13462 5.61202L5.31779 9.45817C5.17481 9.60226 5.09458 9.79702 5.09458 10C5.09458 10.203 5.17481 10.3977 5.31779 10.5418L9.13462 14.388C9.20578 14.4599 9.29042 14.517 9.3837 14.5561C9.47697 14.5952 9.57704 14.6156 9.67819 14.616C9.77934 14.6164 9.87957 14.5968 9.97315 14.5585C10.0667 14.5201 10.1518 14.4636 10.2236 14.3923Z"></path></svg></span><span className="mx-2">Back</span></Link>
                        }
                    </div>
                </div>

                <div className="main__bottom awesome__scroll_bar lead-group-main-table">

                    <GlobalTable
                        table_unique_id={'lead_group'}
                        columns={columns}
                        data={leadGroups}

                        keyField={'id'}
                        noDataComponent={(
                            <span style={{padding: 10, color: 'red'}}>No Group created yet! Create new...</span>
                        )}
                        progressPending={isFetchingData}

                        defaultSortField="title"

                        selectableRows={false}

                        pagination={leadGroups.length === 0 ? false : true}
                        paginationData ={{
                            paginationTotalRows: totalGroups,
                            paginationPerPage: itemPerPage,
                            onChangePage: onChangePage, //for pagination change
                            onChangeRowsPerPage: onChangeRowsPerPage, //for per page change
                            paginationComponentOptions: {
                                rowsPerPageText : 'Pages of '
                            }
                        }}
                        subHeader={true}
                        noHeader={noHeader}
                        noHeaderData={{
                            noContextMenu: false,
                            contextActions: [],
                            actions: []
                        }}
                    />

                    {openLeadGroupModal &&
                        <LeadGroupModal
                            openLeadGroupModal={openLeadGroupModal}
                            data={leadGroupToEdit}
                            hideModal={handleCloseLeadGroupModal}
                            SaveAndHideModal={handleCloseLeadGroupModalAndUpdate}
                            title={leadGroupToEdit ? 'Update Group' : 'New Group'}
                        />
                    }

                </div>
            </div>
    )
}

const mapStateToProps = state => {
    return {

    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchLeadGroupList: (params, callback) => dispatch(fetchLeadGroupList(params, callback))
    };
}


const LeadGroup = connect(mapStateToProps, mapDispatchToProps)(App);

export default LeadGroup;