import React from "react";

const StepContent = ({title,content,btnText=""}) => {
    return(
        <div className="geo__step__content">
           <h2 className="geo__common__h2">{title}</h2>
           <p className="geo__common__p">{content}</p>
           { btnText === '' ? ' ' :
            <div className="geo__step__btn__wr">
                <a href="#" className="geo__global__btn">{btnText}</a>
            </div>
            }
        </div>
    )
}


export default StepContent;