import React, { useEffect, useState } from "react";
import { changeLeadNotificationStatus, getLeadNotifications, setLeadsMarkAllAsSeen } from "../../api/NotificationsApi";
import Utils from "../../helpers/Utils";
import moment from "moment";
import { NOTIFICATION_ICON } from "../../constants/CoreConstants";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import Errorbox from "./Errorbox";
import Loader from "../globals/Loader";

const LeadNotification = () => {

    const [page,setPage] = useState(1);
    const [notifications,setNotifications] = useState([]);
    const [hideLoadMore,setHideLoadMore] = useState(false);
    const [loading,setLoading] = useState(true);


    useEffect(() => {
        document.title = `Lead Notification | ${Utils.getAccountData('AuthUserAgencyName')}`;
        setLoading(true);
        loadData();
    },[]); // eslint-disable-line react-hooks/exhaustive-deps

    const statusChange = (id,seen,index) => {
        changeLeadNotificationStatus({
            notification_id: id,
            seen: seen
        }).then(res => {
            let notificationArr = [...notifications];
            notificationArr[index].seen = seen;
            setNotifications(notificationArr);
            window.showNotification("SUCCESS", "Notification marked as unseen")
        });
    }

    const loadData = (append = true) => {
        getLeadNotifications({
            page : append ? page : 1
        }).then(res => {
            let data = res.data.data.notifications.data;
            if(data.length < 10) {
                setHideLoadMore(true);
            }
            if(append) {
                setNotifications([...notifications,...data]);
                setPage(page+1);
            } else {
                setPage(1);
                setNotifications(data);
                if(data.length >= 10) {
                    setHideLoadMore(false);
                }
            }
            setLoading(false);
        });
    }

    const formatMessage = (message) => {
        let noteText = message;
        let regix = /\@.*?\)/g; // eslint-disable-line no-useless-escape
        let matchStrign = noteText.match(regix);

        if (Array.isArray(matchStrign)) {
            matchStrign.forEach(str => {
                let eachStr = str.match(/@\[(.*)\]\(.*\)/);
                if (eachStr) {
                    noteText = noteText.replace(str, "<b>@" + eachStr[1] + "</b>")
                }
            });
        }

        var content = noteText.replace(/<[^>]*>/g, '');
        return content;
    }

    const handleMarkAllAsSeen = () => {

        confirmAlert({
            title: 'Are you sure?',
            message: 'Do you want to mark as seen all notifications?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        setLeadsMarkAllAsSeen({}).then((res) => {
                            // eslint-disable-next-line eqeqeq
                            if(res.data.success == true) {
                              window.showNotification("SUCCESS", res.data.message)
                              loadData(false);
                            }
                        });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {return}
                }
            ]
          });
    }

    const renderNotifications = () => {

        if(loading) {
            return <Loader/>
        }

        if(notifications.length === 0) {
            return <Errorbox/>
        }

        return notifications.map((notification,index) => {

            const fullName = notification.contact ? notification.contact.first_name + ' ' + notification.contact.last_name : '';
            const number = notification.contact ? notification.contact.number : '';
            const email = notification.contact ? notification.contact.email : '';

            return <div key={index} className="tabs_list_notification">
            <div className="items_box">
                <div className="checkbox">
                    <form action="">
                        {
                            // eslint-disable-next-line eqeqeq
                        }<input type="checkbox" checked={notification.seen !== 0} onChange={() => statusChange(notification.id,notification.seen == 0 ? 1 : 0,index)}/>
                    </form>
                </div>
                <div className="userBox">
                    <p>{Utils.getInitial(fullName)}</p>
                    {NOTIFICATION_ICON[notification.type]}
                </div>
                <div className="notification_info">
                    <div className="userName">
                        <h6>{Utils.getContactDisplayName(fullName,number,email,true)} 
                        {
                            notification.seen === 0 &&
                            <span className="new_msg">new</span>
                        }
                        <span className="date ml-2">{moment(notification.local_created_at.date_time).format('Do MMMM YYYY, h:mm A')}</span></h6>

                    </div>
                    <div className="info_msg">
                        <p>{formatMessage(notification.message)}</p>
                    </div>
                </div>
            </div>
            <div className="mark_icons">
                <ul>
                    <li>
                        <Link to={'/contacts/'+notification.contact_id}>
                            <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.27344 0C3.52877 0 2.10938 1.4194 2.10938 3.16406C2.10938 4.90873 3.52877 6.32812 5.27344 6.32812C7.0181 6.32812 8.4375 4.90873 8.4375 3.16406C8.4375 1.4194 7.0181 0 5.27344 0Z" fill="#546376"/>
                                <path d="M9.21014 8.3952C8.34389 7.51563 7.19552 7.03125 5.97656 7.03125H4.57031C3.35138 7.03125 2.20298 7.51563 1.33673 8.3952C0.474726 9.27045 0 10.4258 0 11.6484C0 11.8426 0.157406 12 0.351562 12H10.1953C10.3895 12 10.5469 11.8426 10.5469 11.6484C10.5469 10.4258 10.0721 9.27045 9.21014 8.3952Z" fill="#546376"/>
                            </svg>	
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
        })
    }

    return ( 
        <div className="container-fluid">
            <div className="notifications_bx">
                <h5>Lead Notifications</h5>
            </div>
            <div className="notification_tab">
                <div className="mark_list">
                    <p onClick={() => handleMarkAllAsSeen()}>Mark All as Seen</p>
                </div>
            </div>
            <div className="tabs_list_items">
                <div className="tab-notifications-items awesome__scroll_bar">
                    {renderNotifications()}
                </div>

                {
                    !hideLoadMore && 
                    <div className="load">
                        <a href="#!" onClick={e => { e.preventDefault(); loadData() }}>load more</a>
                    </div>
                }
            </div>
        </div>
     );
}
 
export default LeadNotification;