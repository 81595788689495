import PersonalInfoTab from "./tabs/PersonalInfoTab";
import ChangePasswordTab from "./tabs/ChangePasswordTab";
import FaviconInfoTab from "./tabs/FaviconInfoTab";
import CompanyInfoTab from "./tabs/CompanyInfoTab";
import {connect} from "react-redux";
const RightSideIndex = (props) => {
    return (
        <div className="col xl8 l7 m6 pl-4">
            <div className="profile_right_items white">
                <PersonalInfoTab active_tab={props.active_tab} />
                <ChangePasswordTab active_tab={props.active_tab} />
                <CompanyInfoTab active_tab={props.active_tab} />
                <FaviconInfoTab active_tab={props.active_tab} />
            </div>
        </div>
    )
}
const MapStateToProps = (state) => {
    return {
        
    };
}
const MapDispatchToProps = (dispatch) => {
    return {

    }
}
const RightSide = connect(MapStateToProps,MapDispatchToProps)(RightSideIndex);
export default RightSide;