import React, {Fragment, useEffect, useState} from "react";
import Styles from "./ShakenStirCnam.module.css";
import {Button} from "@material-ui/core";
import Icons from "./Icons";
import Utils from "../../helpers/Utils";
import Loader from "../globals/Loader";
import TablePagination from "@material-ui/core/TablePagination";
import {getVirtualNumberList, virtualNumberRequestForShakenStirRegistration} from "../../api/profileApi";
import NewConfirmLinkAlert from "../activity/new-alert/NewConfirmLinkAlert";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import InfoIcon from "@material-ui/icons/Info";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import BootstrapTooltip from "../globals/BootstrapTooltip";
import DoneIcon from "@material-ui/icons/Done";
import {Description} from "@material-ui/icons";

const ShakenStirCnam = ({ loading: pageLoading, registrationType }) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [sortType, setSortType] = useState("asc");
    const [sortBy, setSortBy] = useState("created_at");
    const [canRegisterShakenStir,setCanRegisterShakenStir] = useState(false);

    useEffect(() => {
        getVirtualNumbers();
    }, [currentPage, perPage, sortType]); // eslint-disable-line react-hooks/exhaustive-deps

    const getVirtualNumbers = () => {
        setLoading(true);
        getVirtualNumberList({
            "form_data": {
                page: currentPage + 1,
                per_page: perPage,
                sort_by: sortBy,
                sort_type: sortType,
            }
        })
            .then(response => {
                if(response.data.canRegisterShakenStir !== undefined && response.data.canRegisterShakenStir === true){
                    setCanRegisterShakenStir(true);
                }
                setData(response.data.data.data);
                setTotal(response.data.data.total);
            }).finally(() => {
            setLoading(false);
        });
    };

    const requestForShakenStirRegistration = (item) => {
        NewConfirmLinkAlert({
            onSubmit : () => {
                virtualNumberRequestForShakenStirRegistration({
                    "form_data": {
                        type: "single",
                        id: item.id
                    }
                }).then(response => {
                    if (response.data.status === "success") {
                        window.showNotification("SUCCESS", response.data.message);
                        getVirtualNumbers();
                    } else {
                        window.showNotification("ERROR", response.data.message);
                    }
                }).catch(error => {
                    window.showNotification("ERROR", "Something went wrong!");
                });
            },
            title: "Would you like to register this number for Shaken/Stir and CNAM?",
            description: "",
            cancelText: "No",
            submitText: "Yes",
            width: "530px",
            footerLink: {text: "Learn more", link: "https://s3.us-east-1.amazonaws.com/pypepro/contact/27567256/SRH4WAqAZ5RoKYDk3IduoNTmKPxTQcDM3x5CYYgZ.pdf"}
        });
    };

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    if ((Utils.getAccountData("userId") >
            process.env.REACT_APP_CHANGE_IN_10DLC_FOR_SUBACCOUNT_AFTER_USER_ID ||
            Utils.getAccountData("centralBilling")) &&
        !Utils.getAccountData("userIsOwner")){
        window.location.href = "/";
        return;
    }

  return(
      <div className={Styles.pageWrapper}>
        {
          (loading || pageLoading) ?
              <div className={Styles.loaderPage}>
                <Loader />
              </div> :
              <Fragment>
                <div className={Styles.pageHeader}>
                  <h1 className={Styles.pageTitle}>SHAKEN/STIR & CNAM</h1>
                  <p className={Styles.pageSubtitle}>SHAKEN/STIR is a protocol mandated by the FCC to combat the rise in unwanted robocalls and unlawful caller ID spoofing. When adopted, carriers can present a trust indicator, like "Caller Verified," to recipients' phones.</p>
                  <div className={Styles.itemJustify}>
                      <ul className={Styles.pageHints}>
                          <li className={Styles.pageHint}>
                              {Icons.checkMark}
                              Increase call answer rates for trusted callers
                          </li>
                          <li className={Styles.pageHint}>
                              {Icons.checkMark}
                              Improve the reputation of your calls
                          </li>
                          <li className={Styles.pageHint}>
                              {Icons.checkMark}
                              Trust indicators
                          </li>
                      </ul>
                      <div>
                          {
                              canRegisterShakenStir &&
                              <div style={{ marginTop: "40px", marginRight: "200px" }}>
                                  <a href="https://s3.us-east-1.amazonaws.com/pypepro/contact/27567256/SRH4WAqAZ5RoKYDk3IduoNTmKPxTQcDM3x5CYYgZ.pdf" target="_blank" className={Styles.helpDocButton}>
                                      <Description/>
                                      <span style={{ textDecoration: "underline" }}>
                                    HELP DOC
                                  </span>
                                  </a>
                              </div>
                          }
                      </div>
                  </div>
                </div>
                <div className={Styles.pageBody}>
                    {
                            registrationType === "SOLE_PROPRIETOR" ? (
                                <div>
                                    <div className={Styles.alertMessage}>
                                        <InfoIcon/>
                                        Currently you are in Sole Proprietorship Registration
                                    </div>
                                </div>
                            ) : (
                            canRegisterShakenStir ?
                                <Fragment>
                                    <table className={Styles.tableTable}>
                                        <thead>
                                        <tr className={Styles.tableTrTh}>
                                            <th className={Styles.tableTh}>
                                                <div className={Styles.tableThLabel} onClick={() => {
                                                    setSortBy("virtual_number");
                                                    if (sortType === "asc") {
                                                        setSortType("desc");
                                                    } else {
                                                        setSortType("asc");
                                                    }
                                                }}>
                                                    Number
                                                    {Icons.sortIcon}
                                                </div>
                                            </th>
                                            <th style={{ width: "10%",textAlign: "center" }} className={Styles.tableTh}>
                                                Shaken/Stir, CNAM
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            data.map((item)=>(
                                                <tr className={Styles.tableTrTh}>
                                                    <td>
                                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                            {item.virtual_number}
                                                            {
                                                                (item.is_default === 1) &&
                                                                <span className={Styles.defaultIcon}>
                                                    <BootstrapTooltip arrow title="Default Number">
                                                        <DoneIcon fontSize="small" />
                                                    </BootstrapTooltip>
                                                </span>
                                                            }
                                                        </div>
                                                    </td>
                                                    <td className={Styles.itemCenter}>
                                                        { item.shaken_stir_status === 0 &&
                                                            <Button variant="contained" color="primary" className={Styles.neutralButton} onClick={()=>{requestForShakenStirRegistration(item)}}>
                                                                Register
                                                            </Button>
                                                        }

                                                        {
                                                            item.shaken_stir_status === 1 &&
                                                            <div className={`${Styles.itemAlignCenter} ${Styles.girBans}`}>
                                                                <CheckCircleIcon fontSize="small" className={Styles.successIcon} />
                                                                <span className={Styles.alertLabel}>Twilio Approved</span>
                                                            </div>
                                                        }

                                                        {
                                                            item.shaken_stir_status === 2 &&
                                                            <div className={`${Styles.itemAlignCenter} ${Styles.girBans}`}>
                                                                <InfoIcon fontSize="small" className={Styles.infoIcon} />
                                                                <span className={Styles.alertLabel}>In Review</span>
                                                            </div>
                                                        }

                                                        {
                                                            item.shaken_stir_status === 3 &&
                                                            <div className={`${Styles.itemAlignCenter} ${Styles.girBans}`}>
                                                                <InfoIcon fontSize="small" className={Styles.infoIcon} />
                                                                <span className={Styles.alertLabel}>In Review</span>
                                                            </div>
                                                        }

                                                        {
                                                            item.shaken_stir_status === 4 &&
                                                            <div className={Styles.itemCenter}>
                                                                <div className={`${Styles.itemAlignCenter} ${Styles.girBans}`}>
                                                                    <ReportProblemIcon fontSize="small" className={Styles.problemIcon} />
                                                                    <span className={Styles.alertLabel}>Failed</span>
                                                                </div>
                                                                <span className={Styles.resubmitShaken} onClick={requestForShakenStirRegistration}>Re-Submit</span>
                                                            </div>
                                                        }

                                                        {
                                                            item.shaken_stir_status === 5 &&
                                                            <div className={`${Styles.itemAlignCenter} ${Styles.girBans}`}>
                                                                <InfoIcon fontSize="small" className={Styles.infoIcon} />
                                                                <span className={Styles.alertLabel}>In Review</span>
                                                            </div>
                                                        }
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                    </table>
                                    <TablePagination
                                        className={Styles.tableTable}
                                        component="div"
                                        count={total}
                                        page={currentPage}
                                        onPageChange={handleChangePage}
                                        rowsPerPage={perPage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </Fragment> :
                                <div>
                                    <div className={Styles.alertMessage}>
                                        <InfoIcon/>
                                        A2p Business Profile not registered. Please Complete your registration.
                                    </div>
                                </div>
                        )
                    }
                </div>
              </Fragment>
        }
      </div>
  );
};

export default ShakenStirCnam;