import moment from 'moment';
import { useMemo } from 'react';
import './TimeRange.css';

const TimeRange = (props) => {

    const listItems = useMemo(() => {
        
        let startTime = moment(props.startTime,'hh:mm A');
        let endTime = moment(props.endTime,'hh:mm A');

        return Array.from(Array(24).keys()).map((number) => {
        
            let blockClass = `${((startTime.hour() < endTime.hour()) && (number<startTime.hour() || number >= endTime.hour())) || ((startTime.hour() > endTime.hour()) && (number<startTime.hour() && number >= endTime.hour()))  ? 'danger' : 'active'}`;
            let startMinute = startTime.minute();
            let endMinute = endTime.minute();
            let startMinuteWidth = (startMinute*100)/60;
            let endMinuteWidth = (endMinute*100)/60;
    
            if(startTime.hour() === endTime.hour() && startMinute < endMinute) {
                blockClass = 'danger';
            }
    
            let timeString = `${number%12 === 0 ? 12 : number%12} ${number < 12 ? "AM" : "PM"}`;
    
            if((number === startTime.hour()) && (startTime.hour() === endTime.hour())){
                let diffMinuteWidth = (Math.abs((startMinute-endMinute))*100)/60;
                let marginLeft = 0;
                let timeClass = 'cutoff__end_time';
                if(blockClass === 'active') {
                    marginLeft = endMinuteWidth;
                    timeClass = 'cutoff__start_time';
                } else {
                    marginLeft = startMinuteWidth;
                }
    
                return <div className={blockClass}>
                    <div className={timeClass} style={{width : diffMinuteWidth+"%",marginLeft : marginLeft+"%"}}>
                        {
                            startMinute !== endMinute && 
                            <>
                            <div className='cuttoff__virtical_line left'>
                                <span className={`cuttoff__virtical_line_text`}>{blockClass === 'active' ? endTime.format('h:mm A') : startTime.format('h:mm A')}</span>
                            </div>
                            <div className='cuttoff__virtical_line'>
                                <span className={`cuttoff__virtical_line_text_right`}>{blockClass === 'danger' ? endTime.format('h:mm A'): startTime.format('h:mm A')}</span>
                            </div>
                            </>
                        }
                        <span className='cutoff_hour'>{timeString}</span>
                    </div>
                </div>
            }  else if(number === startTime.hour()) {
                return <div className={blockClass}>
                    <div className='cutoff__start_time' style={{width : startMinuteWidth+"%"}}>
                        <div className='cuttoff__virtical_line'>
                            <span className={`cuttoff__virtical_line_text${startTime.hour() > endTime.hour() ? "_right" : ""}`}>{startTime.format('h:mm A')}</span>
                        </div>
                        <span className='cutoff_hour'>{timeString}</span>
                    </div>
                </div>
            } else if (number === endTime.hour()) {
                return <div className={blockClass}>
                    <div className='cutoff__end_time' style={{width : endMinuteWidth+"%"}}>
                        <div className='cuttoff__virtical_line'>
                            <span className={`cuttoff__virtical_line_text${startTime.hour() < endTime.hour() ? "_right" : ""}`}>{endTime.format('h:mm A')}</span>
                        </div>
                        <span className='cutoff_hour'>{timeString}</span>
                    </div>
                </div>
            } else {
                return <div className={blockClass}>
                    <span className='cutoff_hour'>{timeString}</span>
                </div>
            }
            
        })
    },[props.startTime,props.endTime]);

    return(
        <>
        <div className='chart_block__color_indicator'>
            <div><div className='cutoff__green_indicator'></div><span>Sending Hrs</span></div>
            <div><div className='cutoff__red_indicator'></div><span>Blocked Hrs</span></div>
        </div>
            <div className='cutoff_time_chart_wrapper flex-container'>  
                {listItems}    
            </div>
        </>
        
    );
}

export default TimeRange;