import React from 'react';
import modalUseStyles from "./modalUseStyles";
import {Backdrop, Fade, Modal} from "@material-ui/core";

const BasicModal = ({open = false, children}) => {
    const classes = modalUseStyles();

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>
                    {children}
                </div>
            </Fade>
        </Modal>
    );
};

export default BasicModal;