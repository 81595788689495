import React, { useEffect } from "react";
import {Link, useHistory} from "react-router-dom";
import Utils from "../../helpers/Utils";

const Header = (props) => {
    const history = useHistory();

    useEffect(() => {
        document.title = `Lead Permission Requests | ${Utils.getAccountData("AuthUserAgencyName")}`;
        if(window.setActiveSidebar) {
            window.setActiveSidebar("setting");
        }
    },[]);

    return(
        <div>
            <div className="tagBox lead_source_page">
                <div className="tag_list">
                    <h5>Lead Permission Requests</h5>
                </div>
                <div className="add_new_user ">
                    <a href="#" className="modal-trigger accent--bg--text--color" onClick={(e)=>{
                        e.preventDefault();
                        props.refresh(Math.random() * 10);
                    }}>
                        <span>
                           <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.8271 1.25633C13.7117 1.14102 13.5749 1.08317 13.4169 1.08317C13.2589 1.08317 13.1223 1.14102 13.0067 1.25633L11.8219 2.43229C11.1718 1.8185 10.4273 1.34292 9.58883 1.00576C8.75041 0.668564 7.88732 0.5 7.00013 0.5C5.32905 0.5 3.87078 1.00579 2.62506 2.01759C1.37932 3.02926 0.559038 4.34923 0.164062 5.97782V6.04157C0.164062 6.12055 0.192891 6.18897 0.250643 6.24662C0.308395 6.30438 0.376715 6.33321 0.455697 6.33321H2.26948C2.40322 6.33321 2.4943 6.26329 2.54295 6.12362C2.79813 5.51602 2.95909 5.16057 3.02601 5.05723C3.45131 4.36452 4.01642 3.82065 4.72132 3.42567C5.42629 3.03073 6.18582 2.83317 7 2.83317C8.22148 2.83317 9.28177 3.24944 10.1812 4.08192L8.92313 5.33967C8.80797 5.45514 8.75006 5.59194 8.75006 5.74994C8.75006 5.9079 8.80794 6.04464 8.92313 6.16008C9.03873 6.27555 9.1755 6.33324 9.33349 6.33324H13.4169C13.5749 6.33324 13.7117 6.27555 13.8271 6.16008C13.9424 6.04464 14.0001 5.90793 14.0001 5.74994V1.66651C14.0001 1.50851 13.9428 1.37184 13.8271 1.25633Z" fill="#fff" fill-opacity="0.65"></path>
                                <path d="M13.4804 8.66835H11.7304C11.5967 8.66835 11.5056 8.73817 11.4571 8.87797C11.202 9.48563 11.0408 9.84092 10.9741 9.94426C10.5487 10.6368 9.98352 11.1809 9.27881 11.5758C8.57381 11.9709 7.81428 12.1682 7.00006 12.1682C6.4107 12.1682 5.8425 12.0589 5.29568 11.8403C4.74881 11.6213 4.25959 11.3114 3.82822 10.9103L5.07697 9.66163C5.19238 9.54629 5.25013 9.40939 5.25013 9.25149C5.25013 9.09353 5.19238 8.95663 5.07697 8.84135C4.96149 8.72578 4.82482 8.66797 4.66683 8.66797H0.583397C0.425305 8.66797 0.28857 8.72578 0.173129 8.84135C0.0576884 8.95667 0 9.09356 0 9.25152V13.335C0 13.4928 0.0576884 13.6297 0.173161 13.7451C0.288698 13.8603 0.425432 13.9184 0.583429 13.9184C0.741394 13.9184 0.878065 13.8604 0.993569 13.7451L2.16933 12.5692C2.81955 13.1889 3.55941 13.666 4.38886 14.0002C5.2183 14.3345 6.0766 14.5014 6.9637 14.5014C8.62875 14.5014 10.081 13.9956 11.3205 12.9839C12.5601 11.9723 13.3744 10.652 13.7634 9.02361C13.7695 9.01148 13.7724 8.99019 13.7724 8.95992C13.7724 8.88081 13.7435 8.81269 13.6857 8.75474C13.6279 8.69731 13.5595 8.66835 13.4804 8.66835Z" fill="#fff" fill-opacity="0.65"></path>
                            </svg>
                        </span>
                        <span>Refresh</span>
                    </a>
                    {
                        history.location.state !== undefined && history.location.state.from === "settingPage" &&
                            <Link to="/user/settings" className="modal-trigger accent--bg--text--color">
                                <span>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 10C0 4.4774 4.4774 0 10 0C15.5226 0 20 4.4774 20 10C20 15.5226 15.5226 20 10 20C4.4774 20 0 15.5226 0 10ZM10.2236 14.3923C10.2953 14.3212 10.3523 14.2366 10.3913 14.1434C10.4304 14.0502 10.4507 13.9502 10.4511 13.8492C10.4515 13.7482 10.432 13.6481 10.3937 13.5546C10.3554 13.4611 10.299 13.376 10.2279 13.3043L7.7125 10.7692H14.1346C14.3386 10.7692 14.5343 10.6882 14.6785 10.5439C14.8228 10.3997 14.9038 10.204 14.9038 10C14.9038 9.79599 14.8228 9.60033 14.6785 9.45607C14.5343 9.31181 14.3386 9.23077 14.1346 9.23077H7.7125L10.2279 6.69567C10.299 6.62389 10.3554 6.53879 10.3936 6.44524C10.4319 6.35169 10.4514 6.25152 10.4509 6.15045C10.4505 6.04937 10.4301 5.94938 10.391 5.85617C10.3519 5.76296 10.2949 5.67837 10.2231 5.60721C10.1513 5.53606 10.0662 5.47974 9.97264 5.44147C9.8791 5.40321 9.77892 5.38374 9.67785 5.38419C9.57678 5.38463 9.47678 5.40498 9.38357 5.44407C9.29037 5.48317 9.20577 5.54023 9.13462 5.61202L5.31779 9.45817C5.17481 9.60226 5.09458 9.79702 5.09458 10C5.09458 10.203 5.17481 10.3977 5.31779 10.5418L9.13462 14.388C9.20578 14.4599 9.29042 14.517 9.3837 14.5561C9.47697 14.5952 9.57704 14.6156 9.67819 14.616C9.77934 14.6164 9.87957 14.5968 9.97315 14.5585C10.0667 14.5201 10.1518 14.4636 10.2236 14.3923Z"></path>
                                    </svg>
                                </span>
                                <span>Back</span>
                            </Link>
                    }
                </div>
            </div>
        </div>
    );
}

export default Header;