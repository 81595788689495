import React from 'react'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// import '../customdata-fields.css'

const CustomFieldTabs = (props) => {
    const { selected, onChange } = props

    return(
        <Tabs className="tab__topper" value={selected} onChange={onChange}>
            <Tab className="system__tab__button" label="Standard Fields"  />
            <Tab className="fields__tab__button" label="Custom Data Fields"  />
            <Tab className="personalized__tab__button" label="Personalized Fields" />
        </Tabs>
    )
}
export default CustomFieldTabs