import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { deleteMailBox, getMailBoxList } from "../../api/profileApi";
import { useIsMounted } from "../../hooks/IsMounted";
import Loader from "../globals/Loader";
import TablePagination from '@material-ui/core/TablePagination';
import Popup from './Popup';
import styled from "styled-components";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Utils from "../../helpers/Utils";
import { useHistory,Link } from "react-router-dom";

const EditButton  = styled.button`
    border: 1px solid #ddd;
    padding: 10px 15px;
    background: transparent;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
    margin: 0 5px;

    svg {
        margin-right: 5px;
        height: 13px !important;
        width: 13px !important;
    }

    span {
        color: var(--dark_blue);
        font-size: 16px;
        font-weight: 500;
        font-family: Poppins;
    }
`;

const MailBox = (props) => {

    const isMounted = useIsMounted();
    const history = useHistory();
    const sortBy = 'created_at';
    const sortType = 'desc';
    const userTimeZone = Utils.getAccountData('userTimezoneRaw');

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [debouncedQuery, setDebouncedQuery] = useState('');
    const [query, setQuery] = useState('');
    const [reset, setReset] = useState(0);
    const [modalVisible, setModalVisible] = useState(false);
    const [dataToEdit, setDataToEdit] = useState(null);


    useEffect(() => {
        document.title = `Mail Box | ${Utils.getAccountData('AuthUserAgencyName')}`;
        if(window.setActiveSidebar) {
            window.setActiveSidebar('setting');
        }
    },[])

    useEffect(() => {
        loadData();
    }, [currentPage, reset, perPage, sortType, debouncedQuery]); // eslint-disable-line react-hooks/exhaustive-deps


    // debouncing
    useEffect(() => {
        if (isMounted) {
            const timer = setTimeout(() => {
                setDebouncedQuery(query);
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [query]);  // eslint-disable-line react-hooks/exhaustive-deps


    const loadData = () => {
        setLoading(true);

        getMailBoxList({
            "form_data": {
                page: currentPage + 1,
                per_page: perPage,
                sort_by: sortBy,
                sort_type: sortType,
                query: debouncedQuery
            }
        })
            .then(response => {
                setData(response.data.data.data);
                setTotal(response.data.data.total);
            }).finally(() => {
            setLoading(false);
        });
    }

    const deleteItem = (_id) => {
        confirmAlert({
            title: 'Are you sure?',
            message: 'Do you want to delete this?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        setLoading(true);
                        deleteMailBox({
                            "form_data": {
                                id: _id
                            }
                        })
                            .then(response => {
                                setReset(reset + 1);
                            }).finally(() => {
                            setLoading(false);
                        });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        return;
                    }
                }
            ]
        })
    }

    const renderData = () => {
        if (loading) {
            return (
                <tr>
                    <td colspan="4">
                        <div style={{paddingTop: 50}}>
                            <Loader/>
                        </div>
                    </td>
                </tr>
            )
        } else if (!loading && !data.length) {
            return (
                <tr>
                    <td colspan="4">
                        <div style={{paddingTop: 50}}>
                            NO DATA AVAILABLE
                        </div>
                    </td>
                </tr>
            )
        } else if (!loading && data.length) {
            return data.map((item,index) => (
                <tr>
                    <td>{window.globalTimezoneConversionToDifferentTimezone(item.created_at, 'UTC',userTimeZone,'MM/DD/YYYY hh:mm a')}</td>
                    <td>{item.title ?? item.leadflow_title}</td>
                    <td>{item.sender}</td>
                    <td>{item.email_subject}</td>
                    <td>{item.status ? <div className="d-flex justify-content-center">
                        <div>
                            <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10zM6.6 9.602a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm7.8-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-4.4 7c2.72 0 4.066-2.4 4.4-3.6H5.6c.333 1.2 1.68 3.6 4.4 3.6z"
                                      fill="#27AE60"></path>
                            </svg>
                        </div>
                        <span className="text-green pl-1"> Parsed</span></div> :
                        <div className="d-flex justify-content-center">
                            <div>
                                <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M17.047 2.954A9.966 9.966 0 1 0 2.954 17.047 9.967 9.967 0 1 0 17.047 2.954zm-3.844 4.91a1.068 1.068 0 1 1 0 2.136 1.068 1.068 0 0 1 0-2.135zm-6.406 0a1.068 1.068 0 1 1 0 2.136 1.068 1.068 0 0 1 0-2.135zM10 11.425c2.02 0 3.726 1.312 4.258 3.106.045.157.104.453.104.453H5.64s.058-.298.104-.453c.524-1.795 2.233-3.106 4.256-3.106z"
                                        fill="#FF264A"></path>
                                </svg>
                            </div>
                            <span className="text-red pl-1"> Not Parsed</span></div>}</td>
                    <td>
                        <span className="action">
                            <EditButton className="edit__list modal-trigger" data-target="update_list_modal" onClick={(e) => {
                                e.preventDefault();
                                setDataToEdit(item);
                                setModalVisible(true);
                            }}>
                                <svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.08573 2.91356L13.0862 6.91425L4.39929 15.6016L0.832505 15.9953C0.355018 16.0482 -0.0484094 15.6444 0.00471426 15.1669L0.401579 11.5975L9.08573 2.91356ZM15.5606 2.31793L13.6822 0.43946C13.0962 -0.146487 12.146 -0.146487 11.56 0.43946L9.7929 2.20668L13.7934 6.20737L15.5606 4.44015C16.1465 3.85389 16.1465 2.90388 15.5606 2.31793Z" fill="#133159"></path>
                                </svg>
                                <span>Details</span>
                            </EditButton>
                            <EditButton style={{backgroundColor: 'red', color: 'white'}} className="edit__list modal-trigger" data-target="update_list_modal" onClick={(e) => {
                                e.preventDefault();
                                deleteItem(item.id);
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" height="427pt" viewBox="-40 0 427 427.00131" width="427pt"><path d="m232.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0"/><path d="m114.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0"/><path d="m28.398438 127.121094v246.378906c0 14.5625 5.339843 28.238281 14.667968 38.050781 9.285156 9.839844 22.207032 15.425781 35.730469 15.449219h189.203125c13.527344-.023438 26.449219-5.609375 35.730469-15.449219 9.328125-9.8125 14.667969-23.488281 14.667969-38.050781v-246.378906c18.542968-4.921875 30.558593-22.835938 28.078124-41.863282-2.484374-19.023437-18.691406-33.253906-37.878906-33.257812h-51.199218v-12.5c.058593-10.511719-4.097657-20.605469-11.539063-28.03125-7.441406-7.421875-17.550781-11.5546875-28.0625-11.46875h-88.796875c-10.511719-.0859375-20.621094 4.046875-28.0625 11.46875-7.441406 7.425781-11.597656 17.519531-11.539062 28.03125v12.5h-51.199219c-19.1875.003906-35.394531 14.234375-37.878907 33.257812-2.480468 19.027344 9.535157 36.941407 28.078126 41.863282zm239.601562 279.878906h-189.203125c-17.097656 0-30.398437-14.6875-30.398437-33.5v-245.5h250v245.5c0 18.8125-13.300782 33.5-30.398438 33.5zm-158.601562-367.5c-.066407-5.207031 1.980468-10.21875 5.675781-13.894531 3.691406-3.675781 8.714843-5.695313 13.925781-5.605469h88.796875c5.210937-.089844 10.234375 1.929688 13.925781 5.605469 3.695313 3.671875 5.742188 8.6875 5.675782 13.894531v12.5h-128zm-71.199219 32.5h270.398437c9.941406 0 18 8.058594 18 18s-8.058594 18-18 18h-270.398437c-9.941407 0-18-8.058594-18-18s8.058593-18 18-18zm0 0"/><path d="m173.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0"/></svg>
                                <span className={"text-white"}>Delete</span>
                            </EditButton>
                        </span>
                    </td>
                </tr>
            ))
        }
    }

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    return (
        <Fragment>
            <div className="settings_section">
                {
                    <Fragment>
                        <div className="container-fluid mail__inbox">
                            {
                                history.location.state !== undefined && history.location.state.from === 'settingPage' &&
                                <div className="back_button_to_account mt-3 ml-3 has_gap">
                                    <Link className={"accent--bg--text--color"} to="/user/settings">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 10C0 4.4774 4.4774 0 10 0C15.5226 0 20 4.4774 20 10C20 15.5226 15.5226 20 10 20C4.4774 20 0 15.5226 0 10ZM10.2236 14.3923C10.2953 14.3212 10.3523 14.2366 10.3913 14.1434C10.4304 14.0502 10.4507 13.9502 10.4511 13.8492C10.4515 13.7482 10.432 13.6481 10.3937 13.5546C10.3554 13.4611 10.299 13.376 10.2279 13.3043L7.7125 10.7692H14.1346C14.3386 10.7692 14.5343 10.6882 14.6785 10.5439C14.8228 10.3997 14.9038 10.204 14.9038 10C14.9038 9.79599 14.8228 9.60033 14.6785 9.45607C14.5343 9.31181 14.3386 9.23077 14.1346 9.23077H7.7125L10.2279 6.69567C10.299 6.62389 10.3554 6.53879 10.3936 6.44524C10.4319 6.35169 10.4514 6.25152 10.4509 6.15045C10.4505 6.04937 10.4301 5.94938 10.391 5.85617C10.3519 5.76296 10.2949 5.67837 10.2231 5.60721C10.1513 5.53606 10.0662 5.47974 9.97264 5.44147C9.8791 5.40321 9.77892 5.38374 9.67785 5.38419C9.57678 5.38463 9.47678 5.40498 9.38357 5.44407C9.29037 5.48317 9.20577 5.54023 9.13462 5.61202L5.31779 9.45817C5.17481 9.60226 5.09458 9.79702 5.09458 10C5.09458 10.203 5.17481 10.3977 5.31779 10.5418L9.13462 14.388C9.20578 14.4599 9.29042 14.517 9.3837 14.5561C9.47697 14.5952 9.57704 14.6156 9.67819 14.616C9.77934 14.6164 9.87957 14.5968 9.97315 14.5585C10.0667 14.5201 10.1518 14.4636 10.2236 14.3923Z"></path></svg>
                                        <span>Back</span>
                                    </Link></div>
                            }
                            <div className="tagBox">
                                <div className="tag_list">
                                    <h5>Mailbox <span>list</span> </h5>
                                </div>
                            </div>
                            <div className="details">
                                <div className="items">
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    </div>
                                    <div className="record_search">
                                        <input type="text" placeholder="Search Mail By Subject" className="search" onChange={(e) => setQuery(e.target.value)}/>
                                        <div className="search_icon">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.4351 10.0629H10.7124L10.4563 9.81589C11.3528 8.77301 11.8925 7.4191 11.8925 5.94625C11.8925 2.66209 9.23042 0 5.94625 0C2.66209 0 0 2.66209 0 5.94625C0 9.23042 2.66209 11.8925 5.94625 11.8925C7.4191 11.8925 8.77301 11.3528 9.81589 10.4563L10.0629 10.7124V11.4351L14.6369 16L16 14.6369L11.4351 10.0629ZM5.94625 10.0629C3.66838 10.0629 1.82962 8.22413 1.82962 5.94625C1.82962 3.66838 3.66838 1.82962 5.94625 1.82962C8.22413 1.82962 10.0629 3.66838 10.0629 5.94625C10.0629 8.22413 8.22413 10.0629 5.94625 10.0629Z" fill="#133159" fill-opacity="0.65"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <table>
                                    <thead>
                                    <tr className="header">
                                        <th style={{width:'15%'}}>
                                            Date
                                        </th>
                                        <th style={{width:'15%'}}>
                                            Campaign/Leadflow
                                        </th>
                                        <th style={{width:'15%'}}>
                                            Email
                                        </th>
                                        <th style={{width:'20%'}}>
                                            Subject
                                        </th>
                                        <th style={{width:'13%'}}>
                                            Status
                                        </th>
                                        <th style={{width:'22%'}}>
                                            Action
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody className="tbody_content">
                                    {renderData()}
                                    </tbody>
                                </table>
                            </div>
                            {
                                true && (
                                    <div className="mt-3">
                                        <TablePagination
                                            component="div"
                                            count={total}
                                            page={currentPage}
                                            onChangePage={handleChangePage}
                                            rowsPerPage={perPage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                        />
                                    </div>
                                )
                            }
                        </div>
                    </Fragment>
                }
            </div>
            {
                modalVisible && (
                    <Popup
                        hideModal={() => {
                            setModalVisible(false);
                        }}
                        title={'Email Details'}
                        data={dataToEdit}
                        isOpen={modalVisible}
                    />
                )
            }
        </Fragment>
    )
}

const mapStateToProps = state => {
    return {

    }
}
const mapDispatchToProps = dispatch => {
    return {

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MailBox);