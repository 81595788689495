import {Fragment, useEffect, useState} from "react";
import {connect} from "react-redux";
import TimePicker from 'react-times';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {Button, FormGroup} from "@material-ui/core";
import {getCutoffSettings, updateCutoffSettings} from "../../api/profileApi";
import Utils from "../../helpers/Utils";
import Loader from "../globals/Loader";
import moment from 'moment';
import 'react-times/css/material/default.css';
import TimeRange from './TimeRange';


const CutoffTime = (props) => {

    const [componentLoading, setComponentLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [cutOffStartTime, setCutOffStartTime] = useState(null);
    const [cutOffEndTime, setCutOffEndTime] = useState(null);
    const [sendFollowupOnSunday, setSendFollowupOnSunday] = useState(null);
    const [sendFollowupOnSatday, setSendFollowupOnSatday] = useState(null);

    useEffect(() => {
        loadData();
    }, [])

    const loadData = () => {
        getCutoffSettings({})
            .then(response => {
                setCutOffStartTime(response.data.data.cut_off_start_time);
                setCutOffEndTime(response.data.data.cut_off_end_time);
                setSendFollowupOnSunday(parseInt(response.data.data.send_followup_on_sunday));
                setSendFollowupOnSatday(parseInt(response.data.data.send_followup_on_satday));
            }).finally(() => {
            setComponentLoading(false);
        });
    }

    const submitData = () => {
        setLoading(true);

        updateCutoffSettings({
            "form_data": {
                cut_off_start_time: cutOffStartTime,
                cut_off_end_time: cutOffEndTime,
                bDeliverSundays: sendFollowupOnSunday,
                bDeliverSatdays: sendFollowupOnSatday
            }
        }).then((response) => {
            window.showNotification("SUCCESS", response.data.message)
        }).finally(() => {
            setLoading(false);
        })
    }

    const convertTimeFrom24HourTo12Hour = (_time) => {
        return moment(_time, 'h:mm a').format('h:mm a');
    }

    const onStartTimeChange = (options) => {
        let timeStr = options.hour + ':' + options.minute + ' ' + options.meridiem;
        setCutOffStartTime(moment(timeStr, 'h:mm a').format('H:mm'));
    }

    const onEndTimeChange = (options) => {
        let timeStr = options.hour + ':' + options.minute + ' ' + options.meridiem;
        setCutOffEndTime(moment(timeStr, 'h:mm a').format('H:mm'));
    }

    return (
        <Fragment>
            {
                (componentLoading || !props.user) ? (
                    <div style={{paddingTop: 50}}>
                        <Loader/>
                    </div>
                ) : (
                    <Fragment>
                        <div id="cut_time" class="col s12">
                            <p>You may receive leads 24/7, but that does not mean you want to respond immediately. Why?
                                Because the purpose of​​​ {props.user.agency.name} is to appear personal in your
                                communications. A voicemail, text, or email at midnight does not accomplish this. Also
                                if the lead responded, you might not be available to respond at 3am.</p>
                            <p>We highly recommend you set up times and days for your responses. Pick a start and stop
                                time so that no communications will go out before or after your cutoff.</p>
                            <p class="msg">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M10 0C4.486 0 0 4.48606 0 10.0001C0 15.5141 4.486 20 10 20C15.514 20 20 15.5141 20 10.0001C20 4.48606 15.514 0 10 0ZM10 18.1818C5.48848 18.1818 1.81818 14.5115 1.81818 10.0001C1.81818 5.48861 5.48848 1.81818 10 1.81818C14.5115 1.81818 18.1818 5.48861 18.1818 10.0001C18.1818 14.5115 14.5115 18.1818 10 18.1818Z"
                                        fill="#133159" fill-opacity="0.65"/>
                                    <path
                                        d="M10.0009 4.24219C9.3327 4.24219 8.78906 4.78619 8.78906 5.45485C8.78906 6.12292 9.3327 6.66643 10.0009 6.66643C10.6692 6.66643 11.2128 6.12292 11.2128 5.45485C11.2128 4.78619 10.6692 4.24219 10.0009 4.24219Z"
                                        fill="#133159" fill-opacity="0.65"/>
                                    <path
                                        d="M9.99893 8.48438C9.49687 8.48438 9.08984 8.89141 9.08984 9.39347V14.848C9.08984 15.3501 9.49687 15.7571 9.99893 15.7571C10.501 15.7571 10.908 15.3501 10.908 14.848V9.39347C10.908 8.89141 10.501 8.48438 9.99893 8.48438Z"
                                        fill="#133159" fill-opacity="0.65"/>
                                </svg>
                                Don't send any messages
                                before <span>{convertTimeFrom24HourTo12Hour(cutOffStartTime)}</span> , and don't send
                                any messages after <span>{convertTimeFrom24HourTo12Hour(cutOffEndTime)}</span>. This is
                                based
                                on {props.user.timezone ? Utils.generateTimezoneList(props.user.timezone) : 'Eastern Time'} Zone
                            </p>
                            <form action="#" method="POST">
                                <div class="time_input">
                                    <h5>Don't send any messages before:</h5>
                                    <div class="form_group">
                                        <TimePicker
                                            timeMode="12"
                                            onTimeChange={onStartTimeChange}
                                            time={cutOffStartTime}
                                        />
                                    </div>
                                </div>
                                <div class="time_input">
                                    <h5>Don't send any messages after:</h5>
                                    <div class="form_group">
                                        <TimePicker
                                            timeMode="12"
                                            onTimeChange={onEndTimeChange}
                                            time={cutOffEndTime}
                                        />
                                    </div>
                                </div>
                                <div className={"time_input justify-content-end"}>
                                    <div className="form_group">
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<Checkbox color={"default"} checked={sendFollowupOnSunday}
                                                                   onChange={(e) => setSendFollowupOnSunday(e.target.checked)}/>}
                                                label="Send messages on Sundays"
                                                style={{margin: 0}}
                                            />
                                            <FormControlLabel
                                                control={<Checkbox  color={"default"} checked={sendFollowupOnSatday}
                                                                   onChange={(e) => setSendFollowupOnSatday(e.target.checked)}/>}
                                                label="Send messages on Saturdays"
                                                style={{margin: 0}}
                                            />
                                        </FormGroup>
                                    </div>
                                </div>

                                <Button variant="contained" className={"accent--bg--text--color"} disabled={loading}
                                        onClick={submitData}>
                                    {!loading ? 'Save Cut Off Time' : 'Saving...'}
                                </Button>
                                <hr/>
                                <TimeRange startTime={cutOffStartTime} endTime={cutOffEndTime}/>
                            </form>
                        </div>
                    </Fragment>
                )
            }
        </Fragment>
    )
}

const mapStateToProps = state => {
    return {
        user: state.profileReducer.user
    }
}
const mapDispatchToProps = dispatch => {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(CutoffTime);