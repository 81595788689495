import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getUser } from "../../actions/profileAction";
import {
  createPowerDialerFromOutsideApi,
  deleteTag,
  getTagList,
} from "../../api/profileApi";
import { getCookie } from "../../helpers/Cookie";
import { useIsMounted } from "../../hooks/IsMounted";
import Loader from "../globals/Loader";
import TablePagination from "@material-ui/core/TablePagination";
import Popup from "./Popup";
import "./Tags.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Utils from "../../helpers/Utils";
import { useHistory, Link } from "react-router-dom";
import BootstrapTooltip from "../globals/BootstrapTooltip";
import CustomCircleLoader from "../common/Loader/CustomCircleLoader";

const Tags = (props) => {
  const history = useHistory();
  const isMounted = useIsMounted();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortType, setSortType] = useState("desc");
  const [sortBy, setSortBy] = useState("created_at");
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [query, setQuery] = useState("");
  const [reset, setReset] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [dataToEdit, setDataToEdit] = useState(null);

  /* power dialer */
  const [startingSession, setStartingSession] = useState(false);
  const [sessionTagId, setSessionTagId] = useState(null);

  useEffect(() => {
    document.title = `Tag | ${Utils.getAccountData("AuthUserAgencyName")}`;
    if (window.setActiveSidebar) {
      window.setActiveSidebar("setting");
    }

    props.getUser({
      "api-key": getCookie(process.env.REACT_APP_ACCESS_TOKEN),
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    loadData();
  }, [currentPage, reset, perPage, sortType, debouncedQuery]); // eslint-disable-line react-hooks/exhaustive-deps

  // debouncing
  useEffect(() => {
    if (isMounted) {
      const timer = setTimeout(() => {
        setDebouncedQuery(query);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadData = () => {
    setLoading(true);

    getTagList({
      form_data: {
        page: currentPage + 1,
        per_page: perPage,
        sort_by: sortBy,
        sort_type: sortType,
        query: debouncedQuery,
      },
    })
      .then((response) => {
        setData(response.data.data.data);
        setTotal(response.data.data.total);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteItem = (_id) => {
    confirmAlert({
      title: "Are you sure?",
      message: "Do you want to delete this tag?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            setLoading(true);
            deleteTag({
              form_data: {
                id: _id,
              },
            })
              .then((response) => {
                setReset(reset + 1);
              })
              .finally(() => {
                setLoading(false);
              });
          },
        },
        {
          label: "No",
          onClick: () => {
            return;
          },
        },
      ],
    });
  };

  const handleClickSessionStart = (item) => {
    // let list = {
    //     id: 244,
    //     title: 'cccccc'
    // }
    // const win = window.open(`/power-dialer?listId=${list.id}&listName=${list.title}&tags=${[item.id].join(',')}&from=tagList&tagName=${item.name}&for=startSession`, "_blank");
    // win.focus();
    // return

    // globalPowerDialerModal

    if (startingSession) {
      return;
    }
    setSessionTagId(item.id);
    setStartingSession(true);
    window.globalPowerDialerModal(true, {
      type: "single-tag",
      value: item.id,
      name: item.name,
      callback: (response, formData) => {
        if (response.success) {
          const win = window.open(
            `/power-dialer?listId=${response?.data}&listName=${
              formData.name
            }&tags=${[item.id].join(",")}&from=tagList&tagName=${
              item.name
            }&for=startSession`,
            "_blank"
          );
          win.focus();
          setStartingSession(false);
        } else {
          setStartingSession(false);
        }
        setSessionTagId(null);
      },
    });
    // createPowerDialerFromOutsideApi({
    //     "type": "tag-list",
    //     "value": item.id,
    //     "hasMultiple": false
    // })
    // .then(res => {
    //     const response = res.data;
    // if(response.status){
    //     let list = {
    //         id: 123,
    //         title: 'new list'
    //     }
    //     const win = window.open(`/power-dialer?listId=${list.id}&listName=${list.title}&tags=${[item.id].join(',')}&from=tagList&tagName=${item.name}&for=startSession`, "_blank");
    //     win.focus();
    // }
    //     else{
    //         Utils.showNotification("Can not start session");
    //     }
    //     setStartingSession(false)
    // }).catch(error => {
    //     setStartingSession(false)
    // });
  };

  const renderData = () => {
    if (loading) {
      return (
        <tr>
          <td colspan='4'>
            <div style={{ paddingTop: 50 }}>
              <Loader />
            </div>
          </td>
        </tr>
      );
    } else if (!loading && !data.length) {
      return (
        <tr>
          <td colspan='4'>
            <div style={{ paddingTop: 50 }}>NO DATA AVAILABLE</div>
          </td>
        </tr>
      );
    } else if (!loading && data.length) {
      return data.map((item, index) => (
        <tr>
          <td>{item.name}</td>
          <td>{item.total_contact}</td>
          <td>
            {window.globalTimezoneConversionToDifferentTimezone(
              item.created_at,
              "UTC",
              "",
              "MM/DD/YYYY hh:mm a"
            )}
          </td>
          <td>
            <div>
              <div
                  onClick={() => handleClickSessionStart(item)}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "3px 7px",
                    cursor: "pointer",
                    color: "#fff",
                    backgroundColor: "#6070ed",
                    float: "left",
                    borderRadius: "4px",
                    marginRight: "5px",
                  }}
              >
                {startingSession && sessionTagId == item.id && (
                    <CustomCircleLoader
                        style={{ display: "inline-flex", marginRight: "5px" }}
                        fill='#fff'
                        size='extra-small'
                    />
                )}
                {startingSession && sessionTagId == item.id
                    ? "Starting"
                    : "Start Dialing Session"}
              </div>
              <BootstrapTooltip arrow title='Edit Tag'>
                <a
                  href='#!'
                  onClick={(e) => {
                    e.preventDefault();
                    setDataToEdit(item);
                    setModalVisible(true);
                  }}
                >
                  <span>
                    <svg
                      width='30'
                      height='30'
                      viewBox='0 0 30 30'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M27.4987 5.33245L24.4154 2.24912C24.1503 1.99643 23.7982 1.85547 23.432 1.85547C23.0658 1.85547 22.7137 1.99643 22.4487 2.24912L19.707 4.99912H4.9987C4.55667 4.99912 4.13275 5.17472 3.82019 5.48728C3.50763 5.79984 3.33203 6.22376 3.33203 6.66579V24.9991C3.33203 25.4412 3.50763 25.8651 3.82019 26.1776C4.13275 26.4902 4.55667 26.6658 4.9987 26.6658H23.332C23.7741 26.6658 24.198 26.4902 24.5105 26.1776C24.8231 25.8651 24.9987 25.4412 24.9987 24.9991V9.79912L27.4987 7.29912C27.7592 7.03819 27.9056 6.68453 27.9056 6.31579C27.9056 5.94705 27.7592 5.59338 27.4987 5.33245ZM15.6904 16.7741L12.1987 17.5491L13.032 14.0908L20.9904 6.11579L23.682 8.80745L15.6904 16.7741ZM24.582 7.85745L21.8904 5.16579L23.432 3.62412L26.1237 6.31579L24.582 7.85745Z'
                        fill='#133159'
                        fill-opacity='0.5'
                      ></path>
                    </svg>
                  </span>
                </a>
              </BootstrapTooltip>
              <BootstrapTooltip arrow title='Delete Tag'>
                <a
                  href='#!'
                  onClick={(e) => {
                    e.preventDefault();
                    deleteItem(item.id);
                  }}
                >
                  <span>
                    <svg
                      width='25'
                      height='26'
                      viewBox='0 0 25 26'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M3.125 8.3125L4.57715 22.1069C4.60744 22.3949 4.74329 22.6615 4.95851 22.8553C5.17374 23.0491 5.45308 23.1563 5.74268 23.1562H19.2573C19.5469 23.1563 19.8263 23.0491 20.0415 22.8553C20.2567 22.6615 20.3926 22.3949 20.4229 22.1069L21.875 8.3125H3.125ZM15.2344 18.9307L12.5 16.1963L9.76562 18.9307L8.52246 17.6875L11.2568 14.9531L8.52246 12.2188L9.76562 10.9756L12.5 13.71L15.2344 10.9756L16.4775 12.2188L13.7432 14.9531L16.4775 17.6875L15.2344 18.9307Z'
                        fill='#FF264A'
                      ></path>
                      <path
                        d='M22.8516 2.84375H2.14844C1.82483 2.84375 1.5625 3.10608 1.5625 3.42969V6.16406C1.5625 6.48767 1.82483 6.75 2.14844 6.75H22.8516C23.1752 6.75 23.4375 6.48767 23.4375 6.16406V3.42969C23.4375 3.10608 23.1752 2.84375 22.8516 2.84375Z'
                        fill='#FF264A'
                      ></path>
                    </svg>
                  </span>
                </a>
              </BootstrapTooltip>
            </div>
          </td>
        </tr>
      ));
    }
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  return (
    <Fragment>
      <div className='settings_section'>
        {!props.user ? (
          <div style={{ paddingTop: 100 }}>
            <Loader />
          </div>
        ) : (
          <Fragment>
            <div className='container-fluid mail__inbox mt-1'>
              <div className='tagBox'>
                <div className='tag_list'>
                  <h5>Tags </h5>
                </div>
                <div className='add_new_user'>
                  <a
                    href='#!'
                    className='modal-trigger accent--bg--text--color'
                    onClick={(e) => {
                      e.preventDefault();
                      setModalVisible(true);
                    }}
                  >
                    <span>
                      <svg
                        width='20'
                        height='20'
                        viewBox='0 0 20 20'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <circle cx='10' cy='10' r='10'></circle>
                        <path
                          d='M13.8447 9.9985C13.8447 10.353 13.8139 10.6395 13.4594 10.6395H10.6395V13.4594C10.6395 13.8132 10.353 13.8447 9.9985 13.8447C9.64401 13.8447 9.35747 13.8132 9.35747 13.4594V10.6395H6.5376C6.18375 10.6395 6.15234 10.353 6.15234 9.9985C6.15234 9.64401 6.18375 9.35747 6.5376 9.35747H9.35747V6.5376C9.35747 6.18311 9.64401 6.15234 9.9985 6.15234C10.353 6.15234 10.6395 6.18311 10.6395 6.5376V9.35747H13.4594C13.8139 9.35747 13.8447 9.64401 13.8447 9.9985Z'
                          className={"accent--fill--color"}
                        ></path>
                      </svg>
                    </span>
                    Create Tag
                  </a>
                  {history.location.state !== undefined &&
                    history.location.state.from === "accountPage" && (
                      <Link
                        className={"modal-trigger accent--bg--text--color"}
                        to='/user/profile'
                      >
                        <span>
                          <svg
                            width='20'
                            height='20'
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path d='M0 10C0 4.4774 4.4774 0 10 0C15.5226 0 20 4.4774 20 10C20 15.5226 15.5226 20 10 20C4.4774 20 0 15.5226 0 10ZM10.2236 14.3923C10.2953 14.3212 10.3523 14.2366 10.3913 14.1434C10.4304 14.0502 10.4507 13.9502 10.4511 13.8492C10.4515 13.7482 10.432 13.6481 10.3937 13.5546C10.3554 13.4611 10.299 13.376 10.2279 13.3043L7.7125 10.7692H14.1346C14.3386 10.7692 14.5343 10.6882 14.6785 10.5439C14.8228 10.3997 14.9038 10.204 14.9038 10C14.9038 9.79599 14.8228 9.60033 14.6785 9.45607C14.5343 9.31181 14.3386 9.23077 14.1346 9.23077H7.7125L10.2279 6.69567C10.299 6.62389 10.3554 6.53879 10.3936 6.44524C10.4319 6.35169 10.4514 6.25152 10.4509 6.15045C10.4505 6.04937 10.4301 5.94938 10.391 5.85617C10.3519 5.76296 10.2949 5.67837 10.2231 5.60721C10.1513 5.53606 10.0662 5.47974 9.97264 5.44147C9.8791 5.40321 9.77892 5.38374 9.67785 5.38419C9.57678 5.38463 9.47678 5.40498 9.38357 5.44407C9.29037 5.48317 9.20577 5.54023 9.13462 5.61202L5.31779 9.45817C5.17481 9.60226 5.09458 9.79702 5.09458 10C5.09458 10.203 5.17481 10.3977 5.31779 10.5418L9.13462 14.388C9.20578 14.4599 9.29042 14.517 9.3837 14.5561C9.47697 14.5952 9.57704 14.6156 9.67819 14.616C9.77934 14.6164 9.87957 14.5968 9.97315 14.5585C10.0667 14.5201 10.1518 14.4636 10.2236 14.3923Z'></path>
                          </svg>
                        </span>
                        <span>Back</span>
                      </Link>
                    )}

                  {history.location.state !== undefined &&
                    history.location.state.from === "contactListPage" && (
                      <Link
                        className={"modal-trigger accent--bg--text--color"}
                        to='/contacts'
                      >
                        <span>
                          <svg
                            width='20'
                            height='20'
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path d='M0 10C0 4.4774 4.4774 0 10 0C15.5226 0 20 4.4774 20 10C20 15.5226 15.5226 20 10 20C4.4774 20 0 15.5226 0 10ZM10.2236 14.3923C10.2953 14.3212 10.3523 14.2366 10.3913 14.1434C10.4304 14.0502 10.4507 13.9502 10.4511 13.8492C10.4515 13.7482 10.432 13.6481 10.3937 13.5546C10.3554 13.4611 10.299 13.376 10.2279 13.3043L7.7125 10.7692H14.1346C14.3386 10.7692 14.5343 10.6882 14.6785 10.5439C14.8228 10.3997 14.9038 10.204 14.9038 10C14.9038 9.79599 14.8228 9.60033 14.6785 9.45607C14.5343 9.31181 14.3386 9.23077 14.1346 9.23077H7.7125L10.2279 6.69567C10.299 6.62389 10.3554 6.53879 10.3936 6.44524C10.4319 6.35169 10.4514 6.25152 10.4509 6.15045C10.4505 6.04937 10.4301 5.94938 10.391 5.85617C10.3519 5.76296 10.2949 5.67837 10.2231 5.60721C10.1513 5.53606 10.0662 5.47974 9.97264 5.44147C9.8791 5.40321 9.77892 5.38374 9.67785 5.38419C9.57678 5.38463 9.47678 5.40498 9.38357 5.44407C9.29037 5.48317 9.20577 5.54023 9.13462 5.61202L5.31779 9.45817C5.17481 9.60226 5.09458 9.79702 5.09458 10C5.09458 10.203 5.17481 10.3977 5.31779 10.5418L9.13462 14.388C9.20578 14.4599 9.29042 14.517 9.3837 14.5561C9.47697 14.5952 9.57704 14.6156 9.67819 14.616C9.77934 14.6164 9.87957 14.5968 9.97315 14.5585C10.0667 14.5201 10.1518 14.4636 10.2236 14.3923Z'></path>
                          </svg>
                        </span>
                        <span>Back</span>
                      </Link>
                    )}

                  {history.location.state !== undefined &&
                    history.location.state.from === "settingPage" && (
                      <Link
                        className={"modal-trigger accent--bg--text--color"}
                        to='/user/settings'
                      >
                        <span>
                          <svg
                            width='20'
                            height='20'
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path d='M0 10C0 4.4774 4.4774 0 10 0C15.5226 0 20 4.4774 20 10C20 15.5226 15.5226 20 10 20C4.4774 20 0 15.5226 0 10ZM10.2236 14.3923C10.2953 14.3212 10.3523 14.2366 10.3913 14.1434C10.4304 14.0502 10.4507 13.9502 10.4511 13.8492C10.4515 13.7482 10.432 13.6481 10.3937 13.5546C10.3554 13.4611 10.299 13.376 10.2279 13.3043L7.7125 10.7692H14.1346C14.3386 10.7692 14.5343 10.6882 14.6785 10.5439C14.8228 10.3997 14.9038 10.204 14.9038 10C14.9038 9.79599 14.8228 9.60033 14.6785 9.45607C14.5343 9.31181 14.3386 9.23077 14.1346 9.23077H7.7125L10.2279 6.69567C10.299 6.62389 10.3554 6.53879 10.3936 6.44524C10.4319 6.35169 10.4514 6.25152 10.4509 6.15045C10.4505 6.04937 10.4301 5.94938 10.391 5.85617C10.3519 5.76296 10.2949 5.67837 10.2231 5.60721C10.1513 5.53606 10.0662 5.47974 9.97264 5.44147C9.8791 5.40321 9.77892 5.38374 9.67785 5.38419C9.57678 5.38463 9.47678 5.40498 9.38357 5.44407C9.29037 5.48317 9.20577 5.54023 9.13462 5.61202L5.31779 9.45817C5.17481 9.60226 5.09458 9.79702 5.09458 10C5.09458 10.203 5.17481 10.3977 5.31779 10.5418L9.13462 14.388C9.20578 14.4599 9.29042 14.517 9.3837 14.5561C9.47697 14.5952 9.57704 14.6156 9.67819 14.616C9.77934 14.6164 9.87957 14.5968 9.97315 14.5585C10.0667 14.5201 10.1518 14.4636 10.2236 14.3923Z'></path>
                          </svg>
                        </span>
                        <span>Back</span>
                      </Link>
                    )}
                </div>
              </div>
              <div className='details mb-5'>
                <div className='items'>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  ></div>
                  <div className='record_search'>
                    <input
                      type='text'
                      placeholder='Search tag'
                      className='search'
                      onChange={(e) => setQuery(e.target.value)}
                    />
                    <div className='search_icon'>
                      <svg
                        width='16'
                        height='16'
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M11.4351 10.0629H10.7124L10.4563 9.81589C11.3528 8.77301 11.8925 7.4191 11.8925 5.94625C11.8925 2.66209 9.23042 0 5.94625 0C2.66209 0 0 2.66209 0 5.94625C0 9.23042 2.66209 11.8925 5.94625 11.8925C7.4191 11.8925 8.77301 11.3528 9.81589 10.4563L10.0629 10.7124V11.4351L14.6369 16L16 14.6369L11.4351 10.0629ZM5.94625 10.0629C3.66838 10.0629 1.82962 8.22413 1.82962 5.94625C1.82962 3.66838 3.66838 1.82962 5.94625 1.82962C8.22413 1.82962 10.0629 3.66838 10.0629 5.94625C10.0629 8.22413 8.22413 10.0629 5.94625 10.0629Z'
                          fill='#133159'
                          fill-opacity='0.65'
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>
                <table>
                  <thead>
                    <tr className='header'>
                      <th>
                        Name
                        <svg
                          onClick={() => {
                            setSortBy("name");
                            if (sortType === "asc") {
                              setSortType("desc");
                            } else {
                              setSortType("asc");
                            }
                          }}
                          style={{ cursor: "pointer", float: "right" }}
                          width='11'
                          height='18'
                          viewBox='0 0 11 18'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M0 11.5L5.14286 17.5L10.2857 11.5H0Z'
                            fill='#546376'
                          ></path>
                          <path
                            d='M10.2852 6.5L5.1423 0.5L-0.000557899 6.5L10.2852 6.5Z'
                            fill='#546376'
                          ></path>
                        </svg>
                      </th>
                      <th>
                        Total Contacts
                        <svg
                          onClick={() => {
                            setSortBy("total_contact");
                            if (sortType === "asc") {
                              setSortType("desc");
                            } else {
                              setSortType("asc");
                            }
                          }}
                          style={{ cursor: "pointer", float: "right" }}
                          width='11'
                          height='18'
                          viewBox='0 0 11 18'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M0 11.5L5.14286 17.5L10.2857 11.5H0Z'
                            fill='#546376'
                          ></path>
                          <path
                            d='M10.2852 6.5L5.1423 0.5L-0.000557899 6.5L10.2852 6.5Z'
                            fill='#546376'
                          ></path>
                        </svg>
                      </th>
                      <th>
                        Created
                        <svg
                          onClick={() => {
                            setSortBy("created_at");
                            if (sortType === "asc") {
                              setSortType("desc");
                            } else {
                              setSortType("asc");
                            }
                          }}
                          style={{ cursor: "pointer", float: "right" }}
                          width='11'
                          height='18'
                          viewBox='0 0 11 18'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M0 11.5L5.14286 17.5L10.2857 11.5H0Z'
                            fill='#546376'
                          ></path>
                          <path
                            d='M10.2852 6.5L5.1423 0.5L-0.000557899 6.5L10.2852 6.5Z'
                            fill='#546376'
                          ></path>
                        </svg>
                      </th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody className='tbody_content'>{renderData()}</tbody>
                </table>
              </div>
              {true && (
                <div className='mt-3' style={{bottom:0, position:"fixed", background: "white"}}>
                  <TablePagination
                    component='div'
                    count={total}
                    page={currentPage}
                    onChangePage={handleChangePage}
                    rowsPerPage={perPage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </div>
              )}
            </div>
          </Fragment>
        )}
      </div>
      {modalVisible && (
        <Popup
          hideModal={() => {
            setReset(reset + 1);
            setDataToEdit(null);
            setModalVisible(false);
          }}
          isOpen={modalVisible}
          title={"Tag"}
          user={props.user}
          data={dataToEdit}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.profileReducer.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUser: (params) => dispatch(getUser(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Tags);
