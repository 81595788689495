import React, {useState} from "react";
import Header from "./Header";
import "../leadSource/lead_source.css";
import { InvoiceProvider } from "./InvoiceProvider";
import InvoiceTable from "./InvoiceTable";

const App = () => {

    return (
        <InvoiceProvider>
            <div className="container-fluid mail__inbox">
                <Header/>
                <div className="details">
                    <InvoiceTable/>
                </div>
            </div>
        </InvoiceProvider>
    );
};

export default App;