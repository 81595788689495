import { sendRequest } from './rootApi';
import Api from "../constants/Api";

export const getNotifications = async (payload) => {
    return await sendRequest("post", Api.getNotifications,payload);
}

export const getLeadNotifications = async (payload) => {
    return await sendRequest("post", Api.getLeadNotifications,payload);
}

export const changeNotificationStatus = async (payload) => {
    return await sendRequest("post", Api.changeNotificationStatus,payload);
}

export const changeLeadNotificationStatus = async (payload) => {
    return await sendRequest("post", Api.changeLeadNotificationStatus,payload);
}

export const removeNotification = async (payload) => {
    return await sendRequest("post", Api.removeNotification,payload);
}

export const setMarkAllAsSeen = async (payload) => {
    return await sendRequest("post", Api.markAllAsSeen,payload);
}

export const setLeadsMarkAllAsSeen = async (payload) => {
    return await sendRequest("post", Api.leadMarkAllAsSeen,payload);
}

