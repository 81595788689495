import {createContext, useReducer} from "react";
import ActionType from "./ActionTypes";

export const ViewControlContext = createContext({});

const viewControlStates = {
    isLoading: false,
    viewControls: []
};


const viewControlReducer = (state,action) => {
    switch (action.type){
        case ActionType.UPDATE_STATE:
            return { ...state, ...action.payload };
        case ActionType.UPDATE_INDEX_VALUE:
            let newData = [...state.viewControls];
            let newDataIndex = state.viewControls[action.payload.index];
            newData[action.payload.index] = { ...newDataIndex, ...action.payload.data };
            return { ...state, viewControls: newData }
        default:
            return state;
    }
};

export const ViewControlProvider = ({children}) => {
    const [state, dispatch] = useReducer(viewControlReducer, viewControlStates);
    return (
        <ViewControlContext.Provider value={{state, dispatch}}>
            {children}
        </ViewControlContext.Provider>
    )
};