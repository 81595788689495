import { Fragment, useEffect, useState } from "react"
import GlobalModal from "../../components/globals/Modal/GlobalModal";


const Popup = (props) => {
    
    const [sender, setSender] = useState('');
    const [subject, setSubject] = useState('');
    const [body, setBody] = useState('');

    useEffect(() => {
        if (props.data) {
            setSender(props.data.sender);
            setSubject(props.data.email_subject);
            setBody(props.data.email_body);
        }
    }, [props.data])

    const toggle = () => {
        props.hideModal();
    }

    return (
        <Fragment>
            <GlobalModal
                open={props.isOpen}
                onClose={() => toggle()}
                title={props.title}
                hideFooter={true}
                >
                <div className="modal_body">
                    <div className="input-group">
                        <label htmlFor="">Sender</label>
                        <input className="modal_input" type="text" placeholder="Tag Name"
                               value={sender} readOnly={true}/>
                    </div>
                    <div className="input-group">
                        <label htmlFor="">Email Subject</label>
                        <input className="modal_input" type="text" placeholder="Tag Name"
                               value={subject} readOnly={true}/>
                    </div>
                    <div className="input-group">
                        <label htmlFor="">Email Body</label>
                        <div>
                            <span className="__message_text" dangerouslySetInnerHTML={{__html: body}}/>
                        </div>

                    </div>
                </div>
            </GlobalModal>
        </Fragment>
    )
}

export default Popup;