import React, {useEffect, useState} from "react";
import Styles from "./Styles.module.css";
import PhoneSettings from "../phoneSettings/PhoneSettings";
import TrustCenterV2 from "../trustCenterV2/TrustCenterV2";
import {useHistory, useLocation} from "react-router-dom";
import ShakenStirCnam from "../shakenStirCnam/ShakenStirCnam";
import {getA2pTrustCenterStatusApi, getUserA2PRegistrationTypeApi} from "../../api/profileApi";

const telephonyTabs = {
    PHONE_NUMBERS: "/user/telephony-service/phone-settings",
    TRUST_CENTER: "/user/telephony-service/trust-center",
    SHAKEN_STIR_CNAM: "/user/telephony-service/shaken-stir-cnam"
};

const tabs = [
    {
        code: telephonyTabs.PHONE_NUMBERS,
        label: "Phone Numbers",
    },
];

const TelephonyService = () => {
    const history = useHistory();
    const location = useLocation();
    const activeTab = location.pathname;
    const [loading, setLoading] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [a2pRegistration, setA2pRegistration] = useState(null);
    const [campaignUseCase, setCampaignUseCase] = useState(null);
    const [registrationType, setRegistrationType] = useState(null);

    useEffect(() => {
        geta2pInfos();
    }, []);

    const geta2pInfos = () => {
        getA2pTrustCenterStatusApi()
            .then((res) => {
                res = res.data;
                if (res.success) {
                    if (res.data && res.data.a2pRegistration) {
                        setA2pRegistration(res.data.a2pRegistration);
                    }
                    if (res.data && res.data.campaignUseCase) {
                        setCampaignUseCase(res.data.campaignUseCase);
                    }

                    tabs[1] = {
                        code: telephonyTabs.TRUST_CENTER,
                        label: "Trust Center",
                    };

                    tabs[2] = {
                        code: telephonyTabs.SHAKEN_STIR_CNAM,
                        label: "Shaken/Stir & CNAM",
                    };
                }else {
                    tabs[1] = {
                        code: telephonyTabs.SHAKEN_STIR_CNAM,
                        label: "Shaken/Stir & CNAM",
                    };
                }
            })
            .catch((err) => {
                setA2pRegistration(null);
                setCampaignUseCase(null);
            })
            .finally(() => {
                setLoading(false);
                setLoaded(true);
            });

        getUserA2PRegistrationTypeApi()
            .then((res)=>{
                res = res.data;
                if (res && res.success && res.userA2pRegistrationType) {
                    setRegistrationType(res.userA2pRegistrationType);
                }
            })
            .catch((err) => {
                setRegistrationType(null);
            })
            .finally(() => {
                setLoading(false);
            });
    };


    const getTabs = () => {
        switch (activeTab){
            case telephonyTabs.PHONE_NUMBERS:
                return <PhoneSettings
                    registrationType={registrationType}
                />;
            case telephonyTabs.TRUST_CENTER:
                return <TrustCenterV2
                    a2pRegistration={a2pRegistration}
                    campaignUseCase={campaignUseCase}
                    loading={loading}
                    geta2pInfos={geta2pInfos}
                    loaded={loaded}
                />;
            case telephonyTabs.SHAKEN_STIR_CNAM:
                return <ShakenStirCnam
                    registrationType={registrationType}
                />;
            default:
                return <PhoneSettings
                    registrationType={registrationType}
                />;
        }
    };

    return(
        <div className={Styles.pageWrapper}>
            <div className={Styles.pageHeader}>
                <div className={Styles.pageHeaderLeft}>
                    <div className={Styles.pageTab}>
                        {
                            tabs.map((tab)=>(
                                <div className={`${Styles.pageTabItem} ${tab.code === activeTab ? Styles.activeTab : ""}`} onClick={()=>{history.push(tab.code)}}>
                                    {tab.label}
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className={Styles.pageBody}>
                    {getTabs()}
                </div>
            </div>
        </div>
    );
};

export default TelephonyService;
