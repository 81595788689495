import React from "react";
import TelegramIcon from '@material-ui/icons/Telegram';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import VideocamIcon from '@material-ui/icons/Videocam';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import VoicemailIcon from '@material-ui/icons/Voicemail';

const StepRight = ({step,title,content="",img}) => {
    return(
        <div className="geo__step__right__wr">
            <div className="geo__step__right__left">
                <img src={img}/>
            </div>
            <div className="geo__step__right__right">
                <h2 className="geo__step__right__right__step">{step}</h2>
                <h3 className="geo__step__right__right__title">{title}</h3>
                <p className="geo__step__right__right__content">{content}</p>
                
                <div className="geo__marketing__social">
                    <div className="geo__marketing__social__single">
                        {/* <TelegramIcon/><span>Name</span> */}
                        <ol style={{listStyle : 'disc'}}>
                            <li style={{fontSize: '20px'}}>Name</li>
                            <li style={{fontSize: '20px'}}>Phone</li>
                            <li style={{fontSize: '20px'}}>Email</li>
                            <li style={{fontSize: '20px'}}>Income Range</li>
                            <li style={{fontSize: '20px'}}>Home Value</li>
                            <li style={{fontSize: '20px'}}>Marital Status</li>
                            <li style={{fontSize: '20px'}}>Credit Score Range</li>
                            <li style={{fontSize: '20px'}}>And Much More…</li>
                        </ol>
                    </div>
                    {/* <div className="geo__marketing__social__single">
                        <AllInboxIcon/><span>Phone</span>
                    </div>
                    <div className="geo__marketing__social__single">
                        <VideocamIcon/><span>Email</span>
                    </div>
                    <div className="geo__marketing__social__single">
                        <ChatBubbleOutlineIcon/><span>Income Range</span>
                    </div>
                    <div className="geo__marketing__social__single">
                        <VoicemailIcon/><span>Home Value</span>
                    </div>
                    <div className="geo__marketing__social__single">
                        <VoicemailIcon/><span>Marital Status</span>
                    </div>
                    <div className="geo__marketing__social__single">
                        <VoicemailIcon/><span>Credit Score Range</span>
                    </div>
                    <div className="geo__marketing__social__single">
                        <VoicemailIcon/><span>And Much More…</span>
                    </div> */}

                </div>
            </div>
          
        </div>
    )
}


export default StepRight;