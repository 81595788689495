import { Box, Button, CircularProgress, Typography } from "@material-ui/core";
import React, { createContext, useEffect, useReducer,useState } from "react";
import { fetchUser, submitMapIntegrationApi } from "../../api/profileApi";
import Utils from "../../helpers/Utils";
import moment from "moment";

import CreditCard from "./CreditCard";
import { CheckCircle, } from "@material-ui/icons";

export const mapCardContext = createContext();

const CardBox = (props) => {

  const reducerFunction = (state, data) => {

    switch (data.type) {

      case 'updateDataList':
        return { ...state, ...data.payload }
      default:
        return data.payload;
    }
  }
  const [dataInformation, dispatchDataInformation] = useReducer(reducerFunction,
    {
      'userNumber': '', 'cardNumber': '', 'cvc': '', 'focus': '', 'cardMaxLength': '16','expiry':'',
      'cardmaxLength':'16','submitting':false, 'submitModal': true,'focusUpdate':'',
      'selectedPackage': null
    })

  const [focusUpdate,setFocusUpdate] = useState('');
  useEffect(() => {

    if(dataInformation.userNumber === ''){
      try {
        var number = Utils.getAccountData('phone_number');
        if (number !== undefined && number !== null && number !== "") {
          updateReducerInformation(number,'userNumber')
        }
        else {
          fetchUser().then(res => {
            let response = res.data
            updateReducerInformation(response.phone,'userNumber')
  
          })
        }
      } catch (error) {
        
      }
    }
    
  }, [])

  const updateReducerInformation = (value, key) => {
    if(key === 'expiry'){
      
      expiryCheck(value,key)
    }else if(key=== 'cardNumber'){
      checkCardNumber(value,key)
    }else if (key=== 'cvc'){
      checkCvc(value,key)
    }
    else {
      dispatchDataInformation({ 'type': 'updateDataList', 'payload': { [key]: value } })

    }
  }

  const checkCardNumber = (value, key) => {

    value = Utils.returnFilteredText(value, 'number')
    let length = value.length;
    if (length <= dataInformation.cardmaxLength) {
      if (length <= 4) {
        dispatchDataInformation({ 'type': 'updateDataList', 'payload': { [key]: value } })

      } else if (length <= 8) {
        dispatchDataInformation({ 'type': 'updateDataList', 'payload': { [key]: value.substr(0, 4) + ' ' + value.substr(4, 4) } })

      }
      else if (length <= 12) {
        dispatchDataInformation({ 'type': 'updateDataList', 'payload': { [key]: value.substr(0, 4) + ' ' + value.substr(4, 4) + ' ' + value.substr(8, 4) } })

      } else if(length === 16){
        
        dispatchDataInformation({ 'type': 'updateDataList', 'payload': { [key]: value.substr(0, 4) + ' ' + value.substr(4, 4) + ' ' + value.substr(8, 4) + ' ' + value.substr(12) } })
        setFocusUpdate('expiryRef')
      }else{
        dispatchDataInformation({ 'type': 'updateDataList', 'payload': { [key]: value.substr(0, 4) + ' ' + value.substr(4, 4) + ' ' + value.substr(8, 4) + ' ' + value.substr(12) } })

      }
      
    }

  }

  const checkCvc=(value, key)=>{
    value= Utils.returnFilteredText(value,'number')
    if(value.length <= 4){
      dispatchDataInformation({ 'type': 'updateDataList', 'payload': { [key]: value } })

    }
  }

  const expiryCheck=(value,key)=>{
    value= Utils.returnFilteredText(value,'number')
    if(value.length <= 2){
        
          if(value > 12){
            dispatchDataInformation({ 'type': 'updateDataList', 'payload': { [key]: 12+'/' } })
          }
          else{
            if(value.length === 1){
              dispatchDataInformation({ 'type': 'updateDataList', 'payload': { [key]: value } })

            }else{
              dispatchDataInformation({ 'type': 'updateDataList', 'payload': { [key]: value+'/' } })

            }
          }
        
      }else if(value.length === 3){
        dispatchDataInformation({ 'type': 'updateDataList', 'payload': { [key]: value[0]+value[1]+'/'+value[2] } })

      }else if(value.length === 4){
          setFocusUpdate('cvcRef')
          dispatchDataInformation({ 'type': 'updateDataList', 'payload': { [key]: value[0]+value[1]+'/'+value[2]+value[3] } })
        
      }

    
  }

  const handleSubmit = () => {
    if(dataInformation.submitting){
        return
    }
    if(props.packageInformation.packageLoading){
      window.showNotification('Geofarming package updating...')
    }
    updateReducerInformation(true,'submitting');

    let splitExpiry = dataInformation.expiry.split('/');
    let month = splitExpiry[0];
    let year = splitExpiry[1]
    /* check validation */
    if(dataInformation.cardNumber.trim() === "" || month?.trim() === "" || year?.trim() === "" || dataInformation.cvc.trim() === "" || props.packageInformation.selectedPackage == null){
        window.showNotification("WARNING", "Fill up the form correctly. Every field is required");
        updateReducerInformation(false,'submitting');
        return;
    }

    if(month === 0 || month === "00" || month === "0"){
        window.showNotification("WARNING", "Month is wrong !");
        updateReducerInformation(false,'submitting');
        return;
    }

    if(year.trim().length < 2){
        window.showNotification("WARNING", "Year is wrong !");
        updateReducerInformation(false,'submitting');
        return;
    }

    if(dataInformation.cvc.trim().length < 3 || dataInformation.cvc.trim().length >4){
      updateReducerInformation(false,'submitting');
        window.showNotification("WARNING", "CVC is wrong !");
        return;
    }

    let fullName = Utils.getAccountData('fullName');
    let names = fullName.split(' ', 1)
    let firstName = names[0];
    let lastName = fullName.replace(firstName, '')

    let form_data = {
        'email' : Utils.getAccountData('email'),
        'phone' : dataInformation.userNumber,
        'first_name' : firstName.trim(),
        'last_name' : lastName != null ? lastName.trim() : ' ',
        'number' : dataInformation.cardNumber.replace(/\s/g,''),
        'exp_month' : month,
        'exp_year' : year,
        'cvc' : dataInformation.cvc,
        'agency_id' : Utils.getAccountData('agencyId'),
        'from' : 1,
        'user_id' : Utils.getAccountData('userId'),
        'package_id' : props.packageInformation.selectedPackage.package_id,
        'additional_charge' : parseFloat(props.packageInformation?.additionalCharge),
        'total_usages' : parseInt(props.packageInformation.selectedPackage?.usage_limit),
        'total_users' : parseInt(props.packageInformation.selectedPackage?.max_user_count)
    }

    submitMapIntegrationApi(form_data).then(res => {
        let response = res.data;
        if(response.status){

            if(response.html === 'User already exists!'){
              let info = response.data;
              if(info.renew_date && info.renew_date.date){
                info.renew_date = moment(info.renew_date.date).format('MM-DD-YYYY')
              }
              props.updateReducerInformations({'submitModal':'success','hasPackage':true,'packageInfo': info,'message':
              response.html})

            }else{
              let info = response.data;
              let successMessage = <>The payment of <b>${ (parseFloat(props.packageInformation.selectedPackage.subscription_fee) + parseFloat(props.packageInformation.additionalCharge)).toFixed(2)}</b> is successful.<br />Transaction Id : <b>{info.transaction_id}</b></>
              if(info.renew_date && info.renew_date.date){
                info.renew_date = moment(info.renew_date.date).format('MM-DD-YYYY')
              }
              props.updateReducerInformations({'submitModal':'success','hasPackage':true,'packageInfo': info,'message': successMessage,'transactionId' : info.transaction_id
              })

            }

            sessionStorage.setItem('mapPackage'+Utils.getAccountData('userId'),'true');
            props.getTeamList();
        }else{
            if(response.data === "Can not create stripe token !"){
              window.showNotification("ERROR", "We aren't able to process your payment with this card. Please check your card.");
            }else{
              if(response.data !== undefined && response.data !== null){
                if(response.data.html !== undefined && response.data.html !== null){
                    window.showNotification("ERROR", response.data.html);
                }else{
                    window.showNotification("ERROR", "Something went wrong !");
                }
              }else{
                window.showNotification("ERROR", "Something went wrong !");
              }
            }
            props.dispatchPackageInformation({'type':'updateDataList','payload': {'submitModal':'error',message: 
            'We aren\'t able to process your payment. Your Balance Low . Please Try Again'}})
        }
        updateReducerInformation(false,'submitting');
      }).catch(error => {
        updateReducerInformation(false,'submitting');
      })
}

const contextValue = {
  dataInformation: dataInformation,
  handleSubmit: handleSubmit,
  updateReducerInformation: updateReducerInformation,
}
  return (

    <mapCardContext.Provider value={contextValue}>

    <Box className="cardBox__wrapper">
      <Box>
        <Typography className="title__h6">
          <strong>Name:</strong> {Utils.getAccountData('fullName')}
        </Typography>

        <Typography className="title__h6">
          <strong>Email: </strong> {Utils.getAccountData('email')}
        </Typography>

        <Typography className="title__h6">
          <strong>Phone:</strong> {dataInformation.userNumber}
        </Typography>
      </Box>

      <Box className="cardBox__body">
        <CreditCard setFocusUpdate={setFocusUpdate} focusUpdate={focusUpdate}/>
      </Box>

      <Button
        variant="contained"
        color="primary"
        className="pay__btn alt"
        startIcon={dataInformation.submitting ? <CircularProgress /> : <CheckCircle />}
        onClick={()=>handleSubmit()}
      >
        {dataInformation.submitting ? 'Purchasing...'  : 'Pay And Use' }
      </Button>

    </Box>
    </mapCardContext.Provider>

  );
}

export default CardBox;
