import LeftSideApp from './left-side/LeftSideApp';
import Styles from './style.module.css'
import './style.css'
import RightSideApp from './right-side/RightSideApp';
import { CustomFieldProvider } from './reducer/CustomProvider';

const CustomFieldV2App = (props) => {
  return (
    <CustomFieldProvider>
      <div className={Styles.componentMainWrp}>
        <div className={Styles.leftSideWrp}>
          <LeftSideApp />
        </div>
        <div className={Styles.rightSideWrp}>
          <RightSideApp />
        </div>
      </div>
    </CustomFieldProvider>
  );
}
export default CustomFieldV2App;
export const updateLocalStorage = () => {
  try{
    let local_data = localStorage.getItem("global__custom__field__data");
    if(local_data){
      localStorage.removeItem("global__custom__field__data")
    }
  }catch(error){}
}