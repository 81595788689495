import React from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { IconList } from '../../constants/IconList';
import { getContactFormFields } from '../../constants/CoreConstants';


const SystemDefineField = props => {

    return(
        <TableContainer>
            <Table className="system__table">
            <TableHead>
                <TableRow>
                    <TableCell className="systemtable__th1 table__thead__th">
                        <span className="label__name1">Filed Label</span> 
                        <span className="lable__icon1">{IconList.info}</span>     
                    </TableCell>
                    <TableCell className="systemtable__th2 table__thead__th">Type	</TableCell>
                    <TableCell className="systemtable__th3 table__thead__th">
                        <span className="label__name1">Personalize Tag</span>
                        <span className="lable__icon1">{IconList.info}</span>
                    </TableCell>
                    <TableCell className="systemtable__th4 table__thead__th">Maximum Character Allowed</TableCell>
                    <TableCell className="systemtable__th5 table__thead__th">Format</TableCell>
                </TableRow>
            </TableHead>
    
            <TableBody>
                {getContactFormFields.map((item, index)=>(
                <TableRow key={index}>
                    <TableCell className="table__body__td">{item.label}</TableCell>
                    <TableCell className="table__body__td">{item.type}</TableCell>
                    <TableCell className="table__body__td">{item.tag}</TableCell>
                    <TableCell className="table__body__td">{item.length}</TableCell>
                    <TableCell className="table__body__td">{item.format}</TableCell>
                </TableRow>
                ))}
            </TableBody>
            </Table>
        </TableContainer>
    )

}
export default SystemDefineField;