import React,{useState} from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import {Add} from '@material-ui/icons';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CustomTable from './CustomTable'
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
    height:50
  },
  selectEmpty: {
    marginTop: theme.spacing(0),
  },
}));

 const CustomFrom = () => {

  const classes = useStyles();
   const [type, setType] = useState('');
   const [fieldName, setFieldName] = useState('');
  return (
     
      <>

    <form className="custom__form custom__form__margin">
      <FormControl className="custom__form__input">
        <FormLabel>Field Name</FormLabel>
        <Input  placeholder="Enter Field Name" onChange={e => setFieldName(e.target.value)} value={fieldName}  disableUnderline={true} />
      </FormControl>
      <FormControl variant="outlined" margin="none" focused="false" className="custom__form__select">
      <FormLabel>Select Field Type</FormLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={type}
          onChange={e => setType(e.target.value)}
          IconComponent = {KeyboardArrowDownIcon}
          displayEmpty
          className={classes.selectEmpty}
          inputProps={{ 'aria-label': 'Without label' }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            },
            getContentAnchorEl: null
          }}
        >
          <MenuItem value="" disabled>
          Select Field Type
          </MenuItem>
          <MenuItem value="Ten">Ten</MenuItem>
          <MenuItem value="Twenty">Twenty</MenuItem>
          <MenuItem value="Thirty">Thirty</MenuItem>
        </Select>
      </FormControl>
      <Button type="submit"
        className="add__icon custom__form__icon"
        startIcon={<Add />}
      >
        Create Fields
      </Button>
    </form>
    <CustomTable/>

    </>
    
  );
}

export default CustomFrom
