import React from "react";
import DataTable from "react-data-table-component";
import Checkbox from '@material-ui/core/Checkbox';

export const GlobalTable = (props) => {
     /* table defaul functions */
     const onRowClicked = (event) => {
        console.log(event)
    }

    const handleSort = (column, sortDirection) => {   
        console.log(column, sortDirection) 
    };

    const onChangePage = (page_change_event) => {
        console.log(page_change_event)
    }

    const onChangeRowsPerPage = (newPerPage, page) => {
        console.log(newPerPage, page)
    }

    const handleRowChange = (state) => {
        // console.log('Selected Rows: ', state.selectedRows);
    };

    /* const */
    const selectProps = { indeterminate: isIndeterminate => isIndeterminate };
    const filerInputField = (
        <>
            <input 
                id={`search_on_custom_datatable_${props.table_unique_id}`}
                type="text" 
                placeholder="Filter..." 
                aria-label="Search Input"
                onChange={(e) => {
                    console.log(e.target.value)
                }}
            />
        </>
    )

    /* props */
    let sort_props = {}, select_props = {}, progress_props = {}, pagination_props = {}, subheader_props = {}, header_props={}
    if(typeof props.sortIcon != 'undefined'){
        sort_props['sortIcon'] = props.sortIcon
    }

    // selectableRows = Boolean, selectableRowsData = {}
    if(typeof props.selectableRows != 'undefined' &&  props.selectableRows && typeof props.selectableRowsData != 'undefined'){
        let data = props.selectableRowsData
        // following are the props index
        select_props = {
            selectableRowsComponent : Checkbox,
            onSelectedRowsChange: handleRowChange,
            selectableRowsComponentProps: selectProps,
            selectableRowsHighlight: true,
            selectableRowsVisibleOnly: false,
            selectableRowsSingle: false,
            selectableRowsNoSelectAll: false
        }

        select_props = {...select_props, ...data}

        if(typeof data.selectableRowSelected != 'undefined'){
            // selectableRowSelected={row => row.isSelected}
            select_props['selectableRowSelected'] = data.selectableRowSelected
        }

        if(typeof data.selectableRowDisabled != 'undefined'){
            // selectableRowDisabled={row => row.isDisabled}
            select_props['selectableRowDisabled'] = data.selectableRowDisabled
        }
    }

    if(typeof props.progressPending  != 'undefined' && props.progressPending){
        progress_props['progressComponent'] = typeof props.progressComponent != 'undefined' ? props.progressComponent :  <div className="table-loader-container">Loading...</div>
    }

    // paginationData=Boolean, paginationData={}
    if(typeof props.pagination != 'undefined' && props.pagination && typeof props.paginationData != 'undefined'){
        let data = props.paginationData

        pagination_props={
            paginationServer: true,
            paginationTotalRows: 0,
            paginationPerPage: 10,
            paginationRowsPerPageOptions: [5, 10, 15, 20, 25, 30],
            onChangePage: onChangePage,
            onChangeRowsPerPage: onChangeRowsPerPage,
            paginationComponentOptions : {
                rowsPerPageText: 'Pages', 
                rangeSeparatorText: 'of', 
                noRowsPerPage: false, 
                selectAllRowsItem: true, 
                selectAllRowsItemText: 'All' 
            }
        }

        if(typeof data.paginationComponentOptions != 'undefined'){
            let paginationComponentOptions = data.paginationComponentOptions
            paginationComponentOptions = {...pagination_props.paginationComponentOptions, ...paginationComponentOptions}
            pagination_props={...pagination_props, ...data, paginationComponentOptions : paginationComponentOptions}      
        }
        else{
            pagination_props={...pagination_props, ...data} 
        }
    }

    // subHeader: Boolean, subHeaderData={}
    if(typeof props.subHeader != 'undefined' && props.subHeader && typeof props.subHeaderData != 'undefined'){
        subheader_props = {
            subHeaderAlign: 'left',
            subHeaderWrap: false,
            subHeaderComponent: filerInputField
        }
        subheader_props = {...subheader_props, ...props.subHeaderData}
    }

    // noHeaderData:boolean, noHeaderData={}
    if(typeof props.noHeader != 'undefined' && !props.noHeader && typeof props.noHeaderData != 'undefined'){
        let data = props.noHeaderData
        header_props = {
            actions: [(<button type="button" key={'custom_table_button_1'}>Button 1</button>), (<button type="button" key={'custom_table_button_2'} >Button 2</button>)],
            fixedHeader: true,
            noContextMenu: true,
            contextMessage: {singular: 'item', plural: 'items', message: 'selected' },
            contextActions: [(<button key={'custom_table_action_1'} >Action 1</button>), (<button key={'custom_table_action_2'}>Action 2</button>)]
        }

        header_props= {...header_props, ...data}

        if(typeof data.fixedHeaderScrollHeight != 'undefined'){
            header_props['fixedHeaderScrollHeight'] = data.fixedHeaderScrollHeight //'5ovh'
        }

        if(typeof data.noContextMenu != 'undefined' && !data.noContextMenu){ 
            if(typeof data.contextComponent != 'undefined'){
                header_props['contextComponent'] = props.noHeaderData.contextComponent //for your custom compoent
            }
        }
    }

    return (
        <div key={props.table_unique_id} id={props.table_unique_id}>
            <DataTable
                data={props.data}
                columns={props.columns}

                // table setting
                /* className , style not working */
                keyField={props.keyField || 'id'}
                disabled={props.disabled || false}
                responsive={true}
                striped={props.striped || true}
                highlightOnHover={props.highlightOnHover || true}
                pointerOnHover={props.pointerOnHover || true}
                onRowClicked={props.onRowClicked || onRowClicked}
                persistTableHead={props.persistTableHead || true} //Show the table head (columns) even when progressPending is true.

                noDataComponent={props.noDataComponent || 'no data found'}
                progressPending={props.progressPending || false}
                {...progress_props}

                // for sorting
                defaultSortField={props.defaultSortField || (props.keyField || 'id')}
                onSort={props.onSort || handleSort}
                {...sort_props}

                // for check box
                selectableRows={props.selectableRows || false}
                {...select_props}
                
                // for pagination
                pagination={props.pagination || false}
                {...pagination_props}

                // for header and sub-header
                subHeader={props.subHeader || false}
                {...subheader_props}
                
                noHeader={props.noHeader || false}
                {...header_props}
            />

        </div>
    );
}