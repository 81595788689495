import React from "react";
import { Box } from "@material-ui/core";
import SendWithMail from "./SentInvoice/SendWithMail";
import SendWithText from "./SentInvoice/SendWithText";
import InvoicePageSubHeader from "./common/header/InvoicePageSubHeader";

const EmailTextTemplates = () => {
  return (
    <Box>
      <Box mb={4}>
        <InvoicePageSubHeader
          title={"Email/Text Templates"}
          description={
            "Save time by creating email and text templates. The messages you create here will be used when you send an invoice a to a client via email or text."
          }
        />
      </Box>

      <SendWithMail />
      <SendWithText />
    </Box>
  );
};

export default EmailTextTemplates;
