const TextAreaInput = ({fieldName = "", callBack, value = "", placeHolder = "", extraClassName = "", isRequired=false}) => {
    return (
        <textarea
            className={"personal_info_text_field "+extraClassName}
            name={fieldName}
            onChange={(event) => callBack(event.target.name,event.target.value)}
            placeholder={placeHolder}
            required={isRequired}
            value={value}
        >
        </textarea>
    )
}
export default TextAreaInput;