import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import TableLoaderSkeleton from "../common/TableLoaderSkeleton";
import GlobalEmptyPage from "../common/emptyPage/GlobalEmptyPage";
import { EmptyInboxIcon } from "../common/icons/invoicePageIcons";
import InvoiceUseStyles from "../invoiceUseStyles";
import TaxItemAction from "./TaxItemAction";
import TaxesHeader from "./TaxesHeader";
import { getTaxesInvoice } from "../../../api/InvoiceApi";
import Utils from "../../../helpers/Utils";

const truncate = (clamp = 3) => {
  return {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": clamp,
    "-webkit-box-orient": "vertical",
  };
};

const Taxes = () => {
  const classes = InvoiceUseStyles();

  const [renderId, setRenderId] = useState("");

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onRenderCallback = () => {
    setRenderId(Utils.generateRandomString(5));
  };

  useEffect(() => {
    setLoading(true);
    getTaxesInvoice({
      limit: perPage,
      currentPage: page + 1,
    })
      .then((res) => {
        res = res.data;
        if (res.success) {
          res = res.data;
          setData(res.content);
          setTotal(res.count);
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [page, perPage, renderId]);

  let content = null;

  if (loading && !renderId) {
    content = (
      <TableLoaderSkeleton numberOfRows={10} columns={4} minWidth={120} />
    );
  } else if (data?.length === 0) {
    content = (
      <GlobalEmptyPage icon={<EmptyInboxIcon />} title={"No data available"} />
    );
  } else if (data?.length > 0) {
    content = (
      <TableContainer component={Paper} elevation={0}>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="left">Description</TableCell>
              <TableCell align="center">Tax Rate</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={row.name}>
                <TableCell scope="row">
                  <Box title={row.name} sx={truncate(2)}>
                    {row.name}
                  </Box>
                </TableCell>
                <TableCell align="left">
                  <Box title={row.description} sx={truncate()}>
                    {" "}
                    {row.description}
                  </Box>
                </TableCell>
                <TableCell align="center">{row.taxPercentage}</TableCell>
                <TableCell align="center">
                  <TaxItemAction
                    taxInfo={row}
                    onRenderCallback={onRenderCallback}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <TablePagination
          component="div"
          count={total}
          page={page}
          rowsPerPage={perPage}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[5, 10, 25]}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    );
  }
  return (
    <Box flex={1}>
      <TaxesHeader onRenderCallback={onRenderCallback} loading={loading} />

      {content}
    </Box>
  );
};

export default Taxes;
