import React from 'react';

const InvoicePageSubHeader = ({title, description}) => {
    return (
        <div>
            <h5>{title}</h5>
            <p>{description}</p>
        </div>
    );
};

export default InvoicePageSubHeader;