import React, {useContext} from "react";
import {icons} from "../leadSource/icon";
import {LeadPermissionRequestContext} from "./StateProvider";
import ActionTypes from "./ActionTypes";

const Pagination = () => {
    const {state, dispatch} = useContext(LeadPermissionRequestContext);

    const handlePaginationChange = (event, page) => {
        event.preventDefault();
        if(page != null){
            dispatch({
                type: ActionTypes.UPDATE_PAGE,
                payload: page
            });
        }
    }

    let previous_page = null, next_page = null;
    if(state.page > 1){
        previous_page = state.page - 1
    }

    if(state.page !== state.totalPage){
        next_page = state.page + 1
    }

    if(state.totalRecords === 0){
        return false;
    }

    return (
        <div className="pagination_bar">
            <ul>
                <li>
                    <a href="#" onClick={(event) => handlePaginationChange(event, previous_page)} style={previous_page == null ? {cursor : "not-allowed", backgroundColor: "#9daac1", borderColor: "#9daac1"} : {}}>
                        {icons.previous}
                    </a>
                </li>
                <li>
                    <a href="#" onClick={e => e.preventDefault()}>
                        <span>{state.page}</span>
                    </a>
                </li>
                <li>
                    <a href="#" onClick={e => e.preventDefault()} className="d-flex align-items-center">
						<span>
							{icons.backwardSlash}
						</span>
                        {state.totalPage}
                    </a>
                </li>
                <li>
                    <a href="#" onClick={(event) => handlePaginationChange(event, next_page)} style={next_page == null ? {cursor : "not-allowed", backgroundColor: "#9daac1", borderColor: "#9daac1"} : {}}>
                        {icons.next}
                    </a>
                </li>
                <li style={{color: "#9daac1", marginLeft: 20}}>Total Records : {state.totalRecords}</li>
            </ul>
        </div>
    );
};

export default Pagination;