import * as ACTION from "../constants/profileActionTypes";

export const updateUser = (payload,callback) => ({
    type: ACTION.UPDATE_USER,
    payload: {payload, callback}
});

export const updateUserAvatar = payload => ({
    type: ACTION.UPDATE_USER_AVATAR,
    payload: payload
});

export const updateUserPassword = (payload,callback) => ({
    type: ACTION.UPDATE_USER_PASSWORD,
    payload: {payload, callback}
});

export const updateCompanyInfo = (payload,callback) => ({
    type: ACTION.UPDATE_COMPANY_INFO,
    payload: {payload, callback}
});

export const updateFavicon = (payload,callback) => ({
    type: ACTION.UPDATE_FAVICON,
    payload: {payload, callback}
});

export const getUser = payload => ({
    type: ACTION.GET_USER,
    payload: payload
});

export const fetchLeadGroupList = (payload, callback) => ({
    type: ACTION.FETCH_LEAD_GROUP_LIST,
    payload: {payload, callback}
});

export const fetchUsersList = (payload, callback) => ({
    type: ACTION.FETCH_USERS_LIST,
    payload: {payload, callback}
});

export const createOrUpdateLeadGroup = (payload, callback) => ({
    type: ACTION.CREATE_OR_UPDATE_LEAD_GROUP,
    payload: {payload, callback}
})

export const updateProfileReducerSignatureData = (payload) => ({
    type: ACTION.UPDATE_USER_SIGNATURE,
    payload: payload
})