import React, { useEffect, useState } from "react";
import { getUser } from "../../actions/profileAction";
import { useDispatch, useSelector } from "react-redux";
import Utils from "../../helpers/Utils";
import GlobalSearchBox from "../globals/GlobalSearchBox";
import { GeneralOptions } from "./GeneralOptions";
import "./assets/css/setting.css";
import { SettingSearch } from "./SettingSearch";

const General = () => {
    const userIsOwner = Utils.getAccountData('userIsOwner');
    const userIsAgent = Utils.getAccountData('userIsAgent');
    const isTemplateUser = Utils.getAccountData('isTemplateUser');
    const dispatch = useDispatch();
    const [query, setQuery] = useState('');
    const { user } = useSelector(state => state.profileReducer);

    useEffect(()=> {
        document.title = `Settings | ${Utils.getAccountData('AuthUserAgencyName')}`;
        if(window.setActiveSidebar) {
            window.setActiveSidebar('setting');
        }
        if(user === '') {
            dispatch(getUser());
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="container-fluid sitting-page awesome__scroll_bar">
            <div className="settings_page_search_wrapper">
                <GlobalSearchBox wrapperClass="settings_page_search" placeholder="Search Setting" onChange={e => setQuery(e.target.value)}/>
            </div>
            {
               query ? <SettingSearch query={query} userIsOwner={userIsOwner} user={user} userIsAgent={userIsAgent} isTemplateUser={isTemplateUser}/> : <GeneralOptions user={user} userIsOwner={userIsOwner} isTemplateUser={isTemplateUser}/>
            }
        </div>
    )
}

export default General;