export const PERSONALIZED_TAG_CONTACT = {
  title: "Contact",
  tags: [
    { value: "[[name]]", title: "Name" },
    { value: "[[email]]", title: "Email" },
    { value: "[[phone]]", title: "Phone" },
  ],
};

export const PERSONALIZED_TAG_INVOICE = {
  title: "Invoice Details",
  tags: [
    { value: "[[total_price]]", title: "TotalPrice" },
    { value: "[[total_tax]]", title: "TotalTax" },
    { value: "[[total_discount]]", title: "TotalDiscount" },
    { value: "[[shipping_charge]]", title: "ShippingCharge" },
    { value: "[[invoice_number]]", title: "InvoiceNumber" },
    { value: "[[order_number]]", title: "OrderNumber" },
    { value: "[[start_date]]", title: "StartDate" },
    { value: "[[due_date]]", title: "DueDate" },
    { value: "[[payment_link]]", title: "Payment Link" },
  ],
};

export const PERSONALIZED_TAG_INVOICE_FOR_EMAIL_SEND = {
  title: "Invoice Details",
  tags: [
    ...PERSONALIZED_TAG_INVOICE.tags,
    { value: "[[previous_unpaid_invoice_link]]", title: "Previous Unpaid Invoice Link" },
  ],
};

export const PERSONALIZED_TAGS = [PERSONALIZED_TAG_CONTACT, PERSONALIZED_TAG_INVOICE];
export const PERSONALIZED_TAGS_FOR_EMAIL_SEND = [PERSONALIZED_TAG_CONTACT, PERSONALIZED_TAG_INVOICE_FOR_EMAIL_SEND];
