import {useEffect, useState} from 'react';
import SwitchC from '../../../subComponents/Switch';
import { DeleteIcons, EditIcons, MoveIcons } from '../../helpers/IconList';
import Styles from './style.module.css'
import {
  ALL_FIELD_NAME,
  CHECKBOX,
  DATE, DATETIME,
  MULTILINE_TEXT,
  NUMERIC,
  PHONE,
  RADIO,
  SELECT,
  TEXT, WEBSITE, ZIP_CODE
} from "../../../../../constants/CoreConstants";
import CustomConfirmAlert from "../../helpers/confirm-alert/CustomConfirmAlert";
import {changeCustomFieldStatusApi, deleteCustomFieldApi, updateCustomFieldDataApi} from "../../../../../api/CustomFieldApi";
import {handleSelectFolder, handleStoreFields, handleStoreFolders} from "../../reducer/Actions";
import {useCFContext} from "../../reducer/CustomProvider";
import MoveField from "../../move-field/MoveField";
import CustomModalPype from "../../helpers/modal/CustomModalPype";
import CustomDrawerPype from "../../helpers/drawer/CustomDrawerPype";
import EditCustomField from "../../edit-field/EditCustomField";
import { updateLocalStorage } from '../../CustomFieldV2App';
import BootstrapTooltip from "../../../../common/BootstrapTooltip";

const format = (data) => {
  let typeId = parseInt(data.type_id);
  if(typeId === TEXT)
    return 'text'
  else if(typeId === MULTILINE_TEXT)
    return 'text'
  else if(typeId === NUMERIC)
    return '0-9'
  else if(typeId === DATE)
    return 'date'
  else if(typeId === CHECKBOX)
    return 'checkbox'
  else if(typeId === SELECT)
    return 'select'
  else if(typeId === RADIO)
    return 'radio'
  else if(typeId === PHONE)
    return '0-9'
  else if(typeId === ZIP_CODE)
    return '0-9'
  else if(typeId === WEBSITE)
    return 'url'
  else if(typeId === DATETIME)
    return 'datetime'
}

const EachItem = (props) => {
  const {state, dispatch} = useCFContext();
  const [status, setStatus] = useState(props.item.status);
  const [openMoveModal, setMoveModal] = useState(false);
  const [openEditModal, setEditModal] = useState(false);
  const [visibleStatus, setVisibleStatus] = useState(props.item.user_disable_custom_fields === null);

  useEffect(()=>{
    setStatus(props.item.status);
  },[props.item.status]);

  const handleDelete = () => {
    CustomConfirmAlert({
      onSubmit : () => {
        deleteCustomFieldApi({fieldId : props.item.id}).then(res => {
          let response = res.data;
          if (response.status === 'success') {
            window.showNotification('success', response.html);
            let old_data = [...state.fields];
            old_data.splice(props.index, 1)

            if (!old_data[0]){
              let updatedFolders = state.folders.filter((folder)=>(folder.group_name.toLowerCase() !== (state.selectedFolder && state.selectedFolder.toLowerCase())));
              dispatch(handleStoreFolders(updatedFolders));
              dispatch(handleSelectFolder(null));
            }

            dispatch(handleStoreFields(old_data));
            updateLocalStorage()
          }
        })
      },
      title: 'Are you sure to delete ?',
      description: 'Updated & sync with account information.'
    })
  }

  const handleMove = () => {
    setMoveModal(true);
  }

  const handleEdit = () => {
    setEditModal(true);
  }

  const handleStatus = () => {
    CustomConfirmAlert({
      onSubmit : () => {
        changeCustomFieldStatusApi({fieldId : props.item.id, status: !status}).then(res => {
          let response = res.data
          if (response.status === 'success') {
            window.showNotification('success', response.html);
            setStatus(!status)
            updateLocalStorage()
          } else {
            setStatus(status)
          }
        })
      },
      title: 'Are you sure to ' + (status ?  'disable' : 'enable') + '?',
      description: 'Updated & sync with account information.',
      cancelText: 'No, Keep it',
      submitText: 'Yes, Change it'
    })
  }
  const handleVisibilityStatus = () => {
    CustomConfirmAlert({
      onSubmit : () => {
        updateCustomFieldDataApi({
          fieldId : props.item.id, 
          disable: !visibleStatus, 
          'field_type' : 'disable_custom_field',
          'field_name': props.item.title
        }).then(res => {
          let response = res.data
          if (response.status === 'success') {
            window.showNotification('success', response.html);
            setVisibleStatus(!visibleStatus)
            updateLocalStorage()
          } else if (response.status === 'error') {}
        })
      },
      title: 'Are you sure to ' + (visibleStatus ?  'disable' : 'enable') + '?',
      description: 'Updated & sync with account information.',
      cancelText: 'No, Keep it',
      submitText: 'Yes, Change it'
    })
  }
  return (
    <div className={Styles.itemWrp} {...props?.provided_ref?.dragHandleProps}>
      <div className={Styles.header}>
        <span className={Styles.title}>{props.item.title}</span>
        {state.selectedFolder !== '___more_fields___' &&
        <div className={Styles.actionWrp}>
            <BootstrapTooltip arrow placement="bottom" title="Edit">
              <span style={{ display: "flex"}} onClick={handleEdit}>
                <EditIcons />
              </span>
            </BootstrapTooltip>
            <BootstrapTooltip arrow placement="bottom" title="Delete">
              <span style={{ display: "flex"}} onClick={handleDelete}>
                <DeleteIcons />
              </span>
            </BootstrapTooltip>
            <BootstrapTooltip arrow placement="bottom" title="Move">
              <span style={{ display: "flex"}} onClick={handleMove}>
                <MoveIcons />
              </span>
            </BootstrapTooltip>
          <BootstrapTooltip arrow placement="bottom" title="Change Status">
            <SwitchC status={status} brandColor={"#f94f72"} onChange={handleStatus}/>
          </BootstrapTooltip>
        </div>
        }
      </div>
      <div className={Styles.body}>
        <div className={Styles.bodyLeft}>
          <div className={Styles.tag}>
            <span className={Styles.tagLabel}>Personalize Tag:</span>
            <span className={Styles.tagText}>{props.item.personalize_tag}</span>
          </div>
          <div className={Styles.info}>
            <span className={Styles.infoLabel}>Maximum Character Allowed:</span>
            <span className={Styles.infoText}>{props.item.max_length}</span>
          </div>
          {
            (props.item.default_values &&
                props.item.default_values != null &&
                props.item.default_values != ""
            ) && (
              <div className={Styles.info}>
                <span className={Styles.infoLabel}>Value:</span>
                <span className={Styles.infoText}>{String(props.item.default_values)}</span>
              </div>
            )
          }
        </div>
        <div className={Styles.bodyRight}>
          <div className={Styles.info}>
            <span className={Styles.infoLabel}>Type:</span>
            <span className={Styles.infoText}>{ALL_FIELD_NAME[props.item.type_id.toString()]}</span>
          </div>
          <div className={Styles.info}>
            <span className={Styles.infoLabel}>Format:</span>
            <span className={Styles.infoText}>{format(props.item)}</span>
          </div>

          {state.selectedFolder === '___more_fields___' &&
          <div className={Styles.info} style={{alignItems: 'center'}}>
            <span className={Styles.infoLabel}>Visibility:</span>
            <span className={Styles.infoText}>
              <SwitchC status={visibleStatus} brandColor={"#f94f72"} onChange={handleVisibilityStatus}/>
            </span>
          </div>
        }

        </div>
      </div>

      <CustomModalPype
        isShow={openMoveModal}
        hanldeModal={(status) => {}}
        component={(
          <MoveField selectedItem={props.item} index={props.index} onClose={() => setMoveModal(false)} />
        )}
      />

      <CustomDrawerPype
        isShow={openEditModal}
        hanldeDrawer={(status) => {}}
        renderChild={() => {
          return <EditCustomField selectedItem={props.item} index={props.index} onClose={() => setEditModal(false)} />
        }}
      />
    </div>
  );
}
export default EachItem;