import { useRef } from 'react';
import { LeftIcon, RightIcon } from '../../helpers/IconList';
import { useIsMounted } from '../../helpers/IsMounted';
import useDelayCallback from '../../helpers/useDelayCallback';
import { handlePaginationPage, handleStates } from '../../reducer/Actions';
import Styles from './style.module.css'
const Pagination = ({state, dispatch}) => {
  const isMounted = useIsMounted();
  const inputRef = useRef(null);
  useDelayCallback(() => {
    if(isMounted){
      if(inputRef.current.value !== state.page){
        // inputRef.current.value = (state.page+1);
        inputRef.current.value = 1;
      }
    }
  }, [state.selectedFolder, state.perPage])
  const onClickPage = (type="back") => {
    if(type === 'back'){
      if(state.page > 0){
        dispatch(handlePaginationPage(state.page - 1))
        inputRef.current.value = state.page;
      }
    }else{
      if(state.page < Math.ceil(state.totalFields/state.perPage)){
        dispatch(handleStates({gettingFields: false}))
        dispatch(handlePaginationPage(state.page + 1))
        inputRef.current.value = (state.page + 2)
      }
    }
  }
  const onChangeInput = (event) => {
    const value = event.target.value.trim()
    if(value !== ""){
      if(/^\d+$/.test(value)){
        const pageValue = parseInt(value);
        if(pageValue <= 0){}
        else if(pageValue > Math.ceil(state.totalFields / state.perPage)){}
        else{
          dispatch(handlePaginationPage(parseInt(value-1)))
        }
      }
    }
  }
  return (
    <div className={Styles.paginationWrp}>
      <span className={`${Styles.paginationIcon} ${state.page === 0 ? Styles.disabled : ''}`} onClick={() => onClickPage('back')}><LeftIcon /></span>
      <input onChange={onChangeInput} ref={inputRef} className={Styles.paginationIuput} placeholder="Page" defaultValue={(state.page+1)} />
      <span className={`${Styles.paginationIcon} ${Styles.separator}`}>/</span>
      <span className={Styles.paginationIcon}>{Math.ceil(state.totalFields / state.perPage)}</span>
      <span className={`${Styles.paginationIcon} ${((state.page+1) === Math.ceil(state.totalFields / state.perPage))? Styles.disabled : ''}`} onClick={() => onClickPage('forward')}><RightIcon /></span>
      <span className={Styles.total}>Total records: {state.totalFields}</span>
    </div>
  );
}
export default Pagination;
