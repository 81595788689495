import React, {useContext} from "react";
import {LeadPermissionRequestContext} from "./StateProvider";
import ActionTypes from "./ActionTypes";
import {Button} from "@material-ui/core";
import NewConfirmAlert from "../activity/new-alert/NewConfirmAlert";
import {bulkActionContactPermissionRequest} from "../../api/profileApi";
import {Constants} from "./Constants";

const Search = () => {
    const {state, dispatch} = useContext(LeadPermissionRequestContext);

    const handlePerPageChange = (event) => {
        dispatch({
           type: ActionTypes.UPDATE_TABLE_DATA,
           payload: { perPage: event.target.value, page: 1 }
        });
    }

    const handleBulkActionContactPermissionRequest = (action) => {
        try {
            NewConfirmAlert({
                onSubmit : () => {
                    bulkActionContactPermissionRequest({ action: action })
                        .then((res)=>{
                            if(res.success){
                                window.showNotification("SUCCESS",res.message);

                                dispatch({
                                    type: ActionTypes.UPDATE_TABLE_DATA,
                                    payload: {
                                        leadPermissionRequests: [],
                                        page: 1,
                                        totalRecords: 0
                                    }
                                });
                            }else {
                                window.showNotification("ERROR",res.message);
                            }
                        }).catch((err)=>{
                        window.showNotification("ERROR","Something went wrong. Please try again later!");
                    });
                },
                title: 'Are your sure?',
                description: `${action === Constants.BULK_ACTION_ACCEPT ? 'Accept' : 'Reject'} All (${state.totalRecords}) Contact Permission Requests`,
                cancelText: 'Cancel',
                submitText: 'Submit',
                width: '480px'
            });
        }catch (err){

        }
    }

  return(
      <div className="items">
          <div className="records">
              <span>Rows per page</span>
              <div className="input-field col s12">
                  <select style={{display: 'block'}} value={state.perPage} onChange={handlePerPageChange}>
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="100">100</option>
                  </select>
              </div>
          </div>
          <div className="record_search">
              {
                  state.totalRecords > 0 ? (
                      <div>
                          <Button variant="contained" color="primary" onClick={()=>{
                              handleBulkActionContactPermissionRequest(Constants.BULK_ACTION_ACCEPT)
                          }}>
                              Accept All
                          </Button>
                          <Button style={{ marginLeft: "10px" }} variant="contained" color="secondary" onClick={()=>{
                              handleBulkActionContactPermissionRequest(Constants.BULK_ACTION_REJECT)
                          }}>
                              Reject All
                          </Button>
                      </div>
                  ) : ''
              }
          </div>
      </div>
  );
};

export default Search;