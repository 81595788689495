export const Icons = {
    check: (<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20"><path d="m8.938 13 4.958-4.938L12.833 7l-3.895 3.875-1.771-1.75-1.063 1.063ZM4.5 17q-.625 0-1.062-.438Q3 16.125 3 15.5v-11q0-.625.438-1.062Q3.875 3 4.5 3h11q.625 0 1.062.438Q17 3.875 17 4.5v11q0 .625-.438 1.062Q16.125 17 15.5 17Z"/></svg>),
    unCheck: (<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20"><path d="M4.5 17q-.625 0-1.062-.438Q3 16.125 3 15.5v-11q0-.625.438-1.062Q3.875 3 4.5 3h11q.625 0 1.062.438Q17 3.875 17 4.5v11q0 .625-.438 1.062Q16.125 17 15.5 17Zm0-1.5h11v-11h-11v11Z"/></svg>),
    search: (<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20"><path d="m15.938 17-4.98-4.979q-.625.458-1.375.719Q8.833 13 8 13q-2.083 0-3.542-1.458Q3 10.083 3 8q0-2.083 1.458-3.542Q5.917 3 8 3q2.083 0 3.542 1.458Q13 5.917 13 8q0 .833-.26 1.583-.261.75-.719 1.375L17 15.938ZM8 11.5q1.458 0 2.479-1.021Q11.5 9.458 11.5 8q0-1.458-1.021-2.479Q9.458 4.5 8 4.5q-1.458 0-2.479 1.021Q4.5 6.542 4.5 8q0 1.458 1.021 2.479Q6.542 11.5 8 11.5Z"/></svg>),
    arrow_down: (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 10L12 15L17 10H7Z" fill="#949DB2"/>
        </svg>
    ),
    checkMark: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1305_9)">
            <path d="M12 23.9921C18.6274 23.9921 24 18.6231 24 12C24 5.37693 18.6274 0.00787735 12 0.00787735C5.37258 0.00787735 0 5.37693 0 12C0 18.6231 5.37258 23.9921 12 23.9921Z" fill="#cce7da"/>
            <path d="M11.0378 18.3788L5.36401 13.9586L6.97726 11.8883L10.4614 14.6025L16.2116 6.30488L18.3694 7.79963L11.0378 18.3788Z" fill="#348a46"/>
        </g>
        <defs>
            <clipPath id="clip0_1305_9">
                <rect width="24" height="24" fill="white"/>
            </clipPath>
        </defs>
    </svg>,
    crossMark: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1305_27)">
            <path d="M12 23.9921C18.6274 23.9921 24 18.6231 24 12C24 5.37693 18.6274 0.00787735 12 0.00787735C5.37258 0.00787735 0 5.37693 0 12C0 18.6231 5.37258 23.9921 12 23.9921Z" fill="#f6c6d5"/>
            <path d="M15.8072 17.6612L17.6631 15.8054L8.19437 6.33667L6.3385 8.19254L15.8072 17.6612Z" fill="#ee4139"/>
            <path d="M8.19461 17.6636L17.6633 8.19493L15.8074 6.33905L6.33874 15.8077L8.19461 17.6636Z" fill="#ee4139"/>
        </g>
        <defs>
            <clipPath id="clip0_1305_27">
                <rect width="24" height="24" fill="white"/>
            </clipPath>
        </defs>
    </svg>
}