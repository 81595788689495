export const LOAD_CUSTOM_FIELD = 'LOAD_CUSTOM_FIELD';
export const UPDATE_CUSTOM_FIELD = 'UPDATE_CUSTOM_FIELD';
export const UPDATE_CUSTOM_FIELD_LENGTH = 'UPDATE_CUSTOM_FIELD_LENGTH';
export const DELETE_CUSTOM_FIELD = 'DELETE_CUSTOM_FIELD';
export const CHANGE_CUSTOM_FIELD_STATUS = 'CHANGE_CUSTOM_FIELD_STATUS';
export const CUSTOM_FIELD_ADD = 'CUSTOM_FIELD_ADD';
export const CHANGE_CUSTOM_FIELD_VALUE = 'CHANGE_CUSTOM_FIELD_VALUE';
export const LOAD_USER_PERSONALIZED_DATA = 'LOAD_USER_PERSONALIZED_DATA';
export const CREATE_PERSONALIZED_FIELD_DATA = 'CREATE_PERSONALIZED_FIELD_DATA';
export const UPDATE_PERSONALIZED_VALUE = 'UPDATE_PERSONALIZED_VALUE';
export const DELETE_PERSONALIZED_TAG = 'DELETE_PERSONALIZED_TAG';
export const CREATE_NEW_PERSONALIZED_FIELD = 'CREATE_NEW_PERSONALIZED_FIELD';