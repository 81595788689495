import * as ACTION from "../constants/profileActionTypes";

const initialState = {
    user : '',
    isLoading: true,
    response: true,

    taxes: [
        {id: 1, name: 'Tax - 1', description: 'Lorem Ipsum - 1', taxPercentage: 5}, 
        {id: 2, name: 'Tax - 2', description: 'Lorem Ipsum - 2', taxPercentage: 6},
        {id: 3, name: 'Tax - 3', description: 'Lorem Ipsum - 3', taxPercentage: 3},
    ],

    // custom field
    reloadCustomFieldFlag: false,
    reloadPersonalizedFlag: false
};

const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION.SET_USER:
            return {...state, user: action.payload}
        case ACTION.UPDATE_USER_SET:
            // let data = {
            //     ...state.user,
            //     action.payload
            // }
            return {...state, user: {
                    ...state.user,
                    ...action.payload
                }
            }
        case ACTION.SET_RESPONSE:
            return {...state, response: action.payload}

        case ACTION.RELOAD_CUSTOM_FIELD_FLAG:
            return {...state, reloadCustomFieldFlag: action.payload.payload.status}

        case ACTION.RELOAD_PERSONALIZED_TAG_FLAG:
            return {...state, reloadPersonalizedFlag: action.payload.payload.status}

        case ACTION.UPDATE_USER_SIGNATURE:
            return {...state, user: {
                    ...state.user,
                    signature: action.payload.signature,
                    show_signature: action.payload.show_signature,
                }
            }

        case ACTION.ADD_TAX:
            const updatedTaxes = [...state.taxes, action.payload];
            return {...state, taxes: updatedTaxes}

        default:
            return state;
    }
}

export default profileReducer;
