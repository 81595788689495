export const setCookie = (cname, cvalue, exTime) => {
    document.cookie = cname+"="+ btoa(cvalue) +"; Secure; path=/";
}

export const getCookie = cname => {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return atob(c.substring(name.length, c.length));
        }
    }
    return "";
}

export const deleteCookie = cname => {
    if( getCookie(cname) ) {
        document.cookie = cname + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
    }
}


export const checkCookie = (cname) => {
    const cookieInfo = getCookie(cname);
    return !!cookieInfo;
}

export const getCookieLogin = cname => {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            let str = c.substring(name.length, c.length).replace(/\s/g, ''); 
            return decodeURIComponent(window.atob(str))
        }
    }
    return "";
}