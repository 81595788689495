import React, { useState } from 'react';
import DoneIcon from '@material-ui/icons/Done';
import DeleteIcon from '@material-ui/icons/Delete';
import BootstrapTooltip from '../globals/BootstrapTooltip';
import { deleteVirtualNumber, virtualNumberChangeDefault, virtualNumberRequestForShakenStirRegistration } from '../../api/profileApi';
import { confirmAlert } from 'react-confirm-alert';

const VirtualNumberEdit = ({ item, updateTable }) => {

    const [mouseOver,setMouseOver] = useState(false);

    const deleteNumber = () => {
        confirmAlert({
            title: 'Are you sure?',
            message: `You will not be able to recover this data. Do you want to remove this number?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        deleteVirtualNumber({
                            "form_data": {
                                type: 'single', 
                                id: item.id
                            }
                        }).then(response => {
                            if (response.data.status === 'success') {
                                window.showNotification('SUCCESS',response.data.message);
                                updateTable();
                            } else {
                                window.showNotification('ERROR',response.data.message);
                            }
                        }).catch(error => {
                            window.showNotification('ERROR','Data delete failed. Submit the form again');
                        })
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        return;
                    }
                }
            ]
        })
    }

    const makeDefault = () => {
        confirmAlert({
            title: 'Are you sure?',
            message: `Do you want to make this number default?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        virtualNumberChangeDefault({
                            "form_data": {
                                type: 'single', 
                                id: item.id
                            }
                        }).then(response => {
                            if (response.data.status === 'success') {
                                window.showNotification('SUCCESS',response.data.message);
                                updateTable();
                            } else {
                                window.showNotification('ERROR',response.data.message);
                            }
                        }).catch(error => {
                            window.showNotification('ERROR','Something went wrong');
                        });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        return;
                    }
                }
            ]
        })
    }

    return (
        <div className="text-field-item-vn-page forward__number_" onMouseEnter={()=>setMouseOver(true)} onMouseLeave={()=> setMouseOver(false)} style={{position: 'relative'}}>
            <BootstrapTooltip arrow title={item?.virtual_number}>
            <input className="text-field-vn-page text-field-vn-page-v2 text-gray" value={item ? item.virtual_number ? item.virtual_number : "" : ""} type="text" disabled={true}/>

            </BootstrapTooltip>
            
            {(mouseOver && item.is_default !== 1) &&
                <div style={{position: 'absolute', top: 0, right: '-25px', zIndex: 9999}}>
                    <BootstrapTooltip arrow title={`Make Default`}>
                        <span className="small-round-icon-vn-page checkIcon" onClick={makeDefault}>{<DoneIcon fontSize="small" />}</span>
                    </BootstrapTooltip>
                    <BootstrapTooltip arrow title={`Delete Number`}>
                        <span className="small-round-icon-vn-page cancelIconNew" onClick={deleteNumber}>{<DeleteIcon fontSize="small" />}</span>
                    </BootstrapTooltip>
                </div>
            }

            {(item.is_default === 1) &&
                <span>
                    <BootstrapTooltip arrow title={`Default Number`}>
                        <span className="small-round-icon-vn-page activeDefaultIcon" >{<DoneIcon fontSize="small" />}</span>
                    </BootstrapTooltip>
                </span>
            }
        </div>
    );
}

export default VirtualNumberEdit;