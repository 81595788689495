import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import React, { useEffect, useState, useRef } from 'react';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import SwitchC from './subComponents/Switch';
import { changeCustomFieldStatus, reloadCustomField } from '../../actions/CustomFieldAction';
import { connect } from 'react-redux'
import { changeCustomFieldStatusApi, deleteCustomFieldApi, getCustomFieldDataV2Api, reorderCustomFieldDataV2Api, updateCustomFieldDataApi } from '../../api/CustomFieldApi';
import Utils from '../../helpers/Utils';
import { ALL_FIELD_NAME, CHECKBOX, DATE, MULTILINE_TEXT, NUMERIC, PHONE, RADIO, SELECT, TEXT, WEBSITE, ZIP_CODE } from '../../constants/CoreConstants';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { confirmAlert } from 'react-confirm-alert';
import CustomFieldMove from './CustomFieldMove';

const grid = 6;

const CustomFieldDragDrop = (props) => {
  const [expandIndex, setExpandIndex] = useState(null);
  const [customFields, setCustomFields] = useState([])
  const [loading, setLoading] = useState(false)
  const [groups, setGroups] = useState([]);
  const [parentData, setParentData] = useState([]) 
  const [agencyData, setAgencyData] = useState([]) 
  
  useEffect(() => {
    loadCustomFieldData();
  }, [])

  useEffect(() => {
    if(props.reloadCustomFieldFlag){
      props.reloadCustomField({status : false})
      loadCustomFieldData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.reloadCustomFieldFlag]) 

  const loadCustomFieldData = () => {
    setLoading(true)
    getCustomFieldDataV2Api().then(res => {
      let response = res.data;
      if(response.status === "success"){
        setCustomFields(response.data.data)
        setGroups(response.data.groups)
        setParentData(response.data.parent_user_data)
        if(response.data.agency_data !== undefined && response.data.agency_data != null){
          setAgencyData(response.data.agency_data)
        }
      }
      setLoading(false)
    }).catch(error => {
      setLoading(false)
    })
  }  

  const  onDragEnd = (result) => {
    let {destination, draggableId, source, type} = result;

    if(destination == null || destination.index === source.index){
      return;
    }

    if(type === "CUSTOM_GROUP"){
      const custom_field_groups = Reorder(
        customFields,
        source.index,
        destination.index
      );
      setCustomFields(custom_field_groups)

      reorderCustomFieldDataV2Api({
        type: 'group',
        group_name: draggableId,
        destination_group_name: customFields[parseInt(destination.index)].group_name,
        destination_index: destination.index,
        source_index: source.index,
        lastItem: (customFields.length === (destination.index + 1))
      }).then(res => {
        let response = res.data;
        if(response.status === "success"){
            try{
                let local_data = localStorage.getItem("global__custom__field__data");
                if(local_data){
                    localStorage.removeItem("global__custom__field__data")
                }
            }
            catch(error){
                console.log(error)
            }
        }else{
          /* refresh the page */
        }
      }).catch(error => {

      })
    }else{
      if(destination == null && destination.index === source.index){
        return;
      }
      /* group info */
      let group_index = destination['droppableId'];
      let group_index_id = group_index.replace(/droppable-/g, '');
      let group_data = customFields[parseInt(group_index_id)]
      let field_name = group_data['group_name'];
  
      /* destination dat */
      let destination_data = group_data['fields'][parseInt(destination.index)]
      let destination_id = destination_data.id;
  
      /* source data */
      let source_data = group_data['fields'][parseInt(source.index)]
      let source_id = source_data.id
  
      const custom_fields = Reorder(
        customFields[parseInt(group_index_id)].fields,
        result.source.index,
        result.destination.index
      );
  
      let old_data = [...customFields]
      old_data[parseInt(group_index_id)].fields = custom_fields
      setCustomFields(old_data)
  
      reorderCustomFieldDataV2Api({
        id: source_id,
        group_name: field_name,
        destination_index: destination.index,
        source_index: source.index,
        destination_id: destination_id
      }).then(res => {
        let response = res.data;
        if(response.status === "success"){
            try{
                let local_data = localStorage.getItem("global__custom__field__data");
                if(local_data){
                    localStorage.removeItem("global__custom__field__data")
                }
            }
            catch(error){
                console.log(error)
            }
        }else{
          /* refresh the page */
        }
      }).catch(error => {
  
      })
    }
  }

  const handleUpdateData = (data, index, type, group_index = null) => {
    if(type === 'group_name'){
      let old_data = [...customFields];
      old_data[index].field_name = data;
      setCustomFields(old_data)
    }else if(type === 'field_title'){
      let tag_info = data.replace(/ /g, '_');
      tag_info = '[[' + tag_info + ']]';
      let old_data = [...customFields];
      old_data[group_index].fields[index].label = data;
      old_data[group_index].fields[index].tag = tag_info;
      setCustomFields(old_data)
    }
    else if(type === 'field_tag'){
      let old_data = [...customFields];
      old_data[group_index].fields[index].tag = data;
      setCustomFields(old_data)
    }
    else if(type === 'field_value'){
      let old_data = [...customFields];
      old_data[group_index].fields[index].value = [data];
      setCustomFields(old_data)
    }
  }

  const removeFieldFromList = (group_index, field_index) => {
    let old_data = [...customFields]
    let group_field_length = old_data[group_index].fields.length;
    old_data[group_index].fields.splice(field_index,1);
    if(group_field_length === 1){
      old_data.splice(group_index,1)
    }
    setCustomFields(old_data)
  }

  const renderParentCustomField = () => {
    let view = [];
    parentData.forEach((item, index) => {
      view.push(
        <div className='custom_field_parent__wrapper__list'>
           <ParentEachCustomFieldItem 
          key={index}
          index={index}
          item={item}
          updateParentData={() => {}}
        />
        </div>
       
      )
    })
    return view;
  }

  const renderAgencyCustomField = () => {
    let view = [];
    agencyData.forEach((item, index) => {
      view.push(
        <div className='custom_field_parent__wrapper__list'>
           <ParentEachCustomFieldItem 
              key={index}
              index={index}
              item={item}
              updateParentData={() => {}}
            />
        </div>
       
      )
    })
    return view;
  }

  if(loading){
    return(
      <div>
        Loading...
      </div>
    )
  }

  return(
    <div className='custom_field_container__drag_drop'>
      {customFields.length > 0 &&
      <DragDropContext
        onDragEnd={onDragEnd}
      >
        <Droppable droppableId="droppable" type="CUSTOM_GROUP">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getGroupListStyle(snapshot.isDraggingOver)}
            >
              {customFields.map((group_item, group_index) => (
                <Draggable
                  key={`${group_index}-${group_item.group_order}`}
                  draggableId={`${group_item.group_name}`}
                  index={group_index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <EachGroupItem
                        item={group_item}
                        index={group_index}
                        key={group_index}
                        provided_ref={provided}
                        expand_group_index={expandIndex}
                        handleExpandGroupIndex={(value) => {
                          setExpandIndex(value)
                        }}
                        handleUpdateData={handleUpdateData}
                        removeFieldFromList={removeFieldFromList}
                        groups={groups}
                        reloadData={loadCustomFieldData}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      }
      {customFields.length === 0 &&
        <div className='no__personal__custom__field__wrapper'>
          <span className='no__personal__custom__field'>You have no personal custom field</span>
        </div>
      }

      {(parentData.length > 0 || agencyData.length > 0) &&
        <div className='custom_field_parent__wrapper'>
          <span className='custom_field_parent__wrapper__title'>More custom field</span>
          <div className='custom_field_parent__wrapper__inner'>
            {parentData.length > 0 && renderParentCustomField()}
            {agencyData.length > 0 && renderAgencyCustomField()}
          </div>
        </div>
      }
      
    </div>
  )
}

const mapStateToProps = state => ({
  reloadCustomFieldFlag: state.profileReducer.reloadCustomFieldFlag
});

const mapDispatchToProps = dispatch => ({
  reloadCustomField: (params) => dispatch(reloadCustomField(params)),
  changeCustomFieldStatus: (params, callback) => dispatch(changeCustomFieldStatus(params, callback)),
})
export default connect(mapStateToProps, mapDispatchToProps)(CustomFieldDragDrop);

const EachGroupItem = ({
  item,
  index,
  provided_ref,
  expand_group_index,
  handleExpandGroupIndex,
  handleUpdateData,
  removeFieldFromList,
  groups,
  reloadData
}) => {
  const [expand, setExpand] = useState(false);
  const [groupName, setGroupName] = useState(item.group_name);
  const [isEdit, setIsEdit] = useState(false);

  const group_title_ref = useRef(null)

  useEffect(() => {
    if(expand){
      handleExpandGroupIndex(index)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expand])

  useEffect(() => {
    if(expand_group_index !== index){
      setExpand(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expand_group_index])

  const handleEditClick = () => {
    if(!isEdit){
      setIsEdit(true)
      setTimeout(() => {
        if(group_title_ref.current !== undefined){
          group_title_ref.current.focus()
        }
      }, 0);
    }else{
      setIsEdit(false)
      /* call api to update  */
      updateCustomFieldDataApi({
        'field_type' : 'group_name',
        'previous_group_name' : item.group_name,
        'new_group_name': groupName
      }).then(res => {
        let response = res.data;
        if(response.status === 'success'){
          handleUpdateData(groupName, index, 'group_name')
          try{
            let local_data = localStorage.getItem("global__custom__field__data");
            if(local_data){
                localStorage.removeItem("global__custom__field__data")
            }
        }
        catch(error){
            console.log(error)
        }
        }else{
          setGroupName(item.group_name)
        }
      }).catch(error => {
        
      })
    }
  }

  return(
    <div className='each_group_container'>
        {/* group */}
        <div className='custom_field_group_info'>
          <div className='custom_field_group_info__header'>
            <span className='expand_collapse_icon' onClick={() => setExpand(!expand)}>{expand ? <ExpandLessIcon fontSize='medium' style={{ color: '#546376'}}  /> : <ExpandMoreIcon fontSize='medium' style={{ color: '#546376'}}  />}</span>
            <div className='group_label_header'>
              <span className='group_label'>
                {isEdit ? 
                  <input className='custom__field__text__edit' placeholder='Enter the group title' ref={group_title_ref} onChange={(e) => setGroupName(e.target.value)} value={groupName} /> :
                  groupName
                }
              </span>
              {groupName.toLowerCase() !== 'default' &&
              <span className='group_edit_icon' onClick={handleEditClick}>{isEdit ? <CheckIcon fontSize='small' style={{ color: '#546376'}} /> : <EditIcon fontSize='small' style={{ color: '#546376'}} />}</span>
              }
              {isEdit &&
              <span className='group_edit_icon' onClick={() => {setIsEdit(false); setGroupName(item.group_name)}}><ClearIcon fontSize='small' style={{ color: '#546376'}} /></span>
              }
            </div>
          </div>
          <span className='group_drag_icon' {...provided_ref.dragHandleProps} ><OpenWithIcon fontSize='medium' style={{ color: '#546376'}} /></span>
        </div>
        {expand &&
          <CustomFieldItemList group_item={item} group_index={index} removeFieldFromList={removeFieldFromList} groups={groups} reloadData={reloadData} handleUpdateData={handleUpdateData}/>
        }
    </div>
  )
}

const CustomFieldItemList = ({
  group_item,
  group_index,
  removeFieldFromList,
  groups,
  reloadData,
  handleUpdateData
}) => {
  return(
    <div className='group_custom_field_container'>
      <Droppable droppableId={`droppable-${group_index}`} type={'CUSTOM_FIELD_'+group_item['group_name']}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          style={getFieldListStyle(snapshot.isDraggingOver)}
        >
          {group_item.fields.map((item, index) => {
            return (
              <Draggable
                key={`${group_index}${index}${item.id}`}
                draggableId={`${item.order}-${item.id}`}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <EachCustomFieldItem 
                      key={index}
                      index={index}
                      provided_ref={provided}
                      item={item}
                      removeFieldFromList={removeFieldFromList}
                      group_index={group_index}
                      groups={groups}
                      reloadData={reloadData}
                      handleUpdateData={handleUpdateData}
                    />
                  </div>
                )}
              </Draggable>
            );
          })}
          {provided.placeholder}
        </div>
      )}
      </Droppable>
    </div>
  )
}

const EachCustomFieldItem = ({
  item,
  index,
  provided_ref,
  removeFieldFromList,
  group_index,
  groups,
  reloadData,
  handleUpdateData
}) => {
  
  const [status, setStatus] = useState(item.active_status);
  const [visibleStatus, setVisibleStatus] = useState(item.visible_status);  
  const [showMenu, setShowMenu] = useState(false)
  const [isEdit, setIsEdit] = useState(''); //field_value
  const [editText, setEditText] = useState(item.label);

  const list_item_ref = useRef(null)
  const list_item_value_ref = useRef(null)

  const hanldeMenu = (value) => {
    setShowMenu(value)
  }

  const format = (data) => {
    let typeId = parseInt(data.type);
    if(typeId === TEXT)
        return 'text'
    else if(typeId === MULTILINE_TEXT)
        return 'text'
    else if(typeId === NUMERIC)
        return '0-9'
    else if(typeId === DATE)
        return 'date'
    else if(typeId === CHECKBOX)
        return 'checkbox'
    else if(typeId === SELECT)
        return 'select'
    else if(typeId === RADIO)
        return 'radio'
    else if(typeId === PHONE)
        return '0-9'
    else if(typeId === ZIP_CODE)
        return '0-9'
    else if(typeId === WEBSITE)
        return 'url'
  }

  const handleStatusChange = (status) => {
    confirmAlert({
      title: 'Are you sure?',
      message: 'Do you want to chagne this activity status of this field?',
      buttons: [
          {
              label: 'Yes',
              onClick: () => {
                changeCustomFieldStatusApi({fieldId : item.id, status: status}).then(res => {
                  let response = res.data
                  if (response.status === 'success') {
                    setStatus(status)
                    try{
                        let local_data = localStorage.getItem("global__custom__field__data");
                        if(local_data){
                            localStorage.removeItem("global__custom__field__data")
                        }
                    }
                    catch(error){
                        console.log(error)
                    }
                  } else if (response.status === 'error') {
                  }
                })
              }
          },
          {
              label: 'No',
              onClick: () => {return}
          }
      ]
    });
  }

  const deleteItem = () =>{
    confirmAlert({
      title: 'Do you want to delete this?',
      message: 'Are you sure to delete this custom field?',
      buttons: [
          {
              label: 'Yes',
              onClick: () => {
                deleteCustomFieldApi({fieldId : item.id}).then(res => {
                  let response = res.data;
                  if (response.status === 'success') {
                    removeFieldFromList(group_index, index)
                    try{
                        let local_data = localStorage.getItem("global__custom__field__data");
                        if(local_data){
                            localStorage.removeItem("global__custom__field__data")
                        }
                    }
                    catch(error){
                        console.log(error)
                    }
                  } else if (response.status === 'error') {
                  }
                })
              }
          },
          {
              label: 'No',
              onClick: () => {return}
          }
      ]
    });
  }

  const handleVisibleStatusChange = (visibleStatus) => {
    confirmAlert({
      title: 'Are you sure?',
      message: 'Do you want to chagne this visibility status of this field?',
      buttons: [
          {
              label: 'Yes',
              onClick: () => {
                updateCustomFieldDataApi({
                  fieldId : item.id, 
                  disable: visibleStatus, 
                  'field_type' : 'disable_custom_field',
                  'field_name': item.label
                }).then(res => {
                  let response = res.data
                  if (response.status === 'success') {
                    setVisibleStatus(visibleStatus)
                    try{
                        let local_data = localStorage.getItem("global__custom__field__data");
                        if(local_data){
                            localStorage.removeItem("global__custom__field__data")
                        }
                    }
                    catch(error){
                        console.log(error)
                    }
                  } else if (response.status === 'error') {
                  }
                })
              }
          },
          {
              label: 'No',
              onClick: () => {return}
          }
      ]
    });
  }

  const handleEditLabel = () => {
    if(isEdit === 'field_title'){
      if(editText.trim() === ''){
        window.showNotification('warning', 'Field title is required !');
        return
      }
    }
    else if(isEdit === 'field_tag'){
      if(editText.trim() === ''){
        window.showNotification('warning', 'Field tag is required !');
        return
      }
    }
    else if(isEdit === 'field_value'){
      if(editText.trim() === ''){
        window.showNotification('warning', 'Field value is required !');
        return
      }
    }
    
    updateCustomFieldDataApi({
      'fieldId' : item.id,
      'field_type' : isEdit,
      'value': editText
    }).then(res => {
      let response = res.data
      if (response.status === 'success') {
        handleUpdateData(editText, index, isEdit, group_index)
        setIsEdit('')
        try{
            let local_data = localStorage.getItem("global__custom__field__data");
            if(local_data){
                localStorage.removeItem("global__custom__field__data")
            }
        }
        catch(error){
            console.log(error)
        }
      } else if (response.status === 'error') {
        window.showNotification('warning', response.html);
      }
    })
  }

  return(
    <div className='each_custom_field_container'>
        {/* field header */}
        <div className='custom_field_header__wr'>
          <div className='custom_field_header__space__between'>
            <span className='custom_field_drag_icon' {...provided_ref.dragHandleProps}><OpenWithIcon fontSize='small' style={{ color: '#546376'}} /></span>
            <div className='field_title_info_header'>
              <span className='field_title_text'>
                {isEdit === 'field_title' ? 
                  <input className="custom__field__text__edit" placeholder='Enter custom field title' ref={list_item_ref} value={editText} onChange={(e) => setEditText(e.target.value)} />
                  :
                  item.label
                }
              </span>
              {/* <span className='field_owner'>  ({item.user_id === Utils.getAccountData('userId') ? 'Owner' : 'Not Owner'})</span> */}
            </div>
          </div>
          <div className='field_action_container__wr'>
            <span className='field_edit_icon'>
              {isEdit === 'field_title' ?
              <CheckIcon fontSize='small' onClick={handleEditLabel} style={{ color: '#546376'}} /> :
              <EditIcon 
                fontSize='small' 
                onClick={
                  () => {
                    setIsEdit('field_title'); 
                    setEditText(item.label);
                    setTimeout(() => {
                      if(list_item_ref.current !== undefined)
                        list_item_ref.current.focus();
                    }, 0);
                  }
                } 
                style={{ color: '#546376'}} 
              />
              }
            </span>
            {isEdit === 'field_title' &&
            <span className='field_edit_icon' onClick={() => {setIsEdit(''); setEditText('')}}>
              <ClearIcon fontSize='small' style={{ color: '#546376'}} />
            </span>
            }
            <span className='field_delete_icon' onClick={deleteItem}><DeleteIcon fontSize='small' style={{ color: '#546376'}} /></span>
            <span className='field_delete_icon'>
              {/* {moveIcon} */}
              <CustomFieldMove 
                  hanldeMenu={hanldeMenu}
                  showMenu={showMenu}
                  list={groups}
                  id={item.id}
                  previous_group_name={item.group_name}
                  callback={() => reloadData()}
                />
            </span>
            <div className='field_on_off'>
              <SwitchC status={status} brandColor={"#f94f72"} onChange={handleStatusChange}/>
            </div>
          </div>
        </div>

        {/* field description */}
        <div className='custom_field_description_container'>
          <div className='description_left_side'>
            <div className={`field_info ${ isEdit === 'field_tag' ? '' : 'field_info_ellipse'}`}>
              <span className='personalize__tag__custom__field'>Personalize Tag:
              {item.tag}
              </span>
            </div>
            <div className='field_info'><span>Maximum Character Allowed:</span> {item.max_char}</div>
            {item.value !== null &&
            <>
            <div className='field_info field__info__extra'>
              <span className='field__info__extra__container__flex'>
              <span>Value:</span> 
              {isEdit === 'field_value' ? 
                <div>
                  <input value={editText} placeholder='Enter value' ref={list_item_value_ref} onChange={(e) => setEditText(e.target.value)} />
                  <span className='field__info__extra__hints'>Enter value with comma (,) separator</span>
                </div>
                :
                Object.values(item.value).join(', ')
              }
              </span>
              <span className='field_edit_icon'>
              {isEdit === 'field_value' ?
              <CheckIcon fontSize='small' style={{ color: '#546376','font-size':'16px'}} onClick={handleEditLabel} /> :
              <EditIcon 
                style={{ 'color': '#546376','font-size':'16px','margin-top':'2px'}}  
                onClick={
                  () => {
                    setIsEdit('field_value'); 
                    setEditText(item.value)
                    setTimeout(() => {
                      if(list_item_value_ref.current !== undefined){
                        list_item_value_ref.current.focus()
                      }
                    }, 0);
                  }
                }
              />
              }
            </span>
            {isEdit === 'field_value' &&
            <span className='field_edit_icon' onClick={() => {setIsEdit(''); setEditText('')}}>
              <ClearIcon style={{ color: '#546376','font-size':'16px'}}  />
            </span>
            }
            </div>
            
            </>
            }
          </div>

          <div className='description_right_side'>
            <div className='field_info'><span>Type:</span> {ALL_FIELD_NAME[item.type.toString()]}</div>
            <div className='field_info'><span>Format:</span> {format(item)}</div>
            {/* <div className='field_info'>
              <span> Visible: </span>
              <SwitchC status={visibleStatus} brandColor={"#f94f72"} onChange={handleVisibleStatusChange}/>
            </div> */}
          </div>
        </div>
    </div>
  )
}

const ParentEachCustomFieldItem = ({
  index,
  item,
  updateParentData
}) => {
  const [visibleStatus, setVisibleStatus] = useState(item.user_disable_custom_fields === null);  

  const format = (data) => {
    let typeId = parseInt(data.type_id);
    if(typeId === TEXT)
        return 'text'
    else if(typeId === MULTILINE_TEXT)
        return 'text'
    else if(typeId === NUMERIC)
        return '0-9'
    else if(typeId === DATE)
        return 'date'
    else if(typeId === CHECKBOX)
        return 'checkbox'
    else if(typeId === SELECT)
        return 'select'
    else if(typeId === RADIO)
        return 'radio'
    else if(typeId === PHONE)
        return '0-9'
    else if(typeId === ZIP_CODE)
        return '0-9'
    else if(typeId === WEBSITE)
        return 'url'
  }

  const handleVisibleStatusChange = (visibleStatus) => {
    confirmAlert({
      title: 'Are you sure?',
      message: 'Do you want to chagne this visibility status of this field?',
      buttons: [
          {
              label: 'Yes',
              onClick: () => {
                updateCustomFieldDataApi({
                  fieldId : item.id, 
                  disable: visibleStatus, 
                  'field_type' : 'disable_custom_field',
                  'field_name': item.label
                }).then(res => {
                  let response = res.data
                  if (response.status === 'success') {
                    setVisibleStatus(visibleStatus)
                    try{
                        let local_data = localStorage.getItem("global__custom__field__data");
                        if(local_data){
                            localStorage.removeItem("global__custom__field__data")
                        }
                    }
                    catch(error){
                        console.log(error)
                    }
                  } else if (response.status === 'error') {
                  }
                })
              }
          },
          {
              label: 'No',
              onClick: () => {return}
          }
      ]
    });
  }

  return(
    <div className='each_custom_field_container'>
        {/* field header */}
        <div className='custom_field_header__wr'>
          <div className='custom_field_header__space__between'>
            <div className='field_title_info_header'>
              <span className='field_title_text'>
                {item.title}
              </span>
            </div>
          </div>
        </div>

        {/* field description */}
        <div className='custom_field_description_container'>
          <div className='description_left_side'>
            <div className={`field_info field_info_ellipse personalize__tag__title`}>
              <span>Personalize Tag:</span>
              {item.personalize_tag}
            </div>
            <div className='field_info'><span>Maximum Character Allowed:</span> {item.max_length}</div>
            {item.default_values !== null &&
            <>
            <div className='field_info'>
              <span>Value:</span> 
              {Object.values(item.default_values).join(', ')}
            </div>
            </>
            }
          </div>

          <div className='description_right_side'>
            <div className='field_info'><span>Type:</span> {ALL_FIELD_NAME[item.type_id.toString()]}</div>
            <div className='field_info'><span>Format:</span> {format(item)}</div>
            <div className='field_info'>
              <span> Visible: </span>
              <SwitchC status={visibleStatus} brandColor={"#f94f72"} onChange={handleVisibleStatusChange}/>
            </div>
          </div>
        </div>
    </div>
  )
}

// a little function to help us with reordering the result
export const Reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const getItemStyle = (isDragging, draggableStyle) => {
  return {
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    textAlign: "right",
    borderRadius: "6px",

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "#fff",

    // styles we need to apply on draggables
    ...draggableStyle
  };
};

export const getGroupListStyle = isDraggingOver => ({
  background: isDraggingOver ? "#ebecf0" : "#f0f1f3",
  padding: 12,
  width: '100%',
  borderRadius:6
});

export const getFieldListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightblue" : "#f0f1f3",
  padding: 8,
  borderRadius:6,
  width: "100%"
});