import React, { useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { Box, Button, TextareaAutosize } from "@material-ui/core";
import Styles from "../PaymentGateway.module.css";
import InvoiceUseStyles from "../invoiceUseStyles";
import { addNewTaxInvoice, editTaxInvoice } from "../../../api/InvoiceApi";

const AddOrEditTax = ({ isEdit, onClose, taxInfo, onRenderCallback }) => {
  const classes = InvoiceUseStyles();
  const [, setForceUpdate] = useState(false);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    taxPercentage: "",
  });

  const simpleValidator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: setForceUpdate },
      className: "small text-danger mdi mdi-alert pt-1 pl-1",
    })
  );

  useEffect(() => {
    if (taxInfo?.id) {
      setFormData({ ...formData, ...taxInfo });
    }

    return () => setFormData({})
  }, []);

  const validateTaxPercentage = (value) => {
    // Regular expression to allow up to two digits with up to two decimal places
    if(value > 9999.99) return false;
    const regex = /^\d*(\.\d{0,2})?$/;
  
    // Use the test method to check if the value matches the regex
    return regex.test(value);
  };
  
  // Usage in your handleChangeInput function
  const handleChangeInput = (name, value) => {
    if (name === "taxPercentage" && !validateTaxPercentage(value)) {
      // Display an error message or handle the invalid input
      // For example, you can set an error state or show a message to the user
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = () => {
    if (simpleValidator.current.allValid()) {
      try {
        setLoading(true);
        const requestBody = {
          id: taxInfo?.id,
          name: formData.name.replace(/\s+/g, ' ').trim(),
          description: formData.description,
          taxPercentage: formData.taxPercentage,
        };

        if (isEdit) {
          // requestBody.id = taxInfo?.id;

          editTaxInvoice(requestBody)
            .then((res) => {
              res = res.data;
              if (res.success) {
                onRenderCallback();
                window.showNotification("SUCCESS", res.message);
                onClose();
              } else {
                window.showNotification("ERROR", res.message);
              }
            })
            .catch((error) => {
              console.log(error)
              window.showNotification(
                "ERROR",
                `Error occurred while creating product - ${error.message}`,
               
              );
            })
            .finally(() => setLoading(false));

          window.showNotification("SUCCESS", "Tax Item Updated");
          onClose();
        } else {
          if (requestBody) {
            addNewTaxInvoice(requestBody)
              .then((res) => {
                res = res.data;
                if (res.success) {
                  window.showNotification("SUCCESS", res.message);
                  onRenderCallback();
                  onClose();
                } else {
                  window.showNotification("ERROR", res.message);
                }
              })
              .catch((error) => {
                window.showNotification(
                  "ERROR",
                  `${error.response.data.message}`
                );
              })
              .finally(() => setLoading(false));
            window.showNotification("SUCCESS", "Tax Added");
            onClose();
          } else {
            window.showNotification("ERROR", "res.message");
            onClose();
          }
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    } else {
      simpleValidator.current.showMessages();
      setForceUpdate(true);
    }
  };

  return (
    <Box sx={{ width: 500 }}>
      <Box
        sx={{ display: "flex", flexDirection: "column", gap: 20 /* p: 2 */ }}
      >
        <Box>
          <p className={Styles.label_text}>Tax Name *</p>
          <input
            type="text"
            placeholder="Ex. Michigan Sales Tax"
            className={classes.itemInput}
            name="name"
            value={formData.name}
            onChange={(e) => {
              handleChangeInput(e.target.name, e.target.value);
            }}
          />

          {simpleValidator.current.message(
            "Tax name",
            formData.name,
            "required"
          )}
        </Box>

        <Box>
          <p className={Styles.label_text}>Enter your tax rate % *</p>
          <input
            type="number"
            placeholder=""
            className={classes.itemInput}
            name="taxPercentage"
            value={formData.taxPercentage}
            min={0}
            onChange={(e) => {
              handleChangeInput(e.target.name, e.target.value);
            }}

          />

          {simpleValidator.current.message(
            "Tax rate",
            formData.taxPercentage,
            "required"
          )}
        </Box>

        <Box>
          <p className={Styles.label_text}>Description</p>
          <TextareaAutosize
            className={classes.textArea}
            fullWidth
            name="description"
            minRows={4}
            maxRows={5}
            maxLength={250}
            aria-label="maximum height"
            placeholder=""
            value={formData.description}
            onChange={(e) => {
              handleChangeInput(e.target.name, e.target.value);
            }}
          />
        </Box>

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            // disabled={loading}
            variant="contained"
            style={{
              backgroundColor: "#3C7EF3",
              color: "white",
              width: "115px",
              fontSize: "16px",
            }}
            disabled={loading}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AddOrEditTax;
