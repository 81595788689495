import { sendRequest } from './rootApi';
import Api from "../constants/Api";

export const addLeadSourceApi = async (payload) => {
    return await sendRequest("post", Api.addLeadSource,payload);
}

export const editLeadSourceApi = async (payload) => {
    return await sendRequest("post", Api.editLeadSource,payload);
}

export const deleteLeadSourceApi = async (payload) => {
    return await sendRequest("post", Api.deleteLeadSource,payload);
}

export const getLeadSourceByPaginationApi = async (payload) => {
    return await sendRequest("post", Api.getLeadSourceByPagination,payload);
}

export const getAllLeadSourceApi = async (payload) => {
    return await sendRequest("post", Api.getAllLeadSource,payload);
}

export const deleteLeadSourceWithoutTransferApi = async (payload) => {
    return await sendRequest("post", Api.deleteLeadSourceWithoutTransfer,payload);
}

